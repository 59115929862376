import EStyleSheet from 'react-native-extended-stylesheet';
import {Colors} from "../foundation/styles";

const styles = EStyleSheet.create({

  classMainContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  blockHeader: {
    flexDirection: 'column',
    borderBottomWidth: 2,
    borderColor: Colors.border,
    justifyContent: 'center',
    height: 72,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 25
  },
  classListWrapper: {
    flex: 0.3,
    borderRightWidth: 10,
    borderColor: Colors.border
  },
  classContentWrapper:{
    flex: 1,
  },
  classListItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    height: 50,
  },
  classListItemText:{
    color: '#ffffff',
    fontSize: 14,
    fontWeight: '200',
  },
  classListItemSelected:{
    backgroundColor: Colors.primaryColor
  },
  addRemoveWrapper: {
    backgroundColor: '#272727',
    marginTop: -15,
    marginRight: -15,
    marginBottom: -15,
    marginLeft: -15,
  },
  addBtn: {
    alignSelf: 'flex-end'
  },
  addRemoveBtn: {
    backgroundColor: Colors.primaryColor,
    // width: 40,
    // height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 25,
    paddingRight: 25,
    marginRight: 5,
    marginLeft: 5
  },
  addRemoveBtnDisabled: {
    backgroundColor: '#3a3a3a'
  },
  addRemoveBtnLabel: {
    color: '#f1f1f1',
  },
  addRemoveBtnLabelDisabled: {
    color: '#797979',
  },
  addUsersHeader: {
    justifyContent: 'space-between',
    height: 56,
    marginTop: 15,
    marginBottom: 12,
    marginRight: 15,
    marginLeft: 15
  },
  classNameHeader: {
    width: '65%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  classStatsBlock: {
    paddingLeft: 15,
    marginTop: -20,
    paddingBottom: 10,
    marginBottom: 10,
    justifyContent: 'flex-start',
    borderColor: Colors.border,
    borderBottomWidth: 2,

  },
  classToolbar: {
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15
  },
  classSearchW: {
    alignItems: 'center',
    marginBottom: 20,
  },
  searchTextInput: {
    minWidth: 250,
    maxWidth: 300,
    backgroundColor: '#272727',
    color: '#ffffff',
    borderRadius: 30,
    paddingTop: 15,
    paddingRight: 12,
    paddingBottom: 15,
    paddingLeft: 12,
    marginRight: 0
  },
  classGraphW: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    marginLeft: 20,
    marginBottom: 20
  },
  indicatorBlock: {
    flexBasis: '13%',
    borderLeftWidth: 1,
    borderLeftColor: '#454545',
    paddingTop: 5,
    paddingLeft: 5,
    paddingBottom: 5,
    paddingRight: 5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  // stats for user styles
  questionsBlock: {

    marginRight: 10,
    // paddingRight: 20
  },
  totalColor: {
    color: '#888888',
    fontWeight: '500'
  },
  completedColor: {
    color: Colors.correct,
    fontWeight: '500'
  },
  errorColor: {
    color: Colors.wrong,
    fontWeight: '500'
  },
  quizGraphWrapper:{
    flex: 1,
    // justifyContent: 'center',
    paddingLeft: 30,
  },
  lessonTable: {
    width: '100%',
    marginTop: 25,
    marginBottom: 25
  },
  lessonRow:{
    flexDirection: 'row',
  },
  rowHeader: {
    fontSize: 20,
    fontWeight: '600',
    paddingBottom: 8
  },
  headerRight: {
    textAlign: 'right'
  },
  lessonCol1: {
    flexBasis: '40%'
  },
  lessonCol2: {
    flexBasis: '20%',
    paddingRight: 3,
    marginRight: 3,
    borderRightColor: '#ffffff',
    borderRightWidth: 1

  },
  lessonCol3: {
    flexBasis: '20%',
    paddingRight: 3,
    marginRight: 3,
    borderRightColor: '#ffffff',
    borderRightWidth: 1
  },
  lessonCol4: {
    flexBasis: '20%',
    paddingRight: 3,
    // marginRight: 3,
    // borderRightColor: '#ffffff',
    // borderRightWidth: 1
  },
  lessonName: {
    fontWeight: '300',
    paddingBottom: 4
  },
  statData: {
    fontWeight: '500',
    textAlign: 'right',
    paddingBottom: 4
  },
  chooserWrapper: {
    height: 'auto',
    width: '100%',
    flexDirection: 'column',
    marginTop: 0,
    marginBottom: 30,
    paddingRight: 20,
  },
  listItem: {
    backgroundColor: '#393939',
    borderRadius: 40,
    height: 40,
    paddingLeft: 15,
    paddingRight: 30,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  listItemName: {
    // textTransform: 'uppercase',
    fontSize: 12,
    marginLeft: 15,
    color: '#ffffff',
  },
  listItemPreset : {
    backgroundColor: Colors.primaryColor,
  },
  addRemoveUsersView: {
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  searchFilter: {
    // borderBottomColor: Colors.border,
    // borderBottomWidth: 2,
    paddingBottom: 23,
    marginBottom: 10
  },
  searchFilterDarker: {
    backgroundColor: '#1f1f1f',
    marginLeft: 10
  },

  // Detail Stat
  statContainer: {
    flex: 1,
    marginTop: 20
  },
  noStatMessage: {
    textAlign: 'center',
    fontSize: 16,
    color: Colors.textLight
  }

});

export default styles;
