import React from 'react';
import { View, Image, ScrollView, Switch, Text, TextInput, TouchableOpacity, Picker, Animated, ActivityIndicator} from 'react-native';
import {actionStyles, Colors, styles} from "../foundation/styles";
import prospectStyles from './styles';
import {getFormattedDate, isWeb} from "../commons/utils";
import {ActionBlock, EgafSectionList, EgafSelectionCheck, FiltersButton} from "../foundation/widgets";
import axios from "../commons/axios";
import UserContext from "../user";
import {reaction} from "mobx";
import ConfigStyles from "../section_configuration/styles";
import classStyles from "../section_classes/styles";
import errorManager from "../commons/error_manager";


export default class ProspectsHome extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.sectionHeaders = [
      {name: 'Iscrizione', prop: 'DataIscrizione'},
      {name: 'Nome', prop: 'Nome'},
      {name: 'Cognome', prop: 'Cognome'},
      {name: 'Classe', prop: 'classe'},
      {name: 'Corso', prop: 'corso'},
      {name: 'Location', prop: 'RAGSOCIALE'},
      {name: 'Stato', prop: 'STATO'}
    ];

    this.unfilteredListItems = [];

    this.excludedFieldsOnActionEnabled = ['RAGSOCIALE', ];

    this.advFilterLocationValues= [];
    // this.advFilterCourseValues = [];
    this.advFilterClassValues = [];

    this.filterPanelAnimation = new Animated.Value(0);

    // Store a reference to contact used in "Add to Class"
    this.contactToAdd = null;

    this.state = {

      //initial loading
      loading: true,
      listItems: [],

      selectedUsers: [],
      action: null,
      isActionEnabled: false,
      showingUserDetailId: null,

      // State depending on outer button, to execute action on child component
      hasConfirmed: false,
      hasRejected: false,

      // Filters stuff
      openFilters: false,
      filters: [],
      // filterName: '',

      //FastFilters
      topStatusFilters: [
        // {key: 'potenziali', value: false},
        // {key: 'interessati', value: false},
      ],
      topCourseFilters: [
      ],

      // State that shows if there are active filters (inside panels)
      panelFiltersActive: false,
    }
  }

  createTopStatusFilters() {
    const topFiltersValues = new Set();
    for (const student of this.unfilteredListItems) {
      topFiltersValues.add(student.STATO);
    }
    let topStatusFilters = [];
    for (const value of topFiltersValues){
      topStatusFilters.push({
        key: value,
        value: false
      })
    }
    return topStatusFilters;
  }

  createTopCourseFilters() {
    const topLocationFiltersValues = new Set();
    for (const student of this.unfilteredListItems) {
      topLocationFiltersValues.add(student.corso);
    }
    let topCourseFilters = [];
    for (const value of topLocationFiltersValues){
      topCourseFilters.push({
        key: value,
        value: false
      })
    }
    return topCourseFilters;
  }

  createAdvancedFilters() {
    const advFilterLocationSet = new Set();
    const advFilterLocationValues = [];

    const advFilterClassSet = new Set();
    const advFilterClassValues = [];
    for (const student of this.unfilteredListItems) {

      if (!advFilterLocationSet.has(student.COD_INTERL)){
        advFilterLocationSet.add(student.COD_INTERL);
        advFilterLocationValues.push({
          value: student.COD_INTERL,
          label: student.RAGSOCIALE
        });
      }

      if (!advFilterClassSet.has(student.idclasse)){
        advFilterClassSet.add(student.idclasse);
        advFilterClassValues.push({
          value: student.idclasse,
          label: student.classe
        });
      }
    }
    this.advFilterLocationValues = advFilterLocationValues;

    // this.advFilterCourseValues = advFilterCourseValues;
    this.advFilterClassValues = advFilterClassValues;
  }

  async loadStudentsList() {

    const studentsResp = await axios.get("students/getallievioperatore/", {
      params: {
        idutente: this.context.currentCloud.idutente,
        idnuvola: this.context.currentCloud.idnuvola,
        opera: this.context.currentCourse.opera
      }
    });
    this.unfilteredListItems = studentsResp.data;

    // console.info('ALL Prospects: ', studentsResp.data);

    // Extracting advanced filters values from unfiltered results
    this.createAdvancedFilters();

    this.setState({
      loading: false,
      listItems: this.unfilteredListItems,
      topStatusFilters: this.createTopStatusFilters(),
      topCourseFilters: this.createTopCourseFilters()
    });
  }

  filterStudents(payload) {
    const filter = payload.action.params? payload.action.params.filter : null;
    if (filter === 'POTENZIALE') {
      // this.updateStatusTopFilters(true, 2);
      const topFilters = [
        {key: "ISCRITTO", value: false},
        {key: "PROMOSSO", value: false},
        {key: "POTENZIALE", value: true}
      ];
      this._applyAllFilters(topFilters, null, []);
    }
    if (filter === 'ISCRITTO') {
      // this.updateStatusTopFilters(true, 0);
      const topFilters = [
        {key: "ISCRITTO", value: true},
        {key: "PROMOSSO", value: false},
        {key: "POTENZIALE", value: false}
      ];
      this._applyAllFilters(topFilters, null, []);
    }
  }

  componentDidMount() {
    this.loadStudentsList();

    this._prospectsReactionOnLocationDispose = reaction(
      () => this.context.currentLocation,
      () => {
        this.loadStudentsList();
      }
    );

    this._prospectsReactionOnCourseDispose = reaction(
      () => this.context.currentCourse,
      () => {
        this.loadStudentsList();
      }
    );

    // Add onFocus listener for filtering students (when navigating from dashboard)
    this.focusListener = this.props.navigation.addListener('didFocus', (payload)=>this.filterStudents(payload));
  }

  componentWillUnmount() {
    this._prospectsReactionOnLocationDispose();
    this._prospectsReactionOnCourseDispose();
    this.focusListener.remove();
  }

  async refresh(IdAllievo=null) {
    await this.loadStudentsList();

    // If idallievo is set, update also the student detail
    if (IdAllievo) {
      let refreshedAction = Object.assign({}, this.state.action);
      refreshedAction.params.contact = this.unfilteredListItems.find((student) => student.IdAllievo === IdAllievo);
      // console.info('refreshed action', refreshedAction);
      this.openActionBox(refreshedAction);
    }

  }

  // ACTION BLOCK METHODS
  openActionBox(action=null) {
    this.setState({
      action: action,
      isActionEnabled: true
    });
  }

  _prepareForAction() {
    let component = null;
    if (this.state.action){
      switch (this.state.action.name) {
        case 'contactdetail':
          component = <ContactDetail
            contact={this.state.action.params.contact}
            animated={this.state.action.params.animated}
            hasConfirmed={this.state.hasConfirmed}
            goBack={() => this.actionCompleted()}
            refresh={(IdAllievo)=>this.refresh(IdAllievo)}
            addToClass={(contact)=>this.addToClass(contact)}
          />;
          break;

        case 'addtoclass':
          component = <AddToClass
            contact={this.state.action.params.contact}
            hasConfirmed={this.state.hasConfirmed}
            hasRejected={this.state.hasRejected}
            userAdded={()=>this.onReject()}
            refresh={()=>this.refresh()}
            sourceClassId={this.state.action.params.contact.idclasse}
            closeAddClassPanel={()=>this.closeAddClassPanel()}
          />;
          break;

        default:
          break;
      }
      // Change detail component

    }


    return component;
  }

  // Function called only to close "add to class" panel programmatically
  closeAddClassPanel() {
    setTimeout(() => {
      this.showContactDetail(this.contactToAdd, true);
      // Resetting hasrejected or panel will not close after the first time
      this.setState({
        hasConfirmed: false,
        hasRejected: false,
      })

    }, 200);
  }

  onConfirm() {
    // Toggle Action Box
    this.setState({
      hasConfirmed: true,
      hasRejected: false,
    })
  }

  onReject() {

    if (this.state.action.name === 'addtoclass'){
      // const contactToAdd = this.contactToAdd;
      // this.contactToAdd = null;
      // this.showContactDetail(contactToAdd);
      this.setState({
        hasRejected: true,
      })


    } else {
      // Reject as usual
      this.setState({
        action: null,

        isActionEnabled: false,
        showingUserDetailId: null
      });
    }
  }

  onClose() {
    // Close all, even if add to class panel is open
    this.setState({
      action: null,
      isActionEnabled: false,
      hasRejected: false,
      showingUserDetailId: null
    });
  }

  actionCompleted() {
    // this.openActionBox();
    this.setState({
      hasConfirmed: false,
      isActionEnabled: false,
      showingUserDetailId: null
    })
  }

  toggleFilters() {

    if (!this.state.openFilters){
      Animated.sequence([
        Animated.timing(this.filterPanelAnimation, {
          toValue: 100,
          duration: 750,
          useNativeDriver: false
        })
      ]).start(() => {

      });
    } else {
      this.filterPanelAnimation = new Animated.Value(0);
    }

    this.setState({
      openFilters: !this.state.openFilters
    });

  }

  // Filters stuff

  //  TOP FILTERS
  updateStatusTopFilters(newValue, index) {
    let topStatusFilters = Array.from(this.state.topStatusFilters);
    topStatusFilters[index].value = newValue;

    this._applyAllFilters(topStatusFilters, null, null)
  }

  updateCourseTopFilters(newValue, index) {
    let topCourseFilters = Array.from(this.state.topCourseFilters);
    topCourseFilters[index].value = newValue;

    this._applyAllFilters(null, topCourseFilters, null);
  }

  // ADV FILTERS LOGIC
  getCurrentFilter(filter) {
   const currentFilter = this.state.filters.find((filterType)=>filterType.filter === filter);
   return currentFilter ? currentFilter.value : '';
  }

  _updateFilters(filter, value, filterType) {
    //If filters is empty add it, otherwise Loop through all filters and update or delete
    let oldFilters = this.state.filters;

    // If filter is null, return untouched filters
    if (!filter){
      return this.state.filters
    }

    if (value) {
      // Update or add it
      let updated = false;
      for (const filterObject of oldFilters) {
        // Check if current filterType is the right one
        if (filterObject.filter === filter){
          filterObject.value = value;
          updated = true;
        }
      }
      if (!updated){
        oldFilters.push({
          filter,
          value,
          filterType
        });
      }

    } else {
      // Delete
      for (const [index, filterObject] of oldFilters.entries()) {
        // Check if current filterType is the right one
        if (filterObject.filter === filter){
          oldFilters.splice(index, 1);
        }
      }
    }

    return oldFilters;
  }

  filterByCriteria(filter, value, filterType) {
    // elaborating filters
    const currentFilters = this._updateFilters(filter, value, filterType);
    this._applyAllFilters(null, null, currentFilters);
  }

  // Main filtering funcitons (to be rafctored in th future, many changes to filters were made
  _applyAllFilters(statusFilters=null, locationFilters=null, advFilters=null) {
    console.info('_applyAllFilters', statusFilters, locationFilters, advFilters);
    let items = Array.from(this.unfilteredListItems);

    // Set variable to track if there are active filters (inside panel)
    let panelFiltersActive = false;

    // Preparing filters
    let topStatusFilters = statusFilters? statusFilters : this.state.topStatusFilters;
    let topCourseFilters = locationFilters? locationFilters : this.state.topCourseFilters;
    const activeStatusFiltersValues = [];
    for (const topFilter of topStatusFilters) {
      if (topFilter.value) {
        activeStatusFiltersValues.push(topFilter.key);
      }
    };
    const activeLocationFiltersValues = [];
    for (const topFilter of topCourseFilters) {
      if (topFilter.value) {
        activeLocationFiltersValues.push(topFilter.key);
        // Filter button activated by course filter
        panelFiltersActive = true;
      }
    };

    let advancedFilters = advFilters? advFilters : this.state.filters;

    // checking filter to enable panel filter buttons
    if (advancedFilters && advancedFilters.length) {
      // Filter button activated by location or class filter
      panelFiltersActive = true;
      if (advancedFilters.length === 1 && advancedFilters[0].filter === 'textString'){
        panelFiltersActive = false;
      }
    }

    // Filtering logic
    // Better using for of loop with continue statement for performance
    let filteredItems = [];
    for (const item of items) {
      let passedFiltering = true;

      // First Status Filters
      if (activeStatusFiltersValues.length && !activeStatusFiltersValues.includes(item.STATO)) {
        passedFiltering = false;
        continue;
      }

      // Then Location Filters
      if (activeLocationFiltersValues.length && !activeLocationFiltersValues.includes(item.corso)) {
        passedFiltering = false;
        continue;
      }

      // Then advancedFilters
      if (advancedFilters.length) {
        for (const filterObject of advancedFilters) {
          if (filterObject.filterType === 'textString') {
            // Handling here filter by string
            if (!item.Nome.toLowerCase().startsWith(filterObject.value.toLowerCase()) && !item.Cognome.toLowerCase().startsWith(filterObject.value.toLowerCase())) {
              passedFiltering = false;
            }

          } else if (filterObject.filterType === 'date') {
            // Handling here filter by date
          } else {
            // handling here filter by prop (select)
            // Handle case with value as 0, indicating value not specified
            if (parseInt(filterObject.value)){
              if (parseInt(item[filterObject.filter]) !== parseInt(filterObject.value)) {
                passedFiltering = false;
              }
            } else {
              if (item[filterObject.filter]){ // Check if not null
                passedFiltering = false;
              }
            }
          }
        }
      }

      if (passedFiltering){
        filteredItems.push(item);
      }
    }

    // console.info('FILTERED Prospects: ', filteredItems);

    // Filtering logic
    this.setState({
      listItems: filteredItems,
      topStatusFilters,
      topCourseFilters,
      filters: advancedFilters,
      panelFiltersActive: panelFiltersActive
    });

  }

  removeFilters() {
    // Resetting Top Filters
    const resettedTopStatusFilters = Array.from(this.state.topStatusFilters);
    const resettedTopLocationFilters = Array.from(this.state.topCourseFilters);
    resettedTopStatusFilters.map((statusFilter) => {
      statusFilter.value = false;
      return statusFilter;
    });
    resettedTopLocationFilters.map((locationFilter) => {
      locationFilter.value = false;
      return locationFilter;
    });
    this.setState({
      listItems: Array.from(this.unfilteredListItems),
      filters: [],
      topStatusFilters: resettedTopStatusFilters,
      topCourseFilters: resettedTopLocationFilters,
      panelFiltersActive: false
    })
  }

  // Rendering Logic
  render() {

    if (this.context.isAllowedUser){
      // User is allowed to see this section
      if (this.state.loading){
        return(
          <View style={[styles.sectionContainer, styles.activityIndicatorContainer]}>
            <ActivityIndicator size={'large'}/>
          </View>
        )
      } else {
        return (
          <View style={styles.sectionContainer}>

            { isWeb ?
              <ActionBlock
                key="prospects"
                renderMasterHeader={()=>this.renderMasterHeader()}
                renderMasterBody={()=>this.renderMasterBody()}
                prepareForAction={()=>this._prepareForAction()}
                onConfirm={()=>this.onConfirm()}
                onReject={()=>this.onReject()}
                onClose={()=>this.onClose()}
                isActionEnabled={this.state.isActionEnabled}
                cancelBtnLabel={'Annulla'}
                confirmBtnLabel={'Conferma'}
              />
              :
              <EgafSectionList
                key={"native_prospect_list"}
                headers={this.sectionHeaders}
                dataForListing={this.state.listItems}
                onPressRow={(contact)=>this.showContactDetail(contact)}
                isActionEnabled={this.state.isActionEnabled}
                hideFields={this.excludedFieldsOnActionEnabled}
                refID={'Id'}
              />
            }

          </View>
        );
      }
    } else {
      // User is NOT allowed to see this section
      return null;
    }
  }

  _createStatusTopFilters() {
    let filters = [];
    for (const [index, filter ] of this.state.topStatusFilters.entries()) {
      filters.push(
        <View
          key={index}
          style={[styles.rowContainer, index === this.state.topStatusFilters.length -1 ? null : prospectStyles.topFilterSeparator]}
        >
          <Text style={[prospectStyles.topFilterLabel, ]}>
            {filter.key.toUpperCase()}
          </Text>
          <Switch
            // style={{backgroundColor: '#ccefef'}}
            value={filter.value}
            onValueChange={(newValue) => {
              this.updateStatusTopFilters(newValue, index)
            }}
            thumbColor={Colors.textLight}
            activeThumbColor={Colors.textLight}
            activeTrackColor={Colors.primaryColor}
            trackColor={Colors.border}
          />
        </View>
      );
    }
    return filters;
  }

  _createLocationTopFilters() {
    let filters = [];
    for (const [index, filter ] of this.state.topCourseFilters.entries()) {

      filters.push(
        <View
          key={index}
          style={[styles.rowContainer, prospectStyles.courseFilterItem]}
        >
          <Text style={[prospectStyles.topFilterLabel, {marginLeft: 7}]}>
            {filter.key.toUpperCase()}
          </Text>
          <Switch
            // style={{backgroundColor: '#ccefef'}}
            value={filter.value}
            onValueChange={(newValue) => {
              this.updateCourseTopFilters(newValue, index)
            }}
            thumbColor={Colors.textLight}
            activeThumbColor={Colors.textLight}
            activeTrackColor={Colors.primaryColor}
            trackColor={Colors.border}
          />

        </View>
      );
    }
    return filters;
  }

  renderTopFilters() {
    return(
      <View style={[prospectStyles.topFiltersMainWrapper, ]}>
        <View style={prospectStyles.topFiltersWrapper}>
          <View style={[styles.rowContainer, prospectStyles.topFilter] }>
            <Text style={prospectStyles.topFilterMainLabel}>STATO:</Text>
            {
              this._createStatusTopFilters()
            }
          </View>
        </View>

      </View>
    )
  }

  renderMasterHeader() {
    return(
      <View style={[actionStyles.actionMasterInnerHeader, {marginBottom: 12, flexDirection: 'column'}, ]}>
        <View style={[styles.rowContainer, styles.alignMiddle, ]}>
          <View style={[styles.rowContainer, {justifyContent: 'space-between'}, {width: '100%'}]}>

            <TextInput
              style={prospectStyles.searchTextInput}
              placeholder={'Cerca per Nome/Cognome'}
              placeholderTextColor={'#797979'}
              onChangeText={(text) => this.filterByCriteria('textString', text, 'textString',)}
              value={this.getCurrentFilter('textString')}
            />

            {this.renderTopFilters()}

            <FiltersButton
              active={this.state.panelFiltersActive}
              toggleFilters={()=>this.toggleFilters()}
            />

          </View>
        </View>
        <Animated.View style={
          {opacity: this.filterPanelAnimation.interpolate({
              inputRange: [0, 100],
              outputRange: [0, 1],
            }),

          }
        }>
          <View style={this.state.openFilters? actionStyles.filtersOpen : actionStyles.filtersClosed}>
            <Text style={[styles.title1, styles.textLight]}>Filtri avanzati</Text>

            <View style={prospectStyles.topCourseFiltersWrapper}>
                <Text style={[prospectStyles.topFilterMainLabel, {marginBottom: 10}]}>CORSO:</Text>
                  <View style={prospectStyles.topCourseFiltersItems}>
                    {
                      this._createLocationTopFilters()
                    }
                  </View>
            </View>

            <View style={[styles.rowContainer, prospectStyles.advFiltersWrapper, ]}>
              {
                this.advFilterLocationValues.length > 1 ?
                  <View style={[styles.selectWrapper, prospectStyles.filterWrapper]}>
                    <Picker
                      selectedValue={this.getCurrentFilter('COD_INTERL')}
                      style={styles.select}
                      onValueChange={(itemValue, itemIndex) =>
                        this.filterByCriteria('COD_INTERL', itemValue, 'select') // there will be also date type filter
                      }>
                      <Picker.Item key={0} label={'Filtra per Location'} value={''} />
                      {
                        this.advFilterLocationValues.map((filter, index) => {
                          return <Picker.Item key={index} label={filter.label? filter.label : 'Non settato'} value={filter.value} />
                        })
                      }
                    </Picker>
                  </View>
                  :
                  null
              }

              {
                this.advFilterClassValues.length > 1 ?
                  <View style={[styles.selectWrapper, prospectStyles.filterWrapper]}>
                    <Picker
                      selectedValue={this.getCurrentFilter('idclasse')}
                      style={styles.select}
                      onValueChange={(itemValue, itemIndex) =>
                        this.filterByCriteria('idclasse', itemValue, 'select') // there will be also date type filter
                      }>
                      <Picker.Item key={0} label={'Filtra per Classe'} value={''} />
                      {
                        this.advFilterClassValues.map((filter, index) => {
                          return <Picker.Item key={index} label={filter.label? filter.label : 'Non settata'} value={filter.value? filter.value : '0'} />
                        })
                      }
                    </Picker>
                  </View>
                  :
                  null
              }

              <View style={prospectStyles.removeFiltersW}>
                <TouchableOpacity
                  onPress={()=>this.removeFilters()}
                  style={prospectStyles.removeFiltersBtn}
                >
                  <Text style={styles.textLight}>Cancella Filtri</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Animated.View>
      </View>
    )
  }

  renderMasterBody() {
    return(
      <View style={styles.container}>
        <EgafSectionList
          key={"web_prospects_list"}
          headers={this.sectionHeaders}
          dataForListing={this.state.listItems}
          onPressRow={(contact)=>this.showContactDetail(contact)}
          selectedRowItemId={this.state.showingUserDetailId}
          isActionEnabled={this.state.isActionEnabled}
          hideFields={this.excludedFieldsOnActionEnabled}
          refID={'IdAllievo'}
        />
      </View>
    )
  }

  // Specific Class methods
  // Web only method, specify action
  showContactDetail(contact, animated=false) {
    const action = {
      name: 'contactdetail',
      params: {
        contact: contact,
        animated: animated
      }
    };
    this.setState({
      showingUserDetailId: contact.IdAllievo
    });
    this.openActionBox(action);
  }

  // Add to class logic
  addToClass(contact) {
    // console.info('addToClass() for contact', contact);
    this.contactToAdd = contact;
    const action = {
      name: 'addtoclass',
      params: {
        contact: contact,
      }
    };
    // this.setState({
    //   showingUserDetailId: contact.IdAllievo
    // });
    this.openActionBox(action);
  }

}


class ContactDetail extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Animation props (only if required by prop)
    this.opacity = props.animated? new Animated.Value(0) : new Animated.Value(1);
    this.translateRight = props.animated? new Animated.Value(60) : new Animated.Value(0);

    this.state = {
      loading: true,
      contactDetails: null,

      promotingToggle: this.setupPromotingToggle()
    }
  }

  setupPromotingToggle() {
    return {
      showButton: this.props.contact.DataPromozione || this.props.contact.STATO === "ISCRITTO",
      loading: false,
      sent: false,
      sentWithError: false,
      toggleAction: this.props.contact.DataPromozione? 'revoking' : 'promoting'
    }
  }

  async loadStats() {
    const response = await axios.get();
    const data = response.data.results[0];
    //Adapting randomuserresp to egaf endpoint props
    const detail = {
      name: data.name.first,
      surname: data.name.last,
      email: data.email,
      image: data.picture.thumbnail,
      stats: {
        totalCompleted: 184,
        totalwrong: 34,
        details: [
          {
            lesson: 'Segnali stradali',
            total: 20,
            completed: 15,
            wrong: 4
          },
          {
            lesson: 'Segnali orizzontali',
            total: 30,
            completed: 12,
            wrong: 8
          },
          {
            lesson: 'Segnali luminosi',
            total: 40,
            completed: 10,
            wrong: 2
          },
          {
            lesson: 'Segnali complementari',
            total: 30,
            completed: 19,
            wrong: 4
          },
          {
            lesson: 'Velocità e limiti',
            total: 30,
            completed: 15,
            wrong: 5
          },

        ]
      }
    }

    this.setState({
      userData: detail
    })
  }

  componentDidMount() {
    // this.loadStats();
    // console.info('passed student', this.props.contact);
    this.setState({
      loading: false,
      contactDetails: this.props.contact
    });
    if (this.props.animated) {
      this.mountingAnimation();
    }
  }

  mountingAnimation() {
    Animated.parallel([
      Animated.timing(this.opacity, {
        toValue: 1,
        duration: 250,
        useNativeDriver: false
      }),
      Animated.timing(this.translateRight, {
        toValue: 0,
        duration: 250,
        useNativeDriver: false
      })
    ]).start();
  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.hasConfirmed  && !prevProps.hasConfirmed){
      // console.log('componentDidUpdate.... executeAction');
      this.executeAction();
    }

    // console.log('componentDidUpdate, prevProps.hasRejected:' + prevProps.hasRejected);
    if (this.props.hasRejected && !prevProps.hasRejected){
      // console.log('componentDidUpdate.... rejectAction');
      this.rejectAction();
    }

    // Check if operator has changed (by pressing another row), in case update the operator infos
    if (this.props.contact !== prevProps.contact){
      // console.log('UPDATING Contact detail');
      this.setState({
        contactDetails: this.props.contact,
        promotingToggle: this.setupPromotingToggle()
      })
    }
  }

  executeAction() {
    this.goBack();
  }

  rejectAction() {
    // console.log('rejectAction.... now close all');
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  updateDetail(prop, value) {
    let contactDetails = {...this.state.contactDetails};
    contactDetails[prop] = value;
    this.setState({
      contactDetails,
      statusInfo: '',
    });
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.navigation.navigate.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.sendInvitation()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  async _promotingToggle() {
    this.setState({
      promotingToggle: {
        loading: true,
        showButton: true,
        sent: false
      }
    });


    let sentWithError = true;
    let toggleAction = this.state.promotingToggle.toggleAction;
    try{

      if (toggleAction === 'promoting') {
        // PROMOTING
        // https://autoscuole.egaf.it/students/set?func=promuoviAllievo
        const promotingToggleResp = await axios.post("students/set?func=promuoviAllievo", {
          // IdAllievo Opera idclasse
          idallievo: this.state.contactDetails.IdAllievo,
          opera: this.state.contactDetails.Opera,
          idclasse: this.state.contactDetails.idclasse,
        });

        const resp = promotingToggleResp.data;
        if (resp.hasOwnProperty("promuoviAllievo") && parseInt(resp.promuoviAllievo)){
          sentWithError = false;
          toggleAction = 'revoking';
        }

      } else {
        // REVOKING
        // https://autoscuole.egaf.it/students/set?func=revocaPromozioneAdAllievo
        const revokingToggleResp = await axios.post("students/set?func=revocaPromozioneAdAllievo", {
          // IdAllievo Opera idclasse
          idallievo: this.state.contactDetails.IdAllievo,
          opera: this.state.contactDetails.Opera,
          idclasse: this.state.contactDetails.idclasse,
        });

        const resp = revokingToggleResp.data;
        if (resp.hasOwnProperty("revocaPromozioneAdAllievo") && parseInt(resp.revocaPromozioneAdAllievo)){
          sentWithError = false;
          toggleAction = 'promoting';
        }
      }

      this.context.refreshClasses();

    } catch (e) {
      sentWithError = true
    }


    this.setState({
      promotingToggle: {
        showButton: true,
        loading: false,
        sent: true,
        sentWithError: sentWithError,
        toggleAction: toggleAction
      }
    });

    // Refreshing class list
    this.props.refresh(this.state.contactDetails.IdAllievo);
  }

  promotingToggle() {
    if (this.state.promotingToggle.loading){
      return(
        <View style={ConfigStyles.invitationBtnW}>
          <ActivityIndicator size={'small'}/>
        </View>
      )
    } else {
      if (this.state.promotingToggle.sent){
        return (
          <View style={ConfigStyles.invitationBtnW}>
           {
              this.state.promotingToggle.sentWithError?
              <Text style={ConfigStyles.invitationBtnText}>Si è verificato un errore, riprovare più tardi.</Text>
              :
              <Text style={ConfigStyles.invitationBtnText}>
                Promozione {this.state.promotingToggle.toggleAction === 'promoting'? 'revocata' : 'assegnata'} con successo
              </Text>
            }
          </View>
        )
      } else {

        return(
          <View style={ConfigStyles.invitationBtnW}>
            <TouchableOpacity
              style={[ConfigStyles.invitationBtn,
                this.state.promotingToggle.toggleAction === 'revoking'? ConfigStyles.disableBtn : ConfigStyles.enableBtn
              ]}
              onPress={()=>this._promotingToggle()}
            >
              <Text style={ConfigStyles.invitationBtnText}>
                {this.state.promotingToggle.toggleAction === 'promoting'? 'Promuovi' : 'Revoca Promozione'}
              </Text>
            </TouchableOpacity>
          </View>
        )
      }
    }
  }

  renderActionButtons() {
    console.info(this.state.contactDetails);
    return (
      <View style={[styles.gridRow, {justifyContent: 'space-between', marginBottom: 15}]}>

        { this.state.contactDetails.STATO === "ISCRITTO"?
          <View style={[{alignItems: 'flex-start', flex: 1, paddingRight: 2}]}>
            <View style={ConfigStyles.invitationBtnW}>
              <TouchableOpacity
                style={[ConfigStyles.invitationBtn,
                  {flex: 1, justifyContent: 'center', alignItems: 'center', height: 'auto'}
                ]}
                onPress={() => this.props.addToClass(this.state.contactDetails)}
              >
                <Text numberOfLines={2} style={[ConfigStyles.invitationBtnText, {fontSize: 12, textAlign: 'center',}]}>
                  Aggiungi ad ulteriore classe
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          :
          null
        }

        <View style={{flex:1, paddingLeft: 2, alignItems: 'flex-end', justifyContent: 'center', }}>
          {
            this.state.promotingToggle.showButton ?
              this.promotingToggle()
              :
              null
          }
        </View>
      </View>
    )
  }

  render() {
    if (this.state.contactDetails) {
      const student = this.state.contactDetails;
      return (
        <Animated.View style={[
          styles.container,
          {
            opacity: this.opacity.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 1],
            }),
            transform: [
              {
                translateX: this.translateRight.interpolate({
                  inputRange: [0, 60],
                  outputRange: [0, -25],
                })
              }
            ],
          }
        ]}>
          <View style={[actionStyles.actionDetailInnerHeader, {marginBottom: 12}]}>
            { student.image?
              <Image
                style={{width: 45, height: 45, borderRadius: 50, marginRight: 20}}
                source={student.image}
              />
              :
              <Image
                style={{width: 45, height: 45, marginRight: 20}}
                source={require('../commons/images/profile_icon.png')}
              />
            }
            <Text style={actionStyles.actionDetailTitle}>Anagrafica</Text>
          </View>

          {this.renderActionButtons()}

          <ScrollView style={actionStyles.scrollableView}>

            <View style={styles.gridRow}>
              <View style={styles.gridBlock_50}>
                <Text style={styles.label}>Nome:</Text>
                <Text style={[styles.textInputDisabled, ]}>{this.state.contactDetails.Nome}</Text>
              </View>

              <View style={styles.gridBlock_50}>
                <Text style={styles.label}>Cognome:</Text>
                <Text style={[styles.textInputDisabled,]}>{this.state.contactDetails.Cognome}</Text>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridBlock_50}>
                <Text style={styles.label}>Email:</Text>
                <Text style={[styles.textInputDisabled,]}>{this.state.contactDetails.Email}</Text>
              </View>

              <View style={styles.gridBlock_50}>
                <Text style={styles.label}>Città:</Text>
                <Text style={[styles.textInputDisabled,]}>{this.state.contactDetails.Citta}</Text>
              </View>
            </View>

            {
              this.state.contactDetails.Telefono?
              <View style={{paddingLeft: 6, paddingRight: 6}}>
                <Text style={styles.label}>Telefono:</Text>
                <Text style={[styles.textInputDisabled,]}>{this.state.contactDetails.Telefono + ' '}</Text>
              </View>
              :
              null
            }

            <View style={{paddingLeft: 6, paddingRight: 6}}>
              <Text style={styles.label}>Classe:</Text>
              <Text style={[styles.textInputDisabled,]}>{this.state.contactDetails.classe}</Text>
            </View>

            <View style={{paddingLeft: 6, paddingRight: 6}}>
              <Text style={styles.label}>Corso:</Text>
              <Text style={[styles.textInputDisabled,]}>{this.state.contactDetails.corso}</Text>
            </View>

            {
              this.context.hasMultiLocations?
                <View style={{paddingLeft: 6, paddingRight: 6}}>
                  <Text style={styles.label}>Scuola:</Text>
                  <Text style={[styles.textInputDisabled,]}>{this.state.contactDetails.RAGSOCIALE}</Text>
                </View>
                :
                null
            }


            <View style={{paddingLeft: 6, paddingRight: 6}}>
              <Text style={styles.label}>Stato:</Text>
              <Text style={[styles.textInputDisabled,]}>{this.state.contactDetails.STATO}</Text>
            </View>

            <View style={{paddingLeft: 6, paddingRight: 6}}>
              <Text style={styles.label}>Data Promozione:</Text>
              <Text style={[styles.textInputDisabled,]}>{
                this.state.contactDetails.DataPromozione ?
                  getFormattedDate(this.state.contactDetails.DataPromozione)
                  :
                  '--'
              }</Text>
            </View>

            {this.renderBtnToolbar()}

          </ScrollView>

        </Animated.View>
      );
    } else {
      return(
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    }
  }

  editablefields__render() {
    if (this.state.contactDetails) {
      const student = this.state.contactDetails;
      return (
        <View style={styles.container}>
          <View style={actionStyles.actionDetailInnerHeader}>
            { student.image?
              <Image
                style={{width: 50, height: 50, borderRadius: 50, marginRight: 20}}
                source={student.image}
              />
              :
              null
            }
            <Text style={actionStyles.actionDetailTitle}>Anagrafica</Text>
          </View>

          {/*<View style={styles.rowContainer}>*/}
            {/*<View style={classStyles.questionsBlock}>*/}
                {/*<Text style={styles.textLight}>Domande</Text>*/}
                {/*<Text style={styles.textLight}>Completate</Text>*/}
                {/*<Text style={styles.textLight}>Errori</Text>*/}
            {/*</View>*/}
            {/*<View>*/}
                {/*<Text style={classStyles.totalColor}>1824</Text>*/}
                {/*<Text style={classStyles.completedColor}>1193 (65%)</Text>*/}
                {/*<Text style={classStyles.errorColor}>155 (13%)</Text>*/}
            {/*</View>*/}
            {/*<View style={classStyles.quizGraphWrapper}>*/}
              {/*<QuizAnswersBarChart*/}
                {/*completed={0.65}*/}
                {/*wrong={0.23}*/}
                {/*height={40}*/}
                {/*// maxWidth={500}*/}
              {/*/>*/}
            {/*</View>*/}
          {/*</View>*/}

          <ScrollView style={actionStyles.scrollableView}>
            <Text style={styles.label}>Nome:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(name) => this.updateDetail('Nome', name)}
              value={this.state.contactDetails.Nome}
            />

            <TextInput
              style={styles.textInput}
              onChangeText={(surname) => this.updateDetail('Cognome', surname)}
              value={this.state.contactDetails.Cognome}
            />

            <Text style={styles.label}>Email:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(email) => this.updateDetail('Email', email)}
              value={this.state.contactDetails.Email}
            />

            <Text style={styles.label}>Telefono:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(phone) => this.updateDetail('Telefono', phone)}
              value={this.state.contactDetails.Telefono}
            />

            <Text style={styles.label}>Città:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(city) => this.updateDetail('Citta', city)}
              value={this.state.contactDetails.Citta}
            />

            <Text style={styles.label}>Data Iscrizione:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(subscriptionDate) => this.updateDetail('DataIscrizione', subscriptionDate)}
              value={this.state.contactDetails.DataIscrizione}
            />


            { this.state.statusInfo?
              <Text style={styles.label}>{this.state.statusInfo}</Text>
              :
              null
            }

            {this.renderBtnToolbar()}

          </ScrollView>

        </View>
      );
    } else {
      return(
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    }
  }

}



class AddToClass extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Animatino on Mounting /Unmounting
    this.opacity = new Animated.Value(0);
    this.translateLeft = new Animated.Value(100);
    // this.translateLeft = new Animated.Value(0);

    this.state = {
      //initial loading
      loading: true,
      classList: [],
      selectedClass: null,

    }
  }

  async loadClasses() {
    try{

      const classesResp = await axios.get("classrooms/getclassioperatore", {
        params: {
          idutente: this.context.currentCloud.idutente,
        }
      });

      this.unfilteredListItems = classesResp.data;

      // sorting classes by school
      this.unfilteredListItems = this.unfilteredListItems.sort((a, b) => {
        if (a.RAGSOCIALE > b.RAGSOCIALE) {
          return 1;
        } else if (a.RAGSOCIALE < b.RAGSOCIALE) {
          return -1;
        } else {
          // Second criteria: course
          if (a.corso > b.corso) {
            return 1;
          } else if (a.corso < b.corso) {
            return -1;
          } else {
            return 0;
          }
        }
      });


      this.setState({
        loading: false,
        classList: this.unfilteredListItems
      })
    } catch (e) {
      errorManager(e);
    }
  }

  componentDidMount() {
    this.loadClasses();
    this.mountingAnimation();
  }

  componentWillUnmount() {
    // this.unMountingAnimation();
  }

  // Animation functions
  mountingAnimation() {
    Animated.parallel([
      Animated.timing(this.opacity, {
        toValue: 1,
        duration: 600,
        useNativeDriver: false
      }),
      Animated.timing(this.translateLeft, {
        toValue: 0,
        duration: 500,
        useNativeDriver: false
      })

    ]).start();
  }

  unMountingAnimation() {
    Animated.parallel([
      Animated.timing(this.opacity, {
        toValue: 0,
        duration: 250,
        useNativeDriver: false
      }),
      Animated.timing(this.translateLeft, {
        toValue: 60,
        duration: 250,
        useNativeDriver: false
      })

    ]).start(()=>{

    });
    this.props.closeAddClassPanel()

  }

  // Animation functions
  closeAddClassPanel(){
    this.unMountingAnimation();

  }


  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {
   if (this.props.hasConfirmed  && !prevProps.hasConfirmed){
      // console.log('componentDidUpdate.... executeAction');
      this.executeAction();
    }
   if (this.props.hasRejected  && !prevProps.hasRejected){
      // console.log('componentDidUpdate.... executeAction');
      this.closeAddClassPanel();
    }
  }

  alertMessage(message=null) {
    const msg = message? message : 'Si è verificato un errore. Riprovare più tardi, grazie';
    alert(msg);
  }

  async executeAction() {

    try{
      // https://autoscuole.egaf.it/students/set?func=assegnaClasseAdAllievo
      const assignClassResp = await axios.post("students/set?func=assegnaClasseAdAllievo", {
        idallievo: this.props.contact.IdAllievo,
        opera: this.props.contact.Opera,
        idclasse: this.state.selectedClass.idclasse
      });

      const resp = assignClassResp.data;
      // console.info('assegnaClasseAdAllievoResp: ', resp);
      if (resp.hasOwnProperty('assegnaClasseAdAllievo') && parseInt(resp.assegnaClasseAdAllievo)) {
        // updating class list (in classes section)
        this.context.refreshClasses();
        // Updating contacts List
        this.props.refresh();
        this.alertMessage('Studente aggiunto con successo');
        // this.props.userAdded();
        this.closeAddClassPanel();
      } else {
        this.alertMessage('Non è stato possibile aggiungere lo studente alla classe selezionata.');
      }

    } catch (e) {
      errorManager(e);
    }
  }

  rejectAction() {
    // console.log('AddToClass rejectAction.... now close all');
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.navigation.navigate.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.sendInvitation()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  chooseClass(classItem) {
    // console.info('chooseClass(classItem)', classItem);
    // console.info('Current Opera: ', this.context.currentCourse.opera);
    this.context.hasUnprocessedEdits = true;
    this.setState({
      selectedClass: classItem
    })
  }

  render() {
    let firstSchool = this.state.loading? '' : this.state.classList[0].RAGSOCIALE;
    let firstCourse = this.state.loading? '' : this.state.classList[0].corso;

    return (
      <Animated.View style={[
        styles.container,
        {
          opacity: this.opacity.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 1],
          }),
          transform: [
            {
              translateX: this.translateLeft.interpolate({
                inputRange: [0, 100],
                outputRange: [0, 60],
              })
            }
          ],
        }
      ]}>
        {
          this.state.loading?
            <View style={[styles.container, {justifyContent: 'center'}]}>
              <ActivityIndicator />
            </View>
            :
            <View style={styles.container}>
              <View style={[actionStyles.actionDetailInnerHeader, ]}>
                <Text style={actionStyles.actionDetailTitle}>Scegli la Classe di destinazione</Text>
              </View>

              <View style={{marginBottom: 15}}>
                {this.state.selectedClass ?
                  <Text style={{color: Colors.textLight,opacity: 0.3, fontSize: 15}}>&#8617; Indietro</Text>
                  :
                  <TouchableOpacity
                    onPress={()=>this.closeAddClassPanel()}
                  >
                    <Text style={{color: Colors.textLight, fontSize: 15}}>&#8617; Indietro</Text>
                  </TouchableOpacity>
                }
              </View>

              <ScrollView style={classStyles.chooserWrapper}>
                {
                  this.context.hasMultiLocations?
                    <Text style={[prospectStyles.schoolTitle, {marginTop: 5}]}>{firstSchool}</Text>
                    :
                    null
                }


                <Text style={prospectStyles.courseTitle}>{firstCourse}</Text>
                {this.state.classList.map((classItem, index) => {
                  // if (classItem.idclasse !== this.props.sourceClassId) {
                    let changedSchool =  null;
                    if (classItem.RAGSOCIALE !== firstSchool) {
                      changedSchool = classItem.RAGSOCIALE;
                      firstSchool = classItem.RAGSOCIALE;
                    }

                    let changedCourse = null;
                    if (classItem.corso !== firstCourse) {
                      changedCourse = classItem.corso;
                      firstCourse = classItem.corso;
                    }

                    return (
                      <View key={'school_'+index}>
                        {
                          this.context.hasMultiLocations && changedSchool?
                            <Text style={prospectStyles.schoolTitle}>{changedSchool}</Text>
                            :
                            null
                        }
                        {
                          changedCourse?
                            <Text style={prospectStyles.courseTitle}>{changedCourse}</Text>
                            :
                            null
                        }
                        <ListItem
                          key={classItem.idclasse}
                          text={classItem.classe}
                          item={classItem}
                          isCurrent={this.state.selectedClass && this.state.selectedClass.idclasse === classItem.idclasse? true : false}
                          isPresetClass={parseInt(classItem.PREDEFINITO)}
                          onChoose={(classItem) => this.chooseClass(classItem)}
                        />
                      </View>
                    )
                  // }
                })
                }
              </ScrollView>

              {this.renderBtnToolbar()}
            </View>
        }
      </Animated.View>
    );
  }
}


const ListItem = (props) => {

  return(
    <TouchableOpacity
      style={[classStyles.listItem, {marginBottom: 14}, props.isPresetClass ? classStyles.listItemPreset : null ]}
      onPress={() => props.onChoose(props.item)}
    >
      <EgafSelectionCheck isSelected={props.isCurrent} isSmall={true} />
      <Text style={classStyles.listItemName}>{props.text}</Text>
    </TouchableOpacity>
  );

}
