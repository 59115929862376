import React, {useContext, useEffect, useRef, useState} from "react";
import {View, FlatList, Text, ActivityIndicator, TouchableOpacity, Image, Animated} from 'react-native';
import {drillPanelStyles, styles} from '../../foundation/styles';
import statsStyles from '../../section_statistics/styles';
import UserContext from "../../user";
import axios, {CancelToken} from "../../commons/axios";
import {OverallAnswersBarChart, QuizAnswersBarChart} from "../../foundation/widgets";
import classStyles from "../../section_classes/styles";
import errorManager from "../../commons/error_manager";
import {WrongCorrectimage} from "../../section_statistics/statistics/wrong_questions";


export default class StudentTopics extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Cancel Token for topics request
    this.source = CancelToken.source();

    this.period = props.period;

    this.state = {
      loading: true,
      statsData: [],

    }
  }

  async loadStats() {
    try {
      // https://autoscuole.egaf.it/statistics/?stat=statFocus_AllievoOpera&idAllievo=10629&opera=4&giorni=180
      const statsTopicsResp = await axios.get("statistics/", {
        cancelToken: this.source.token,
        params: {
          stat: 'statFocus_AllievoOpera',
          idAllievo: this.props.student.IdAllievo,
          // idClasse: this.props.currentClass.idclasse,
          opera: this.context.currentCourse.opera,
          giorni: this.period
        }
      });


      // console.info('statsResp for tab STUDENT TOPICS', statsTopicsResp.data);

      this.setState({
        loading: false,
        statsData: statsTopicsResp.data,
        showDrillPanel: false,
        drillParams: null
      });
    } catch (e) {
      errorManager(e, 'Non è stato possibile processare le statistiche nel periodo scelto. Riprovare più tardi. Grazie');
    }
  }

  componentDidMount() {
    this.loadStats();
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.student !== this.props.student) {
      console.info('Comp di d update, current student is', this.props.student);
      this.setState({
        loading: true,
      });
      this.loadStats();
    }

    if (prevProps.period !== this.props.period) {
      this.period = this.props.period;
      this.setState({
        loading: true,
      });
      this.loadStats();
    }
  }

  render() {
    if (this.state.loading) {
      return <View style={statsStyles.statContainer}>
        <ActivityIndicator/>
      </View>;
    } else {

      if (this.state.statsData.length) {
        return (
          <View style={styles.container}>
            <View>
              {this._renderListHeading()}

              {
                this.state.statsData.map((set, index) => {
                  return(
                    <TopicsAccordion
                      key={set.id}
                      title={set.descrizione}
                      stats={set.stat}
                      open={index === 0}
                      isSingle={this.state.statsData.length === 1}
                      drillDown={(idLesson, title)=>this.props.topicDrillDown(idLesson, title)}
                    />
                  )
                })
              }
            </View>
          </View>
        );
      } else {
        return(
          <View style={statsStyles.statContainer}>
            <Text style={statsStyles.noStatMessage}>Queste statistiche non sono ancora disponibili</Text>
          </View>
        );
      }

    }
  }

  _renderListHeading() {
    return (
      <View>
        <View
          style={styles.rowContainer}
        >
          <View style={[styles.rowContainer, {marginRight: 20}]}>
            <View style={statsStyles.correctLegend}/>
            <Text style={[styles.textLight, ]}>giuste</Text>
          </View>

          <View style={[styles.rowContainer, {marginRight: 20}]}>
            <View style={statsStyles.wrongLegend}/>
            <Text style={[styles.textLight, ]}>sbagliate</Text>
          </View>

          {/*<View style={[styles.rowContainer, {marginRight: 20}]}>*/}
          {/*  <View style={statsStyles.overallMixLegend}>*/}
          {/*    <View style={statsStyles.overallMixLegendDark}/>*/}
          {/*  </View>*/}
          {/*  <Text style={[styles.textLight, ]}>avanzamento (sul totale)</Text>*/}
          {/*</View>*/}

          <View style={styles.rowContainer}>
            <View style={statsStyles.overallPeriodLegend}/>
            <Text style={[styles.textLight, ]}>fatte in precedenza</Text>
          </View>
        </View>
      </View>
    );
  }

}

class TopicsAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.orderingTypes = ['ASC', 'DESC'];

    this.state = {
      open: this.props.open,
      stats: [],

      ordering: {
        criteria: 'name',
        type: this.orderingTypes[1] // Start with DESC therefore on mounting stats will be ordered by ASC
      }
    }
  }

  componentDidMount() {
    this.orderTopics('name', this.props.stats);
  }

  orderTopics(criteria, statistics=null) {
    // Get ordering type
    let orderType = this.state.ordering.type;
    if (criteria !== this.state.ordering.criteria) {
      orderType = this.orderingTypes[0];
    } else{
      orderType = this.orderingTypes[0] === orderType ? this.orderingTypes[1] : this.orderingTypes[0];
    }

    // Order by criteria and type
    let topics = statistics? Array.from(statistics) : Array.from(this.state.stats);
    if (criteria === 'name') {
      // order by name
      topics = topics.sort((a, b) => {
        // let propA = a.LEZIONE.split('-')[1];
        // let propB = b.LEZIONE.split('-')[1];
        let propA = a.LEZIONE;
        let propB = b.LEZIONE;
        if (propA < propB) {
          return orderType === this.orderingTypes[0] ? -1 : 1;
        }
        if (propA > propB) {
          return orderType === this.orderingTypes[0] ? 1 : -1;
        }
        return 0;

      });
    } else if (criteria === 'correct') {
      // order by percentage
      topics = topics.sort((a, b) => {
        const totalA = a.SBAGLIATE + a.GIUSTE;
        const totalB = b.SBAGLIATE + b.GIUSTE;

        let propA = totalA? a.GIUSTE/totalA : 0;
        let propB = totalB? b.GIUSTE/totalB : 0;
        if (propA < propB) {
          return orderType === this.orderingTypes[0] ? -1 : 1;
        }
        if (propA > propB) {
          return orderType === this.orderingTypes[0] ? 1 : -1;
        }
        return 0;

      });
    } else {
      // order by percentage
      topics = topics.sort((a, b) => {
        const totalA = a.SBAGLIATE + a.GIUSTE;
        const totalB = b.SBAGLIATE + b.GIUSTE;

        let propA = totalA? a.SBAGLIATE/totalA : 0;
        let propB = totalB? b.SBAGLIATE/totalB : 0;
        if (propA < propB) {
          return orderType === this.orderingTypes[0] ? -1 : 1;
        }
        if (propA > propB) {
          return orderType === this.orderingTypes[0] ? 1 : -1;
        }
        return 0;

      });
    }

    // Set state
    const currentOrdering = {
      criteria: criteria,
      type: orderType
    }

    this.setState({
      stats: topics,
      ordering: currentOrdering
    })

  }

  getOrderingImage(criteria) {
    let orderingImg = <Image
      style={{width: 10, height: 20, marginRight: 7}}
      source={require('../../commons/images/order_off.png')}
    />;

    if (this.state.ordering.criteria === criteria) {
      if (this.state.ordering.type === this.orderingTypes[1]) {
        orderingImg = <Image
          style={{width: 10, height: 20, marginRight: 7}}
          source={require('../../commons/images/order_desc.png')}
        />
      } else {
        orderingImg = <Image
          style={{width: 10, height: 20, marginRight: 7}}
          source={require('../../commons/images/order_asc.png')}
        />;
      }
    }

    return orderingImg;
  }

  toggleOpen() {
    this.setState({
      open: !this.state.open
    })
  }

  _renderOrderingHeading() {
    return (
      <View
        style={[styles.rowContainer, statsStyles.accOrderingHeader, ]}
      >
        <TouchableOpacity
          style={[statsStyles.accOrderingCriteria, {flex:1}, ]}
          onPress={() => this.orderTopics('name')}
        >
          <Text style={statsStyles.accOrderingCriteriaText}>Argomento</Text>
          {this.getOrderingImage('name')}
        </TouchableOpacity>

        <TouchableOpacity
          style={statsStyles.accOrderingCriteria}
          onPress={() => this.orderTopics('correct')}
        >
          <Text style={statsStyles.accOrderingCriteriaText}>Giuste</Text>
          {this.getOrderingImage('correct')}
        </TouchableOpacity>

        {/*<TouchableOpacity*/}
          {/*style={statsStyles.accOrderingCriteria}*/}
          {/*onPress={() => this.orderTopics('wrong')}*/}
        {/*>*/}
          {/*<Text style={statsStyles.accOrderingCriteriaText}>Sbagliate</Text>*/}
          {/*{this.getOrderingImage('wrong')}*/}
        {/*</TouchableOpacity>*/}
      </View>
    );
  }

  render() {

    return (
      <View
        style={statsStyles.accordion}
      >
        {
          this.props.isSingle?
            null
            :
            <View
              style={statsStyles.accHeading}
            >
              <Text style={[statsStyles.accTitle, {fontSize: 16}]}>{this.props.title}</Text>
              <TouchableOpacity
                style={[statsStyles.openBtn, {marginRight: 5, paddingTop: 2, paddingRight: 2, paddingBottom: 2, paddingLeft: 2,}]}
                onPress={() => this.toggleOpen()}
              >
                {
                  this.state.open?
                    <Image size={20} style={{tintColor: '#ffffff', width: 16, height: 16}} source={require('../../commons/images/minus.svg')}/>
                    :
                    <Image size={20} style={{tintColor: '#ffffff', width: 16, height: 16}} source={require('../../commons/images/plus.svg')}/>
                }
              </TouchableOpacity>
            </View>
        }

        {
          this.state.open?
            this._renderTopics()
            :
            null
        }
      </View>
    );
  }

  _renderTopics() {
    return(
      <View
        style={[statsStyles.accTopicsW, this.props.isSingle? null : {marginTop: 10}]}
      >
        {
          this._renderOrderingHeading()
        }

        {
          this.state.stats.map((stat, index)=> {
            // debugger;
            const total = stat.SBAGLIATE + stat.GIUSTE;
            const wrongValue = total ? stat.SBAGLIATE / total : 0;
            const correctValue = total ? stat.GIUSTE / total : 0;

            const totalOverall = parseInt(stat.TOTALE);
            const doneOverall = parseInt(stat.SVOLTE);
            const correctInPeriod = stat.GIUSTE === null ? 0 : parseInt(stat.GIUSTE);
            const wrongInPeriod = stat.SBAGLIATE === null ? 0 : parseInt(stat.SBAGLIATE);

            const doneInPeriod = doneOverall - (wrongInPeriod + correctInPeriod);

            let wrongPercentage = wrongValue * 100;
            wrongPercentage = wrongPercentage.toFixed();
            // let correctPercentage = correctValue * 100;
            let correctPercentage = 0;
            if (correctValue) {
              correctPercentage = wrongValue ? 100 - wrongPercentage : 100;
            }
            correctPercentage = correctPercentage.toFixed();
            return(
              <TouchableOpacity
                style={[statsStyles.accTopic, {paddingBottom: 7, marginBottom: 7,}]}
                key={index}
                onPress={()=>this.props.drillDown(stat.IDLEZIONE, stat.LEZIONE)}
              >

                <View style={statsStyles.accTopicTopRow}>
                  <View style={{flex: 1}}>
                    <Text style={[statsStyles.accTopicName, {fontSize: 12, fontWeight: '300'}]}>{stat.LEZIONE}</Text>
                    <QuizAnswersBarChart
                      completed={correctValue+wrongValue}
                      wrong={wrongValue}
                      height={15}
                      bgColor={'#1b1b1b'}
                    />
                  </View>

                  <View style={{flexShrink: 1, paddingLeft: 10, paddingRight: 7}}>
                    <Text style={[classStyles.completedColor, statsStyles.smallGraphText]}>{correctPercentage}%</Text>
                    <Text style={[classStyles.errorColor, statsStyles.smallGraphText]}>{wrongPercentage}%</Text>
                  </View>
                </View>

                <View style={statsStyles.accTopicBottomRow}>
                  <OverallBar
                    correctBar={doneOverall}
                    wrongBar={doneOverall - correctInPeriod}
                    previousDoneBar={doneOverall - correctInPeriod - wrongInPeriod}
                    total={totalOverall}
                    enabled
                    correctInPeriod={correctInPeriod}
                    wrongInPeriod={wrongInPeriod}
                  />
                </View>

              </TouchableOpacity>
            )
          })
        }

      </View>
    )
  }
}


const OverallBar = ({total, correctBar, wrongBar, previousDoneBar, enabled, correctInPeriod, wrongInPeriod}) => {
  // calculating values for chart. It accepts values from 0 to 1
  let doneCorrect = 0;
  let doneWrong = 0;
  let doneBefore = 0;
  let overallText = '';
  let didPercent = 0;

  if (enabled) {
    // doneAllForGraph = done?  done / total : 0;
    // doneForGraph = doneInPeriod && done? doneAllForGraph - (done - doneInPeriod) / total : 0;
    const did = correctBar;
    didPercent = did / total * 100;
    overallText = `Totale ${total}, svolte ${did} di cui ${correctInPeriod} giuste e ${wrongInPeriod} sbagliate nel periodo`;

    // New graph
    doneCorrect = correctBar / total;
    doneWrong = wrongBar / total;
    doneBefore = previousDoneBar / total
  }

  return(
    <View style={statsStyles.overallWrapper}>
      <Text style={statsStyles.overallText}>{overallText}</Text>
      <View style={statsStyles.overallGraphWrapper}>
        <OverallAnswersBarChart
          correct={doneCorrect}
          wrong={doneWrong}
          before={doneBefore}
          // correctColor={'#0054a6'}
          // wrongColor={'#4176d9'}
          beforeColor={'#969b95'}
          height={15}
          bgColor={'#1b1b1b'}
        />
        <Text style={statsStyles.overallPercentText}>{didPercent.toFixed()}%</Text>
      </View>
    </View>
  )
}


export const TopicsDrillDownPanel = ({showDrillPanel, drillParams, close, hasScroll, showImage}) => {

  const cancelRequest = CancelToken.source();
  const [data, setData] = useState(null);
  // const slideX = new Animated.Value(0);
  const slideX = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    // Manage Animation
    Animated.timing(slideX, {
      toValue: showDrillPanel ? 1 : 0,
      duration: 400,
    }).start();

    // Manage data loading (only when panel is opening)
    if (showDrillPanel) {
      loadData();
    } else {
      setData(null);
    }

  }, [showDrillPanel]);

  const loadData = async () => {
    // debugger;
    // console.info('Drill PARAMS:', drillParams);
    try {
      // https://autoscuole.egaf.it/statistics/?stat=statDomandeSbagliate_AllievoOperaLezione&idAllievo=288818&idClasse=869&opera=3&idLezione=66&giorni=30
      const statsTopicsResp = await axios.get("statistics/", {
        cancelToken: cancelRequest.token,
        params: {
          stat: 'statDomandeSbagliate_AllievoOperaLezione',
          idAllievo: drillParams.idAllievo,
          idClasse: drillParams.idClasse,
          opera: drillParams.opera,
          idLezione: drillParams.idLezione,
          giorni: drillParams.giorni
        }
      });

      setData(statsTopicsResp.data);

    } catch (e) {
      errorManager(e, 'Non è stato possibile processare queste statistiche. Riprovare più tardi. Grazie');
      setData([])
    }
  }

  const renderWrongQuestions = (data) => {
    if (data.length) {
      return (
        <View style={statsStyles.statContainer}>
          <FlatList
            key={'stat__wrong_questions'}
            data={data}
            renderItem={ ({item, index}) => _renderStatItem(item, index)}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      )
    } else {
      return (
        <View style={statsStyles.statContainer}>
          <Text style={statsStyles.noStatMessage}>Queste statistiche non sono ancora disponibili</Text>
        </View>
      )
    }
  }

  const _renderStatItem = (item, index) => {
    // return <WrongQuestion question={item} />;
    return <WrongQuestion question={item} showImage={(path) => showImage(path)}/>;
  }

  return (
    <Animated.View
      style={[
        drillPanelStyles.panelW,
        hasScroll? drillPanelStyles.panelWScrollable : null,
        {
          opacity: slideX,
          transform: [
            {
              translateX: slideX.interpolate({
                inputRange: [0, 1],
                outputRange: ['100%', '0%']
              })
            }
          ],
        }
      ]}
    >
      {/*<ScrollView style={drillPanelStyles.panelInner}>*/}
        <View style={drillPanelStyles.header}>

          <Text style={drillPanelStyles.headerTitle}>{drillParams? drillParams.title : ''}</Text>
          <TouchableOpacity
            onPress={() => close()}
          >
            <Image style={drillPanelStyles.closeIcon} size={20} source={require('../../commons/images/x_dark.svg')}/>
          </TouchableOpacity>
        </View>

        <View>
          {
            data?
              renderWrongQuestions(data)
              :
              <ActivityIndicator size={'small'} />
          }
        </View>

      {/*</ScrollView>*/}
    </Animated.View>
  )

}


const WrongQuestion = ({question, showImage}) => {
  console.info('rendering question', question);
  const store = useContext(UserContext);
  const currentOpera = store.currentCourse.opera;
  // TODO place base Url in a more convenient place, to reuse it later
  const baseUrl = 'https://img.egaf.it/quizzando/';
  const img = question.DO_IMG2? question.DO_IMG2 : '000.svg';
  const imgPath = `${baseUrl}${currentOpera}/${img}`;
  return(
    <View
      style={statsStyles.wrongItemW}
    >
      <TouchableOpacity
        onPress={()=>showImage(imgPath)}
      >
        <View style={statsStyles.wrongItemImage}>
          <Image data-iefix={'ieimg'} resizeMode={'contain'} style={{width: 60, height: 60}} source={{uri: imgPath}}/>
        </View>
      </TouchableOpacity>

      <View style={statsStyles.wrongItemTextW}>
        <Text style={statsStyles.wrongItemText}>{question.RI_TESTO}</Text>
      </View>
      <View style={[statsStyles.wrongItemResp, {marginLeft: 20}]}>
        <WrongCorrectimage result={question.RI_VF} />
      </View>
    </View>
  );

}
