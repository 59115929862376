import EStyleSheet from 'react-native-extended-stylesheet';
import {Colors, Defaults} from "../foundation/styles";

const styles = EStyleSheet.create({

  tabsWrapper: {
    // Try to absoute positionig them
    position: 'absolute',
    width: 450,
    zIndex: 100,
    // backgroundColor: Colors.background,
    // backgroundColor: '#ff0000',
    paddingTop:25,
    paddingRight:Defaults.padder*2,
    paddingBottom:Defaults.padder,
    paddingLeft:Defaults.padder*2,
    // borderTopWidth: 15,
    // borderRightWidth: 15,
    // borderBottomWidth: 1,
    // borderLeftWidth: 15,
    // borderColor: Colors.border,
  },
  tabsContainerStyle: {
    maxWidth: 400,
    height: 50,
    // backgroundColor: Colors.background
  },
  tabStyle: {
    backgroundColor: '#292929',
    borderWidth: 0,
    borderColor: 'transparent',
    marginRight: 10
  },
  activeTabStyle: {
    backgroundColor: Colors.primaryColor,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  tabTextStyle: {
    color: '#ffffff',
    fontSize: 24,
    marginLeft: 10,
    marginRight: 10,
  },
  activeTabTextStyle: {
    color: '#ffffff',
    marginLeft: 10,
    marginRight: 10,
  },

  sectionWithTabs: {
    borderTopWidth: 0,
  },

  invitationBtnW: {
    // height: 30,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  enableBtnW: {
    // height: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  invitationBtn: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.primaryColor,
    borderRadius:  30,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
  },
  invitationBtnText: {
    lineHeight: '1em',
    color: Colors.textLight
  },
  invitationBtnDisabled: {
    backgroundColor: Colors.primaryColor,
  },

  disableBtn: {
    backgroundColor: Colors.wrong,
  },
  enableBtn: {
    backgroundColor: Colors.correct,
  },

  // Filters
  topFiltersMainWrapper: {
    flexDirection: 'row',
    paddingTop: 0,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    borderBottomColor: Colors.border,
    borderBottomWidth: 2,
    marginBottom: 20
  },

  topFilter: {
    backgroundColor: Colors.navBar,
    borderRadius: 30,
    paddingTop: 7,
    paddingRight: 20,
    paddingBottom: 7,
    paddingLeft: 20,
    justifyContent: 'center'
  },
  innerBlock: {
    borderTopColor: Colors.border,
    borderTopWidth: 1,
    paddingTop: 20,
    marginTop: 5
  }
});


export default styles;
