import React, { useContext } from 'react';
import { View, Text, Image, ActivityIndicator, TextInput, TouchableOpacity, FlatList, Picker, ScrollView} from 'react-native';
import {actionStyles, styles} from "../foundation/styles";
import axios, {CancelToken} from "../commons/axios";
import {isWeb, getFormattedDate} from "../commons/utils";
import {
  ActionBlock,
  EgafSectionList,
  QuizAnswersBarChart,
  EgafSelectionCheck,
  EgafStatPeriodSelect
} from "../foundation/widgets";
import classStyles from './styles';
import UserContext from "../user";
import {Observer} from 'mobx-react';
import {reaction} from "mobx";
import prospectStyles from "../section_prospects/styles";
import StudentStatsIndex from './statistics_detail/views';
import statsStyles from "../section_statistics/styles";
import {StatIndicator} from '../section_statistics/views';
import errorManager from "../commons/error_manager";
import {TopicsDrillDownPanel} from './statistics_detail/topics';
import {ResultsDrillDownPanel} from "./statistics_detail/results";


const RemoveUsersButton = (props) => {

  if (props.isActive) {
    return (
      <View style={[styles.alignRight, styles.rowContainer, styles.alignMiddle, styles.grow]}>
        <TouchableOpacity
          style={classStyles.addRemoveBtn}
          onPress={() => props.removeUsers()}
        >
          <Text style={classStyles.addRemoveBtnLabel}>Rimuovi</Text>
        </TouchableOpacity>
      </View>
    );
  } else {
    return (
      <View style={[styles.alignRight, styles.rowContainer, styles.alignMiddle, styles.grow]}>
        <View
          style={[classStyles.addRemoveBtn, classStyles.addRemoveBtnDisabled]}
        >
          <Text style={[classStyles.addRemoveBtnLabel, classStyles.addRemoveBtnLabelDisabled]}>Rimuovi</Text>
        </View>
      </View>
    );
  }

};


const AddUsersButton = (props) => {

  if (props.isActive) {
    return (
      <TouchableOpacity
        style={classStyles.addRemoveBtn}
        onPress={()=>props.onPress()}
      >
        <Text style={classStyles.addRemoveBtnLabel}>Aggiungi</Text>
      </TouchableOpacity>
    );
  } else {
    return (
      <View
        style={[classStyles.addRemoveBtn, classStyles.addRemoveBtnDisabled]}
      >
        <Text style={[classStyles.addRemoveBtnLabel, classStyles.addRemoveBtnLabelDisabled]}>Aggiungi</Text>
      </View>
    );
  }

};


const AddRemoveUsersButton = (props) => {

  const store = useContext(UserContext);

  const currentClass = store.classes[store.showingStudentsOfClassIndex];

  return (
    <Observer>
      {
        () => {
          if (!parseInt(currentClass['PREDEFINITO'])) {
            return <TouchableOpacity
             style={[styles.roundBtn, {marginLeft: 25}]}
             onPress={() => props.openActionBox({name: 'addusers', params: {targetClassId: currentClass.idclasse, confirmBtnLabel: 'Chiudi'}})}
            >
             <Image size={25} style={{width: 45, height: 45}} source={require('../commons/images/add_users.svg')}/>
            </TouchableOpacity>;
          } else {
            return null;
          }
        }
      }
    </Observer>
  );

}


export default class ClassesHome extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.sectionHeaders = [
      {name: 'Nome', prop: 'Nome'},
      {name: 'Cognome', prop: 'Cognome'},
      {name: 'Email', prop: 'Email'},
      {name: 'Iscrizione', prop: 'DataIscrizione'},
      // {name: 'Sesso', prop: 'gender'},
      {name: 'Telefono', prop: 'Telefono'},
      {name: 'Stato', prop: 'status'}
      // {name: 'Attivo', prop: 'DISABILITATO'}
    ];

    this.excludedFieldsOnActionEnabled = ['DataIscrizione', 'Telefono', 'status'];

    this.actions = [
      // {
      //   name: 'sendMessage',
      //   label: 'Invia un messaggio',
      //   params: {
      //     confirmBtnLabel: 'Invia'
      //   }
      // },
      {
        name: 'moveInClass',
        label: 'Sposta in classe..',
        params: {
          confirmBtnLabel: 'Conferma'
        }
      },
      // {
      //   name: 'addToClass',
      //   label: 'Aggiungi a Classe..',
      //   params: {
      //     confirmBtnLabel: 'Conferma'
      //   }
      // }
    ];

    // Cancel Token for students request
    this.source = null;

    // Creating ref to list Component
    this.listView = null;

    // Creating ref to AddUser component (list on right for adding/removing )
    this.addUserComponent = null;

    this.unfilteredListItems = [];

    this.state = {
      loading: true,
      listItems: [],
      classListShow: false,
      isActionEnabled:  false,
      changeClassLoading: false,
      currentAction: '',
      actionPickerEnabled: false,

      selectedUsers: [],
      showingUserDetailId: null,

      // Class statistics
      classStats: {
        classId: null,
        stats: []
      },

      // State depending on outer button, to execute action on child component
      hasConfirmed: false,
      hasRejected: false,

      // Filters
      filters: {
        text: ''
      }
    }
  }

  async getUsersForActiveClass(id) {
    try{

      const studentsEndpoint = '/students/getallieviclasseSummary/'+id;
      const studentsResp = await axios.get(studentsEndpoint, {
        cancelToken: this.source.token
      });
      // console.info('CLASS STUDENTS RESP:', studentsResp.data);
      // this.getStudentsStats(studentsResp.data, id);

      // Got list, reset cancelToken
      return studentsResp.data;

    } catch (e) {
      errorManager(e);

      return [];
    }
  }

  async getCurrentClassStats(id) {
    // Getting stats for active class (only if class has changed
    let classStats = Object.assign({}, this.state.classStats);
    if (this.state.classStats && this.state.classStats.classId !== id) {
      try{
        // console.time('classStats');
        const classStatsResp = await axios.get("statistics/", {
          params: {
            stat: 'statAndamento_ClasseOpera',
            idClasse: id,
            opera: this.context.currentCourse.opera
          }
        });
        // console.timeEnd('classStats');
        classStats.classId = id;
        classStats.stats = classStatsResp.data; // returning only items[2] that refers to 30 days stats

      } catch (e) {
        console.log('ERROR loading current Class Statistics', e);
      }
    }

    this.setState({
      classStats: classStats,
    });

  }


  async fetchStudentsList(index=null) {

    // Managing cancel token
    this.source = CancelToken.source();

    let activeClass = index? this.context.classes[index] : this.context.classes[0];
    // console.info('activeClass', activeClass);
    // console.info('index', index);
    // console.info('this.context.classes', this.context.classes);

    if (!activeClass){
      // If activeClass is undefined means there are no classes for current user
      this.setState({
        loading: false,
        listItems: [],
        classListShow: false,
        selectedUsers: [],
      });
      return;
    }

    this.context.showingStudentsOfClassIndex = index? index : 0;

    this.unfilteredListItems = await this.getUsersForActiveClass(activeClass['idclasse']);

    // Retreive class stat
    this.getCurrentClassStats(activeClass['idclasse']);

    this.setState({
      loading: false,
      listItems: this.unfilteredListItems,
      classListShow: this.checkClassListVisibility(),
      selectedUsers: [],
    });

  }

  checkClassListVisibility(forceValue=null) {
    let showing = forceValue !== null? forceValue : !this.state.isActionEnabled;
    return this.context.classes.length > 1 ? showing : false;
  }

  componentDidMount() {

    this.fetchStudentsList();

    // This reaction will be triggered also when a Location or Cloud will change
    this._courseReactionDispose = reaction(
      () => this.context.currentCourse,
      () => {
        console.log('REACTION: CURRENTCOURSE has changed, updating students list');
        this.context.showingStudentsOfClassIndex = 0;
        this.setState({
          loading: true,
          isActionEnabled: false,
          action: null,
          currentAction: '',
          classStats: {
            classId: null,
            stats: []
          },
        });
      }
    );

    // Reaction to update Students list when changing lists due to adding or removing
    this._classesReactionDispose = reaction(
      () => this.context.classes,
      (classes) => {
        console.log('REACTION: CLASSES has changed, updating students list');
        console.info('Passed: ', classes);

        this.fetchStudentsList(this.context.showingStudentsOfClassIndex);
        this.setState({
          filters: {
            text: ''
          }
        })
      }
    );
  }

  componentWillUnmount() {
    this._courseReactionDispose();
    this._classesReactionDispose();
  }

  // ACTION BLOCK METHODS

  executeAction(actionValue) {
    // console.log('Action chosen is '+ actionName);
    // const actionType = this.action.find((action)=>action.value === actionName);
    console.info('Executing action: ', actionValue);
    this.setState({
      currentAction: actionValue
    });

  }

  // action param should be an obj like {name: 'userdetail', params: {id: 13}}
  openActionBox(action=null) {
    // console.log('openActionBox');
    // console.info('with action', action);

    this.setState({
      action: action,
      isActionEnabled: true,
      classListShow: false
    });
  }

  // Show image in shadow (for wrong question stat)
  showImage(path) {
    this.props.navigation.navigate('image', {path: path});
  }

  _prepareForAction() {
    let component = null;
    if (this.state.action){
      switch (this.state.action.name) {
        case 'userdetail':
          component = <StudentDetail
            student={this.state.action.params.student}
            hasConfirmed={this.state.hasConfirmed}
            goBack={() => this.actionCompleted()}
            currentClass={this.context.classes[this.context.showingStudentsOfClassIndex]}
            showImage={(path) => this.showImage(path)}
            hasScroll={true}
          />;
          break;

        case 'addusers':
          component = <AddUsers
            ref={(addUserComponent)=>this.addUserComponent = addUserComponent}
            targetClassId={this.state.action.params.targetClassId}
            hasConfirmed={this.state.hasConfirmed}
            // hasRejected={this.state.hasRejected}
            goBack={() => this.actionCompleted()}
            studentsList={this.state.listItems}
            resetMainListSelection={()=>this.resetSelection()}
          />;
          break;

        case 'showStats':
          component = <ShowStats
            // userId={this.state.action.params.id}
            hasConfirmed={this.state.hasConfirmed}
            // hasRejected={this.state.hasRejected}
            goBack={() => this.actionCompleted()}
          />;
          break;

        case 'sendMessage':
          component = <SendMessage
            // userId={this.state.action.params.id}
            hasConfirmed={this.state.hasConfirmed}
            // hasRejected={this.state.hasRejected}
            goBack={() => this.actionCompleted()}
          />;
          break;

        case 'moveInClass':
          component = <MoveInClass
            // userId={this.state.action.params.id}
            hasConfirmed={this.state.hasConfirmed}
            hasRejected={this.state.hasRejected}
            goBack={() => this.actionCompleted()}
            reject={() => this.onReject()} // For testing
            close={() => this.onClose()}
            selectedUsers={this.state.selectedUsers}
            sourceClassId={this.getActiveClass().idclasse}
            studentsList={this.state.listItems}
          />;
          break;

        default:
          break;
      }
      // Change detail component
    }
    this.activeComponent = component;
    return component;
  }

  onConfirm() {
    console.info('onConfirm()');
    // Toggle Action Box
    this.setState({
      hasConfirmed: true
    })
  }

  onClose() {
    console.info('onClose()');

    this.context.refreshClasses();
    this.setState({
      action: null,
      isActionEnabled: false,
      hasConfirmed: false,
      hasRejected:false,
      classListShow: this.checkClassListVisibility(true),
      actionPickerEnabled: false,
      selectedUsers: [],
      showingUserDetailId: null
    });
  }

  onReject() {
    console.info('onReject()');
    // Toggle Action Box
    // this.openActionBox()
    this.listView.resetSelectedItems();
    this.setState({
      action: null,
      isActionEnabled: false,
      actionPickerEnabled: false,
      classListShow: this.checkClassListVisibility(true),
      selectedUsers: [],
      showingUserDetailId: null
    });

  }

  actionCompleted() {
    // this.openActionBox();
    this.setState({
      action: null,
      hasConfirmed: false,
      isActionEnabled: false,
      classListShow: this.checkClassListVisibility(true),
      showingUserDetailId: null,
      selectedUsers: [],
    })
    this.context.refreshClasses();
  }

  onRowSelected(selectedRows=[]) {
    this.setState({
      actionPickerEnabled: selectedRows.length ? true : false,
      selectedUsers: selectedRows
    });

    // Check if the current action is adding/removing, in case reset to other list selection
    if (this.state.action && this.state.action.name === 'addusers' && this.addUserComponent) {
      this.addUserComponent.resetSelection();
    }
  }

  resetSelection() {
    this.listView.resetSelectedItems();
    this.setState({
      selectedUsers: []
    });
  }

  // DO NOT DELETE !! WILL BE USED WHEN MORE ACTIONS WILL BE AVAILABLE
  /*
  renderActionPicker() {

    const availableActions = this.actions.map((action)=>{
      // Excluding show stats when selecte users are more than 1
      action.enabled = true;
      if (action.name === 'addToClass' && this.state.selectedUsers.length > 1){
         action.enabled = false;
      }
      return action;
    });


    if (isWeb()) {
      return (
        <EgafPicker
          availableActions={availableActions}
          enabled={this.state.actionPickerEnabled}
          selectedValue={this.state.currentAction}
          style={styles.actionSelect}
          onValueChange={(itemValue, itemIndex) =>
            this.openActionBox(this.actions.find((action) => action.name === itemValue))
          }
          nullOptionLabel="Esegui un azione"
        />
      );
    } else {
      return (
        <View style={[styles.actionSelectWrapper, {backgroundColor: this.state.actionPickerEnabled? '#ffffff' : '#4B4B4B' }]}>
          <Picker
            enabled={this.state.actionPickerEnabled}
            selectedValue={this.state.currentAction}
            style={styles.actionSelect}
            onValueChange={(itemValue, itemIndex) =>
              this.openActionBox(this.actions.find((action)=>action.name === itemValue))
            }>
            <Picker.Item key={0} label={'Esegui un azione'} value={''} />
            {
              availableActions.map((action, index) => {
                return <Picker.Item key={index} label={action.label} value={action.name} />
              })
            }
          </Picker>
        </View>
      )
    }

  }
  */

  renderActionPicker() {
    if (this.state.actionPickerEnabled){
      return(
        <TouchableOpacity
          style={[classStyles.addRemoveBtn, ]}
          onPress={() => this.openActionBox({
            name: 'moveInClass',
            label: 'Sposta in classe..',
            params: {
              confirmBtnLabel: 'Conferma'
            }
          })}
        >
          <Text style={classStyles.addRemoveBtnLabel}>
            Sposta in classe
          </Text>
        </TouchableOpacity>
      )
    } else {
      return(
        <View style={[classStyles.addRemoveBtn, classStyles.addRemoveBtnDisabled]} >
          <Text style={[classStyles.addRemoveBtnLabel, classStyles.addRemoveBtnLabelDisabled]}>Sposta in classe</Text>
        </View>
      )
    }
  }

  // END ACTION BLOCK METHODS

  render() {

    if (this.state.loading){
      return(
        <View style={[styles.sectionContainer, styles.activityIndicatorContainer]}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    } else {
      return(
        <View style={styles.sectionContainer}>
          <View style={classStyles.classMainContainer}>
            {this.renderClassList()}
            <View style={classStyles.classContentWrapper}>
              {this.renderClassContent()}
            </View>
          </View>

        </View>
      );
    }
  }

  // RENDERING LIST BLOCK
  renderClassList() {
    if (this.state.classListShow) {
      return(
        <View style={classStyles.classListWrapper}>
          <View style={[classStyles.blockHeader, {marginBottom: 12}]}>
            <Text style={[styles.title1, styles.noMarginB, styles.textLight, ]}>Classi</Text>
          </View>
          <Observer>
          {
            () => {
              return <FlatList
                data={this.context.classes}
                renderItem={ (item) => this._renderClassItem(item)}
                keyExtractor={(item, index) => index.toString()}
              />;
            }
          }
        </Observer>

        </View>
      );
    }
  }

  _renderClassItem(item) {
    const classItem = item.item;
    const className = classItem.classe.toUpperCase();

    if (!parseInt(classItem.DISABILITATO)) {

      return <Observer>{() => (
        <TouchableOpacity
          style={
            this.context.showingStudentsOfClassIndex === item.index ?
              [classStyles.classListItem, classStyles.classListItemSelected]
              :
              classStyles.classListItem
          }
          onPress={() => {
            this.setState({changeClassLoading: true});
            this.changeActiveClass(item.index);
          }}
        >
          <Text style={classStyles.classListItemText} numberOfLines={2}>{className}</Text>
          {
            parseInt(classItem.PREDEFINITO) ?
              <Image data-iefix={'ieimg'} size={24} style={{width: 24, height: 24}}
                     source={require('../commons/images/star.svg')}/>
              :
              null
          }

        </TouchableOpacity>
      )}
      </Observer>
    } else {
      return null;
    }

  }

  getActionBlockConfirmLabel() {
    let label = 'Chiudi';
    if (this.state.action) {
      label = this.state.action.params.confirmBtnLabel;
    }
    return label;
  }

  // RENDERING CONTENT BLOCK
  renderClassContent() {
    if (this.state.changeClassLoading){
      return(
        <View style={[styles.activityIndicatorContainer, ]}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    } else {
      return (
        <ActionBlock
          key={'class_detail'}
          renderMasterHeader={() => this.renderMasterHeader()}
          renderMasterBody={() => this.renderMasterBody()}
          prepareForAction={() => this._prepareForAction()}
          onConfirm={() => this.onConfirm()}
          onReject={() => this.onReject()}
          isActionEnabled={this.state.isActionEnabled}
          cancelBtnLabel={'Annulla'}
          confirmBtnLabel={this.getActionBlockConfirmLabel()}
          openingSize={'medium'}
        />
      );
    }
  }

  alertMessage(message=null) {
    console.log('something went wrong');
    const msg = message? message : 'Si è verificato un errore. Riprovare più tardi, grazie';
    alert(msg);
  }

  // ADDING REMOVING USERS STUFF
  async removeUsers() {
    debugger;
    const usersId = this.state.selectedUsers.map((id) => {return {idallievo: id}});
    const sourceClassId = this.context.classes[this.context.showingStudentsOfClassIndex]['idclasse'];
    const targetClassId = this.context.getDefaultClass().idclasse;

    const changingClassResp = await axios.post("students/set?func=cambiaClasseAdAllievo", {
      idallievi: JSON.stringify(usersId),
      opera: this.context.currentCourse.opera,
      idclasseO: sourceClassId,
      idclasseD: targetClassId
    });

    // console.info('changingClassResp: ', changingClassResp.data);

    // Checking error
    let errors = [];
    for (const resp of changingClassResp.data) {
      if (!resp.status){
        const student = this.state.listItems.find((student)=>student.idAllievo === resp.idallievo);
        errors.push(
          student? student.Nome + ' ' + student.Cognome : ''
        );

        // const msg = 'Non è stato possibile spostare l\'allievo con id: '+resp.idallievo;
        // this.alertMessage(msg)
      }
    }

    if (errors.length) {
      const unmovedStudents = errors.length > 1 ? errors.join() : errors[0];
      this.alertMessage(
        'Non è stato possibile spostare il seguente allievo/i: ' + unmovedStudents,
      );
    } else {
      // updating classes
      this.context.refreshClasses();
    }

    // reset selectedusers
    this.setState({
      selectedUsers: []
    });
  }
  // END ADDING REMOVING USERS STUFF

  renderMasterHeader() {
    const activeClass = this.getActiveClass();

    // If there are no classes return null
    if (!activeClass) {
      return (
        <View style={[styles.rowContainer, styles.alignCenter, {marginTop: 20, marginBottom: 20}]}>
          <Text style={[styles.title1, styles.textLight, styles.alignCenter, ]}>Non ci sono classi assegnate al tuo utente</Text>
        </View>
      )
    }

    let actionTools = null;

    if (this.state.action) {
      switch (this.state.action.name) {
        case 'addusers':
          actionTools = <RemoveUsersButton
            removeUsers={()=>this.removeUsers()}
            isActive={this.state.selectedUsers.length}
          />
          break;

        default:
          break;
      }
    } else {
      actionTools = <View style={[styles.alignRight, styles.rowContainer, styles.alignMiddle, styles.grow]}>
        {
          // Show action button only if current user has more than one class
          this.context.classes.length > 1 ?
            this.renderActionPicker()
              :
            null
        }
        {
          // Show action button only if current user has default class available
          this.context.getDefaultClass() ?
            <AddRemoveUsersButton
              openActionBox={(action)=>this.openActionBox(action)}
            />
            :
            null
        }
      </View>;
    }

    return  (
      <View style={[actionStyles.actionMasterInnerHeader, { marginBottom: 15} ]}>
        <View style={[classStyles.classNameHeader, styles.alignLeft, styles.rowContainer, styles.alignMiddle, ]}>
          <Text numberOfLines={1} style={[styles.title1, styles.noMarginB, styles.textLight, ]}>{activeClass.classe}</Text>
        </View>
        {actionTools}
      </View>
    );
  }

  _renderClassItemGraph(stats) {
    const stat30gg = stats[2];
    const wrongPercentage = stat30gg.TOTALE? stat30gg.SBAGLIATE/stat30gg.TOTALE : 0;
    return <QuizAnswersBarChart
      key={'selected_class'}
      completed={wrongPercentage? 1 : 0}
      wrong={parseFloat(wrongPercentage.toFixed(2))}
      height={45}
    />

  }

  _renderClassItemGraphValues(stats) {
    const stat30gg = stats[2];
    const correctPercentage = stat30gg.TOTALE? stat30gg.GIUSTE/stat30gg.TOTALE*100 : 0;
    const wrongPercentage = stat30gg.TOTALE? stat30gg.SBAGLIATE/stat30gg.TOTALE*100 : 0;
    return (
      <View style={[styles.rowContainer, ]}>
        <View style={classStyles.questionsBlock}>
            <Text style={[styles.textLight, statsStyles.smallGraphText]}>Corrette</Text>
            <Text style={[styles.textLight, statsStyles.smallGraphText]}>Sbagliate</Text>
        </View>
        <View>
            <Text style={[classStyles.completedColor, statsStyles.smallGraphText]}>{correctPercentage.toFixed()}%</Text>
            <Text style={[classStyles.errorColor, statsStyles.smallGraphText]}>{wrongPercentage.toFixed()}%</Text>
        </View>
      </View>
    )

  }

  // Main filtering funcitons
  _applyAllFilters(newValue, filterType) {

    let items = Array.from(this.unfilteredListItems);
    // Preparing filters
    let filters = Object.assign({}, this.state.filters);
    filters[filterType] = newValue;
    let textFilter = filters.text.toLowerCase();

    // Filtering logic
    // Better using for of loop with continue statement for performance
    let filteredItems = [];
    for (const item of items) {

      let passedFiltering = true;

      // First Status Filters

      if (textFilter && (!item.Nome.toLowerCase().includes(textFilter) && !item.Cognome.toLowerCase().includes(textFilter))) {
        passedFiltering = false;
        continue;
      }

      if (passedFiltering){
        filteredItems.push(item);
      }
    }

    // Filtering logic
    this.setState({
      listItems: filteredItems,
      filters: filters

    });

  }

  renderMasterBody() {

    if (!this.getActiveClass()) {
      return null;
    }

    // Check if list must show selection checkboxes
    let showSelection = true;
    if (this.state.action && this.state.action.name === 'userdetail'){
      console.log('action is USERDETAIL');
      showSelection = false
    }

    // console.info('this.state.classStats.stats', this.state.classStats.stats);
    return(
      <View style={styles.container}>
        <View style={classStyles.classToolbar}>
          <View style={classStyles.classSearchW}>
            <TextInput
              style={classStyles.searchTextInput}
              placeholder={'Cerca per Nome/Cognome'}
              placeholderTextColor={'#797979'}
              onChangeText={(text) => this._applyAllFilters(text, 'text')}
              value={this.state.filters.text}
            />
          </View>

          {
            this.state.classStats && this.state.classStats.stats.length?
              <View style={[styles.rowContainer, classStyles.classGraphW]}>
                <View style={statsStyles.graphBlock}>
                  {this._renderClassItemGraph(this.state.classStats.stats)}
                </View>
                <View style={statsStyles.graphValuesBlock}>
                  {this._renderClassItemGraphValues(this.state.classStats.stats)}
                </View>
                <View style={classStyles.indicatorBlock}>
                  <StatIndicator stats={this.state.classStats.stats} compact={true} />
                </View>
              </View>
              :
              null
          }
        </View>

        <View style={styles.container}>
          <EgafSectionList
            key={"web_classes_list"}
            ref={(listView)=>this.listView = listView}
            headers={this.sectionHeaders}
            dataForListing={this.state.listItems}
            onPressRow={(id)=>this.showUserDetail(id)}
            selectedRowItemId={this.state.showingUserDetailId}
            isActionEnabled={this.state.isActionEnabled}
            hideFields={this.excludedFieldsOnActionEnabled}
            enableSelection={showSelection}
            onRowSelected={(selectedRows)=>this.onRowSelected(selectedRows)}
            refID={'IdAllievo'}
            orderingIndex={1}
            disableVirtualization={true}
            forceHeight={50}
            showPie
          />
        </View>
      </View>
    )
  }

  getActiveClass() {
    return this.context.classes[this.context.showingStudentsOfClassIndex];
  }

  async changeActiveClass(newIndex){
    console.log('changeActiveClass: '+newIndex);

    console.info('this.cancelTokenExecutorFunction', this.cancelTokenExecutorFunction);
    this.source.cancel('User changed class');

    this.source = CancelToken.source();

    // this.context.updateShowingClassIndex(newIndex);
    this.context.showingStudentsOfClassIndex = newIndex;
    // Fetch users for clicked class
    const classId = this.context.classes[newIndex]['idclasse'];
    const usersForClass = await this.getUsersForActiveClass(classId);
    // Get class stats
    this.getCurrentClassStats(classId);

    this.setState({
      selectedUsers: [],
      actionPickerEnabled: false,
      listItems: usersForClass,
      changeClassLoading: false,
      filters: {
        text: ''
      }
    })
  }
  // Specific Class methods

  // Web only method, specify action

  showUserDetail(student) {
    // If we are adding removing users do nothing
    if (this.state.action && this.state.action.name === 'addusers') {
      return;
    }

    console.info('PRESSED ROW: showUserDetail(id)', student);
    const action = {
        name: 'userdetail',
        label: 'Mostra anagrafica',
        params: {
          student: student,
          confirmBtnLabel: 'Chiudi'
        }
      };
    this.setState({
      selectedUsers: [],
      showingUserDetailId: student.IdAllievo
    });
    this.openActionBox(action);
  }

  showUserStats(id) {
    const action = {
        name: 'showStats',
        label: 'Mostra statistiche',
        params: {
          id: id,
          confirmBtnLabel: 'Chiudi'
        }
      };
    this.setState({
      selectedUsers: [],
      showingUserDetailId: id
    });
    this.openActionBox(action);
  }

}


// Move student from preset class to destination class
class AddUsers extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.sectionHeaders = [
      {name: 'Nome', prop: 'Nome'},
      {name: 'Cognome', prop: 'Cognome'},
      {name: 'Email', prop: 'Email'},
      // {name: 'Sesso', prop: 'gender'},
      // {name: 'Città', prop: 'city'},
      // {name: 'Status', prop: 'status'}
    ];

    this.currentClassId = null;

    this.listView = null;

    this.state = {
      //initial loading
      loading: true,
      listItems: [],
      selectedUsers: [],
      userTextFilter: '',
    }
  }

  async fetchStudentsList() {

    const classDefault = this.context.getDefaultClass();
    console.info('Class default is: ', classDefault);
    this.currentClassId = classDefault.idclasse;
    const studentsEndpoint = '/students/getallieviclasse/'+classDefault.idclasse;
    const studentsResp = await axios.get(studentsEndpoint);

    console.info('Studentes to ADD: ', studentsResp.data);
    this.unfilteredListItems = studentsResp.data;

    this.setState({
      loading: false,
      listItems: this.unfilteredListItems,
    })
  }

  componentDidMount() {
    this.fetchStudentsList();

    this._addUsersReactionDispose = reaction(
      () => this.context.classes,
      () => {
        this.fetchStudentsList();
      }
    );
  }

  componentWillUnmount() {
    this._addUsersReactionDispose();
  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {
   if (this.props.hasConfirmed  && !prevProps.hasConfirmed){
      console.log('componentDidUpdate.... executeAction');
      this.executeAction();
    }

    // console.log('componentDidUpdate, prevProps.hasRejected:' + prevProps.hasRejected);
    if (this.props.hasRejected && !prevProps.hasRejected){
      console.log('componentDidUpdate.... rejectAction');
      this.rejectAction();
    }
  }

  alertMessage(message=null) {
    console.log('something went wrong');
    const msg = message? message : 'Si è verificato un errore. Riprovare più tardi, grazie';
    alert(msg);
    console.log('closed alert');
  }

  async executeAction() {
    console.log('Executing action (move user from preset to other class) and reporting eventual errors');

    debugger;
    const usersId = this.state.selectedUsers.map((id) => {return {idallievo: id}});
    const sourceClassId = this.currentClassId;
    const targetClassId = this.props.targetClassId;

    const changingClassResp = await axios.post("students/set?func=cambiaClasseAdAllievo", {
      idallievi: JSON.stringify(usersId),
      opera: this.context.currentCourse.opera,
      idclasseO: sourceClassId,
      idclasseD: targetClassId
    });

    console.info('changingClassResp: ', changingClassResp.data);

    // Checking error
    let errors = [];
    for (const resp of changingClassResp.data) {
      if (!resp.status){
        const student = this.unfilteredListItems.find((student)=>student.idAllievo === resp.idallievo);
        errors.push(
          student? student.Nome + ' ' + student.Cognome : ''
        );

        // const msg = 'Non è stato possibile spostare l\'allievo con id: '+resp.idallievo;
        // this.alertMessage(msg)
      }
    }
    if (errors.length) {
      const unmovedStudents = errors.length > 1 ? errors.join() : errors[0];
      this.alertMessage(
        'Non è stato possibile spostare il seguente allievo/i: ' + unmovedStudents,
      );
    } else {
      // Something has changed, refresh classes
      // updating classes
      this.context.refreshClasses();
    }

    // reset selectedusers in any case
    this.resetSelection();
    // this.goBack();
  }

  resetSelection() {
    this.listView.resetSelectedItems();
    this.setState({
      selectedUsers: []
    });
  }

  rejectAction() {
    console.log('rejectAction.... now close all');
    this.setState({
      // hasRejected: false,
    });
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  onRowSelected(selectedUsers) {
    this.setState({selectedUsers});
    // resetting selection on main list
    this.props.resetMainListSelection();
  }

  filterByText(searchString) {
    let items = Array.from(this.unfilteredListItems);
    if (searchString) {
      items = items.filter((item) => {

        return item.Nome.toLowerCase().startsWith(searchString.toLowerCase()) || item.Cognome.toLowerCase().startsWith(searchString.toLowerCase())

      });
    }
    this.setState({
      listItems: items,
      userTextFilter: searchString
    })
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.navigation.navigate.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.sendInvitation()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <View style={[styles.container, styles.activityIndicatorContainer]}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    } else {
      return (
        <View style={[styles.container, classStyles.addRemoveWrapper]}>
          <View style={[actionStyles.actionDetailInnerHeader, classStyles.addUsersHeader]}>
            <Text style={actionStyles.actionDetailTitle}>Allievi disponibili</Text>
            <AddUsersButton
              isActive={this.state.selectedUsers.length}
              onPress={()=>this.executeAction()}
            />

          </View>
          <View style={classStyles.searchFilter}>
            <TextInput
              style={[prospectStyles.searchTextInput, classStyles.searchFilterDarker]}
              placeholder={'Filtra per Nome/Cognome'}
              onChangeText={(text) => this.filterByText(text)}
              value={this.state.userTextFilter}
            />
          </View>
          <EgafSectionList
            key={"add_users_list"}
            ref={(listView)=>this.listView = listView}
            headers={this.sectionHeaders}
            dataForListing={this.state.listItems}
            onRowSelected={(selectedRows)=>this.onRowSelected(selectedRows)}
            enableSelection={true}
            refID={'idAllievo'}
            // onPressRow={(id)=>this.goToUserDetail(id)}
            // isActionEnabled={this.state.isActionEnabled}
            // hideFields={this.excludedFieldsOnActionEnabled}
          />
          {this.renderBtnToolbar()}
        </View>
      );
    }
  }
}


export class StudentDetail extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      studentDetails: null,
      studentStatPeriod: 30,

      statTabIndex: 0
    }
  }

  alertMessage(message=null) {
    console.log('something went wrong');
    const msg = message? message : 'Non è stato possibile processare le statistiche per questo studente. Riprovare più tardi. Grazie';
    alert(msg);
  }

  componentDidMount() {

    this.setState({
      loading: false,
      studentDetails: this.props.student,

    });
  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.hasConfirmed  && !prevProps.hasConfirmed){
      this.executeAction();
    }

    // console.log('componentDidUpdate, prevProps.hasRejected:' + prevProps.hasRejected);
    if (this.props.hasRejected && !prevProps.hasRejected){
      this.rejectAction();
    }

    // Check if operator has changed (by pressing another row), in case update the operator infos
    if (this.props.student !== prevProps.student){
      this.setState({
        studentDetails: this.props.student
      });
      this.hideResultsDrillDown();
    }
  }

  executeAction() {
    // console.log('loading.... No action for this task, going back');
    this.goBack();
  }

  rejectAction() {
    // console.log('rejectAction.... now close all');
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  updateDetail(prop, value) {
    let studentDetails = {...this.state.studentDetails};
    studentDetails[prop] = value;
    this.setState({
      studentDetails,
      statusInfo: '',
    });
    this.hideResultsDrillDown();
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.navigation.navigate.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.sendInvitation()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  // Open image in shadow
  showImage(path) {
    console.info('PATH:', path);
    this.props.showImage(path);
    // this.props.navigation.navigate('image', {path: path});
  }

  renderStudentStatistics() {
    return(
     <View >
        <StudentStatsIndex
          period={this.context.statsPeriod}
          student={this.state.studentDetails}
          onTabChange={(tabIndex) => this.setState({statTabIndex: tabIndex})}
          showImage={(path) => this.showImage(path)}
          topicDrillDown={(idLesson, title)=>this.topicDrillDown(idLesson, title)}
          resultsDrillDown={(idQuestionnaire, idAllievo)=>this.resultsDrillDown(idQuestionnaire, idAllievo)}
        />
      </View>
    );

  }

  topicDrillDown(idLesson, title) {
    this.setState({
      showTopicDrillPanel: true,
      drillParams: {
        title: title,
        idAllievo: this.props.student.IdAllievo,
        idClasse: this.props.currentClass.idclasse,
        opera: this.context.currentCourse.opera,
        idLezione: idLesson,
        giorni: this.context.statsPeriod
      }
    });
  }

  resultsDrillDown(idQuestionnaire, idAllievo) {
    this.setState({
      showResultsDrillPanel: true,
      drillParams: {
        idQuestionnaire,
        idAllievo
      }
    });
  }

  hideResultsDrillDown() {
    this.setState({
      showResultsDrillPanel: false,
      drillParams: {}
    });
  }

  drillBack() {
    this.setState({
      showTopicDrillPanel: false,
      showResultsDrillPanel: false
    });
  }

  render() {
    if (this.state.studentDetails) {
      const student = this.state.studentDetails;
      return (
        <View style={styles.container}>

          <ScrollView style={actionStyles.scrollableView}>

            <View style={actionStyles.actionDetailInnerHeader}>
              { student.image?
                <Image
                  style={{width: 40, height: 40, borderRadius: 50, marginRight: 20}}
                  source={student.image}
                />
                :
                <Image
                  style={{width: 40, height: 40, marginRight: 20}}
                  source={require('../commons/images/profile_icon.png')}
                />
              }
              <Text style={actionStyles.actionDetailTitle}>Anagrafica</Text>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridBlock_25}>
                <Text style={styles.label}>Nome:</Text>
                <Text style={[styles.textInputDisabled,]}>{this.state.studentDetails.Nome}</Text>
              </View>

              <View style={styles.gridBlock_25}>
                <Text style={styles.label}>Cognome:</Text>
                <Text style={[styles.textInputDisabled,]}>{this.state.studentDetails.Cognome}</Text>
              </View>

              <View style={styles.gridBlock_50}>
                <Text style={styles.label}>Email:</Text>
                <Text style={[styles.textInputDisabled,]}>{this.state.studentDetails.Email}</Text>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridBlock_25}>
                  <Text style={styles.label}>Telefono:</Text>
                  {this.state.studentDetails.Telefono ?
                    <Text style={[styles.textInputDisabled,]}>{this.state.studentDetails.Telefono + ' '}</Text>
                    :
                    <Text style={[styles.textInputDisabled,]}>Non indicato</Text>
                  }
              </View>

              <View style={styles.gridBlock_25}>
                <Text style={styles.label}>Città:</Text>
                <Text style={[styles.textInputDisabled,]}>{this.state.studentDetails.Citta}</Text>
              </View>

              <View style={styles.gridBlock_50}>
                <Text style={styles.label}>Data Iscrizione:</Text>
                <Text style={[styles.textInputDisabled,]}>{getFormattedDate(this.state.studentDetails.DataIscrizione)}</Text>
              </View>
            </View>

            <View style={[actionStyles.actionDetailInnerHeader, {marginTop: 12, marginBottom: 10, zIndex: 10}]}>
              <Image
                  style={{width: 40, height: 40, marginRight: 20}}
                  source={require('../commons/images/stat_icon.png')}
                />
              <Text style={actionStyles.actionDetailTitle}>Statistiche</Text>

              <View style={{flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>

                {
                  this.props.hidePeriodSelector?
                    null
                    :
                    <EgafStatPeriodSelect
                      selectedValue={this.context.statsPeriod}
                      style={statsStyles.periodSelector}
                      onValueChange={(period) => {
                        this.context.statsPeriod = period;
                        this.setState({
                          studentStatPeriod: period
                        })
                      }}
                      enabled
                    />
                }
              </View>
            </View>

            {this.renderStudentStatistics()}

            {this.renderBtnToolbar()}

          </ScrollView>

          <TopicsDrillDownPanel
            showDrillPanel={this.state.showTopicDrillPanel}
            close={() => this.drillBack()}
            drillParams={this.state.drillParams}
            hasScroll={this.props.hasScroll}
            showImage={(path) => this.showImage(path)}
          />

          <ResultsDrillDownPanel
            showDrillPanel={this.state.showResultsDrillPanel}
            close={() => this.drillBack()}
            drillParams={this.state.drillParams}
            hasScroll={this.props.hasScroll}
            showImage={(path) => this.showImage(path)}
          />

        </View>
      );
    } else {
      return(
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    }
  }

  editablefields__render() {
    if (this.state.studentDetails) {
      const student = this.state.studentDetails;
      return (
        <View style={styles.container}>
          <View style={actionStyles.actionDetailInnerHeader}>
            { student.image?
              <Image
                style={{width: 50, height: 50, borderRadius: 50, marginRight: 20}}
                source={student.image}
              />
              :
              null
            }
            <Text style={actionStyles.actionDetailTitle}>Anagrafica</Text>
          </View>

          {/*<View style={styles.rowContainer}>*/}
            {/*<View style={classStyles.questionsBlock}>*/}
                {/*<Text style={styles.textLight}>Domande</Text>*/}
                {/*<Text style={styles.textLight}>Completate</Text>*/}
                {/*<Text style={styles.textLight}>Errori</Text>*/}
            {/*</View>*/}
            {/*<View>*/}
                {/*<Text style={classStyles.totalColor}>1824</Text>*/}
                {/*<Text style={classStyles.completedColor}>1193 (65%)</Text>*/}
                {/*<Text style={classStyles.errorColor}>155 (13%)</Text>*/}
            {/*</View>*/}
            {/*<View style={classStyles.quizGraphWrapper}>*/}
              {/*<QuizAnswersBarChart*/}
                {/*completed={0.65}*/}
                {/*wrong={0.23}*/}
                {/*height={40}*/}
                {/*// maxWidth={500}*/}
              {/*/>*/}
            {/*</View>*/}
          {/*</View>*/}

          <ScrollView style={actionStyles.scrollableView}>
            <Text style={styles.label}>Nome:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(name) => this.updateDetail('Nome', name)}
              value={this.state.studentDetails.Nome}
            />

            <TextInput
              style={styles.textInput}
              onChangeText={(surname) => this.updateDetail('Cognome', surname)}
              value={this.state.studentDetails.Cognome}
            />

            <Text style={styles.label}>Email:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(email) => this.updateDetail('Email', email)}
              value={this.state.studentDetails.Email}
            />

            <Text style={styles.label}>Telefono:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(phone) => this.updateDetail('Telefono', phone)}
              value={this.state.studentDetails.Telefono}
            />

            <Text style={styles.label}>Città:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(city) => this.updateDetail('Citta', city)}
              value={this.state.studentDetails.Citta}
            />

            <Text style={styles.label}>Data Iscrizione:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(subscriptionDate) => this.updateDetail('DataIscrizione', subscriptionDate)}
              value={this.state.studentDetails.DataIscrizione}
            />


            { this.state.statusInfo?
              <Text style={styles.label}>{this.state.statusInfo}</Text>
              :
              null
            }

            {this.renderBtnToolbar()}

          </ScrollView>

        </View>
      );
    } else {
      return(
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    }
  }

}


class ShowStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null
    }
  }

  async loadStats() {
    const response = await axios.get();
    const data = response.data.results[0];
    console.info(response.data.results[0]);
    //Adapting randomuserresp to egaf endpoint props
    const detail = {
      name: data.name.first,
      surname: data.name.last,
      email: data.email,
      image: data.picture.thumbnail,
      stats: {
        totalCompleted: 184,
        totalwrong: 34,
        details: [
          {
            lesson: 'Segnali stradali',
            total: 20,
            completed: 15,
            wrong: 4
          },
          {
            lesson: 'Segnali orizzontali',
            total: 30,
            completed: 12,
            wrong: 8
          },
          {
            lesson: 'Segnali luminosi',
            total: 40,
            completed: 10,
            wrong: 2
          },
          {
            lesson: 'Segnali complementari',
            total: 30,
            completed: 19,
            wrong: 4
          },
          {
            lesson: 'Velocità e limiti',
            total: 30,
            completed: 15,
            wrong: 5
          },

        ]
      }
    }

    this.setState({
      userData: detail
    })
  }

  componentDidMount() {
    // this.loadStats();
  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.hasConfirmed){
      this.executeAction();
    }
  }

  executeAction() {
    console.log('loading.... No action for this task, going back');
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.navigation.navigate.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.sendInvitation()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  render() {
    if (this.state.userData) {
      const user = this.state.userData;
      return (
        <View style={styles.container}>
          <View style={actionStyles.actionDetailInnerHeader}>
            <Image
              style={{width: 50, height: 50, borderRadius: 50, marginRight: 20}}
              source={user.image}
            />
            <Text style={actionStyles.actionDetailTitle}>Statistiche di {user.name} {user.surname}</Text>
          </View>

          <View style={styles.rowContainer}>
            <View style={classStyles.questionsBlock}>
                <Text style={styles.textLight}>Domande</Text>
                <Text style={styles.textLight}>Completate</Text>
                <Text style={styles.textLight}>Errori</Text>
            </View>
            <View>
                <Text style={classStyles.totalColor}>1824</Text>
                <Text style={classStyles.completedColor}>1193 (65%)</Text>
                <Text style={classStyles.errorColor}>155 (13%)</Text>
            </View>
            <View style={classStyles.quizGraphWrapper}>
              <QuizAnswersBarChart
                completed={0.65}
                wrong={0.23}
                height={40}
                // maxWidth={500}
              />
            </View>
          </View>

          <ScrollView style={actionStyles.scrollableView}>
            <View style={classStyles.lessonTable}>
              <View style={classStyles.lessonRow}>
                <View style={classStyles.lessonCol1}>
                  <Text numberOfLines={1} style={[classStyles.rowHeader, styles.textLight]}>Lezioni</Text>
                </View>
                <View style={classStyles.lessonCol2}>
                  <Text numberOfLines={1} style={[classStyles.rowHeader, classStyles.headerRight, styles.textLight]}>Domande</Text>
                </View>
                <View style={classStyles.lessonCol3}>
                  <Text numberOfLines={1} style={[classStyles.rowHeader, classStyles.headerRight, styles.textLight]}>% Com.</Text>
                </View>
                <View style={classStyles.lessonCol4}>
                  <Text numberOfLines={1} style={[classStyles.rowHeader, classStyles.headerRight, styles.textLight]}>Errori</Text>
                </View>
              </View>
              {
                user.stats.details.map((stat, index) => {
                  return <View style={classStyles.lessonRow} key={'lesson_'+index}>
                    <View style={classStyles.lessonCol1}>
                      <Text numberOfLines={1} style={[classStyles.lessonName, styles.textLight]}>{stat.lesson}</Text>
                    </View>
                    <View style={classStyles.lessonCol2}>
                      <Text numberOfLines={1} style={[classStyles.statData, styles.textLight]}>{stat.total}</Text>
                    </View>
                    <View style={classStyles.lessonCol3}>
                      <Text numberOfLines={1} style={[classStyles.statData, styles.textLight]}>{stat.completed}</Text>
                    </View>
                    <View style={classStyles.lessonCol4}>
                      <Text numberOfLines={1} style={[classStyles.statData, styles.textLight]}>{stat.wrong}</Text>
                    </View>
                  </View>;
                })
              }
            </View>
          </ScrollView>

        </View>
      );
    } else {
      return(
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    }
  }

}


class SendMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: true
    }
  }


  componentDidMount() {
  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.hasConfirmed){
      this.executeAction();
    }
  }

  executeAction() {
    console.log('loading.... No action for this task, going back');
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.navigation.navigate.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.sendInvitation()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  render() {
    if (this.state.userData) {
      const user = this.state.userData;
      return (
        <View style={styles.container}>
          <View style={actionStyles.actionDetailInnerHeader}>
            <Text style={actionStyles.actionDetailTitle}>Invia un messaggio</Text>
          </View>

          <ScrollView style={actionStyles.scrollableView}>

            <Text style={styles.label}>Selezione messaggio predefinito:</Text>
            <View style={styles.selectWrapper}>
              <Picker
                selectedValue={user.role}
                style={styles.select}
                onValueChange={(itemValue, itemIndex) =>
                  null
                }>
                <Picker.Item key={'1'} label={'Messaggio uno'} value={'m1'} />
                <Picker.Item key={'2'} label={'Messaggio due'} value={'m2'} />
                <Picker.Item key={'3'} label={'Messaggio tre'} value={'m3'} />
              </Picker>
            </View>

            <Text style={styles.label}>Oggetto:</Text>
            <TextInput
              style={styles.textInput}
              onChangeText={(name) => null}
              value={''}
            />

            <Text style={styles.label}>Messaggio:</Text>
            <TextInput
              multiline = {true}
              numberOfLines = {8}
              style={[styles.textArea, styles.noMarginB]}
              onChangeText={(message) => null}
              value={''}
            />
          </ScrollView>

        </View>
      );
    } else {
      return(
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    }
  }

}


class MoveInClass extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      //initial loading
      selectedClass: null
    }
  }

  componentDidMount() {
    // this.setState({
    //   selectedClass: this.context.classes[0]
    // })
  }

  componentWillUnmount() {

  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {
   if (this.props.hasConfirmed  && !prevProps.hasConfirmed){
      console.log('componentDidUpdate.... executeAction');
      this.executeAction();
    }

    // console.log('componentDidUpdate, prevProps.hasRejected:' + prevProps.hasRejected);
    if (this.props.hasRejected && !prevProps.hasRejected){
      console.log('componentDidUpdate.... rejectAction');
      this.rejectAction();
    }
  }

  alertMessage(message=null) {
    console.log('something went wrong');
    const msg = message? message : 'Si è verificato un errore. Riprovare più tardi, grazie';
    alert(msg);
    console.log('closed alert');
  }

  async executeAction() {
    console.log('execute action cambiaClasseAdAllievo');

    debugger;
    const usersId = this.props.selectedUsers.map((id) => {return {idallievo: id}});
    const sourceClassId = this.props.sourceClassId;
    const targetClassId = this.state.selectedClass.idclasse;

    const changingClassResp = await axios.post("students/set?func=cambiaClasseAdAllievo", {
      idallievi: JSON.stringify(usersId),
      opera: this.context.currentCourse.opera,
      idclasseO: sourceClassId,
      idclasseD: targetClassId
    });

    const resp = changingClassResp.data;
    console.info('changingClassResp: ', resp);
    // TODO Check response, if there are errors, in case notify them
    let errors = [];
    for (const resp of changingClassResp.data) {
      if (!resp.status){
        const student = this.props.studentsList.find((student)=>student.idAllievo === resp.idallievo);
        errors.push(
          student? student.Nome + ' ' + student.Cognome : ''
        );

        // const msg = 'Non è stato possibile spostare l\'allievo con id: '+resp.idallievo;
        // this.alertMessage(msg)
      }
    }

    if (errors.length){
      const unmovedStudents = errors.length > 1 ? errors.join() : errors[0];
      if (isWeb()){
        this.alertMessage(
          'Non è stato possibile spostare il seguente allievo/i: ' + unmovedStudents,
        );
      } else {
        // Here Alert for app
      }

    }

    // Everything was fine, eventually alertmessage "Everything ok!!"
    this.setState({
      // statusInfo: 'Non è stato possibile assegnare lo studente alla classe specificata.',
      loading: false,
    });

    // Close panel with class list
    this.props.close();

  }

  rejectAction() {
    console.log('rejectAction.... now close all');
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.navigation.navigate.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.sendInvitation()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  chooseClass(classItem) {
    this.context.hasUnprocessedEdits = true;
    this.setState({
      selectedClass: classItem
    })
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={[actionStyles.actionDetailInnerHeader, classStyles.addUsersHeader]}>
          <Text style={actionStyles.actionDetailTitle}>Scegli la Classe di destinazione</Text>
        </View>
        <ScrollView style={classStyles.chooserWrapper}>
          {this.context.classes.map((classItem) => {
              if (classItem.idclasse !== this.props.sourceClassId) {
                return (
                  <ListItem
                    key={classItem.idclasse}
                    text={classItem.classe}
                    item={classItem}
                    isCurrent={this.state.selectedClass && this.state.selectedClass.idclasse === classItem.idclasse? true : false}
                    onChoose={(classItem) => this.chooseClass(classItem)}
                  />
                )
              }
              return null;
            })
          }
        </ScrollView>
        {this.renderBtnToolbar()}
      </View>
    );
  }
}

/*
class AddToClass extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      //initial loading
      selectedClass: null,
      statusInfo: '',
    }
  }

  componentDidMount() {
    this.setState({
      selectedClass: this.context.classes[0]
    })
  }

  componentWillUnmount() {

  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {
   if (this.props.hasConfirmed  && !prevProps.hasConfirmed){
      console.log('componentDidUpdate.... executeAction');
      this.executeAction();
    }

    // console.log('componentDidUpdate, prevProps.hasRejected:' + prevProps.hasRejected);
    if (this.props.hasRejected && !prevProps.hasRejected){
      console.log('componentDidUpdate.... rejectAction');
      this.rejectAction();
    }
  }

  alertMessage(message=null) {
    console.log('something went wrong');
    const msg = message? message : 'Si è verificato un errore. Riprovare più tardi, grazie';
    alert(msg);
    console.log('closed alert');
  }

  async executeAction() {

    // Try adding all selected students
    let errors = [];


    const targetClassId = this.state.selectedClass.idclasse;
    for (const studentId of this.props.selectedUsers) {
      console.info('T class:', targetClassId);
      console.info('xhr studentId: ', studentId);
      console.info('xhr opera: ', this.context.currentCourse.opera);
      console.info('xhr targetClass: ', targetClassId);
      const assignClassResp = await axios.post("students/set?func=assegnaClasseAdAllievo", {
        idallievo: studentId,
        opera: this.context.currentCourse.opera,
        idclasse: targetClassId
      });

      const resp = assignClassResp.data;
      console.info('assegnaClasseAdAllievoResp: ', resp);

      if (resp.hasOwnProperty('assegnaClasseAdAllievo') && !resp.assegnaClasseAdAllievo) {
        const student = this.props.studentsList.find((student)=>student.idAllievo === studentId);
        errors.push(
          student? student.Nome + ' ' + student.Cognome : ''
        );
      }
    }

    if (errors.length) {
      const unmovedStudents = errors.length > 1 ? errors.join() : errors[0];
      this.alertMessage(
        'Non è stato possibile aggiungere il seguente allievo: ' + unmovedStudents,
      );
      this.setState({
        // statusInfo: 'Non è stato possibile assegnare lo studente alla classe specificata.',
        loading: false,
      });

    } else {
      this.context.refreshClasses();
    }
    this.setState({
      selectedUsers: []
    });
    this.goBack();

  }

  async __executeAction() {
    const userId = this.props.selectedUsers[0];
    const targetClassId = this.state.selectedClass.idclasse;

    const assignClassResp = await axios.post("students/set?func=assegnaClasseAdAllievo", {
      idallievo: userId,
      opera: this.context.currentCourse.opera,
      idclasse: targetClassId
    });

    const resp = assignClassResp.data;

    console.info('assegnaClasseAdAllievoResp: ', resp);

    if (resp.hasOwnProperty('assegnaClasseAdAllievo') && resp.assegnaClasseAdAllievo) {
        console.log('Successfully assiged class to student');

        // this.alertMessage('Utente aggiunto con successo');

        this.setState({
          // statusInfo: 'Studente assegnato alla classe indicata!',
          loading: false,
        });
        this.context.refreshClasses();

        // setTimeout(() => {
        //   this.goBack();
        // }, 1500);
        this.goBack();

      } else {

        this.alertMessage('Non è stato possibile assegnare lo studente alla classe specificata.');

        this.setState({
          // statusInfo: 'Non è stato possibile assegnare lo studente alla classe specificata.',
          loading: false,
        });

        // setTimeout(() => {
        //   this.goBack();
        // }, 1500);
      }
  }

  rejectAction() {
    console.log('rejectAction.... now close all');
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.navigation.navigate.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.sendInvitation()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  chooseClass(classItem) {
    this.context.hasUnprocessedEdits = true;
    this.setState({
      selectedClass: classItem
    })
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={[actionStyles.actionDetailInnerHeader, classStyles.addUsersHeader]}>
          <Text style={actionStyles.actionDetailTitle}>Scegli la Classe di destinazione</Text>
        </View>
        <ScrollView style={classStyles.chooserWrapper}>
          {this.context.classes.map((classItem) => {
            if (classItem.idclasse !== this.props.sourceClassId) {
              return (
                <ListItem
                  key={classItem.idclasse}
                  text={classItem.classe}
                  item={classItem}
                  isCurrent={this.state.selectedClass && this.state.selectedClass.idclasse === classItem.idclasse? true : false}
                  onChoose={(classItem) => this.chooseClass(classItem)}
                />
              )
            }
          })
          }
        </ScrollView>

        { this.state.statusInfo?
          <Text style={styles.label}>{this.state.statusInfo}</Text>
          :
          null
        }

        {this.renderBtnToolbar()}
      </View>
    );
  }
}
*/

const ListItem = (props) => {

  return(
    <TouchableOpacity
      style={classStyles.listItem}
      onPress={() => props.onChoose(props.item)}
    >
      <EgafSelectionCheck isSelected={props.isCurrent} isSmall={true} />
      <Text style={classStyles.listItemName}>{props.text}</Text>
    </TouchableOpacity>
  );

}


