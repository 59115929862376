import React from "react";
import {View, Text, ScrollView, ActivityIndicator, TouchableOpacity} from 'react-native';
import {ListItem} from '../views';
import UserContext from "../../user";
import axios from "../../commons/axios";
import homeStyles from "../styles";
import {actionStyles, styles} from "../../foundation/styles";
import {isWeb} from "../../commons/utils";
import {LoginAction} from "../../navigator/actions";


export class WizardCloudChooserView extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.currentCloud = null;
    this.currentLocation = null;
    this.currentCourse = null;

    this.state = {
      loading: true,


      loadingLocations: true,
      locationsList: [],
      selectedLocation: null,

      loadingCourses: true,
      coursesList: [],
      selectedCourse: null
    }
  }

  alertMessage(message = null) {
    const msg = message ? message : 'Non è stato possibile salvare la preferenza';
    alert(msg);
  }

  async loadCloudsList() {
    const cloudsResp = await axios.get("users/", {
      params: {
        COD_LOGIN: this.context.COD_LOGIN
      }
    });
    this.cloudsList = cloudsResp.data;

    let selectedCloud = null;
    if (this.context.currentCloud.hasOwnProperty('idnuvola')) {
      selectedCloud = this.context.currentCloud
    }
    this.currentCloud = selectedCloud;

    // If selectedCloud not set menas we are at very first login
    if (selectedCloud) {
      const selectedLocation = this.context.currentLocation? this.context.currentLocation : null;
      this.currentLocation = selectedLocation;
      const selectedCourse = this.context.currentCourse? this.context.currentCourse : null;
      this.currentCourse = selectedCourse;

      this.setState({
        selectedLocation: selectedLocation,
        selectedCourse: selectedCourse
      });

      if (selectedLocation){
        await this.loadLocationsList();
      }
      if (selectedCourse) {
        await this.loadCoursesList();
      }
    }

    this.setState({
      loading: false,
      selectedCloud: selectedCloud
    })

  }

  async loadLocationsList() {
    // https://autoscuole.egaf.it/users?idutente=2&idnuvola=900187
    const locationsResp = await axios.get("users/", {
      params: {
        idutente: this.currentCloud.idutente,
        idnuvola: this.currentCloud.idnuvola
      }
    });
    const locations = locationsResp.data;

    if (locations.length === 1) {
      this.currentLocation = locations[0];
      this.loadCoursesList();
      this.setState({
        loadingLocations: false,
        locationsList: locations,
        selectedLocation: locations[0]
      });
    } else {

      this.setState({
        loadingLocations: false,
        locationsList: locations
      });
    }

  }

  async loadCoursesList() {
    // https://autoscuole.egaf.it/users?idutente=2&COD_INTERL=900187&COD_SIGLAIND=.
    const coursesResp = await axios.get("users/", {
      params: {
        idutente: this.currentCloud.idutente,
        COD_INTERL: this.currentLocation.COD_INTERL,
        COD_SIGLAIND: this.currentLocation.COD_SIGLAIND
      }
    });
    const courses = coursesResp.data;

    this.setState({
      loadingCourses: false,
      coursesList: courses
    });

  }

  _onKeyDown(e) {
    try {
      const key = e.key || e.keyCode;
      if (key === 'Escape') {
        if (this.props.navigation.isFocused()) {
          this.reject();
        }
      }
    } catch (e) {
      console.log('error for event key', e.key);
    }
  }

  componentDidMount() {
    if (isWeb()) {
      document.addEventListener("keydown", (e) => this._onKeyDown(e));
    }
    this.loadCloudsList();
  }

  componentWillUnmount() {
    if (isWeb()) {
      document.removeEventListener("keydown", (e) => this._onKeyDown(e));
    }
  }

  // CONFIRM
  async confirm() {
    this.setState({
      loading: true,
    });

    // Saving cloud location and course preference, then reinit app
    // Saving CLOUD
    try {
      //https://autoscuole.egaf.it/users/set?func=setNuvolaDefault
      const cloudPreferenceSaved = await axios.post("users/set?func=setNuvolaDefault",
        {
          idutente: this.currentCloud.idutente,
          idnuvola: this.currentCloud.idnuvola,
        });
      // console.info('SAVING CLOUD PREF:', cloudPreferenceSaved.data);
      if (cloudPreferenceSaved.data && parseInt(cloudPreferenceSaved.data[0].success) !== 1) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    } catch (e) {
      if (e.response.status !== 403) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    }

    // Saving LOCATION
    try {
      //https://autoscuole.egaf.it/users/set?func=setLocationDefault
      const locationPreferenceSaved = await axios.post("users/set?func=setLocationDefault",
        {
          idutente: this.currentCloud.idutente,
          idnuvola: this.currentCloud.idnuvola,
          COD_INTERL: this.currentLocation.COD_INTERL,
          COD_SIGLAIND: this.currentLocation.COD_SIGLAIND
        });
      // console.info('SAVING LOCATION PREF:', locationPreferenceSaved.data);
      if (locationPreferenceSaved.data && parseInt(locationPreferenceSaved.data[0].success) !== 1) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    } catch (e) {
      if (e.response.status !== 403) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    }

    // Saving COURSE
    try {
      //https://autoscuole.egaf.it/users/set?func=setCorsoDefault
      const coursePreferenceSaved = await axios.post("users/set?func=setCorsoDefault",
        {
          idutente: this.currentCloud.idutente,
          idnuvola: this.currentCloud.idnuvola,
          COD_INTERL: this.currentLocation.COD_INTERL,
          COD_SIGLAIND: this.currentLocation.COD_SIGLAIND,
          opera: this.currentCourse.opera
        });
      // console.info('SAVING COURSE PREF:', coursePreferenceSaved.data);
      if (coursePreferenceSaved.data && parseInt(coursePreferenceSaved.data[0].success) !== 1) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    } catch (e) {
      if (e.response.status !== 403) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    }

    // INIT app
    await this.context.initWithCloud();
    this.props.navigation.dispatch(LoginAction);
  }

  // Cancel
  reject() {
    // console.log('rejecting');
    this.props.navigation.pop();
  }


  // CHOOSING ACTIONS
  chooseCloud(cloud) {
    this.currentCloud = cloud;
    this.currentLocation = null;
    this.currentCourse = null;
    this.setState({
      selectedCloud: cloud,
      selectedLocation: null,
      loadingCourses: true,
      coursesList: [],
      selectedCourse: null
    });
    this.loadLocationsList();
  }

  chooseLocation(location) {
    this.currentLocation = location;
    this.currentCourse = null;
    this.setState({
      selectedLocation: location,
      selectedCourse: null
    });
    this.loadCoursesList();
  }

  chooseCourse(course) {
    this.currentCourse = course;
    this.setState({
      selectedCourse: course
    });
  }

  render() {
    console.info('this.state.selectedCourse:', this.state.selectedCourse);

    if (this.state.loading) {
      return (
        <View style={homeStyles.chooserContainer}>
          <ActivityIndicator/>
        </View>
      )
    } else {

      return (
        <View style={[homeStyles.chooserContainer]}>
          <View style={[homeStyles.chooserColWrapper, ]}>
            {this.renderCloudSection()}
            {this.renderLocationSection()}
            {this.renderCourseSection()}
          </View>

          <WizardChooserBar
            onReject={() => {
              this.reject();
            }}
            onConfirm={() => {
              this.confirm();
            }}
            enableConfirm={this.state.selectedCourse}
          />
        </View>
      );
    }
  }

  renderCloudSection() {
    const selectedCloud = this.state.selectedCloud? this.state.selectedCloud.idnuvola : '';
    return(
      <View style={homeStyles.chooserThird}>
        <Text style={[styles.title1, styles.textLight, {marginTop: 20}]}>Scegli la nuvola predefinita</Text>
        <ScrollView style={homeStyles.chooserThirdWrapper}>
          {this.cloudsList.map((cloud) => <ListItem key={cloud.idnuvola} text={cloud.nuvola} item={cloud}
                                                    isCurrent={cloud.idnuvola === selectedCloud}
                                                    onChoose={(cloud) => this.chooseCloud(cloud)}/>)
          }
        </ScrollView>
      </View>
    )
  }

  renderLocationSection() {
    const locationCOD_INTERL = this.currentLocation? this.currentLocation.COD_INTERL : '';
    const locationCOD_SIGLAIND = this.currentLocation? this.currentLocation.COD_SIGLAIND : '';
    return(
      <View style={homeStyles.chooserThird}>
        <Text style={[styles.title1,
          styles.textLight,
          {marginTop: 20},
          this.state.loadingLocations? {opacity: 0.3} : null
        ]}>Seleziona la location</Text>

        <ScrollView style={homeStyles.chooserThirdWrapper}>
          {this.state.locationsList.map((location, index) =>
            <ListItem
              key={index}
              text={location.RAGSOCIALE}
              item={location}
              isCurrent={locationCOD_INTERL === location.COD_INTERL && locationCOD_SIGLAIND === location.COD_SIGLAIND}
              onChoose={(location) => this.chooseLocation(location)}
            />)
          }
        </ScrollView>


      </View>
    )
  }

  renderCourseSection() {
    const operaId = this.currentCourse? this.currentCourse.opera : '';
    return(
      <View style={homeStyles.chooserThird}>
        <Text style={[
          styles.title1,
          styles.textLight,
          {marginTop: 20},
          this.state.loadingCourses? {opacity: 0.3} : null
        ]}>Seleziona il corso</Text>

        <ScrollView style={homeStyles.chooserThirdWrapper}>
          {this.state.coursesList.map((course, index) =>
            <ListItem key={index} item={course} text={course.corso}
              isCurrent={operaId === course.opera}
              onChoose={(course) => this.chooseCourse(course)}/>)
          }
        </ScrollView>

      </View>
    )

  }

}


export class WizardLocationChooserView extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.currentLocation = null;
    this.currentCourse = null;

    this.state = {
      loading: true,

      loadingLocations: true,
      locationsList: [],
      selectedLocation: null,

      loadingCourses: true,
      coursesList: [],
      selectedCourse: null
    }
  }

  alertMessage(message = null) {
    const msg = message ? message : 'Non è stato possibile salvare la preferenza';
    alert(msg);
  }

  async loadLocationsList() {
    // https://autoscuole.egaf.it/users?idutente=2&idnuvola=900187
    const locationsResp = await axios.get("users/", {
      params: {
        idutente: this.context.currentCloud.idutente,
        idnuvola: this.context.currentCloud.idnuvola
      }
    });
    const locations = locationsResp.data;

    // Prepopulate with current selection
    const selectedLocation = this.context.currentLocation? this.context.currentLocation : null;
    this.currentLocation = selectedLocation;
    if (selectedLocation) {
      const selectedCourse = this.context.currentCourse? this.context.currentCourse : null;
      this.currentCourse = selectedCourse;

      this.setState({
        selectedLocation: selectedLocation,
        selectedCourse: selectedCourse
      });

      if (selectedCourse) {
        await this.loadCoursesList();
      }
    }

    this.setState({
      loading: false,
      loadingLocations: false,
      locationsList: locations
    });

  }

  async loadCoursesList() {
    // https://autoscuole.egaf.it/users?idutente=2&COD_INTERL=900187&COD_SIGLAIND=.
    const coursesResp = await axios.get("users/", {
      params: {
        idutente: this.context.currentCloud.idutente,
        COD_INTERL: this.currentLocation.COD_INTERL,
        COD_SIGLAIND: this.currentLocation.COD_SIGLAIND
      }
    });
    const courses = coursesResp.data;

    this.setState({
      loadingCourses: false,
      coursesList: courses
    });

  }

  _onKeyDown(e) {
    try {
      const key = e.key || e.keyCode;
      if (key === 'Escape') {
        if (this.props.navigation.isFocused()) {
          this.reject();
        }
      }
    } catch (e) {
      console.log('error for event key', e.key);
    }
  }

  componentDidMount() {
    if (isWeb()) {
      document.addEventListener("keydown", (e) => this._onKeyDown(e));
    }
    this.loadLocationsList();
  }

  componentWillUnmount() {
    if (isWeb()) {
      document.removeEventListener("keydown", (e) => this._onKeyDown(e));
    }
  }

  // CONFIRM
  async confirm() {
    this.setState({
      loading: true,
    });

    // Saving LOCATION
    try {
      //https://autoscuole.egaf.it/users/set?func=setLocationDefault
      const locationPreferenceSaved = await axios.post("users/set?func=setLocationDefault",
        {
          idutente: this.context.currentCloud.idutente,
          idnuvola: this.context.currentCloud.idnuvola,
          COD_INTERL: this.currentLocation.COD_INTERL,
          COD_SIGLAIND: this.currentLocation.COD_SIGLAIND
        });
      // console.info('SAVING LOCATION PREF:', locationPreferenceSaved.data);
      if (locationPreferenceSaved.data && parseInt(locationPreferenceSaved.data[0].success) !== 1) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    } catch (e) {
      if (e.response.status !== 403) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    }

    // Saving COURSE
    try {
      //https://autoscuole.egaf.it/users/set?func=setCorsoDefault
      const coursePreferenceSaved = await axios.post("users/set?func=setCorsoDefault",
        {
          idutente: this.context.currentCloud.idutente,
          idnuvola: this.context.currentCloud.idnuvola,
          COD_INTERL: this.currentLocation.COD_INTERL,
          COD_SIGLAIND: this.currentLocation.COD_SIGLAIND,
          opera: this.currentCourse.opera
        });
      // console.info('SAVING COURSE PREF:', coursePreferenceSaved.data);
      if (coursePreferenceSaved.data && parseInt(coursePreferenceSaved.data[0].success) !== 1) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    } catch (e) {
      if (e.response.status !== 403) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    }

    // Reactiveness
    await this.context.initWithCloud();
    this.props.navigation.pop();
  }

  // Cancel
  reject() {
    // console.log('rejecting');
    this.props.navigation.pop();
  }

  chooseLocation(location) {
    this.currentLocation = location;
    this.currentCourse = null;
    this.setState({
      selectedLocation: location,
      selectedCourse: null
    });
    this.loadCoursesList();
  }

  chooseCourse(course) {
    // console.info('Slected course', course);
    this.currentCourse = course;
    this.setState({
      selectedCourse: course
    });
  }

  render() {

    if (this.state.loading) {
      return (
        <View style={homeStyles.chooserContainer}>
          <ActivityIndicator/>
        </View>
      )
    } else {

      return (
        <View style={[homeStyles.chooserContainer]}>
          <View style={[homeStyles.chooserColWrapper, {justifyContent: 'center'}]}>
            {/*{this.renderCloudSection()}*/}
            {this.renderLocationSection()}
            {this.renderCourseSection()}
          </View>

          <WizardChooserBar
            onReject={() => {
              this.reject();
            }}
            onConfirm={() => {
              this.confirm();
            }}
            enableConfirm={this.state.selectedCourse}
          />
        </View>
      );
    }
  }

  renderLocationSection() {
    const locationCOD_INTERL = this.currentLocation? this.currentLocation.COD_INTERL : '';
    const locationCOD_SIGLAIND = this.currentLocation? this.currentLocation.COD_SIGLAIND : '';
    return(
      <View style={[homeStyles.chooserThird, {marginRight: 20}]}>
        <Text style={[styles.title1,
          styles.textLight,
          {marginTop: 20},
          this.state.loadingLocations? {opacity: 0.3} : null
        ]}>Seleziona la location</Text>

        <ScrollView style={homeStyles.chooserThirdWrapper}>
          {this.state.locationsList.map((location, index) =>
            <ListItem
              key={index}
              text={location.RAGSOCIALE}
              item={location}
              isCurrent={locationCOD_INTERL === location.COD_INTERL && locationCOD_SIGLAIND === location.COD_SIGLAIND}
              onChoose={(location) => this.chooseLocation(location)}
            />)
          }
        </ScrollView>


      </View>
    )
  }

  renderCourseSection() {
    const operaId = this.currentCourse? this.currentCourse.opera : '';
    return(
      <View style={[homeStyles.chooserThird, {marginLeft: 20}]}>
        <Text style={[
          styles.title1,
          styles.textLight,
          {marginTop: 20},
          this.state.loadingCourses? {opacity: 0.3} : null
        ]}>Seleziona il corso</Text>

        <ScrollView style={homeStyles.chooserThirdWrapper}>
          {this.state.coursesList.map((course, index) =>
            <ListItem key={index} item={course} text={course.corso}
              isCurrent={operaId === course.opera}
              onChoose={(course) => this.chooseCourse(course)}/>)
          }
        </ScrollView>
      </View>
    )
  }

}


export class WizardCourseChooserView extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.currentCourse = null;

    this.state = {
      loading: true,
      coursesList: [],
      selectedCourse: null
    }
  }

  alertMessage(message = null) {
    const msg = message ? message : 'Non è stato possibile salvare la preferenza';
    alert(msg);
  }

  async loadCoursesList() {

    // this.coursesList = this.context.currentLocation.corsi;
    this.currentCourse = this.context.currentCourse;

    this.setState({
      loading: false,
      coursesList: this.context.currentLocation.corsi,
      selectedCourse: this.context.currentCourse
    })
  }

  _onKeyDown(e) {
    try {
      const key = e.key || e.keyCode;
      if (key === 'Escape') {
        if (this.props.navigation.isFocused()) {
          this.reject();
        }
      }
    } catch (e) {
      console.log('error for event key', e.key);
    }
  }

  componentDidMount() {
    if (isWeb()) {
      document.addEventListener("keydown", (e) => this._onKeyDown(e));
    }
    this.loadCoursesList();
  }

  componentWillUnmount() {
    if (isWeb()) {
      document.removeEventListener("keydown", (e) => this._onKeyDown(e));
    }
  }

  async confirm() {
    this.setState({
      loading: true,
    });

    // Saving COURSE
    try {
      //https://autoscuole.egaf.it/users/set?func=setCorsoDefault
      const coursePreferenceSaved = await axios.post("users/set?func=setCorsoDefault",
        {
          idutente: this.context.currentCloud.idutente,
          idnuvola: this.context.currentCloud.idnuvola,
          COD_INTERL: this.context.currentLocation.COD_INTERL,
          COD_SIGLAIND: this.context.currentLocation.COD_SIGLAIND,
          opera: this.currentCourse.opera
        });
      // console.info('SAVING COURSE PREF:', coursePreferenceSaved.data);
      if (coursePreferenceSaved.data && parseInt(coursePreferenceSaved.data[0].success) !== 1) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    } catch (e) {
      if (e.response.status !== 403) {
        this.alertMessage('Non è stato possibile salvare la preferenza.');
        this.props.navigation.pop();
        return;
      }
    }

    // Reactiveness
    this.context.changeCourseForLocation(this.currentCourse.opera);
    this.props.navigation.pop();
  }

  // Cancel
  reject() {
    this.props.navigation.pop();
  }

  chooseCourse(course) {
    this.currentCourse = course;
    this.setState({
      selectedCourse: course
    })
  }

  render() {

    if (this.state.loading) {
      return (
        <View style={homeStyles.chooserContainer}>
          <ActivityIndicator/>
        </View>
      )
    } else {
      return (
        <View style={homeStyles.chooserContainer}>
          <View style={[homeStyles.chooserColWrapper, {justifyContent: 'center'}]}>
            {this.renderCourseSection()}
          </View>

          <WizardChooserBar
            onReject={() => {
              this.reject();
            }}
            onConfirm={() => {
              this.confirm();
            }}
            enableConfirm={this.state.selectedCourse}
          />

        </View>
      );
    }
  }

  renderCourseSection() {
    const operaId = this.currentCourse? this.currentCourse.opera : '';
    return(
      <View style={[homeStyles.chooserThird, ]}>
        <Text style={[
          styles.title1,
          styles.textLight,
          {marginTop: 20},
          this.state.loadingCourses? {opacity: 0.3} : null
        ]}>Seleziona il corso</Text>

        <ScrollView style={homeStyles.chooserThirdWrapper}>
          {this.state.coursesList.map((course, index) =>
            <ListItem key={index} item={course} text={course.corso}
              isCurrent={operaId === course.opera}
              onChoose={(course) => this.chooseCourse(course)}/>)
          }
        </ScrollView>
      </View>
    )
  }

};


const WizardChooserBar = (props) => {

  return (
    <View style={homeStyles.chooserBar}>
      <TouchableOpacity
        style={[styles.barBtn, actionStyles.noBtn, {borderRadius: 30, marginRight: 10}]}
        onPress={() => props.onReject()}
      >
        <Text style={actionStyles.btnLabel}>Annulla</Text>
      </TouchableOpacity>

      {
        props.enableConfirm?
          <TouchableOpacity
            style={[styles.barBtn, actionStyles.yesBtn, {borderRadius: 30}]}
            onPress={() => props.onConfirm()}
          >
            <Text style={actionStyles.btnLabel}>Conferma</Text>
          </TouchableOpacity>
        :
          <View
            style={[styles.barBtn, actionStyles.yesBtn, {borderRadius: 30}, homeStyles.yesBtnDisabled]}
          >
            <Text style={actionStyles.btnLabel}>Conferma</Text>
          </View>
      }

    </View>
  )
};
