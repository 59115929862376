import React from 'react';
import {View, TouchableOpacity, Text, ActivityIndicator, FlatList} from 'react-native';
import {styles} from '../../foundation/styles';
import statsStyles from '../styles';
import UserContext from "../../user";
import axios, {CancelToken} from "../../commons/axios";
import {reaction} from "mobx";
import {QuizAnswersBarChart} from "../../foundation/widgets";
import classStyles from "../../section_classes/styles";
import errorManager from "../../commons/error_manager";
import {StudentDrillDownPanel} from "./drilldown_panels";


export default class QuestionsStat extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Cancel Token for questions request
    this.source = CancelToken.source();

    this.state = {
      loading: true,
      statsData: []
    }
  }

  alertMessage(message=null) {
    const msg = message? message : 'Non è stato possibile processare le statistiche nel periodo scelto. Riprovare più tardi. Grazie';
    alert(msg);
  }

  async loadStats() {
    try {
      const statsResp = await axios.get("statistics/", {
        cancelToken: this.source.token,
        params: {
          stat: 'statAllievi_ClasseOpera',
          idClasse: this.props.currentClass.idclasse,
          opera: this.context.currentCourse.opera,
          giorni: this.context.statsPeriod
        }
      });

      // console.info('statsResp for tab QUESTION', statsResp.data);

      this.setState({
        loading: false,
        statsData: statsResp.data
      });
    } catch (e) {

      errorManager(e);
      // console.info('Cacth error message in questions.js', e);
      // if (e && e.response.status !== 403){
      //   this.alertMessage();
      // }
    }
  }

  componentDidMount() {
    this.loadStats();

    // Reaction to location changing
    this._statPeriodReactionDispose = reaction(
      () => this.context.statsPeriod,
      () => {
        this.setState({
          loading: true,
        });
        this.loadStats();
      }
    );

  }

  componentWillUnmount() {
    this._statPeriodReactionDispose();
    this.source.cancel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentClass !== this.props.currentClass) {
      this.setState({
        loading: true,
      });
      this.loadStats();
    }
  }

   _renderClassItemGraph(stat) {

    const wrongPercentage = stat.TOTALE? stat.SBAGLIATE/stat.TOTALE : 0;
    return <QuizAnswersBarChart
      completed={wrongPercentage? 1 : 0}
      wrong={wrongPercentage}
      height={15}
    />

  }

  _renderClassItemGraphValues(stat) {
    const wrongPercentage = stat.TOTALE? stat.SBAGLIATE/stat.TOTALE*100 : 0;
    // const correctPercentage = stat.TOTALE? stat.GIUSTE/stat.TOTALE*100 : 0;
    // const correctPercentage = wrongPercentage? 100 -wrongPercentage : 0;
    const wrongPercentageValue = wrongPercentage.toFixed();
    const correctPercentageValue = wrongPercentageValue? 100 - wrongPercentageValue : 0;
    return (
      <View style={[styles.rowContainer, ]}>
        <View style={classStyles.questionsBlock}>
            {/*<Text style={[styles.textLight, statsStyles.smallGraphText]}>Totale</Text>*/}
            <Text style={[styles.textLight, statsStyles.smallGraphText]}>Corrette</Text>
            <Text style={[styles.textLight, statsStyles.smallGraphText]}>Sbagliate</Text>
            <Text style={[styles.textLight, statsStyles.smallGraphText]}>Domande fatte</Text>
        </View>
        <View>
            {/*<Text style={[classStyles.totalColor, statsStyles.smallGraphText]}>{stat.TOTALE}</Text>*/}
            <Text style={[classStyles.completedColor, statsStyles.smallGraphText]}>{correctPercentageValue}%</Text>
            <Text style={[classStyles.errorColor, statsStyles.smallGraphText]}>{wrongPercentageValue}%</Text>
            <Text style={[styles.textLight, statsStyles.smallGraphText]}>{stat.TOTALE}</Text>
        </View>
      </View>
    )

  }

  _renderStatItem(item, index) {
    return (
      <TouchableOpacity
        style={statsStyles.questionItemW}
        onPress={()=>this.openStudentDetail(item)}
      >
        <View style={statsStyles.nameBlock}>
          <Text style={statsStyles.questionItemText} numberOfLines={2}>{item.Nome} {item.Cognome}</Text>

          <View style={statsStyles.questionGraphBlock}>
            {this._renderClassItemGraph(item)}
          </View>
        </View>

        <View style={statsStyles.questionValuesBlock}>
          {this._renderClassItemGraphValues(item)}
        </View>
      </TouchableOpacity>
    )
  }

  drillBack() {
    this.setState({
      showDrillPanel: false
    });
  }

  openStudentDetail(item) {
    console.info('OPEN DETAIL FOR STUDENT', item);
    console.info('GET ALL STUDENT FOR CURRENT CLASS', this.props.currentClass.idclasse);
    ///students/getallieviclasse/
    this.setState({
      showDrillPanel: true,
      drillParams: {
        title: `${item.Nome} ${item.Cognome}`,
        idClasse: this.props.currentClass.idclasse,
        idAllievo: item.IdAllievo,
        // opera: this.context.currentCourse.opera,
        // period: this.period
      }
    });
  }

  render() {
    if (this.state.loading) {
      return <View style={statsStyles.statContainer}>
        <ActivityIndicator />
      </View>;
    } else {
     return (
       <View style={[statsStyles.statContainer, statsStyles.statPanelHolder, {position: 'relative'}]}>
         {
           this.state.statsData.length?
             <FlatList
              // style={statsStyles.classListW}
              key={'stat_questions'}
              data={this.state.statsData}
              renderItem={ ({item, index}) => this._renderStatItem(item, index)}
              keyExtractor={(item, index) => index.toString()}
            />
            :
             <Text style={statsStyles.noStatMessage}>Le statistiche per questa classe non sono ancora disponibili</Text>
         }
         <StudentDrillDownPanel
           showDrillPanel={this.state.showDrillPanel}
           close={() => this.drillBack()}
           drillParams={this.state.drillParams}
           currentClass={this.props.currentClass}
           showImage={(path)=>this.props.showImage(path)}
           hasScroll={false}
         />
       </View>
     );
   }
  }
}
