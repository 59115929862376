import React, {useState, useEffect, createRef} from 'react';
import ReactDOM from 'react-dom';
import {View, Text, Image, TouchableOpacity, Animated} from 'react-native';
import {SectionList} from "react-native-web";
import {actionStyles, listStyles, styles, heightActionEnabledPercent, Colors, tooltipStyles} from "./styles";
import prospectStyles from "../section_prospects/styles";
import Svg, {G, Defs, ClipPath, Rect} from 'react-native-svg';
import {isWeb} from "../commons/utils";
import $ from 'jquery';
import 'selectric/public/selectric.css';
import UserContext from "../user";
import {Observer} from 'mobx-react';
import Moment from 'moment';
import {HorizontalBar, Line, Doughnut} from 'react-chartjs-2';
import { Chart } from "react-chartjs-2";


Chart.helpers.canvas.clipArea = function() {};
Chart.helpers.canvas.unclipArea = function() {};

/* SAMPLE DATA for EGAFSECTIONLIST

----------------------------
OLD SECTION LIST

const sectionHeaders  = [
  'Nome Cognome',
  'Città',
  'Ruolo',
  'Status'
]

<SectionList
  // renderSectionHeader={({section: {title}}) => this.renderSectionHeader({section: {title}})}
  // renderItem={({item, index, section}) => <Text key={index}>{item}</Text>}
  sections={dataBySection}
  renderSectionHeader={ (stuff) => this._renderSectionHeader(stuff) }
  renderItem={ ({item}) => this._renderItem(item)}
  keyExtractor={(item, index) => item.name+index}
/>


----------------------------



const sectionHeaders2  = [
  {name: 'Nome', prop: 'name'},
  {name: 'Cognome', prop: 'surname'},
  {name: 'Città', prop: 'city'},
  {name: 'Ruolo', prop: 'role'},
  {name: 'Status', prop: 'status'}
];

const fakeRoles = [
  {
    id: 1,
    name: 'Mariangela',
    surname: 'Segantini',
    city: 'Faenza',
    role: 'Super Admin',
    status: 'Operativo'
  },
  {
    id: 2,
    name: 'Eleonora',
    surname: 'Volpi',
    city: 'Forlì',
    role: 'Admin Federato',
    status: 'Operativo'
  },
  {
    id: 3,
    name: 'Angelo',
    surname: 'Franceschini',
    city: 'Imola',
    role: 'Admin',
    status: 'Operativo'
  },
  {
    id: 4,
    name: 'Giorgia',
    surname: 'Marchigiani',
    city: 'Forlì',
    role: 'Segreteria',
    status: 'Operativo'
  },
  {
    id: 5,
    name: 'Francesco',
    surname: 'Pacchioni',
    city: 'Cesena',
    role: 'Insegnante',
    status: 'Standby'
  },
  {
    id: 6,
    name: 'Marcello',
    surname: 'Vicini',
    city: 'Faenza',
    role: 'Istruttore',
    status: 'Standby'
  }

];

 */


const StudentPieIndicator = (props) => {
  const total = props.student.TOTALE;
  const correct = props.student.GIUSTE;
  const wrong = props.student.SBAGLIATE;
  // if (total && correct && wrong) {

    const correctPercentage = (correct/total*100).toFixed();
    const wrongPercentage = (wrong/total*100).toFixed();
    return (
      <View key={'row-' + props.student[props.refID] + '-' + props.index}
             style={[listStyles.rowBlock, listStyles[props.blockNumber]]}>
        <View style={{maxWidth: '80%'}}>
          <View style={[styles.rowContainer, {marginBottom: 6}]}>
            <Text style={{color: Colors.correct, marginRight: 10}}>{correctPercentage}%</Text>
            <Text style={{color: Colors.wrong}}>{wrongPercentage}%</Text>
          </View>

          <View style={{height: 10, backgroundColor: Colors.navBar, flexDirection: 'row'}}>
            <View style={{height: 10, backgroundColor: Colors.correct, width: correctPercentage+'%'}} />
            <View style={{height: 10, backgroundColor: Colors.wrong, width: wrongPercentage+'%'}} />
          </View>
        </View>
      </View>
    );
};


export class EgafSectionList extends React.Component {

  // This component generates a Section List,
  // expects as props:
  // - headers (array of objects containing heading name a nd mapped props
  // - dataForListing (data for the list )
  // - action (method triggered on click/tap

  static contextType = UserContext;

  constructor(props) {
    super(props);

    const dataBySection = [
      {
        ...this.props.headers,
        data: this.props.dataForListing
      }
    ];
    this.columnsNumber = this.props.headers.length;
    this.rowsNumber = this.props.dataForListing.length;

    // Setting the property used to reference id
    this.refID = this.props.refID ? this.props.refID : 'id';

    // Define ordering type
    // Set default ordering column
    const orderingIndex = this.props.orderingIndex? this.props.orderingIndex : 0
    this.orderingTypes = ['ASC', 'DESC'];

    this.state = {
      data: dataBySection,
      pressedRowIndex: null,
      selectedItems: [],
      currentOrdering: {
        index: orderingIndex,
        type: this.orderingTypes[1]
      }
    };
  }

  // Detecting if Shift was pressed
  _onKeyDown(e) {
    try {
      const key = e.key || e.keyCode;
      if (key === 'Shift') {
        this.shiftKey = true;
      }
    } catch (e) {
      console.log('error for event key', e);
    }
  }

  _onKeyUp(e) {
    try {
      const key = e.key || e.keyCode;
      if (key === 'Shift') {
        this.shiftKey = false;
      }
    } catch (e) {
      console.log('error for event key', e.key);
    }
  }

  componentDidMount() {
    // if (isWeb()) {
    //   window.addEventListener("keydown", () => this._pressedKeyDown());
    // }

    // Forcing orderingtype only in case of 'DataIscrizione', that should be from recent to older
    const orderingType = this.props.headers[0].prop === 'DataIscrizione'? this.orderingTypes[1] : null;
    // Reorder with default criteria
    this._reorder(this.state.currentOrdering.index, this.props.headers[this.state.currentOrdering.index].prop, orderingType);

    // Attach event to detect Shift press
    if (isWeb()) {
      document.addEventListener("keydown", (e) => this._onKeyDown(e));
      document.addEventListener("keyup", (e) => this._onKeyUp(e));
    }
  }

  componentWillUnmount() {

    if (isWeb()) {
      document.removeEventListener("keydown", (e) => {});
      document.removeEventListener("keyup", (e) => {});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dataForListing !== prevProps.dataForListing) {
      // console.log('LISTING HAS CHANGED!!!');
      // console.info(this.props.dataForListing);
      let dataBySection = Array.from(this.state.data);
      dataBySection[0]['data'] = this._sortData(this.props.headers[this.state.currentOrdering.index].prop, this.state.currentOrdering.type, this.props.dataForListing);
      this.rowsNumber = this.props.dataForListing.length;

      this.setState({
        data: dataBySection,
        selectedItems: []
      })
    }
    if (this.props.selectedRowItemId !== prevProps.selectedRowItemId && !this.props.selectedRowItemId) {

      this.setState({
        pressedRowIndex: null
      })
    }
  }

  getSelectedItems() {
    return this.state.selectedItems;
  }

  resetSelectedItems() {
    this.setState({
      selectedItems: []
    })
  }

  _onSelectAll() {
    let selectedItems = [];
    if (this.state.selectedItems.length < this.rowsNumber) {
      for (const item of this.state.data[0]['data']) {
        selectedItems.push(item[this.refID]);
      }
    }
    // Call onRowSelected
    if (this.props.onRowSelected) this.props.onRowSelected(selectedItems);
    this.setState({
      selectedItems
    });
  }

  _onSelectItem(item, index) {

    let selectedItems = Array.from(this.state.selectedItems);
    const lastSelectionId = selectedItems[selectedItems.length-1];

    if (this.shiftKey && lastSelectionId){
      // Shift pressed behaviour: muktiple selection
      // Get a copy of current data (ordered)
      const listData = Array.from(this.state.data[0].data);

      // Get last selected item, if exist
      const lastSelectionIndex = listData.findIndex((item)=>item[this.refID] === lastSelectionId);

      // Multiple selection
      const minIndex = Math.min(lastSelectionIndex, index);
      const maxIndex = Math.max(lastSelectionIndex, index);

      listData.forEach((item, mappindIndex) => {
        if (mappindIndex >= minIndex && mappindIndex <= maxIndex) {
          // Add to selection only if not already included
          if (!selectedItems.includes(item[this.refID])) {
            selectedItems.push(item[this.refID])
          }
        }
      });

    } else {

      // Normal behaviour, single selection
      selectedItems.includes(item[this.refID]) ? selectedItems.splice(selectedItems.indexOf(item[this.refID]), 1) : selectedItems.push(item[this.refID]);
    }

    // Call onRowSelected
    if (this.props.onRowSelected) this.props.onRowSelected(selectedItems);
    this.setState({
      selectedItems,
    });

  }

  // Experimental reordering
  _toggleOrderingType(oldType) {
    if (this.orderingTypes[1] === oldType) {
      return this.orderingTypes[0];
    } else {
      return this.orderingTypes[1];
    }
  }

  _sortData(prop, orderingType, data = null) {

    let items = data ? data : Array.from(this.state.data[0].data);
    items.sort((a, b) => {
      let propA = a[prop] ? a[prop] : '';
      let propB = b[prop] ? b[prop] : '';

      // in case we are reodering status graph
      if (prop === 'status' && this.props.showPie){
        propA = a.GIUSTE/a.TOTALE;
        propB = b.GIUSTE/b.TOTALE;
      }

      // If strings ignoring case
      if (typeof propA === 'string' && typeof propB === 'string') {
        propA = propA.toUpperCase();
        propB = propB.toUpperCase();
      }
      if (propA < propB) {

        return orderingType === this.orderingTypes[0] ? -1 : 1;
      }
      if (propA > propB) {
        return orderingType === this.orderingTypes[0] ? 1 : -1;
      }
      // they are equal
      return 0;
    });
    return items;
  }

  _reorder(index, prop, forcedOrderingType = null) {
    // Check if is current ordering
    let orderingType = this.orderingTypes[0];
    if (forcedOrderingType) {
      orderingType = forcedOrderingType;
    } else {
      if (index === this.state.currentOrdering.index) {
        orderingType = this._toggleOrderingType(this.state.currentOrdering.type);
      }
    }

    const newOrderedData = this._sortData(prop, orderingType);


    // Creating ordered data array
    let newData = Array.from(this.state.data);
    newData[0].data = newOrderedData;

    const newOrdering = {
      index: index,
      type: orderingType
    };

    this.setState({
      data: newData,
      selectedItems: [],
      currentOrdering: newOrdering
    })
  }

  render() {
    return (
      <View style={listStyles.listWrapper}>
        {this.state.data && this.state.data[0].data.length ?
          <SectionList
            sections={this.state.data}
            renderSectionHeader={() => this._renderSectionHeader()}
            renderItem={({item, index}) => this._renderItem(item, index)}
            keyExtractor={(item, index) => index}
            stickySectionHeadersEnabled={true}
            // Comment theese to toggle performance fixes
            // getItemLayout={(data, index) => (
            //   {length: 20, offset: 20 * index, index}
            // )}
            // removeClippedSubviews={true}
          />
          :
          <Text style={listStyles.noResultText}>Non ci sono risultati</Text>
        }

      </View>
    );
  }

  _onPressRow(item, index) {
    if (!this.context.hasUnprocessedEdits && this.props.onPressRow) {
      this.props.onPressRow(item, index);
      this.setState({
        pressedRowIndex: index
      })
    }
  }

  _renderSectionHeader() {
    return (
      <View style={listStyles.headerWrapper}>
        {
          this.props.enableSelection ?
            <View key={'selector'} style={[listStyles.headerBlock, listStyles.selectorBlock]}>
              <TouchableOpacity onPress={() => this._onSelectAll()}>
                {/*<Text>{ this.state.selectedItems.length === this.rowsNumber ? 'Y' : 'N' }</Text>*/}
                <EgafCheckbox isSelected={this.state.selectedItems.length === this.rowsNumber}/>
              </TouchableOpacity>
            </View>
            :
            null
        }
        <View style={listStyles.mainRow}>
          {
            this.props.headers.map((heading, index) => {
              if (!this.props.isActionEnabled || !this.props.hideFields.includes(heading.prop)) {
                const blockNumber = 'block' + (index + 1) + '_' + this.columnsNumber;

                // Check if is current ordergin and what type
                let orderingImg = <Image
                    style={{width: 10, height: 20, marginRight: 7}}
                    source={require('../commons/images/order_off.png')}
                  />;
                if (index === this.state.currentOrdering.index) {
                  orderingImg = <Image
                    style={{width: 10, height: 20, marginRight: 7}}
                    source={require('../commons/images/order_desc.png')}
                  />;
                  if (this.state.currentOrdering.type === this.orderingTypes[0]) {
                    orderingImg = <Image
                      style={{width: 10, height: 20, marginRight: 7}}
                      source={require('../commons/images/order_asc.png')}
                    />;
                  }

                }

                return (
                  <TouchableOpacity
                    style={[listStyles.headerBlock, listStyles[blockNumber]]}
                    key={index}
                    onPress={() => this._reorder(index, heading.prop)}
                  >
                    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                      <Text numberOfLines={1} style={listStyles.listHeadingText}>{heading.name}</Text>
                      {orderingImg}
                    </View>
                  </TouchableOpacity>
                )
              }
              return null;
            })
          }
        </View>
      </View>
    )
  }

  _renderItem(item, index) {
    const isSelected = this.state.selectedItems.includes(item[this.refID]);
    // const isPressed = this.props.selectedRowItemId === item[this.refID];
    const isPressed = this.state.pressedRowIndex === index;

    return (

      <View style={
        [listStyles.rowWrapper,
          isPressed ? listStyles.rowSelected : {},
          this.props.forceHeight? {height: this.props.forceHeight} : null,
        ]}
        // className={'datatable-row'}
        data-row={'custom'}
      >
        {
          this.props.enableSelection ?
            <View key={'selector'} style={[listStyles.rowBlock, listStyles.selectorBlock]}>
              <TouchableOpacity onPress={() => this._onSelectItem(item, index)}>
                <EgafCheckbox isSelected={isSelected}/>
              </TouchableOpacity>
            </View>
            :
            null
        }

        <TouchableOpacity
          style={listStyles.mainRow}
          onPress={() => this._onPressRow(item, index)}
        >
          {
            this.props.headers.map((heading, index) => {
              if (!this.props.isActionEnabled || !this.props.hideFields.includes(heading.prop)) {
                const blockNumber = 'block' + (index + 1) + '_' + this.columnsNumber;

                if (this.props.showPie && heading.prop === 'status') {
                  return <StudentPieIndicator
                    key={'pie_'+index}
                    student={item}
                    blockNumber={blockNumber}
                    refID={this.refID}
                    index={index}
                  />
                } else if (this.props.showRoundIndicators && this.props.indicatorsProps.includes(heading.prop)) {
                  let indicator = <View style={listStyles.indicatorOff}/>;
                  if (parseInt(item[heading.prop])) {
                    indicator = <View style={listStyles.indicatorOn}/>;
                  }
                  return(
                    <View key={'row-' + item[this.refID] + '-' + index}
                               style={[listStyles.rowBlock, listStyles[blockNumber]]}>
                      {indicator}
                    </View>
                  )

                } else {
                  return (
                    <View key={'row-' + item[this.refID] + '-' + index}
                     style={[listStyles.rowBlock, listStyles[blockNumber]]}
                    >
                      <Text numberOfLines={1} style={listStyles.listText}>
                        {heading.prop === 'DataIscrizione' ?
                          item[heading.prop] ? Moment(item[heading.prop]).format('DD/MM/YYYY') : '------'
                          :
                          item[heading.prop]
                        }
                      </Text>
                    </View>
                  );
                }
              }
              return null;
            })
          }
        </TouchableOpacity>
      </View>
    );
  }
}


const EgafCheckbox = (props) => {

  return (
    <View
      style={listStyles.checkbox}
    >
      {props.isSelected ?
        <View style={listStyles.checkboxSelected}></View>
        :
        null
      }
    </View>
  )
};


export const EgafSelectionCheck = (props) => {

  return (
    <View
      style={props.isSmall ? listStyles.selectionBoxSmall : listStyles.selectionBox}
    >
      {props.isSelected ?
        <View style={props.isSmall ? listStyles.selectionboxSelectedSmall : listStyles.selectionboxSelected}/>
        :
        null
      }
    </View>
  )
};

/* ACTION BLOCK RENDERING ANIMATION LOGIC*/
export class ActionBlock extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.masterWidthAnimation = new Animated.Value(100);
    this.detailWidthAnimation = new Animated.Value(0);
    this.detailOpacity = new Animated.Value(0);
    this.barTranslateY = new Animated.Value(200);

    // Defining opening size:
    // this.openSize = props.openingSize? props.openingSize : 'small';
    this.openingSizes = {
      small: {
        masterWidthToValue: 70,
        masterInputRange: [70, 100],
        masterOutputRange: ['70%', '100%'],
        detailWidthToValue: 30,
        detailInputRange: [0, 30],
        detailOutputRange: ['0%', '30%'],
      },
      medium: {
        masterWidthToValue: 55,
        masterInputRange: [55, 100],
        masterOutputRange: ['55%', '100%'],
        detailWidthToValue: 45,
        detailInputRange: [0, 45],
        detailOutputRange: ['0%', '45%'],
      },
      large: {
        masterWidthToValue: 30,
        masterInputRange: [30, 100],
        masterOutputRange: ['30%', '100%'],
        detailWidthToValue: 70,
        detailInputRange: [0, 70],
        detailOutputRange: ['0%', '70%'],
      },
    };
    this.currentOpeningSize = this.openingSizes[props.openingSize ? props.openingSize : 'small'];

    this.isClosed = true;
  }

  triggerActionAnimation() {
    Animated.sequence([
      Animated.parallel([
        Animated.timing(this.masterWidthAnimation, {
          toValue: this.currentOpeningSize.masterWidthToValue,
          duration: 200,
          useNativeDriver: false
        }),
        Animated.timing(this.detailWidthAnimation, {
          toValue: this.currentOpeningSize.detailWidthToValue,
          duration: 200,
          useNativeDriver: false
        })

      ]),
      Animated.timing(this.detailOpacity, {
        toValue: 1,
        duration: 150,
        useNativeDriver: false
      }),
      Animated.timing(this.barTranslateY, {
        toValue: 0,
        duration: 100,
        useNativeDriver: false
      })

    ]).start(() => {
      this.isClosed = false;
    });
  }

  triggerActionDisabledAnimation() {
    Animated.sequence([
      Animated.parallel([
        Animated.timing(this.detailOpacity, {
          toValue: 0,
          duration: 100,
          useNativeDriver: false
        }),
        Animated.timing(this.barTranslateY, {
          toValue: 200,
          duration: 100,
          useNativeDriver: false
        }),
      ]),
      Animated.parallel([
        Animated.spring(this.masterWidthAnimation, {
          toValue: 100,
          useNativeDriver: false
        }),
        Animated.spring(this.detailWidthAnimation, {
          toValue: 0,
          useNativeDriver: false
        })
      ])
    ]).start(() => {
      this.isClosed = true;
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isActionEnabled) {
      this.triggerActionAnimation();
    } else {
      this.triggerActionDisabledAnimation();
    }
  }

  render() {

    return (
      <View style={actionStyles.actionContainer}>

        {/*MASTER*/}
        <Animated.View
          style={[actionStyles.actionMaster, {height: this.props.isActionEnabled ? heightActionEnabledPercent : '100%'},
            {
              width: this.masterWidthAnimation.interpolate({
                inputRange: this.currentOpeningSize.masterInputRange,
                outputRange: this.currentOpeningSize.masterOutputRange,
              }),

            }]}>
          <View style={actionStyles.actionMasterInner}>
            {/*HEADER*/}
            {this.props.renderMasterHeader()}
            {/*BODY*/}
            {this.props.renderMasterBody()}
          </View>
        </Animated.View>

        {/*DETAIL*/}
        <Animated.View style={[actionStyles.actionDetail,
          {
            width: this.detailWidthAnimation.interpolate({
              inputRange: this.currentOpeningSize.detailInputRange,
              outputRange: this.currentOpeningSize.detailOutputRange,
            }),
          },
          {opacity: this.detailOpacity}
        ]}>
          <View style={actionStyles.actionDetailInner}>
            {this.props.prepareForAction()}
          </View>
        </Animated.View>

        {/*BAR*/}
        <Animated.View style={[actionStyles.actionBar,
          {
            transform: [
              {translateY: this.barTranslateY},
            ]
          }

        ]}>
          {this.renderBottomBar()}
        </Animated.View>

      </View>
    );
  }

  renderBottomBar() {
    return (
      <Observer>
        {
          () => {
            if (this.context.hasUnprocessedEdits) {
              return (this.renderCancelSaveButtons());
            } else {
              return (
                <View style={actionStyles.actionBarInner}>
                  <TouchableOpacity
                    style={[actionStyles.barBtn, actionStyles.closeBtn]}
                    onPress={() => {
                      if (this.props.onClose){
                        this.props.onClose();
                      } else {
                        // reject action always present
                        this.props.onReject()
                      }
                    }}
                  >
                    <Text style={actionStyles.btnLabel}>Chiudi</Text>
                  </TouchableOpacity>
                </View>
              );
            }

          }
        }
      </Observer>
    )
  }

  renderCancelSaveButtons() {
    return (
      <View style={actionStyles.actionBarInner}>
        <TouchableOpacity
          style={[actionStyles.barBtn, actionStyles.noBtn]}
          onPress={
            () => {
              this.context.hasUnprocessedEdits = false;
              this.props.onReject();
            }
          }
        >
          {
            this.props.cancelBtnLabel ?
              <Text style={actionStyles.btnLabel}>{this.props.cancelBtnLabel}</Text>
              :
              <Image size={20} style={{width: 30, height: 30, margin: 7}} source={require('../commons/images/x.svg')}/>
          }

        </TouchableOpacity>
        <TouchableOpacity
          style={[actionStyles.barBtn, actionStyles.yesBtn]}
          onPress={
            () => {
              this.context.hasUnprocessedEdits = false;
              this.props.onConfirm();
            }
          }
        >
          {
            this.props.confirmBtnLabel ?
              <Text style={actionStyles.btnLabel}>{this.props.confirmBtnLabel}</Text>
              :
              <Image size={20} style={{width: 30, height: 30, margin: 7}}
                     source={require('../commons/images/check.svg')}/>
          }

        </TouchableOpacity>
      </View>
    );
  }

}


/* HORIZONTAL BAR CHART */
// It expects props like this:
// <VectorQuizAnswersBarChart
//  completed={0.8}
//  wrong={0.8}
//  height={} optional
//  width={} optional
// />

const AnimatedRect = Animated.createAnimatedComponent(Rect);


export class QuizAnswersBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.completed = new Animated.Value(0);
    this.wrong = new Animated.Value(0);

    this.height = this.props.height ? this.props.height : 50;
    this.roundRadius = this.height / 2;
    this.maxWidth = this.props.maxWidth ? this.props.maxWidth : null;

    this.clipPathId = 'clip' + Math.random();

    // Managing Colors, default are red/green
    this.completedColor = this.props.completedColor? this.props.completedColor : Colors.correct;
    this.wrongColor = this.props.wrongColor? this.props.wrongColor : Colors.wrong;
  }

  _startAnimating() {
    const delay = this.props.delay ? this.props.delay : 700;
    this.animatingtimeOut = setTimeout(() => this.animateGraph(), delay);
  }

  componentDidMount() {
   this._startAnimating();
  }

  componentWillUnmount() {
    clearTimeout(this.animatingtimeOut);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( this.props.completed !== prevProps.completed || this.props.wrong !== prevProps.wrong ) {
      clearTimeout(this.animatingtimeOut);
      this._startAnimating();
    }
  }

  animateGraph() {
    Animated.parallel([
      Animated.timing(this.completed, {
        toValue: this.props.completed,
        duration: 200,
        useNativeDriver: false
      }),
      Animated.timing(this.wrong, {
        toValue: this.props.wrong,
        duration: 400,
        useNativeDriver: false
      }),

    ]).start();
  }

  render() {
    const maxWidth = this.maxWidth ? {maxWidth: this.maxWidth} : {maxWidth: '100%'};
    const bgColor = this.props.bgColor ? this.props.bgColor : "#2a2a2a";

    return (
      <View
        style={[{flex: 1}, maxWidth]}
      >
        <Svg width={'100%'} height={this.height} >
          <G>

            <Defs>
              <ClipPath id={this.clipPathId}
                        fill={bgColor}
              >
                <AnimatedRect
                  x="0"
                  y="0"
                  rx={this.roundRadius}
                  ry={this.roundRadius}
                  width={this.completed.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0%', '100%'],
                  })
                  }
                  height={this.height}
                  fill={bgColor}
                />

              </ClipPath>

            </Defs>
            <Rect
              x="0"
              y="0"
              rx={this.roundRadius}
              ry={this.roundRadius}
              width="100%"
              height={this.height}
              fill={bgColor}
            />
            <AnimatedRect
              x="0"
              y="0"
              rx={this.roundRadius}
              ry={this.roundRadius}
              width={this.completed.interpolate({
                inputRange: [0, 1],
                outputRange: ['0%', '100%'],
              })
              }
              height={this.height}
              fill={this.completedColor}
              clipPath={'url(#' + this.clipPathId + ')'}
            />
            <AnimatedRect
              x="0"
              y="0"
              rx={this.roundRadius}
              ry={this.roundRadius}
              width={this.wrong.interpolate({
                inputRange: [0, 1],
                outputRange: ['0%', '100%'],
              })
              }
              height={this.height}
              fill={this.wrongColor}
              clipPath={'url(#' + this.clipPathId + ')'}
            />
          </G>
        </Svg>

      </View>
    )
  }

}


export class OverallAnswersBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.correct = new Animated.Value(0);
    this.wrong = new Animated.Value(0);
    this.before = new Animated.Value(0);

    this.height = this.props.height ? this.props.height : 50;
    this.roundRadius = this.height / 2;
    this.maxWidth = this.props.maxWidth ? this.props.maxWidth : null;

    this.clipPathId = 'clip' + Math.random();

    // Managing Colors, default are red/green
    this.correctColor = this.props.correctColor? this.props.correctColor : Colors.correct;
    this.wrongColor = this.props.wrongColor? this.props.wrongColor : Colors.wrong;
    this.beforeColor = this.props.beforeColor? this.props.beforeColor : '#f5f5f5';
  }

  _startAnimating() {
    const delay = this.props.delay ? this.props.delay : 700;
    this.animatingtimeOut = setTimeout(() => this.animateGraph(), delay);
  }

  componentDidMount() {
    this._startAnimating();
  }

  componentWillUnmount() {
    clearTimeout(this.animatingtimeOut);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( this.props.correct !== prevProps.correct || this.props.wrong !== prevProps.wrong ) {
      clearTimeout(this.animatingtimeOut);
      this._startAnimating();
    }
  }

  animateGraph() {
    Animated.sequence([
      Animated.timing(this.before, {
        toValue: this.props.before,
        duration: 200,
        useNativeDriver: false
      }),
      Animated.timing(this.wrong, {
        toValue: this.props.wrong,
        duration: 200,
        useNativeDriver: false
      }),
      Animated.timing(this.correct, {
        toValue: this.props.correct,
        duration: 200,
        useNativeDriver: false
      }),
    ]).start();
  }

  render() {
    const maxWidth = this.maxWidth ? {maxWidth: this.maxWidth} : {maxWidth: '100%'};
    const bgColor = this.props.bgColor ? this.props.bgColor : "#2a2a2a";

    return (
      <View
        style={[{flex: 1}, maxWidth]}
      >
        <Svg width={'100%'} height={this.height} >
          <G>

            <Defs>
              <ClipPath id={this.clipPathId}
                        fill={bgColor}
              >
                <AnimatedRect
                  x="0"
                  y="0"
                  rx={this.roundRadius}
                  ry={this.roundRadius}
                  width="100%"
                  // width={this.correct.interpolate({
                  //   inputRange: [0, 1],
                  //   outputRange: ['0%', '100%'],
                  // })
                  // }
                  height={this.height}
                  fill={bgColor}
                />

              </ClipPath>

            </Defs>
            <Rect
              x="0"
              y="0"
              rx={this.roundRadius}
              ry={this.roundRadius}
              width="100%"
              height={this.height}
              fill={bgColor}
            />


            <AnimatedRect
              x="0"
              y="0"
              rx={this.roundRadius}
              ry={this.roundRadius}
              width={this.correct.interpolate({
                inputRange: [0, 1],
                outputRange: ['0%', '100%'],
              })
              }
              height={this.height}
              fill={this.correctColor}
              clipPath={'url(#' + this.clipPathId + ')'}
            />
            <AnimatedRect
              x="0"
              y="0"
              rx={this.roundRadius}
              ry={this.roundRadius}
              width={this.wrong.interpolate({
                inputRange: [0, 1],
                outputRange: ['0%', '100%'],
              })
              }
              height={this.height}
              fill={this.wrongColor}
              clipPath={'url(#' + this.clipPathId + ')'}
            />
            <AnimatedRect
              x="0"
              y="0"
              rx={this.roundRadius}
              ry={this.roundRadius}
              width={this.before.interpolate({
                inputRange: [0, 1],
                outputRange: ['0%', '100%'],
              })
              }
              height={this.height}
              fill={this.beforeColor}
              clipPath={'url(#' + this.clipPathId + ')'}
            />
          </G>
        </Svg>
      </View>
    )
  }

}



export const FiltersButton = (props) => {
  return (
    <TouchableOpacity
      style={[styles.roundBtn, props.active ? prospectStyles.filterBtnActive : null,]}
      onPress={() => props.toggleFilters()}
    >
      {props.active ?
        <Image size={25} style={{width: 45, height: 45}} source={require('../commons/images/filters-white.svg')}/>
        :
        <Image size={25} style={{width: 45, height: 45}} source={require('../commons/images/filters-dark.svg')}/>
      }
    </TouchableOpacity>
  )
};


// Creating Picker or custom select for web
export class EgafPicker extends React.Component {

  componentDidMount() {
    require('selectric');
    this.$el = $(this.el);
    this.$el.selectric({
      onChange: (element) => {
        const selectedValue = $(element).val();
        const action = this.props.availableActions.find((action) => action.name === selectedValue);
        this.props.onValueChange(action.name, 0);
      },
    });
  }

  componentWillUnmount() {
    this.$el.selectric('destroy');

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.$el.selectric('refresh');
    // if (this.props.enabled !== prevProps.enabled){
    // }
  }

  render() {
    return (
      <div>

        <select name={"classes_actiond"} className={"action-select"} ref={el => this.el = el}
                disabled={this.props.enabled ? '' : 'disabled'}>
          <option key={0} value={''}>{'Esegui un azione'}</option>
          {
            this.props.availableActions.map((action, index) => {
              return <option key={index} label={action.label} value={action.name}
                             disabled={action.enabled ? '' : 'disabled'}>{action.label}</option>
            })
          }
        </select>
      </div>
    );
  }
}


export class EgafStatPeriodSelect extends React.Component {

  componentDidMount() {
    require('selectric');
    this.$el = $(this.el);

    // Adding change event
    this.$el.selectric({
      onChange: (element) => {
        const selectedValue = $(element).val();
        this.props.onValueChange(selectedValue);
      },
    });

    // Set current value
    if (this.props.selectedValue) {
      this.$el.val(this.props.selectedValue);
      this.$el.selectric('refresh');
    }
  }

  componentWillUnmount() {
    this.$el.selectric('destroy');

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.$el.selectric('refresh');
    // if (this.props.enabled !== prevProps.enabled){
    // }
  }

  render() {
    return (
      <div>

        <select name={"stats_period"} className={"stat-period-select"} ref={el => this.el = el}
                disabled={this.props.enabled ? '' : 'disabled'}>
          {/*<option key={0} value={''}>{'Esegui un azione'}</option>*/}
          <option key={6} value={1}>{'Ultimo giorno'}</option>
          <option key={0} value={7}>{'Ultimi 7 gg'}</option>
          <option key={1} value={30}>{'Ultimi 30 gg'}</option>
          <option key={2} value={60}>{'Ultimi 60 gg'}</option>
          <option key={3} value={90}>{'Ultimi 90 gg'}</option>
          <option key={4} value={120}>{'Ultimi 120 gg'}</option>
          <option key={5} value={180}>{'Ultimi 180 gg'}</option>


        </select>
      </div>
    );
  }
}


//
//
// "idclasse": 8,
// "COD_INTERL": "900187",
// "COD_SIGLAIND": ".",
// "opera": 3,
// "classe": "PATENTI A - B. GESTIONE CORSI E QUIZ",
// "PREDEFINITO": "1",
// "DISABILITATO": "0"


export class EgafStackedChart extends React.Component {

  constructor(props) {
    super(props);
    this.chartInstance = createRef();
    console.info('EgafStackedChart ref:', this.chartInstance);
  }

  componentDidMount() {
    this.chart = ReactDOM.findDOMNode(this);
    console.info('EgafStackedChart this.chart', this.chart);
    // this.chart.helpers;
  }

  componentWillUnmount() {
  }

  getGraphHeight(length) {
    const H_CONST = 10;
    const H_MARGIN = 20;
    // console.info('HEIGHT:', H_CONST * length + H_MARGIN);
    const height = H_CONST * length + H_MARGIN;
    return height > 4000 ? 4000 : height;
  }

  _getGraphHeight(length) {
    console.info('GETTING HEIGHT FOR LENGTH: ', length);
    let height = 2000;
    if (length < 150) {
      height = 1800;
    }
    if (length < 110) {
      height = 1450;
    }
    if (length < 90) {
      height = 1200;
    }
    if (length < 80) {
      height = 1000;
    }
    if (length < 70) {
      height = 900;
    }
    if (length < 60) {
      height = 800;
    }
    if (length < 50) {
      height = 700;
    }
    if (length < 40) {
      height = 600;
    }
    if (length < 30) {
      height = 500;
    }
    if (length < 20) {
      height = 450;
    }
    if (length < 10) {
      height = 350;
    }
    if (length < 5) {
      height = 250;
    }

    return height
  }

  render() {
    const defaultData = {
      labels: ["2 Jan", "9 Jan", "16 Jan", "23 Jan", "30 Jan", "6 Feb", "13 Feb"],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: Colors.wrong,
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 0,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [65, 59, 80, 81, 56, 55, 40]
        },
        {
          label: 'My Second dataset',
          backgroundColor: Colors.correct,
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 0,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [35, 19, 10, 51, 26, 15, 5]
        }
      ]
    };

    const data = this.props.data? this.props.data : defaultData;

    let xScale = 45;
    if (data.hasOwnProperty('maxValueForXScale')) {
      xScale = data.maxValueForXScale + 5;
    }
    console.info('GRAPH DATA;', data);
    // debugger;

    const options = {
      responsive: true,
      // maintainAspectRatio: false,
      "hover": {
        "animationDuration": 0
      },
      "clip": false,
      "animation": {
        "duration": 1000,
      },
      legend: {
        "display": false,
      },
      parsing: false,
      tooltips: {
        callbacks: {
          footer: (tooltipItem, data) => {
            if (this.props.isSingleStudent) {
              // Showing wrongs number
              const dataIndex = tooltipItem[0].index;
              let score = 0;
              for (const points of data.datasets) {
                score = score + parseInt(points.data[dataIndex]);
              }
              // return 'Totale fatte: '+ score;
              var questionsNumbers = data.datasets[0].questionsNumbers[dataIndex];
              return `Sbagliate: ${questionsNumbers-score}`;
            } else {
              // Showing total questionaries

              // Getting all quest. numbers foreach type
              const totalQuests = tooltipItem.map( t => parseInt(t.value));
              return `Questionari totali: ${totalQuests.reduce( (a,b) => a + b)}`;
              // console.info('FOOTER, data available:', data);
              // console.info('FOOTER, dataIndex (as above):', tooltipItem[0].index);
              // console.info('FOOTER, tooltipitem:', tooltipItem);
            }
          }
        }
      },
      scales: {
        xAxes: [
          {
            max: xScale,
            type: 'linear',
            position: 'top',
            gridLines: {
              color: '#4a4a4a',
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            ticks: {
              max: xScale,
              beginAtZero: true,
              fontColor: '#999',
              padding: 3,
              stepSize: 10,
              // callback: function(tick) {
              //   return tick !== Math.max(...data.datasets[0].data) + 10 ? tick : '';
              // }
            }
          },
          {
            max: xScale,
            type: 'linear',
            position: 'bottom',
            gridLines: {
              color: '#4a4a4a',
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            ticks: {
              max: xScale,
              beginAtZero: true,
              fontColor: '#999',
              padding: 3,
              stepSize: 10,
              // callback: function(tick) {
              //   return tick !== Math.max(...data.datasets[0].data) + 10 ? tick : '';
              // }
            }
          },
        ],
        yAxes: [{
          // barPercentage: 0.8,
          categoryPercentage: 0.9,
          maxBarThickness: 15,
          gridLines: {
            color: '#4a4a4a',
            drawOnChartArea: true, // only want the grid lines for one axis to show up
          },
          ticks: {
            fontColor: '#999',
            padding: 3
          }
        }]
      },
      events: ['click', 'hover'],
      interaction: {
        mode: 'index'
      },
      onClick: (e, elements) => {
        // debugger;
        console.info('CHART CLICK:', e);
        console.info('CHART CLICKED ELEMENTS:', elements);
        // My implementation
        if (elements && elements.length && this.props.showQuestions) {
          const clickedElementIndex = elements[0]._index;
          this.props.showQuestions(data.idMapping[clickedElementIndex]);
        }
      },
    };

    // Adding plugin to draw numbers aside bars
    const plugins = [{
      afterDatasetsDraw: () => {

        const isSingleStudent = this.props.isSingleStudent;
        const ctx = this.chartInstance.current.chartInstance.ctx;

        console.info('ctx.font', ctx.font);
        ctx.font = "16px \"Helvetica Neue\", Helvetica, Arial, sans-serif";

        //ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
        ctx.textAlign = 'center';
        ctx.fillStyle = '#ffffff';
        ctx.textBaseline = 'bottom';
        // ctx.zindex = 1;

        data.datasets.forEach((dataset, i) => {
          var meta = this.chartInstance.current.chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            let textToRender = '';
            if (isSingleStudent) {
              // Showing number of wrong questions
              var data = dataset.data[index];
              var questionsNumbers = dataset.questionsNumbers[index];
              if (data >= 0) {
                textToRender = questionsNumbers - data;
              }
            } else {
              // Showing number questionary done
              const numberToShow = dataset.data[index];
              if (numberToShow) {
                textToRender = numberToShow;
              }
            }
            ctx.fillText(textToRender, bar._model.x + 10, bar._model.y + 8);
          });
        });

      }
    }];

    return (
      <HorizontalBar
        ref={this.chartInstance}
        data={data}
        width={100}
        height={this.props.data? this.getGraphHeight(data.labels.length) : 250}
        options={options}
        plugins={plugins}
      />

    );
  }

}

export const StatPie = (props) => {
  const data = {
    datasets: [{
      data: [props.correct, props.wrong, props.toComplete],
      backgroundColor: [Colors.correct, Colors.wrong, '#2a2a2a'],
      borderWidth: 0,
    }],
  };

  const options = {
    animation: {
      // Boolean - Whether we animate the rotation of the Doughnut
      animateRotate: false,
      // Boolean - Whether we animate scaling the Doughnut from the centre
      animateScale: false
    },
    tooltips: () => {}
  }

  return (
    <View style={{width: props.width? props.width : 50}}>
      <Doughnut
        data={data}
        options={options}
      />
    </View>
  )
}

export class EgafMultiAxisLineChart extends React.Component {

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {

    const data = {
			labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
			datasets: [{
				label: 'My First dataset',
				borderColor: '#ff00ff',
				backgroundColor: '#cccccc',
				fill: false,
				data: [
					20,
					40,
					5,
					35,
					23,
					45,
					15
				],
				yAxisID: 'y-axis-1',
			}, {
				label: 'My Second dataset',
				borderColor: '#6f0019',
				backgroundColor: '#00ff00',
				fill: false,
				data: [
					30,
					10,
					56,
					15,
					63,
					25,
					45
				],
				yAxisID: 'y-axis-2'
			}]
		};

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      hoverMode: 'index',
      stacked: false,
      title: {
        display: false,
        text: 'Risposte e andamento'
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: '#4a4a4a',
            drawOnChartArea: true,
          },

          ticks: {
            fontColor: '#999',
            padding: 3
          }
        }],
        yAxes: [{
          type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
          display: 'auto',
          position: 'left',
          id: 'y-axis-percent',

          gridLines: {
            display: true,
            color: Colors.correct,
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },

          ticks: {
            fontColor: '#999',
            padding: 3
          }

        }, {
          type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
          display: 'auto',
          position: 'right',
          id: 'y-axis-average',

          gridLines: {
            display: true,
            color: Colors.primaryColor,
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },

          ticks: {
            fontColor: '#999',
            padding: 3
          }
        }],
      }
    };

    console.info('Rendereing line chart with height', this.props.height? this.props.height : 350);

    return (
      <Line
        data={this.props.data? this.props.data : data}
        options={options}
      />

    );
  }

}


export const Tooltip = (props) => {

  const textLinesNumber = props.textLinesNumber? props.textLinesNumber : 1;
  const [value] = useState(new Animated.Value(props.show? 0 : 1));

  useEffect(()=>{
      Animated.timing(value, {
          toValue: props.show? 1 : 0,
          duration: 200,
          useNativeDriver: false
      }).start() // < Don't forget to start!
  }, [props.show]) // < Run animation only when props.value changed

  // Apply animated property to your style
  return (
      <Animated.View style={[tooltipStyles.tooltip, props.hasRichText? {bottom: -6, right: -10} : null, {opacity: value}]} >
        {
          props.hasRichText?
            props.hasRichText
            :
          <Text numberOfLines={textLinesNumber} style={tooltipStyles.tooltipText}>{props.text}</Text>
        }
        <Image size={20} style={tooltipStyles.tooltipArrow} source={require('../commons/images/triangle.svg')}/>
      </Animated.View>
  )

}


export const PortalButton = (props) => {
    var didatticaURL = 'https://';
    if (window.location.hostname.indexOf("test.") !== -1) {
      didatticaURL += 'test.'
    }
    didatticaURL += 'didattica.docenti.egaf.it/';
    return (
      <a className="portal-link" rel="noopener noreferrer" href={didatticaURL}>
        <Image style={{ width: 80, height: 30 }} source={require('../commons/images/portale_docenti_btn.png')}/>
      </a>
    )
}
