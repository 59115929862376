import React from "react";
import {View, Text, ActivityIndicator, ScrollView, TouchableOpacity, Image} from 'react-native';
import {styles} from '../../foundation/styles';
import statsStyles from '../styles';
import UserContext from "../../user";
import axios, {CancelToken} from "../../commons/axios";
import {reaction} from "mobx";
import {EgafStackedChart, Tooltip} from "../../foundation/widgets";
import Moment from 'moment';
import errorManager from "../../commons/error_manager";


export default class Results extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Cancel Token for topics request
    this.source = CancelToken.source();

    this.state = {
      loading: true,
      statsData: [],
      resultLength: 0
    }
  }

  async loadStats() {
    try {
      // https://autoscuole.egaf.it/statistics/?stat=statAndamentoQuest_ClasseOpera&idClasse=8&opera=4
      const statsResp = await axios.get("statistics/", {
        cancelToken: this.source.token,
        params: {
          stat: 'statAndamentoQuest_ClasseOpera',
          idClasse: this.props.currentClass.idclasse,
          opera: this.context.currentCourse.opera,
          giorni: this.context.statsPeriod
        }
      });

      // console.info('statsResp for tab RESULTS', statsResp.data);

      const coursesGraphData = [];
      for (const courseData of statsResp.data) {
        coursesGraphData.push({
          key: courseData.id,
          title: courseData.descrizione,
          graphData: courseData.stat.length? this._processRawData(courseData.stat) : null,
          info: courseData.info ? courseData.info : null
        });
      }

      this.setState({
        loading: false,
        statsData: coursesGraphData,
        resultLength: statsResp.data[0].stat.length
      });
    } catch (e) {
      errorManager(e, 'Non è stato possibile processare le statistiche nel periodo scelto. Riprovare più tardi. Grazie');
    }
  }

  // Process data foreach course { description: 'fdsfsf', stat: [...] }
  _processRawData(stat) {

    console.info('_processRawData(STAT SECTION)', stat);

    const data = {
      labels: [],
      datasets: [],
    };



    // Set up datasets based on first questionary
    const firstSet = stat[0];
    const dataLength = firstSet.LABELS.length;
    let datasets = [];
    firstSet.LABELS.forEach((label, index)=>{
      datasets.push({
        label: label,
        backgroundColor: firstSet.COLORS[index],
        hoverBackgroundColor: firstSet.COLORS[index],
        borderWidth: 0,
        data: [],
        questionsNumbers: []
      });
    });

    // Calculating max questionary answered, to use for X scale
    let maxValueForXScale = [];

    // Populating datasets and data
    for (const questionary of stat) {
      data.labels.push(Moment(questionary.QU_DATA).format('DD/MM/YYYY'));
      maxValueForXScale.push(Math.max(...questionary.VALUES));
      for (let i = 0; i < dataLength; i++) {
        datasets[i].data.push(questionary.VALUES[i])
        datasets[i].questionsNumbers.push(questionary.QU_TOTALE_DOMANDE);
      }
    }

    data.maxValueForXScale = Math.max(...maxValueForXScale);
    data.datasets = datasets;
    return data;
  }

  componentDidMount() {
    this.loadStats();

    // Reaction to location changing
    this._statPeriodReactionDispose = reaction(
      () => this.context.statsPeriod,
      () => {
        this.setState({
          loading: true,
        });
        this.loadStats();
      }
    );
  }

  componentWillUnmount() {
    this._statPeriodReactionDispose();
    this.source.cancel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentClass !== this.props.currentClass) {
      this.setState({
        loading: true,
      });
      this.loadStats();
    }
  }

  _render() {

    if (this.state.loading) {
      return <View style={statsStyles.statContainer}>
        <ActivityIndicator/>
      </View>;
    } else {
      return (
        <View style={statsStyles.statContainer}>
          <ScrollView style={styles.scrollView}>
            {
              this.state.statsData.map((data)=>{
                return (
                  <View key={data.key} style={{marginTop: 20}}>
                    <Text style={[styles.title1, styles.textLight]}>{data.title}</Text>
                    {
                      data.graphData?
                        <EgafStackedChart
                          data={data.graphData}
                        />
                        :
                        <Text style={statsStyles.noStatMessage}>Queste statistiche non sono ancora disponibili</Text>
                    }

                  </View>
                )
              })
            }
          </ScrollView>
        </View>
      );
    }
  }

  render() {

    if (this.state.loading) {
      return <View style={statsStyles.statContainer}>
        <ActivityIndicator/>
      </View>;
    } else {
      return (
        <View style={statsStyles.statContainer}>
          <ScrollView style={styles.scrollView}>
            {
              this.state.statsData.map((data, index)=>{

                let mappedColors = null;
                if (data.graphData) {
                  mappedColors = {};
                  for (const dataset of data.graphData.datasets) {
                    mappedColors[dataset.label] = dataset.backgroundColor;
                  }
                }

                return (
                  <View key={data.key} style={{marginTop: 20}}>
                    {
                      data.graphData?

                        <ResultsAccordion
                          key={'result_'+index}
                          title={data.title}
                          open={index === 0}
                          isSingle={this.state.statsData.length === 1}
                          info={data.info}
                          mappedColors={mappedColors}
                        >
                          <EgafStackedChart
                            data={data.graphData}
                          />
                        </ResultsAccordion>
                        :
                        <ResultsAccordion
                          key={'result_'+index}
                          title={data.title}
                          open={index === 0}
                          isSingle={this.state.statsData.length === 1}
                          info={data.info}
                          mappedColors={mappedColors}
                        >
                          <Text style={statsStyles.noStatMessage}>Queste statistiche non sono ancora disponibili</Text>
                        </ResultsAccordion>

                    }
                  </View>
                )
              })
            }
          </ScrollView>
        </View>
      );
    }
  }


}


export class ResultsAccordion extends React.Component {
  constructor(props) {
    super(props);
    // console.info('RESULT ACCORDION PROPS', props);
    this.state = {
      open: this.props.open,
      showTooltip: false
    }
  }

  componentDidMount() {
  }

  toggleOpen() {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    return (
      <View
        style={statsStyles.accordion}
      >
        {
          this.props.isSingle?
            null
            :
            <View
              style={statsStyles.accHeading}
            >
              <Text style={statsStyles.accTitle}>{this.props.title}</Text>
              <TouchableOpacity
                style={statsStyles.openBtn}
                onPress={() => this.toggleOpen()}
              >
                {
                  this.state.open?
                    <Image size={20} style={{tintColor: '#ffffff', width: 18, height: 18}} source={require('../../commons/images/minus.svg')}/>
                    :
                    <Image size={20} style={{tintColor: '#ffffff', width: 18, height: 18}} source={require('../../commons/images/plus.svg')}/>
                }
              </TouchableOpacity>
            </View>
        }

        {
          this.state.open?
            this._renderContent()
            :
            null
        }
      </View>
    );
  }

  generateRichText() {
    if (this.props.info && this.props.mappedColors) {
      return (
        <View>
          {
            this.props.info.map((info, index)=>{
              return (
                <View style={{flexDirection: 'row', marginBottom: 2}} key={'info_'+index}>
                  <View style={{width: 18, height: 18, backgroundColor: this.props.mappedColors[info.title], marginRight: 5}} />
                  <Text style={{fontWeight: 'bold', color: '#ffffff', marginRight: 7}}>{info.title}:</Text>
                  <Text style={{color: '#ffffff'}}>{info.descr}</Text>
                </View>
              );
            })
          }
        </View>
      )
    }
  }

  _renderContent() {

    const richText = this.props.info? this.generateRichText() : false;
    return(
      <View
        style={statsStyles.accTopicsW}
      >
        {
          this.props.info ?
            <View style={statsStyles.accTooltipW}>
              <TouchableOpacity
                style={statsStyles.accTooltipBtn}
                onPress={() => this.setState({showTooltip: !this.state.showTooltip})}
              >
                <Text style={statsStyles.accTooltipTxt}>?</Text>
              </TouchableOpacity>

              <Tooltip
                show={this.state.showTooltip}
                // text={'Custom text for testin gpurpose'}
                hasRichText={richText}
                textLinesNumber={4}
                showHtml
              />
            </View>
            :
            null
        }
        {this.props.children}

      </View>
    )
  }
}
