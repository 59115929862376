import {createStackNavigator} from "react-navigation";
import ClassesHome from './views';
import {ImageShow} from "../section_statistics/views";


const ClassesNavigator = createStackNavigator({
  main: {
    screen: ClassesHome
  },
  image: {
    screen: ImageShow
  }
},
{
  mode: 'modal',
  headerMode: 'none',
  transparentCard: true
});

export default ClassesNavigator;
