import {createStackNavigator} from "react-navigation";
import ConfigurationHome from './views';


const ConfigurationNavigator = createStackNavigator({
  Main: {
    screen: ConfigurationHome
  },
  // EditUser: {
  //   screen: ConfigurationEditUser
  // }
},
{
  mode: 'modal',
  headerMode: 'none',
  transparentCard: true,
  cardStyle: { opacity: 1 }
});

ConfigurationNavigator.defaultOptions = (navigation) => {
  return {
    tabBarOnPress: ({ defaultHandler }) => {
      defaultHandler();
    },
  }
}

export default ConfigurationNavigator;
