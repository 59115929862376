import {createStackNavigator} from "react-navigation";
import StatisticsHome from './views';
import {ImageShow} from './views';


const StatisticsNavigator = createStackNavigator({
  main: {
    screen: StatisticsHome
  },
  image: {
    screen: ImageShow
  }
},
{
  mode: 'modal',
  headerMode: 'none',
});

export default StatisticsNavigator;
