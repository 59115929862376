import React from "react";
import {View, Text, ActivityIndicator, ScrollView} from 'react-native';
import {Colors, styles} from '../../foundation/styles';
import statsStyles from '../styles';
import UserContext from "../../user";
import axios, {CancelToken} from "../../commons/axios";
import {reaction} from "mobx";
import {EgafMultiAxisLineChart} from "../../foundation/widgets";
import errorManager from "../../commons/error_manager";


export default class Trend extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Cancel Token for topics request
    this.source = CancelToken.source();

    this.state = {
      loading: true,
      statsData: []
    }
  }

  alertMessage(message = null) {
    const msg = message ? message : 'Non è stato possibile processare le statistiche nel periodo scelto. Riprovare più tardi. Grazie';
    alert(msg);
  }

  async loadStats() {

    try {
      // https://autoscuole.egaf.it/statistics/?stat=statAndamento_ClasseOpera&idClasse=8&opera=4
      const statsResp = await axios.get("statistics/", {
        cancelToken: this.source.token,
        params: {
          stat: 'statAndamento_ClasseOpera',
          idClasse: this.props.currentClass.idclasse,
          opera: this.context.currentCourse.opera,
        }
      });

      // console.info('statsResp for tab TREND', statsResp.data);

      const linearData = this._processRawData(statsResp.data);

      this.setState({
        loading: false,
        statsData: linearData
      });
    } catch (e) {
      errorManager(e);
      // if (e.response.status !== 403){
      //   this.alertMessage();
      // }
    }
  }

  _processRawData(rawData) {

    const data = {
      labels: [],
      datasets: [],
    }

    const datasetCorrectAnswers = {
      label: 'Risposte giuste (%)',
      backgroundColor: Colors.correct,
      pointBackgroundColor: '#ffffff',
      pointHoverBackgroundColor: '#cccccc',
      borderColor: Colors.correct,
      // borderWidth: 1,
      hoverBackgroundColor: Colors.correct,
      hoverBorderColor: Colors.correct,
      fill: false,
      data: [],
      yAxisID: 'y-axis-percent',
    };
    const datasetWrongAnswers = {
      label: 'Risposte sbagliate (%)',
      backgroundColor: Colors.wrong,
      borderColor: Colors.wrong,
      // borderWidth: 1,
      hoverBackgroundColor: Colors.wrong,
      hoverBorderColor: Colors.wrong,
      fill: false,
      data: [],
      yAxisID: 'y-axis-percent',
    };
    const datasetAverage = {
      label: 'Media giornaliera / allievo',
      backgroundColor: Colors.primaryColor,
      borderColor: Colors.primaryColor,
      // borderWidth: 0,
      hoverBackgroundColor: Colors.primaryColor,
      hoverBorderColor: Colors.primaryColor,
      fill: false,
      data: [],
      yAxisID: 'y-axis-average',
    };

    for (const set of rawData) {
      data.labels.push(set.giorni + ' giorni');
      const correct = set.GIUSTE/set.TOTALE*100;
      const wrong = set.SBAGLIATE/set.TOTALE*100;
      datasetCorrectAnswers.data.push(correct.toFixed());
      datasetAverage.data.push(set.MEDIA_GG_ALLIEVO);
      datasetWrongAnswers.data.push(wrong.toFixed());
    }

    data.datasets.push(datasetCorrectAnswers);
    // data.datasets.push(datasetWrongAnswers);
    data.datasets.push(datasetAverage);
    return data;

  }

  componentDidMount() {
    this.loadStats();

    // Reaction to location changing
    this._statPeriodReactionDispose = reaction(
      () => this.context.statsPeriod,
      () => {
        this.setState({
          loading: true,
        });
        this.loadStats();
      }
    );
  }

  componentWillUnmount() {
    this._statPeriodReactionDispose();
    this.source.cancel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentClass !== this.props.currentClass) {
      this.setState({
        loading: true,
      });
      this.loadStats();
    }
  }

  render() {

    if (this.state.loading) {
      return <View style={statsStyles.statContainer}>
        <ActivityIndicator/>
      </View>;
    } else {
      return (
        <View style={statsStyles.statContainer}>
          {/*{this._renderListHeading()}*/}
          <ScrollView style={styles.scrollView}>

            <View style={{marginTop: 20}}>
              <EgafMultiAxisLineChart
                data={this.state.statsData}
              />
            </View>

          </ScrollView>
        </View>
      );
    }
  }

  _renderListHeading() {
    return (
      <View
        style={styles.rowContainer}
      >
        <View style={[styles.rowContainer, {marginRight: 25}]}>
          <View style={statsStyles.correctLegend}/>
          <Text style={[styles.textLight,]}>giuste</Text>
        </View>

        <View style={styles.rowContainer}>
          <View style={statsStyles.wrongLegend}/>
          <Text style={[styles.textLight,]}>sbagliate</Text>
        </View>
      </View>
    );
  }

}
