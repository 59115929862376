import React from "react";
import {View, Text, ActivityIndicator, TouchableOpacity, ScrollView, Image} from 'react-native';
import {styles} from '../../foundation/styles';
import statsStyles from '../styles';
import UserContext from "../../user";
import axios, {CancelToken} from "../../commons/axios";
import {reaction} from "mobx";
import {QuizAnswersBarChart} from "../../foundation/widgets";
import classStyles from "../../section_classes/styles";
import errorManager from "../../commons/error_manager";


export default class Topics extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Cancel Token for topics request
    this.source = CancelToken.source();

    this.state = {
      loading: true,
      statsData: []
    }
  }

  alertMessage(message=null) {
    const msg = message? message : 'Non è stato possibile processare le statistiche nel periodo scelto. Riprovare più tardi. Grazie';
    alert(msg);
  }

  async loadStats() {
    try {
      // https://autoscuole.egaf.it/statistics/?stat=statFocus_ClasseOpera&idClasse=8&opera=4&giorni=180
      const statsResp = await axios.get("statistics/", {
        cancelToken: this.source.token,
        params: {
          stat: 'statFocus_ClasseOpera',
          idClasse: this.props.currentClass.idclasse,
          opera: this.context.currentCourse.opera,
          giorni: this.context.statsPeriod
        }
      });


      // console.info('statsResp for tab TOPICS', statsResp.data);
      // console.log('for opera:' + this.context.currentCourse.opera );

      this.setState({
        loading: false,
        statsData: statsResp.data
      });
    } catch (e) {

      errorManager(e);

      // debugger;
      // console.info('Cancel', axiosLib.isCancel(e));
      // console.info('Cacth error message in topics.js', e);
      // console.info('Cacth error message in topics.js', e.response.status);
      // if (e.response.status !== 403){
      //   this.alertMessage();
      // }
    }
  }

  componentDidMount() {
    this.loadStats();

    // Reaction to location changing
    this._statPeriodReactionDispose = reaction(
      () => this.context.statsPeriod,
      () => {
        this.setState({
          loading: true,
        });
        this.loadStats();
      }
    );
  }

  componentWillUnmount() {
    this._statPeriodReactionDispose();
    this.source.cancel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentClass !== this.props.currentClass) {
      this.setState({
        loading: true,
      });
      this.loadStats();
    }
  }

  render() {
    if (this.state.loading) {
      return <View style={statsStyles.statContainer}>
        <ActivityIndicator/>
      </View>;
    } else {

      if (this.state.statsData.length) {
        return (
          <View style={statsStyles.statContainer}>
            {this._renderListHeading()}
            <ScrollView style={styles.scrollView}>

              {
                this.state.statsData.map((set, index) => {
                  return(
                    <TopicsAccordion
                      key={set.id}
                      title={set.descrizione}
                      stats={set.stat}
                      open={index === 0}
                      isSingle={this.state.statsData.length === 1}
                    />
                  )
                })
              }

            </ScrollView>
          </View>
        );
      } else {
        return(
          <View style={statsStyles.statContainer}>
            <Text style={statsStyles.noStatMessage}>Le statistiche per questa classe non sono ancora disponibili</Text>
          </View>
        );
      }

    }
  }

  _renderListHeading() {
    return (
      <View
        style={styles.rowContainer}
      >
        <View style={[styles.rowContainer, {marginRight: 25}]}>
          <View style={statsStyles.correctLegend}/>
          <Text style={[styles.textLight, ]}>giuste</Text>
        </View>

        <View style={styles.rowContainer}>
          <View style={statsStyles.wrongLegend}/>
          <Text style={[styles.textLight, ]}>sbagliate</Text>
        </View>
      </View>
    );
  }

}

class TopicsAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.orderingTypes = ['ASC', 'DESC'];

    this.state = {
      open: this.props.open,
      stats: [],

      ordering: {
        criteria: 'name',
        type: this.orderingTypes[1] // Start with DESC therefore on mounting stats will be ordered by ASC
      }
    }
  }

  componentDidMount() {
    this.orderTopics('name', this.props.stats);
  }

  orderTopics(criteria, statistics=null) {
    // Get ordering type
    let orderType = this.state.ordering.type;
    if (criteria !== this.state.ordering.criteria) {
      orderType = this.orderingTypes[0];
    } else{
      orderType = this.orderingTypes[0] === orderType ? this.orderingTypes[1] : this.orderingTypes[0];
    }

    // Order by criteria and type
    let topics = statistics? Array.from(statistics) : Array.from(this.state.stats);
    if (criteria === 'name') {
      // order by name
      topics = topics.sort((a, b) => {
        // let propA = a.LEZIONE.split('-')[1];
        // let propB = b.LEZIONE.split('-')[1];
        let propA = a.LEZIONE;
        let propB = b.LEZIONE;
        if (propA < propB) {
          return orderType === this.orderingTypes[0] ? -1 : 1;
        }
        if (propA > propB) {
          return orderType === this.orderingTypes[0] ? 1 : -1;
        }
        return 0;

      });
    } else if (criteria === 'correct') {
      // order by percentage
      topics = topics.sort((a, b) => {
        const totalA = a.SBAGLIATE + a.GIUSTE;
        const totalB = b.SBAGLIATE + b.GIUSTE;

        let propA = totalA? a.GIUSTE/totalA : 0;
        let propB = totalB? b.GIUSTE/totalB : 0;
        if (propA < propB) {
          return orderType === this.orderingTypes[0] ? -1 : 1;
        }
        if (propA > propB) {
          return orderType === this.orderingTypes[0] ? 1 : -1;
        }
        return 0;

      });
    } else {
      // order by percentage
      topics = topics.sort((a, b) => {
        const totalA = a.SBAGLIATE + a.GIUSTE;
        const totalB = b.SBAGLIATE + b.GIUSTE;

        let propA = totalA? a.SBAGLIATE/totalA : 0;
        let propB = totalB? b.SBAGLIATE/totalB : 0;
        if (propA < propB) {
          return orderType === this.orderingTypes[0] ? -1 : 1;
        }
        if (propA > propB) {
          return orderType === this.orderingTypes[0] ? 1 : -1;
        }
        return 0;

      });
    }

    // Set state
    const currentOrdering = {
      criteria: criteria,
      type: orderType
    }

    this.setState({
      stats: topics,
      ordering: currentOrdering
    })

  }

  getOrderingImage(criteria) {
    let orderingImg = <Image
      style={{width: 10, height: 20, marginRight: 7}}
      source={require('../../commons/images/order_off.png')}
    />;

    if (this.state.ordering.criteria === criteria) {
      if (this.state.ordering.type === this.orderingTypes[1]) {
        orderingImg = <Image
          style={{width: 10, height: 20, marginRight: 7}}
          source={require('../../commons/images/order_desc.png')}
        />
      } else {
        orderingImg = <Image
          style={{width: 10, height: 20, marginRight: 7}}
          source={require('../../commons/images/order_asc.png')}
        />;
      }
    }

    return orderingImg;
  }

  toggleOpen() {
    this.setState({
      open: !this.state.open
    })
  }

  _renderOrderingHeading() {
    return (
      <View
        style={[styles.rowContainer, statsStyles.accOrderingHeader, ]}
      >
        <TouchableOpacity
          style={[statsStyles.accOrderingCriteria, {flex:1}, ]}
          onPress={() => this.orderTopics('name')}
        >
          <Text style={statsStyles.accOrderingCriteriaText}>Argomento</Text>
          {this.getOrderingImage('name')}
        </TouchableOpacity>

        <TouchableOpacity
          style={statsStyles.accOrderingCriteria}
          onPress={() => this.orderTopics('correct')}
        >
          <Text style={statsStyles.accOrderingCriteriaText}>Giuste</Text>
          {this.getOrderingImage('correct')}
        </TouchableOpacity>

        {/*<TouchableOpacity*/}
          {/*style={statsStyles.accOrderingCriteria}*/}
          {/*onPress={() => this.orderTopics('wrong')}*/}
        {/*>*/}
          {/*<Text style={statsStyles.accOrderingCriteriaText}>Sbagliate</Text>*/}
          {/*{this.getOrderingImage('wrong')}*/}
        {/*</TouchableOpacity>*/}
      </View>
    );
  }

  render() {

    return (
      <View
        style={statsStyles.accordion}
      >

        {
          this.props.isSingle?
            null
            :
            <View
              style={statsStyles.accHeading}
            >

              <Text style={statsStyles.accTitle}>{this.props.title}</Text>
              <TouchableOpacity
                style={statsStyles.openBtn}
                onPress={() => this.toggleOpen()}
              >
                {
                  this.state.open?
                    <Image size={20} style={{tintColor: '#ffffff', width: 18, height: 18}} source={require('../../commons/images/minus.svg')}/>
                    :
                    <Image size={20} style={{tintColor: '#ffffff', width: 18, height: 18}} source={require('../../commons/images/plus.svg')}/>
                }
              </TouchableOpacity>
            </View>
        }

        {
          this.state.open?
            this._renderTopics()
            :
            null
        }
      </View>
    );
  }

  _renderTopics() {
    return(
      <View
        style={statsStyles.accTopicsW}
      >
        {
          this._renderOrderingHeading()
        }

        {
          this.state.stats.map((stat, index)=> {
            const total = stat.SBAGLIATE + stat.GIUSTE;
            const wrongValue = total ? stat.SBAGLIATE / total : 0;
            const correctValue = total ? stat.GIUSTE / total : 0;

            let wrongPercentage = wrongValue * 100;
            wrongPercentage = wrongPercentage.toFixed();
            // let correctPercentage = correctValue * 100;
            let correctPercentage = wrongPercentage? 100 - wrongPercentage : 0;
            correctPercentage = correctPercentage.toFixed();
            console.info('STAT: topic', stat);
            // Insert here touchableopacity to drill down (like src/section_classes/statistics_detail/topics.js)
            return(
              <View style={statsStyles.accTopic} key={index}>
                <View style={statsStyles.accTopicTopRow}>
                  <View style={{flex: 1}}>
                    <Text style={statsStyles.accTopicName}>{stat.LEZIONE}</Text>
                    <QuizAnswersBarChart
                      completed={correctValue+wrongValue}
                      wrong={wrongValue}
                      height={15}
                      bgColor={'#1b1b1b'}
                    />
                  </View>

                  <View style={{flexShrink: 1, paddingLeft: 10, paddingRight: 7}}>
                    <Text style={[classStyles.completedColor, statsStyles.smallGraphText]}>{correctPercentage}%</Text>
                    <Text style={[classStyles.errorColor, statsStyles.smallGraphText]}>{wrongPercentage}%</Text>
                  </View>
                </View>
              </View>
            )
          })
        }

      </View>
    )
  }
}
