import React, {useContext, useState, useEffect} from "react";
import {View, Text, TouchableOpacity, Image} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import {Colors, Defaults} from "../foundation/styles";
import {getCookie, isWeb} from "../commons/utils";
import UserContext from "../user";
import axios from "axios";
import {EgafCheckbox} from "../foundation/widgets";


const changeLog = (props) => {

  const context = useContext(UserContext);
  let [hasCookie, setHasCookie] = useState(false);
  let [content, setContent] = useState(null);

  const getContentFromFile = async () => {
    const resp = await axios.get('/change_log.json');
    console.info('goit resp from acios: ', resp);
    setContent(resp.data);
  }

  useEffect(()=>{
    getContentFromFile();
    console.info('goit content: ', content);
  }, []);

  const toggleDoNotShow = () => {
    const changeLogCookie = getCookie("hidechangelog");
    if (changeLogCookie) {
      document.cookie = "hidechangelog=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      setHasCookie(false);
    } else {
      document.cookie = "hidechangelog=1; expires=Thu, 18 Dec 2063 12:00:00 UTC;";
      setHasCookie(true);
    }
  }

  return(
    <View style={CLStyles.wrapper}>
      <View style={CLStyles.content}>
        <TouchableOpacity
          style={CLStyles.closerBtn}
          onPress={()=> {
            context.showChangeLog = false;
          }}
        >
          <Text>X</Text>
        </TouchableOpacity>
        <View style={CLStyles.header}>
          <Text style={CLStyles.heading}>CHANGE LOG</Text>
        </View>
        <View style={CLStyles.body}>
          {
            content ?
            content.map( (cl, index) => {
              console.info('Change log:', cl);
              return <ChangeLogItem key={index} changeLog={cl} />;
            })
            :
            null
          }
        </View>

        <View style={CLStyles.footer}>
          <TouchableOpacity
          style={CLStyles.cookieBtn}
          onPress={()=> {
            toggleDoNotShow();
          }}
        >
            {
              hasCookie ?
                <Image size={20} style={{width: 30, height: 30, margin: 7}}
                     source={require('../commons/images/check.svg')}/>
                :
                <Image size={20} style={{width: 30, height: 30, margin: 7}}
                     source={require('../commons/images/x.svg')}/>
            }
          <Text style={CLStyles.cookieBtnText}>Non mostrare più</Text>
        </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}


const ChangeLogItem = (props) => {
  const {version, date, descr} = props.changeLog;
  return (
    <View style={CLStyles.clItem}>
    <View style={CLStyles.clItemTitle}>
      <Text style={CLStyles.clItemVersion}>Versione {version}</Text>
      <Text style={CLStyles.clItemDate}>({date})</Text>
    </View>
      <Text style={CLStyles.clItemDescr}>{descr}</Text>
    </View>
  )
}


export default changeLog;


const CLStyles = EStyleSheet.create({
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    flex: 1,
    backgroundColor: '#171717EF',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
  },
  content: {
    width: '70%',
    height: '60%',
    backgroundColor: '#666666',
    position: 'relative',
    padding: 30
  },
  closerBtn: {
    position: 'absolute',
    top: '-20px',
    right: '-20px',
    backgroundColor: '#ffffff',
    width: '40px',
    height: '40px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  closerBtnText: {
    color: '#000000',
    fontWeight: 'bold'
  },
  header:{
    paddingBottom: 15
  },
  heading:{
    fontSize: 32,
    fontWeight: '600',
    color: '#ffffff'
  },
  body: {
    flex: 1,
    overflow: 'scroll'
  },
  cookieBtn: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  cookieBtnText: {
    textTransform: 'uppercase'
  },
  clItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#eeeeee',
    paddingBottom: 18,
    marginBottom: 18
  },
  clItemTitle:{
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginBottom: 12
  },
  clItemVersion:{
    fontSize: 20,
    fontWeight: '500',
    color: '#ffffff',
    marginRight: 15
  },
  clItemDate:{
    fontSize: 16,
    fontStyle: 'italic',
    color: '#ddd'
  },
  clItemDescr:{

  }
});
