import React from 'react';
import { View } from 'react-native';
import {styles} from '../foundation/styles';
import configStyles from './styles';
import SegmentedControlTab from "react-native-segmented-control-tab";
import ConfigurationOperators from './operators/views';
import ConfigurationClasses from './classes/views';
import UserContext from "../user";


export default class ConfigurationHome extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }


  // TABS
  handleTabChange (index) {
    if ( !this.context.hasUnprocessedEdits){
      this.setState({tabIndex: index });
    }
  };

  renderSelectedTab() {
    if (this.state.tabIndex === 0) {
      return (
        <ConfigurationOperators />
      )
    } else {
      return <ConfigurationClasses />;
    }
  }

  // Rendering Logic
  render() {
    if (this.context.isAllowedUser){
      // User is allowed to see this section
      return (
        <View style={[styles.container, {position: 'relative'}, ]}>
          <View style={configStyles.tabsWrapper}>
            <SegmentedControlTab
                key={'config-tabs-control'}
                values={['Operatori', 'Classi', ]}
                selectedIndex={this.state.tabIndex}
                onTabPress={(index) => this.handleTabChange(index)}
                borderRadius={0}
                tabsContainerStyle={configStyles.tabsContainerStyle}
                tabStyle={configStyles.tabStyle}
                activeTabStyle={configStyles.activeTabStyle}
                tabTextStyle={configStyles.tabTextStyle}
                activeTabTextStyle={configStyles.activeTabTextStyle}
              />
          </View>
          {this.renderSelectedTab()}
        </View>
      );
    } else {

      // User is NOT allowed to see this section
      return null;
    }

  }

}

