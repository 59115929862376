import React from "react";
import {View, Text, Linking, StyleSheet} from 'react-native';
import {styles} from '../foundation/styles';
import {isWeb} from "./utils";


const Link = ({ href, style, children }) => (
  <Text
    accessibilityRole="link"
    target="_blank"
    href={href}
    onPress={() => {
      Linking.openURL(href);
    }}
    style={StyleSheet.compose(
      styles.link,
      style
    )}
  >
    <Text>{children}</Text>
  </Text>
);

const footer = () => {
  return(
    <View style={styles.footer}>
      {
        isWeb() ?
          <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://catalogo.egaf.it/index.php?p=istituzionale&cosa=8#privacy">Privacy Policy</a>
          :
          <Link href="https://catalogo.egaf.it/index.php?p=istituzionale&cosa=8#privacy">
            Privacy Policy
          </Link>
      }

      <Text style={styles.footerText}> | </Text>

      {
        isWeb() ?
          <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://catalogo.egaf.it/index.php?p=istituzionale&cosa=8#cookie">Informativa sui Cookie</a>
          :
          <Link href="https://catalogo.egaf.it/index.php?p=istituzionale&cosa=8#cookie">
            Informativa sui Cookie
          </Link>
      }

      <Text style={styles.footerText}> © EGAF EDIZIONI SRL • P.IVA: 02259990402</Text>
    </View>
  )
}

export default footer;


