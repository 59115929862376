import React from 'react';
import {View, Text, ScrollView, Dimensions, TouchableOpacity, ActivityIndicator} from 'react-native';
import {actionStyles, Colors, styles} from '../foundation/styles';
import {QuizAnswersBarChart} from '../foundation/widgets';
import dashboardStyles from "./styles";
import {getFormattedDate} from "../commons/utils";
import UserContext from '../user'
import axios from "../commons/axios";
import {reaction} from "mobx";
import errorManager from "../commons/error_manager";
import statsStyles from "../section_statistics/styles";
import classStyles from "../section_classes/styles";
import {loginUsername, loginPwd} from './login';


export class Dashboard extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      loadingLeft: true,
      contacts: null,

      loadingCenter: true,
      stats: [],

      loadingRight: true,

    }
  }

  //### Left Area

  sortBy(items, prop) {
    items = items.sort((a, b) => {
      if (a[prop] > b[prop]) {
        return -1;
      } else if (a[prop] < b[prop]) {
        return 1;
      } else {
        return 0;
      }
    });
    return items;
  }

  async loadLeft() {

    const studentsResp = await axios.get("students/getallievioperatore/", {
      params: {
        idutente: this.context.currentCloud.idutente,
        idnuvola: this.context.currentCloud.idnuvola,
        opera: this.context.currentCourse.opera
      }
    });
    const allContacts = studentsResp.data;

    const contacts = {};

    const potential = allContacts.filter((contact)=>contact['STATO'] === 'POTENZIALE');
    const subscribed = allContacts.filter((contact)=>contact['STATO'] === 'ISCRITTO');

    // Reordering contacts by subscription date
    contacts.potential = this.sortBy(potential, 'DataIscrizione');
    contacts.subscribed = this.sortBy(subscribed, 'DataIscrizione');

    this.setState({
      loadingLeft: false,
      contacts: contacts,
    });
  }
  // Left Area

  //### Center Area

  async loadClassStats(aClass) {
    try {
      // https://autoscuole.egaf.it/statistics/?stat=statTopAllievi_ClasseOpera&idClasse=8&opera=3&giorni=90
      const statsResp = await axios.get("statistics/", {
        params: {
          stat: 'statTopAllievi_ClasseOpera',
          idClasse: aClass.idclasse,
          opera: this.context.currentCourse.opera,
          giorni: 60
        }
      });

      // console.info('LOAD STAT CLASS:', statsResp.data);
      return statsResp.data;

    } catch (e) {
      errorManager(e);
    }
  }

  async loadCenter() {
    const classes = this.context.classes;
    const statsByClass = [];
    for (const aClass of classes) {
      const stat = await this.loadClassStats(aClass);
      // console.info('STAT FOR CLASS: ', stat);
      statsByClass.push({
        class: aClass,
        stats: stat
      });
    }

    this.setState({
      loadingCenter: false,
      stats: statsByClass
    })
  }
  // Center Area


  componentDidMount() {
    // Left Area
    this.loadLeft();

    // Center Area
    this.loadCenter();

    // Reaction for LEFT area
    this._leftReactionOnLocationDispose = reaction(
      () => this.context.currentLocation,
      () => {
        this.loadLeft();
      }
    );

    this._leftReactionOnCourseDispose = reaction(
      () => this.context.currentCourse,
      () => {
        this.loadLeft();
      }
    );


    // Reaction for central area
    this._centralReactionOnClassesDispose = reaction(
      () => this.context.classes,
      () => {
        this.loadCenter();
      }
    );

  }

  componentWillUnmount() {
    this._leftReactionOnLocationDispose();
    this._leftReactionOnCourseDispose();

    this._centralReactionOnClassesDispose();
  }

  render() {
    return (
      <View style={styles.sectionContainer}>
        <View style={dashboardStyles.dashboardWrapper}>

          <View style={dashboardStyles.leftWrapper}>

            <View style={dashboardStyles.blockHeader}>
              <Text style={[styles.title1, styles.noMarginB, styles.textLight,]}>Contatti</Text>
            </View>

            <View style={dashboardStyles.blockInner}>

              <ScrollView style={actionStyles.scrollableView}>
                {
                  this.state.loadingLeft?
                    <View style={[styles.activityIndicatorContainer, ]}>
                      <ActivityIndicator size={'small'}/>
                    </View>
                    :
                    <DashboardContacts
                      contacts={this.state.contacts}
                      navigation={this.props.navigation}
                    />
                }
              </ScrollView>
            </View>
          </View>

          <View style={dashboardStyles.centralWrapper}>
            <View style={dashboardStyles.blockHeader}>
              <Text style={[styles.title1, styles.noMarginB, styles.textLight,]}>Iter - novit&agrave;</Text>
            </View>

            <View style={[dashboardStyles.blockInner, {overflow: 'hidden'} ]}>
                <EgafInfo />
            </View>
          </View>

          <View style={dashboardStyles.rightWrapper}>
            <View style={dashboardStyles.blockHeader}>
              <Text style={[styles.title1, styles.noMarginB, styles.textLight,]}>Allievi</Text>
            </View>

            <View style={dashboardStyles.blockInner}>
              <ScrollView style={actionStyles.scrollableView}>
                {
                  this.state.loadingCenter?
                    <View style={[styles.activityIndicatorContainer, ]}>
                      <ActivityIndicator size={'small'}/>
                    </View>
                    :
                    <DashboardTopStudents
                      topStudents={this.state.stats}
                    />
                }
              </ScrollView>
            </View>
          </View>

        </View>
      </View>
    )
  }

}

// Left Area

const DashboardContacts = (props) => {
  const {contacts} = props;

  return (
    <View>
      <View key="dashboard_potential" style={dashboardStyles.contactsWrapper}>
        <View style={dashboardStyles.contactTitleWrapper}>
          <Text style={dashboardStyles.contactTitle}>Contatti Potenziali</Text>
          <TouchableOpacity
            onPress={()=>{props.navigation.navigate('Prospects', {filter: 'POTENZIALE'})}}
          >
            <Text style={dashboardStyles.contactTitleLink}>vedi tutti</Text>
          </TouchableOpacity>
        </View>
        {
          contacts.potential.map((contact, index)=><ContactItem key={'potential_'+index} contact={contact}/>)
        }
      </View>
      <View key="dashboard_subscribed" style={dashboardStyles.contactsWrapper}>
        <View style={dashboardStyles.contactTitleWrapper}>
          <Text style={dashboardStyles.contactTitle}>Contatti Iscritti</Text>
          <TouchableOpacity
            onPress={()=>props.navigation.navigate('Prospects', {filter: 'ISCRITTO'})}
          >
            <Text style={dashboardStyles.contactTitleLink}>vedi tutti</Text>
          </TouchableOpacity>
        </View>
        {
          contacts.subscribed.map((contact, index)=><ContactItem key={'subscribed_'+index} contact={contact}/>)
        }
      </View>
    </View>
  );
}


const ContactItem = (props) => {
  const {contact} = props;
  return (
    <View style={dashboardStyles.contactItem}>
      <View style={dashboardStyles.contactItemLeft}>
        <Text style={dashboardStyles.contactItemText}>{contact.Cognome} {contact.Nome}</Text>
      </View>
      <View style={dashboardStyles.contactItemRight}>
        <Text style={dashboardStyles.contactItemText}>{getFormattedDate(contact.DataIscrizione)}</Text>
      </View>
    </View>
  )
}


// Central Area

const DashboardTopStudents = (props) => {
  const {topStudents} = props;
  const isSingleClass = topStudents.length === 1;

  return (
    <View style={dashboardStyles.statsWrapper}>
      {
        topStudents.map((topData, index) => {
          return (
            <View key={'top_block_'+index}>
              <View style={dashboardStyles.statsBlock}>
                <Text style={[dashboardStyles.contactTitle, {marginBottom: 20}]}>Migliori allievi della classe: {topData.class.classe}</Text>
              </View>
              <StatTopStudentes isSingleClass={isSingleClass} stats={topData.stats} />
            </View>


          )
        })
      }
    </View>
  )

}


const StatTopStudentes = (props) => {
  const {stats, isSingleClass} = props;

  const counter = isSingleClass? 10 : 3;

  const _renderClassItemGraph = (stat) => {

    const wrongPercentage = stat.TOTALE ? stat.SBAGLIATE / stat.TOTALE : 0;
    return <QuizAnswersBarChart
      style={{paddingLeft: 10}}
      completed={wrongPercentage ? 1 : 0}
      wrong={wrongPercentage}
      height={15}
    />

  }

  const _renderClassItemGraphValues = (stat) => {
    const wrongPercentage = stat.TOTALE ? stat.SBAGLIATE / stat.TOTALE*100 : 0;
    const correctPercentage = stat.TOTALE? stat.GIUSTE/stat.TOTALE*100 : 0;
    const errors = wrongPercentage.toFixed();
    const corrects = correctPercentage.toFixed();

    return (
      <View style={{paddingLeft: 7, paddingRight: 7, minWidth: 80}}>
        <Text style={[{color: Colors.textLight}, statsStyles.smallGraphText]}>Punti: {stat.PUNTI}</Text>
        <View style={[styles.rowContainer, {marginTop: 7}]}>
          <Text style={[classStyles.errorColor, statsStyles.smallGraphText, {marginRight: 15}]}>{errors}%</Text>
          <Text style={[classStyles.completedColor, statsStyles.smallGraphText]}>{corrects}%</Text>
        </View>
      </View>
    )

  }

  if (stats.length) {
    return (
      <View style={dashboardStyles.statW}>
        {
          stats.map((item, index) => {
            if (index < counter) {
              return (
                <View
                  key={'stat_student_'+index}
                  style={statsStyles.questionItemW}
                >
                    <View style={statsStyles.nameBlock}>
                      <Text style={statsStyles.questionItemText} numberOfLines={2}>{item.Nome} {item.Cognome}</Text>

                      <View style={statsStyles.questionGraphBlock}>
                        {_renderClassItemGraph(item)}
                      </View>
                    </View>

                    <View style={statsStyles.questionValuesBlock}>
                      {_renderClassItemGraphValues(item)}
                    </View>
                </View>
              )
            } else {
              return null;
            }
          })
        }
      </View>
    )
  } else {
    return (
      <View style={dashboardStyles.statW}>
        <Text style={dashboardStyles.contactItemText}>Non ci sono dati disponibili al momento</Text>
      </View>
    )
  }
}


const windowHeight = Dimensions.get('window').height - 285;


class EgafInfo extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.loginURL = 'https://lcps.egaf.it?forceManualLogin=true';
    this.appLogin = 'https://lcps.egaf.it/appLogin.php?appLoginURL=';
    this.login_string = '';

    // credentials
    this.username = loginUsername;
    this.password = loginPwd;

    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    // Add listener
    window.addEventListener('message', (e)=>this.receiveMessage(e), false);
    // Get iFrame
    this.fLogin = document.getElementById('fLogin');
    this.fLoginCW = (this.fLogin.contentWindow || this.fLogin.contentDocument);
    this.open_loginURL() ;

    // This reaction will be triggered also when a Location or Cloud will change
    this._courseReactionDispose = reaction(
      () => this.context.currentCourse,
      () => {
        console.log('REACTION: CURRENTCOURSE has changed, reloading EGAFINFO');
        // Open base url directly
        // this.open_baseURL();
        // Check login, if everything is fine,
        this.loginCheck();
      }
    );
  }

  receiveMessage(event) {
    if (event.data === "login : Nome utente o password errate" || event.data === "login : Login or password incorrect") {
      // console.log('Nome utente o password errate');
      alert('Nome utente o password errate');
    } else {
      try {
        var mess = JSON.parse(decodeURI(event.data));

        if (mess.type === 'SessioneScaduta') {
          // console.log('login.receiveMessage - SessioneScaduta');
          alert('Sessione scaduta chiudere e riaprire il borowser!');
        } else if (mess.type === 'domIsReady') {
          // console.log('login.receiveMessage - domIsReady');
          this.loginCheck();
        } else if (mess.type === 'formLoginError') {
          // console.log('login.receiveMessage - formLoginError');
        } else if (mess.type === 'loginCheck') {
          // console.log('login.receiveMessage - loginCheck');
          this.loginCheck();
        } else if (mess.type === 'loginCheck_OK') {
          // console.log('login.receiveMessage - loginCheck_OK');
          this.open_baseURL();
        } else if (mess.type === 'loginCheck_KO') {
          // console.log('login.receiveMessage - loginCheck_KO');
          this.sendUP();
        } else {
          // console.log('login.receiveMessage - caso non gestito');
        }
      } catch (e) {
        console.log('login.receiveMessage - errore non gestito');
      }
    }
  }

  open_baseURL(){
    debugger;
    let baseUrl = 'https://www.egaf.it/egfss/docenti?periodico=abbonato,tol&forceManualLogin=true';
    const bancadati = this.context.currentCourse.bancadati;
    console.info('BANCADATI:', bancadati);
    if (bancadati) {
      baseUrl = `https://www.egaf.it/egfss/docenti?periodico=abbonato,${bancadati}&forceManualLogin=true`;
    }
    this.fLoginCW.location.href = baseUrl;
  }

  open_loginURL() {
    this.fLogin.src = this.appLogin + this.loginURL + "&" + Math.random();
    console.info('IFRAME, ADDED SRC: ', this.fLogin.src);
  }

  loginCheck() {
    this.fLoginCW.postMessage('{ "type":"loginCheck","pag":"' + this.loginURL + '"}', '*');
  }

  sendUP() {

    console.info('SENDUP!');
    // login.init();

    if (this.username === '') {
      alert('campo username vuoto!');
      return false;
    }
    if (this.password === '') {
      alert('campo password vuoto!');
      return false;
    }
    var count = this.fLogin.dataCount
    if (!count) count = 0;
    count++;

    this.fLogin.dataCount = count;
    console.log(count);

    if (count > 10) {
      var p = this.fLogin.parentElement;
      this.fLogin.remove();
      var mancaNews = document.createElement("div");
      mancaNews.classList.add("css-text-76zvg2","r-color-jwli3a","r-fontSize-adyw6z","r-marginBottom-117bsoe");
      mancaNews.innerText = 'Novità al momento non disponibili';
      p.append(mancaNews);
    } else {
      this.fLoginCW.postMessage('{ "type":"login", "username":"' + this.login_string + this.username + '", "password": "' + this.password + '"}', '*');
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <View style={[styles.activityIndicatorContainer, ]}>
          <ActivityIndicator size={'small'}/>
        </View>
      );
    } else {
      return (
        <View>
          <iframe
            style={{width: '100%', height: windowHeight}}
            frameBorder="0"
            title="Egaf latest news"
            src=""
            id="fLogin">
          </iframe>
        </View>
      );
    }
  }

}
