import {Dimensions, Platform} from 'react-native';
import Moment from 'moment';


const BREAKPOINT_WIDHT = 640; // Based on Foundation framework

export const SMALL = 'small';
export const LARGE = 'large';


export const isWeb = () => {
  return Platform.OS === 'web';
};


export let getSize = function(obj){

  if (Platform.OS === 'windows') return obj[LARGE];

  const { width } = Dimensions.get('window');
  if (width < BREAKPOINT_WIDHT){
    return obj[SMALL];
  } else {
    return obj[LARGE];
  }
}


export let isSmall = function(){
  if (Platform.OS === 'windows') return false;
  const { width } = Dimensions.get('window');
  return width < BREAKPOINT_WIDHT;
}


export let inspectSize = function(size='w'){
  const { width, height } = Dimensions.get('window');
  if (size === 'w'){
    return width;
  } else {
    return height;
  }
}


export const getFormattedDate = (date, format=null) => {
  const formatToUse = format ? format : 'DD/MM/YYYY';
  return Moment(date).format(formatToUse);

}


export const getCookie = function(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
