import EStyleSheet from 'react-native-extended-stylesheet';
import {Colors, Defaults} from "../foundation/styles";

const styles = EStyleSheet.create({
  classListW: {
    marginTop: 0,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  classItemW: {
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    marginBottom: 10,
    backgroundColor: '#2d2d2d',
  },
  classItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'

  },
  mainBlock: {
    // flexGrow: 1
    flex: 1
  },

  classItemText: {
    fontSize: 20,
    color: Colors.textLight,
    marginBottom: 25
  },

  graphBlock: {
    flex: 1,
    // flexGrow: 1,
    paddingRight: 20,
  },

  graphValuesBlock: {
    flexShrink: 1,
    paddingRight: 20,
  },

  indicatorBlock: {
    flexShrink: 1,
    borderLeftWidth: 1,
    borderLeftColor: '#454545',
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingRight: 5,
    justifyContent: 'center',
    alignItems: 'center'
  },

  classListItemSelected:{
    backgroundColor: '#1e1e1e',
  },

  smallGraphText: {
    fontSize: 12,

  },

  statScrollview: {

  },

  actionMasterInnerHeader: {
    flexDirection: 'row',
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 12,
    paddingLeft: 0,
    // paddingTop: 0,
    // paddingBottom: Defaults.padder,
    borderBottomColor: Colors.border,
    borderBottomWidth: 2,
    marginBottom: Defaults.spacing,
    zIndex: 10000,
  },
  avg: {
    fontSize: 16,
    color: Colors.textLight,
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  noStatMessage: {
    textAlign: 'center',
    fontSize: 16,
    color: Colors.textLight
  },
  legendW: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 15
  },
  legendItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 20,
    marginBottom: 15
  },
  legendColor: {
    width: 32,
    height: 16,
    borderColor: '#666666',
    borderWidth: 1,
    borderStyle: 'solid'
  },
  legendText: {
    // fontSize: 16,
    marginLeft: 5,
    color: Colors.textLight
  },
  // Tabs
  tabsContainerStyle: {
    flexWrap: 'wrap',
    marginRight: -10
  },
  tabStyle: {
    backgroundColor: '#292929',
    borderWidth: 0,
    borderColor: 'transparent',
    marginRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  'tabStyle:last-child': {
    marginRight: 0,
  },
  activeTabStyle: {
    backgroundColor: Colors.primaryColor,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  tabTextStyle: {
    color: '#ffffff',
    fontSize: 12,
    fontWeight: '300',
    marginLeft: 10,
    marginRight: 10,
  },
  activeTabTextStyle: {
    color: '#ffffff',
    marginLeft: 10,
    marginRight: 10,
  },

  // Stats views
  statContainer: {
    flex: 1,
    paddingTop: 20,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
  },
  statPanelHolder: {
    // overflowY: 'auto',
    // overflowX: 'hidden',
  },

  // Questions Stats
  questionItemW: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#373737',
    alignItems: 'center',
    paddingBottom: 15,
    marginBottom: 15
  },
  nameBlock: {
    flex: 1,
  },
  questionItemText: {
    fontSize: 16,
    color: Colors.textLight,
    marginBottom: 6,
  },
  questionGraphBlock: {
    // flexBasis: '50%'
    // flexGrow: 1,
    flex: 1,
  },
  questionValuesBlock: {
    flexShrink: 1,
    paddingLeft: 20,
    alignItems: 'flex-end'
  },
  periodSelector: {
    padding: 8,
    minWidth: 180,
    borderRadius: 30,
    fontSize: 12,
    // lineHeight: 38,
    color: '#444444'

  },
  periodSelectorText: {
    fontSize: 10,
  },
  periodSelectorDisabled: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    minWidth: 220,
    borderRadius: 30,
    fontSize: 13,
    backgroundColor: '#383838',
    color: '#6b6b6b',
  },
  // Top 10
  top10ItemW: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#4a4a4a',
    alignItems: 'center',
    marginTop: 0
  },
  t10NameBlock: {
    flexBasis: '25%',
    borderRightWidth: 1,
    borderRightColor: '#4a4a4a',
    paddingTop: 10,
    paddingBottom: 10,
  },
  t10StatusBlock: {
    // flexGrow: 1,
    flex: 1,
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },
  top10Heading: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#4a4a4a',
    // marginBottom: 15
  },
  top10HeadingText: {
    fontSize: 18,
    fontWeight: '500',
    color: Colors.textLight,
    paddingTop: 5,
    paddingBottom: 5,
  },
  textLeftPadder: {
    paddingLeft: 15
  },
  // Topics
  correctLegend: {
    marginTop: 7,
    marginRight: 6,
    width: 20,
    height: 7,
    backgroundColor: Colors.correct,
    marginBottom: 15
  },
  wrongLegend: {
    marginTop: 7,
    marginRight: 6,
    width: 20,
    height: 7,
    backgroundColor: Colors.wrong
  },

  overallMixLegend: {
    marginTop: 7,
    marginRight: 6,
    width: 20,
    height: 7,
    backgroundColor: '#4176d9',
    position: 'relative',
  },
  overallMixLegendDark: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 10,
    height: 7,
    backgroundColor: '#0054a6',
  },
  overallPeriodLegend: {
    marginTop: 7,
    marginRight: 6,
    width: 20,
    height: 7,
    backgroundColor: '#969b95',
  },

  // Accordion
  accordion: {
    backgroundColor: '#292929',
    padding: 10,
    marginBottom: 10
  },
  accHeading: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accTitle: {
    color: Colors.textLight,
    fontSize: 22,

  },
  openBtn: {
    flexShrink: 1,
    backgroundColor: Colors.btnHeaderBg,
    marginRight: 15,
    paddingTop: 4,
    paddingRight: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    borderRadius: 30,
  },
  accTopicsW: {
    marginTop: 0
  },
  accTopic: {
    paddingBottom: 15,
    marginBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#2f2f2f',
    // backgroundColor: '#ff00ff' // this is topic row
  },
  accTopicTopRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  accTopicBottomRow: {
    flex: 1,
  },
  accTopicName: {
    color: Colors.textLight,
    fontSize: 16,
    marginBottom: 3
  },
  overallWrapper: {
    flex: 1,
    marginTop: 5,
    marginBottom: 7,
  },
  overallText: {
    color: Colors.textLight,
    fontSize: 12,
    fontWeight: '300',
    marginBottom: 3
  },
  overallGraphWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  overallPercentText: {
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 7,
    color: '#4176d9'
  },
  accOrderingHeader: {
    // backgroundColor: '#292929',
    // backgroundColor: '#ff00ff',
    paddingBottom: 8,
    marginBottom: 13,
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
  },
  accOrderingCriteria: {
    flexDirection: 'row',
    marginLeft: 10,
    marginRight: 10,
  },
  accOrderingCriteriaText: {
    color: Colors.textLight,
    marginRight: 7,
  },
  accTooltipW: {
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: 12,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zIndex: 10000
  },
  accTooltipBtn: {
    backgroundColor: '#666666',
    width: 20,
    height: 20,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',


  },
  accTooltipTxt: {
    color: '#ffffff'
  },

  // Wrong questions
  wrongItemW: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 0,
    paddingRight: 22,
    paddingBottom: 20,
    marginBottom: 20,
    borderBottomColor: '#404040',
    borderBottomWidth: 1
  },
  wrongItemBG:{
    backgroundColor: '#c33434',
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15
  },
  wrongItemImage: {
    paddingRight: 20,
  },
  answerImage: {
    marginTop: 5,
    marginLeft: 30
  },
  wrongItemTextW: {
    flex: 1,
  },
  wrongItemText: {
    fontSize: 17,
    color: Colors.textLight
  },
  wrongItemResp: {

  },
  wrongItemRespText: {
    fontSize: 24,
    paddingLeft: 20
  },
  wrongItemMultiW: {
    marginTop: 10,
    marginLeft: 10,
  },
  wrongItemMultiAnswerOuterWrapper: {
    flexDirection: 'column',
    marginBottom: 4
  },
  wrongItemMultiAnswerW: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 12
  },
  multiLetter: {
    color: '#b9b9b9',
    fontSize: 20,
    marginRight: 15,
    flexShrink: 1
  },
  multiText: {
    flex: 1,
    marginRight: 15
  },
  multiAnswerText: {
    fontSize: 15,
    color: '#b9b9b9',
  },
  // image Shadowbox
  imgShadowWrapper: {
    flex: 1,
    backgroundColor: '#2d2d2d',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 30,
    borderBottomWidth: 10,
    borderBottomColor: Colors.border
  },
  imgShadowCloser: {
    flexShrink: 1,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end'
  },
  imgShadowCloserBtn: {
    marginRight: 200,
  },
  imgHolder: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgShadow: {
    width: '80%',
    height: '75%'
  }

});

export default styles;
