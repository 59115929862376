import React from "react";
import {View, Text, ActivityIndicator, FlatList, Image} from 'react-native';
import statsStyles from '../styles';
import UserContext from "../../user";
import axios, {CancelToken} from "../../commons/axios";
import {reaction} from "mobx";
import errorManager from "../../commons/error_manager";
import TouchableOpacity from "react-native-web/dist/exports/TouchableOpacity";


export const WrongCorrectimage = (props) => {
  if (props.result === 'F') {
    return (
      <Image style={{width: 35, height: 35}} source={require('../../commons/images/falso.png')} />
    )
  } else {
    return (
      <Image style={{width: 35, height: 35}} source={require('../../commons/images/vero.png')} />
    )
  }
}

export const WrongQuestion = (props) => {
  const question = props.question;

  if (question.risposte.length === 1) {
    const isWrong = question.risposte.length? !!question.risposte[0]['risposta_sbagliata'] : '';
    return(
      <View
        style={statsStyles.wrongItemW}
      >
        <TouchableOpacity
          onPress={()=>props.showImage(question.fig)}
        >
          <View style={statsStyles.wrongItemImage}>
            <Image data-iefix={'ieimg'} resizeMode={'contain'} style={{width: 60, height: 60}} source={{uri: question.fig}}/>
          </View>
        </TouchableOpacity>

        <View style={isWrong? [statsStyles.wrongItemTextW, statsStyles.wrongItemBG] : statsStyles.wrongItemTextW }>
          <Text style={statsStyles.wrongItemText}>{question.testo}</Text>
        </View>
        <View style={statsStyles.wrongItemResp}>
          <WrongCorrectimage result={question.risposte[0].RI_VF} />
        </View>
      </View>
    );
  } else {
    let isWrong = false;
    try {
      isWrong = question['risposta_sbagliata'];
    } catch (e) {}
    return(
      <View
        style={statsStyles.wrongItemW}
      >
        <TouchableOpacity
          onPress={()=>props.showImage(question.fig)}
        >
          <View style={statsStyles.wrongItemImage}>
            <Image data-iefix={'ieimg'} resizeMode={'contain'} style={{width: 60, height: 60}} source={{uri: question.fig}}/>
          </View>
        </TouchableOpacity>

        <View style={statsStyles.wrongItemTextW}>
          <Text style={isWrong ? [statsStyles.wrongItemText, statsStyles.wrongItemBG] : statsStyles.wrongItemText}>{question.testo}</Text>

          <View style={statsStyles.wrongItemMultiW}>
            {
              question.risposte.map((answer, index) => {

                return(
                  <View style={statsStyles.wrongItemMultiAnswerOuterWrapper} key={index}>
                    <View style={statsStyles.wrongItemMultiAnswerW}>
                        <Text style={statsStyles.multiLetter}>{answer.indiceAlfab}</Text>
                        <View style={statsStyles.multiText }>
                          <Text style={statsStyles.multiAnswerText}>{answer.RI_TESTO}</Text>
                        </View>
                      <WrongCorrectimage result={answer.RI_VF} />
                    </View>
                    {
                      answer.hasOwnProperty('ri_fig') && answer.ri_fig ?
                        <TouchableOpacity
                          onPress={()=>props.showImage(answer.ri_fig)}
                        >
                          <View style={statsStyles.answerImage}>
                            <Image data-iefix={'ieimg'} resizeMode={'contain'} style={{width: 60, height: 60}} source={{uri: answer.ri_fig}}/>
                          </View>
                        </TouchableOpacity>
                        :
                        null
                    }
                  </View>
                )
              })
            }
          </View>
        </View>
      </View>
    );
  }

}

export default class WrongQuestions extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Cancel Token for topics request
    this.source = CancelToken.source();

    this.state = {
      loading: true,
      statsData: []
    }
  }

  async loadStats() {
    try {
      // https://autoscuole.egaf.it/statistics/?stat=statTopDomandeSbagliate_ClasseOpera&idClasse=8&opera=3&giorni=180
      const statsResp = await axios.get("statistics/", {
        cancelToken: this.source.token,
        params: {
          stat: 'statTopDomandeSbagliate_ClasseOpera',
          idClasse: this.props.currentClass.idclasse,
          opera: this.context.currentCourse.opera,
          giorni: this.context.statsPeriod
        }
      });

      // console.info('statsResp for tab WRONGQUESTIONS', statsResp.data);

      this.setState({
        loading: false,
        statsData: statsResp.data
      });
    } catch (e) {
      errorManager(e);
    }
  }

  componentDidMount() {
    this.loadStats();

    // Reaction to location changing
    this._statPeriodReactionDispose = reaction(
      () => this.context.statsPeriod,
      () => {
        this.setState({
          loading: true,
        });
        this.loadStats();
      }
    );
  }

  componentWillUnmount() {
    this._statPeriodReactionDispose();
    this.source.cancel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentClass !== this.props.currentClass) {
      this.setState({
        loading: true,
      });
      this.loadStats();
    }
  }

  showImage(path) {
    this.props.showImage(path);
  }

  render() {

    if (this.state.loading) {
      return <View style={statsStyles.statContainer}>
        <ActivityIndicator/>
      </View>;
    } else {

      if (this.state.statsData.length) {
        return (
          <View style={[statsStyles.statContainer, {paddingRight: 10}]}>
            <FlatList
              key={'stat__wrong_questions'}
              data={this.state.statsData}
              renderItem={ ({item, index}) => this._renderStatItem(item, index)}
              keyExtractor={(item, index) => index.toString()}
            />
          </View>
        );
      } else {
        return (
          <View style={statsStyles.statContainer}>
            <Text style={statsStyles.noStatMessage}>Le statistiche per questa classe non sono ancora disponibili</Text>
          </View>
        );
      }

    }
  }

  _renderStatItem(item, index) {
    return <WrongQuestion question={item} showImage={(path) => this.showImage(path)}/>;
  }

}
