import { AppRegistry } from "react-native";
import App from "./App";
import EStyleSheet from "react-native-extended-stylesheet";
EStyleSheet.build();


AppRegistry.registerComponent("App", () => App);

AppRegistry.runApplication("App", {
  rootTag: document.getElementById("root")
});
