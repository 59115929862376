import React from 'react';
import { View, Text, FlatList, ActivityIndicator, TouchableOpacity, Image } from 'react-native';
import {actionStyles, Colors, styles} from "../foundation/styles";
import UserContext from "../user";
import classStyles from "../section_classes/styles";
import statsStyles from "./styles";
import {Observer} from 'mobx-react';
import {isWeb} from "../commons/utils";
import {ActionBlock, EgafSectionList, QuizAnswersBarChart, EgafStatPeriodSelect} from "../foundation/widgets";
import axios from "../commons/axios";
import SegmentedControlTab from "react-native-segmented-control-tab";
import QuestionsStat from './statistics/questions';
import TopTen from './statistics/top_ten';
import Topics from './statistics/topics';
import Trend from './statistics/trend';
import Results from './statistics/results';
import WrongQuestions from './statistics/wrong_questions';
import {reaction} from "mobx";


export const StatIndicator = (props) => {

  const sizes = {
    normal: {
      width: 60,
      height: 45,
    },
    compact: {
      width: 40,
      height: 30,
    },
  };

  const width = props.compact? sizes.compact.width : sizes.normal.width;
  const height = props.compact? sizes.compact.height : sizes.normal.height;
  const threshold = 2;

  const stat7gg = props.stats[0];
  const stat30gg = props.stats[1];
  const correct7Percentage = stat7gg.TOTALE? stat7gg.GIUSTE/stat7gg.TOTALE*100 : 0;
  const correct30Percentage = stat30gg.TOTALE? stat30gg.GIUSTE/stat30gg.TOTALE*100 : 0;

  const correctDifference = Math.abs(correct30Percentage - correct7Percentage);
  const underThreshold = correctDifference < threshold;

  if (correct7Percentage === correct30Percentage) {
    return (
      <Image size={20} style={{width: width, height: height}} source={require('../commons/images/stat-indicator-flat.png')}/>
    )
  }

  // Up arrow
  let tintColor = underThreshold? Colors.middleCorrect : Colors.correct;
  let indicator = <Image size={20} style={{tintColor: tintColor, width: width, height: height}} source={require('../commons/images/stat-indicator-up.png')}/>;

  if (correct7Percentage < correct30Percentage){
    // Down arrow
    tintColor = underThreshold? Colors.middleWrong : Colors.wrong;
    indicator = <Image size={20} style={{tintColor: tintColor, width: width, height: height}} source={require('../commons/images/stat-indicator-down.png')}/>;
  }

  return indicator;
};


class StatisticsHome extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedClass: null,
      action: null,
      isActionEnabled: false,
      classList: [],
      classStats: []
    }
  }

  componentDidMount() {
    this.loadStatistics();
    const classList = Array.from(this.context.classes);
    this.setState({
      loading: false,
      classList,
    });

    this._classesChangedReactionDispose = reaction(
      () => this.context.classes,
      () => {
        const classList = Array.from(this.context.classes);
        this.setState({
          isActionEnabled: false,
          classList,
          classStats: []
        });
        this.loadStatistics();
      }
    );
  }

  componentWillUnmount() {
    this._classesChangedReactionDispose();
  }

  alertMessage(message = null) {
    const msg = message ? message : 'Non è stato possibile caricare le statistiche per classe';
    alert(msg);
  }

  async loadStatistics() {
    const classList = this.context.classes;
    const classStats = [];
    try {

      for (const classItem of classList) {
        const classStatsResp = await axios.get("statistics/", {
          params: {
            stat: 'statAndamento_ClasseOpera',
            idClasse: classItem.idclasse,
            opera: this.context.currentCourse.opera
          }
        });
        classStats.push(classStatsResp.data)
      }


      this.setState({
        classList: Array.from(classList),
        classStats: classStats
      })

    } catch(e) {
      if (e && e.response.status !== 403){
        this.alertMessage();
      }
    }
  }

  // ACTION BLOCK METHODS
  // action param should be an obj like {name: 'userdetail', params: {id: 13}}
  openActionBox(action=null) {
    this.setState({
      action: action,
      isActionEnabled: true
    });
  }

  _prepareForAction() {
    let component = null;
    if (this.state.action){
      switch (this.state.action.name) {
        case 'classstatdetail':
          component = <ClassStatsDetail
            classItem={this.state.action.params.classItem}
            hasConfirmed={this.state.hasConfirmed}
            hasRejected={this.state.hasRejected}
            goBack={() => this.actionCompleted()}
            retry={() => this.actionRetry()}
            showImage={(path) => this.showImage(path)}
          />;
          break;
        default:
          break;
      }
      // Change detail component
    }
    this.activeComponent = component;
    return component;
  }

  onConfirm() {
    // Toggle Action Box
    this.setState({
      hasConfirmed: true
    })
  }

  onClose() {
    // this.context.refreshClasses();
    this.setState({
      action: null,
      isActionEnabled: false,
      hasConfirmed: false,
      hasRejected:false,
      selectedClass: null,
      classList: Array.from(this.state.classList)
      // classListShow: this.checkClassListVisibility(true),
      // actionPickerEnabled: false,
      // selectedUsers: [],
    });
  }

  onReject() {
    // console.log('onReject');
    // Toggle Action Box
    // this.openActionBox()
    this.setState({
      action: null,
      isActionEnabled: false,
      selectedClass: null,
      classList: Array.from(this.state.classList)
      // classListShow: this.checkClassListVisibility(true),
      // selectedUsers: [],
      // showingUserDetailId: null
    });

  }

  render() {
    if (this.state.loading){
      return(
        <View style={[styles.sectionContainer, styles.activityIndicatorContainer]}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    } else {

      if (this.state.classList.length) {

        return (
          <View style={styles.sectionContainer}>
            {isWeb ?
              <ActionBlock
                key="stat_classes"
                renderMasterHeader={() => this.renderMasterHeader()}
                renderMasterBody={() => this.renderMasterBody()}
                prepareForAction={() => this._prepareForAction()}
                onConfirm={() => this.onConfirm()}
                onReject={() => this.onReject()}
                isActionEnabled={this.state.isActionEnabled}
                cancelBtnLabel={'Annulla'}
                openingSize={'large'}
                // confirmBtnLabel={this.getActionBlockConfirmLabel()}
              />
              :
              <EgafSectionList
                headers={this.sectionHeaders}
                dataForListing={this.state.listItems}
                refID={'idclasse'}
                // onPressRow={(id)=>this.navigateToDetail(id)} in app go to detail passing id
              />
            }
          </View>
        );
      } else {
        return(
          <View style={styles.sectionContainer}>
            <View style={[styles.rowContainer, styles.alignCenter, {marginTop: 20, marginBottom: 20}]}>
              <Text style={[styles.title1, styles.textLight, styles.alignCenter, ]}>Non ci sono classi assegnate al tuo utente</Text>
            </View>
          </View>
        )
      }
    }
  }

  renderMasterHeader() {
    return(
      <View style={[actionStyles.actionMasterInnerHeader, {marginBottom: 20} ]}>
        <Text style={[styles.title1, styles.noMarginB, styles.textLight, {marginTop: 8, marginLeft: 10} ]}>Classi</Text>
      </View>
    )
  }

  renderMasterBody() {

    if (this.state.loading) {
      return <ActivityIndicator />
    } else {
      return this.renderClassList();
    }
  }

  _renderClassItemGraph(stats) {
    if (stats) {
      const stat30gg = stats[2];
      const wrongPercentage = stat30gg.TOTALE? stat30gg.SBAGLIATE/stat30gg.TOTALE : 0;
      return <QuizAnswersBarChart
        completed={wrongPercentage? 1 : 0}
        wrong={wrongPercentage}
        height={45}
      />
    } else {
      return <ActivityIndicator/>;
    }

  }

  _renderClassItemGraphValues(stats) {

    if (stats) {
      const stat30gg = stats[2];
      const correctPercentage = stat30gg.TOTALE? stat30gg.GIUSTE/stat30gg.TOTALE*100 : 0;
      const wrongPercentage = stat30gg.TOTALE? stat30gg.SBAGLIATE/stat30gg.TOTALE*100 : 0;
      return (
        <View style={[styles.rowContainer, ]}>
          <View style={classStyles.questionsBlock}>
              {/*<Text style={[styles.textLight, statsStyles.smallGraphText]}>Totale</Text>*/}
              <Text style={[styles.textLight, statsStyles.smallGraphText]}>Corrette</Text>
              <Text style={[styles.textLight, statsStyles.smallGraphText]}>Sbagliate</Text>
          </View>
          <View>
              {/*<Text style={[classStyles.totalColor, statsStyles.smallGraphText]}>{stat30gg.TOTALE}</Text>*/}
              <Text style={[classStyles.completedColor, statsStyles.smallGraphText]}>{correctPercentage.toFixed()}%</Text>
              <Text style={[classStyles.errorColor, statsStyles.smallGraphText]}>{wrongPercentage.toFixed()}%</Text>
          </View>
        </View>
      )
    } else {
      return null;
    }

  }

  _renderClassItem(item, index) {
    const classItem = item;
    let stats = null;
    try{
      stats = this.state.classStats[index];
    } catch (e) {

    }

    return <TouchableOpacity
      style={
        this.state.selectedClass && (this.state.selectedClass.idclasse === classItem.idclasse)?
          [statsStyles.classItemW, statsStyles.classListItemSelected]
          :
          statsStyles.classItemW
      }
      onPress={() => {
        this.goToClassDetail(classItem)
      }}
    >
      {
        this.state.isActionEnabled ?
          <Text style={[statsStyles.classItemText, {fontSize: 18, marginBottom: 15}]} numberOfLines={1}>{classItem.classe}</Text>
          :
          null
      }

      <View style={statsStyles.classItem}>


        <View style={statsStyles.mainBlock}>

          {
            this.state.isActionEnabled ?
              null
              :
              <Text style={statsStyles.classItemText} numberOfLines={2}>{classItem.classe}</Text>
          }

          <View style={[styles.rowContainer, {flex: 1}]}>
            {this.state.isActionEnabled ?
              null
              :
              <View style={statsStyles.graphBlock}>
                {this._renderClassItemGraph(stats)}
              </View>
            }
            <View style={statsStyles.graphValuesBlock}>
              {this._renderClassItemGraphValues(stats)}
            </View>
          </View>
        </View>

        {
          stats?
            <View style={statsStyles.indicatorBlock}>
              <StatIndicator stats={stats} compact={this.state.isActionEnabled} />
            </View>
            :
            null
        }


      </View>
    </TouchableOpacity>
  }

  renderClassList() {
    return(
      <FlatList
        style={statsStyles.classListW}
        key={'stat_classlisting'}
        data={this.state.classList}
        renderItem={ ({item, index}) => this._renderClassItem(item, index)}
        keyExtractor={(item, index) => index.toString()}
      />
    );
  }

  goToClassDetail(classItem) {
    const action = {
      name: 'classstatdetail',
      params: {
        classItem: classItem,
        confirmBtnLabel: 'Salva'
      }
    };
    this.setState({
      selectedClass: classItem,
      classList: Array.from(this.state.classList)
    });
    this.openActionBox(action);
  }

  showImage(path) {
    this.props.navigation.navigate('image', {path: path});
  }

};

export default StatisticsHome;


class ClassStatsDetail extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0
    }
  }

  // TABS
  handleTabChange (index) {
    this.setState({tabIndex: index });
  };

  showImage(path) {
    this.props.showImage(path);
  }

  renderSelectedTab() {

    let tabComponent = null;
    switch (this.state.tabIndex) {

      case 0: {
        tabComponent = <QuestionsStat currentClass={this.props.classItem} showImage={(path) => this.showImage(path)}/>;
        break;
      }

      case 1: {
        tabComponent = <TopTen currentClass={this.props.classItem} showImage={(path) => this.showImage(path)}/>;
        break;
      }

      case 2: {
        tabComponent = <Topics currentClass={this.props.classItem} showImage={(path) => this.showImage(path)}/>;
        break;
      }

      case 3: {
        tabComponent = <Trend currentClass={this.props.classItem} showImage={(path) => this.showImage(path)}/>;
        break;
      }

      case 4: {
        tabComponent = <Results currentClass={this.props.classItem} showImage={(path) => this.showImage(path)}/>;
        break;
      }

      case 5: {
        tabComponent = <WrongQuestions currentClass={this.props.classItem} showImage={(path) => this.showImage(path)}/>;
        break;
      }

      default:
        tabComponent = <QuestionsStat currentClass={this.props.classItem} showImage={(path) => this.showImage(path)}/>;
        break;
    }

    return tabComponent;

  }

  render() {

    return(
      <View style={styles.container}>

        <View style={[statsStyles.actionMasterInnerHeader, {justifyContent: 'space-between', alignItems: 'center', } ]}>
          <Text style={[styles.title1, styles.noMarginB, styles.textLight, {} ]}>
            {this.props.classItem? this.props.classItem.classe : null}
          </Text>
          <Observer>
            {
              () => {

                if (this.state.tabIndex === 3) {
                  return (
                    <Text style={statsStyles.periodSelectorDisabled}>---</Text>
                  )
                } else {

                  return (

                    <EgafStatPeriodSelect
                      selectedValue={this.context.statsPeriod}
                      style={statsStyles.periodSelector}
                      onValueChange={(period) => {
                        this.context.statsPeriod = period
                      }}
                      enabled={this.state.tabIndex !== 3 || this.state.tabIndex !== 4}
                    />

                  )
                }
              }
            }
          </Observer>

        </View>

        <View style={[styles.container, ]}>
          <View style={{}}>
            <SegmentedControlTab
                key={'stats-tabs-control'}
                values={['Lavoro svolto', 'Top 10', 'Argomenti', 'Andamento', 'Simulazioni d\'esame', 'Domande sbagliate' ]}
                selectedIndex={this.state.tabIndex}
                onTabPress={(index) => this.handleTabChange(index)}
                borderRadius={0}
                tabsContainerStyle={statsStyles.tabsContainerStyle}
                tabStyle={statsStyles.tabStyle}
                activeTabStyle={statsStyles.activeTabStyle}
                tabTextStyle={statsStyles.tabTextStyle}
                activeTabTextStyle={statsStyles.activeTabTextStyle}
              />
          </View>
          {this.renderSelectedTab()}
        </View>

      </View>
    );
  }
}


export const ImageShow = (props) => {
  const path = props.navigation.state.params.path;

  return (
    <View style={[statsStyles.imgShadowWrapper]}>
      <View style={statsStyles.imgShadowCloser}>
        <TouchableOpacity
          style={statsStyles.imgShadowCloserBtn}
          onPress={()=>props.navigation.goBack()}
        >
          <Image size={50} style={{width: 50, height: 50}} source={require('../commons/images/x.svg')}/>
        </TouchableOpacity>
      </View>
      <View style={statsStyles.imgHolder}>
        <Image data-iefix={'ieimg'} resizeMode={'contain'} style={statsStyles.imgShadow} source={{uri: path}}/>
      </View>
    </View>
  )
}
