import React from 'react';
import { View, Text, ScrollView, ActivityIndicator, Switch, TouchableOpacity } from 'react-native';
import {actionStyles, Colors, styles} from '../../foundation/styles';
import ConfigStyles from '../styles';
import {ActionBlock, EgafSectionList} from "../../foundation/widgets";
import {isWeb} from "../../commons/utils";
import TextInput from "react-native-web/dist/exports/TextInput";
import axios from '../../commons/axios';
import UserContext from "../../user";
import {reaction} from "mobx";
import prospectStyles from "../../section_prospects/styles";
import errorManager from "../../commons/error_manager";


// Classes
export default class ConfigurationOperators extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.activeComponent = null;

    this.sectionHeaders  = [
      {name: 'Nome', prop: 'utente'},
      {name: 'E mail', prop: 'E_MAIL'},
      {name: 'Insegnante', prop: 'INSEGNANTE'},
      // {name: 'Associabile', prop: 'ASSOCIABILE'},
      // {name: 'Predefinito', prop: 'PREDEFINITO'},
      {name: 'Operatore', prop: 'OPERATORE'},
      {name: 'Attivo', prop: 'ATTIVO'},
      // {name: 'Bloccato', prop: 'BLOCCATO'}
    ];

    this.excludedFieldsOnActionEnabled = ['ASSOCIABILE', ];

    this.unfilteredListItems = [];

    this.state = {

      loading: true,
      listItems: [],

      selectedUsers: [],
      showingUserDetailId: null,
      action: null,
      isActionEnabled: false,

      // State depending on outer button, to execute action on child component
      hasConfirmed: false,
      hasRejected: false,

      // Filters
      filters: {
        active: false,
        preset: false,
        text: ''
      }
    }
  }

  async loadOperators() {
    const operatorsResp = await axios.get("users/getutentilocation/", {
      params: {
        idutente: this.context.currentCloud.idutente,
        COD_INTERL: this.context.currentLocation.COD_INTERL,
        COD_SIGLAIND: this.context.currentLocation.COD_SIGLAIND
      }
    });

    this.unfilteredListItems = operatorsResp.data;
    this.setState({
      loading: false,
      listItems: this.unfilteredListItems,
    })

   }

  componentDidMount() {
    this.loadOperators();

    // Reaction to location changing
    this._locationReactionDispose = reaction(
      () => this.context.currentLocation,
      () => {
        this.onReject();
        this.loadOperators();
      }
    );
  }

  componentWillUnmount() {
    this._locationReactionDispose();
  }

  // FILTERS STUFF

  updateFilters(newValue, filterType) {
    let filters = Object.assign({}, this.state.filters);
    filters[filterType] = newValue;
    this.setState({
      filters
    });
    this._applyAllFilters()
  }

  // Main filtering funcitons
  _applyAllFilters(newValue, filterType) {

    let items = Array.from(this.unfilteredListItems);
    // Preparing filters
    let filters = Object.assign({}, this.state.filters);
    filters[filterType] = newValue;
    let activeFilter = filters.active;
    let presetFilter = filters.preset;
    let textFilter = filters.text.toLowerCase();

    // Filtering logic
    // Better using for of loop with continue statement for performance
    let filteredItems = [];
    for (const item of items) {

      let passedFiltering = true;

      // First Status Filters
      if (activeFilter && !parseInt(item.ATTIVO) ) {
        passedFiltering = false;
        continue;
      }

      if (presetFilter && !parseInt(item.PREDEFINITO) ) {
        passedFiltering = false;
        continue;
      }

      if (textFilter && !item.utente.toLowerCase().startsWith(textFilter)) {
        passedFiltering = false;
        continue;
      }

      if (passedFiltering){
        filteredItems.push(item);
      }
    }

    // Filtering logic
    this.setState({
      listItems: filteredItems,
      filters: filters

    });

  }

  _createTextFilter() {
    return (
      <TextInput
        style={prospectStyles.searchTextInput}
        placeholder={'Cerca per Nome/Cognome'}
        placeholderTextColor={'#797979'}
        onChangeText={(text) => this._applyAllFilters(text, 'text')}
        value={this.state.filters.text}
      />
    )
  }

  _createActiveFilter() {
    if (this.unfilteredListItems.find((operator)=>parseInt(operator.ATTIVO))) {
      return (
        <View
          key={'active-filter'}
          style={[styles.rowContainer, prospectStyles.topFilterSeparator]}
        >
          <Text style={[prospectStyles.topFilterLabel,]}>
            ATTIVI
          </Text>
          <Switch
            // style={{backgroundColor: '#ccefef'}}
            value={this.state.filters.active}
            onValueChange={(newValue) => {
              this._applyAllFilters(newValue, 'active')
            }}
            thumbColor={Colors.textLight}
            activeThumbColor={Colors.textLight}
            activeTrackColor={Colors.primaryColor}
            trackColor={Colors.border}
          />
        </View>
      );
    } else {
      return null;
    }
  }

  _createPresetFilter() {
    if (this.unfilteredListItems.find((operator)=>parseInt(operator.PREDEFINITO))) {
      return (
        <View
          key={'preset-filter'}
          style={[styles.rowContainer, ]}
        >
          <Text style={[prospectStyles.topFilterLabel,]}>
            PREDEFINITI
          </Text>
          <Switch
            // style={{backgroundColor: '#ccefef'}}
            value={this.state.filters.preset}
            onValueChange={(newValue) => {
              this._applyAllFilters(newValue, 'preset')
            }}
            thumbColor={Colors.textLight}
            activeThumbColor={Colors.textLight}
            activeTrackColor={Colors.primaryColor}
            trackColor={Colors.border}
          />
        </View>
      );
    } else {
      return null;
    }
  }

  renderTopFilters() {
    return(
      <View style={[ConfigStyles.topFiltersMainWrapper, ]}>
          {this._createTextFilter()}
          <View style={[styles.rowContainer, ConfigStyles.topFilter] }>
            <Text style={prospectStyles.topFilterMainLabel}>STATO:</Text>
            {this._createActiveFilter()}
            {this._createPresetFilter()}
          </View>

      </View>
    )
  }

  // ACTION BLOCK METHODS
  // action param should be an obj like {name: 'userdetail', params: {id: 13}}
  openActionBox(action=null) {
    this.setState({
      action: action,
      // isActionEnabled: !this.state.isActionEnabled
      isActionEnabled: true
    });
  }

  closeActionBox() {
    this.setState({
      action: null,
      isActionEnabled: false
    });
  }

  _prepareForAction() {
    let component = <Invitation
      hasConfirmed={this.state.hasConfirmed}
      hasRejected={this.state.hasRejected}
      goBack={() => this.actionCompleted()}
      retry={() => this.actionRetry()}
    />;
    if (this.state.action){
      switch (this.state.action.name) {
        case 'userdetail':
          component = <UserDetail
            // userId={this.state.action.params.id}
            operator={this.state.action.params.operator}
            hasConfirmed={this.state.hasConfirmed}
            hasRejected={this.state.hasRejected}
            goBack={() => this.actionCompleted()}
            refreshOperators={() => this.refreshOperators()}
          />;
          break;
        default:
          break;
      }
      // Change detail component

    }
    this.activeComponent = component;
    return component;
  }

  async refreshOperators() {
    console.log('refreshOperators()');
    await this.loadOperators();
    this.actionRetry();
    // Refreshing action
    if (this.state.action && this.state.action.name === 'userdetail') {
      let refreshedAction = Object.assign({}, this.state.action);
      refreshedAction.params.operator = this.unfilteredListItems.find((operator) => operator.idutente === this.state.action.params.operator.idutente);


      console.log('refresh action');
      this.openActionBox(refreshedAction);
    }
  }

  onConfirm() {
    // Toggle Action Box
    this.setState({
      hasConfirmed: true
    })
  }

  onReject() {
    this.setState({
      hasRejected: true,
      action: null,
      isActionEnabled: false,
      selectedUsers: [],
      showingUserDetailId: null
    })
  }

  actionRetry() {
    this.setState({
      hasConfirmed: false,
      hasRejected: false,
    })
  }

  actionCompleted() {
    this.loadOperators();
    this.setState({
      action: null,
      hasConfirmed: false,
      hasRejected: false,
      isActionEnabled: false,
      showingUserDetailId: null
    });
  }

  getActionBlockConfirmLabel() {
    let label = 'Invia';
    if (this.state.action) {
      label = this.state.action.params.confirmBtnLabel;
    }
    return label;
  }

  // Rendering Logic
  render() {
    return (
      <View style={styles.sectionContainer}>

        { isWeb ?
          <ActionBlock
            key="config_users"
            renderMasterHeader={()=>this.renderMasterHeader()}
            renderMasterBody={()=>this.renderMasterBody()}
            prepareForAction={()=>this._prepareForAction()}
            onConfirm={()=>this.onConfirm()}
            onReject={()=>this.onReject()}
            isActionEnabled={this.state.isActionEnabled}
            // Passing labels for bar buttons
            cancelBtnLabel={'Annulla'}
            confirmBtnLabel={this.getActionBlockConfirmLabel()}
          />
          :
          <EgafSectionList
            headers={this.sectionHeaders}
            dataForListing={this.state.listItems}
            refID={'idutente'}
            // onPressRow={(id)=>this.navigateToDetail(id)} in app go to detail passing id
          />
        }

      </View>
    );
  }

  renderMasterHeader() {
    if (!this.state.isActionEnabled) {
      return (
        <View style={[actionStyles.actionMasterInnerHeader, styles.alignRight, {paddingTop: 17, paddingBottom: 16, marginBottom: 15}]}>
          <TouchableOpacity
            style={styles.actionBtn}
            onPress={() => this.openActionBox()}
          >
            {/*<Image size={20} style={{width: 22, height: 22}} source={require('../../commons/images/plus.svg')}/>*/}
            <Text style={styles.actionBtnLabel}>Invita</Text>
          </TouchableOpacity>
        </View>
      )
    } else {
      return (
        <View style={[actionStyles.actionMasterInnerHeader, styles.alignRight, {height: 72, paddingTop: 20, paddingBottom: 20, marginBottom: 15}]} />
      )
    }
  }

  renderMasterBody() {
    if (this.state.loading) {
      return <ActivityIndicator />
    } else {
      return(
        <View style={styles.container}>
          {this.renderTopFilters()}
          <EgafSectionList
            key={"config_users_list"}
            headers={this.sectionHeaders}
            dataForListing={this.state.listItems}
            onPressRow={(operator)=>this.goToUserDetail(operator)}
            selectedRowItemId={this.state.showingUserDetailId}
            isActionEnabled={this.state.isActionEnabled}
            hideFields={this.excludedFieldsOnActionEnabled}
            showRoundIndicators={true}
            indicatorsProps={['INSEGNANTE', 'OPERATORE', 'ATTIVO', ]}
            refID={'idutente'}
          />
        </View>
      )
    }
  }


  // Specific Class methods
  // Web only method, specify action
  goToUserDetail(operator) {
    // console.log('goToUserDetail: '+operator.idutente);
    // if (!this.state.isActionEnabled){
      const action = {
        name: 'userdetail',
        params: {
          operator: operator,
          confirmBtnLabel: 'Salva'
        }
      };

      this.setState({
        showingUserDetailId: operator.idutente
      });

      this.openActionBox(action);
    // }
  }

}


export class Invitation extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      operatorDetails: {
        utente: '',
        E_MAIL: '',
      },
      statusInfo: '',
    }
  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.hasConfirmed && !prevProps.hasConfirmed){
      this.executeAction();
    }

    if (this.props.hasRejected && !prevProps.hasRejected){
      // console.log('componentDidUpdate.... rejectAction');
      this.rejectAction();
    }
  }

  alertMessage(message=null) {
    const msg = message? message : 'Non è stato possibile invitare l\' operatore, riprovare più tardi. Grazie';
    alert(msg);
  }

  async inviteOperator() {

    // console.info(JSON.stringify({
    //     idnuvola: this.context.currentCloud.idnuvola,
    //     descrnuvola: this.context.currentCloud.nuvola,
    //     utente: this.state.operatorDetails.utente,
    //     E_MAIL: this.state.operatorDetails.E_MAIL,
    //     linkinvitooperatore: this.context.currentLocation.LINK_INVITO_OPERATORE,
    //   }));
    try {
      const inviteOpertorResp = await axios.post("users/set?func=creaUtente", {
        idnuvola: this.context.currentCloud.idnuvola,
        descrnuvola: this.context.currentCloud.nuvola,
        utente: this.state.operatorDetails.utente,
        E_MAIL: this.state.operatorDetails.E_MAIL,
        LINK_INVITO_OPERATORE: this.context.currentLocation.LINK_INVITO_OPERATORE,
      });
      const resp = inviteOpertorResp.data;
      // console.info('inviteOpertorResp ', resp);
      if (resp.hasOwnProperty('idutente') && resp.idutente) {
        // console.log('Successfully invited operator with id ' + resp.idutente);

        this.setState({
          // statusInfo: '',
          loading: false,
        });
        // this.context.refreshOperators();
        this.alertMessage('Operatore invitato con successo.');

        setTimeout(() => {
          this.goBack();
        }, 1500);

      } else {

        this.setState({
          // statusInfo: 'Non è stato possibile invitare l\' operatore, riprovare più tardi. Grazie',
          loading: false,
        });

        this.alertMessage();
      }

    } catch (e) {
      errorManager(e);

    }
  }

  executeAction() {
    if (this.state.operatorDetails.utente && this.state.operatorDetails.E_MAIL){
      this.setState({
        loading: true,
      });
      this.inviteOperator()
      // this.goBack();
    } else {
      this.setState({
        statusInfo: 'Compilare tutti i campi per invitare un nuovo operatore, grazie.',
      });
      this.props.retry();
    }
  }

  rejectAction() {
    // console.log('rejectAction.... now close all');
    this.setState({
      operatorDetails: {
        utente: '',
        E_MAIL: '',
      },
      statusInfo: '',
    });
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  updateDetail(prop, value) {
    this.context.hasUnprocessedEdits = true;
    let operatorDetails = {...this.state.operatorDetails};
    operatorDetails[prop] = value;
    this.setState({
      operatorDetails,
      statusInfo: '',
    });
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.navigation.navigate.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.sendInvitation()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={actionStyles.actionDetailInnerHeader}>
          <Text style={actionStyles.actionDetailTitle}>Invita un operatore</Text>
        </View>

        <ScrollView style={actionStyles.scrollableView}>
          <Text style={styles.label}>Nome e Cognome:</Text>
          <TextInput
            style={styles.textInput}
            onChangeText={(name) => this.updateDetail('utente', name)}
            value={this.state.operatorDetails.utente}
          />

          <Text style={styles.label}>Email:</Text>
          <TextInput
            style={styles.textInput}
            onChangeText={(email) => this.updateDetail('E_MAIL', email)}
            value={this.state.operatorDetails.E_MAIL}
          />

          { this.state.statusInfo?
            <Text style={styles.label}>{this.state.statusInfo}</Text>
            :
            null
          }

          {this.renderBtnToolbar()}
        </ScrollView>
      </View>
    );
  }
}


export class UserDetail extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      operatorDetails: null,
      statusInfo: '',
      resendInvitation: {
        loading: false,
        sent: false,
        sentWithError: false
      },

      enablingOperatorToggle: {
        showButton: true,
        loading: false,
        sent: false,
        sentWithError: false,
        toggleAction: parseInt(this.props.operator.ASSOCIABILE)? 'enabling' : 'disabling'
      }
    }
  }

  componentDidMount() {
    console.info('passed operator ', this.props.operator);
    this.setState({
      loading: false,
      operatorDetails: this.props.operator
    });

  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('componentDidUpdate, prevProps.hasConfirmed:' + prevProps.hasConfirmed);
    if (this.props.hasConfirmed  && !prevProps.hasConfirmed){
      // console.log('componentDidUpdate.... executeAction');
      this.executeAction();
    }

    // console.log('componentDidUpdate, prevProps.hasRejected:' + prevProps.hasRejected);
    if (this.props.hasRejected && !prevProps.hasRejected){
      // console.log('componentDidUpdate.... rejectAction');
      this.rejectAction();
    }

    // Check if operator has changed (by pressing another row), in case update the operator infos
    if (this.props.operator !== prevProps.operator){
      this.setState({
        operatorDetails: this.props.operator,
        enablingOperatorToggle: {
          showButton: true,
          loading: false,
          sent: false,
          sentWithError: false,
          toggleAction: parseInt(this.props.operator.ASSOCIABILE)? 'enabling' : 'disabling'
        }
      })
    }
  }

  alertMessage(message=null) {
    const msg = message? message : 'L\' operatore non è stato aggiornato, riprovare più tardi. Grazie';
    alert(msg);
  }

  async updateOperator() {
    try{
      const updateOperator = await axios.post("users/set?func=aggiornaUtente", {
        idutente: this.state.operatorDetails.idutente,
        idnuvola: this.context.currentCloud.idnuvola,
        utente: this.state.operatorDetails.utente,
        E_MAIL: this.state.operatorDetails.E_MAIL,
        ATTIVO: this.state.operatorDetails.ATTIVO,
        INSEGNANTE: this.state.operatorDetails.INSEGNANTE,
        OPERATORE: this.state.operatorDetails.OPERATORE,
        BLOCCATO: this.state.operatorDetails.BLOCCATO,
      });
      const resp = updateOperator.data;
      if (resp.hasOwnProperty('aggiornaUtente') && resp.aggiornaUtente) {
        // console.log('Successfully invited operator with id ' + resp.aggiornaUtente);

        this.setState({
          // statusInfo: 'Operator updated!',
          loading: false,
        });
        // this.context.refreshOperators();
        this.alertMessage('Operatore modificato con successo.');

        setTimeout(() => {
          this.goBack();
        }, 1500);

      } else {

        this.setState({
          // statusInfo: '',
          loading: false,
        });

        this.alertMessage();

      }

    } catch (e) {
      // console.log('Cought error in updating user');
      // this.alertMessage();
      errorManager(e);
      // this.setState({
      //   statusInfo: 'Si è verificato un errore, riprovare più tardi grazie.',
      // });
      // setTimeout(() => {
      //   this.goBack();
      // }, 1500);
    }

  }

  executeAction() {
    // console.log('executeAction.... now close all');
    this.setState({
        loading: true,
    });

    this.updateOperator();

  }

  rejectAction() {
    // console.log('rejectAction.... now close all');
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  updateDetail(prop, value) {
    this.context.hasUnprocessedEdits = true;
    let operatorDetails = {...this.state.operatorDetails};
    operatorDetails[prop] = value;
    this.setState({
      operatorDetails,
      statusInfo: '',
    });
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.executeAction()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  async _resendInvitation() {
    debugger;
    this.setState({
      resendInvitation: {
        loading: true,
        sent: false
      }
    })

    // Resend invitation
    let sentWithError = false;
    try{
      const inviteOpertorResp = await axios.post("users/set?func=invitaUtente", {
        idutente: this.state.operatorDetails.idutente,
        descrnuvola: this.context.currentCloud.nuvola,
        utente: this.state.operatorDetails.utente,
        E_MAIL: this.state.operatorDetails.E_MAIL,
        LINK_INVITO_OPERATORE: this.context.currentLocation.LINK_INVITO_OPERATORE,
      });

      if (inviteOpertorResp.hasOwnProperty('status') && inviteOpertorResp.status === 200) {
        // Success
        sentWithError = false;
      } else {
        // Error
        sentWithError = true;
      }
    } catch (e) {
      sentWithError = true
    }


    this.setState({
      resendInvitation: {
        loading: false,
        sent: true,
        sentWithError: sentWithError
      }
    });

    // resetting button
    setTimeout(() => {
      this.setState({
        resendInvitation: {
          loading: false,
          sent: false,
          sentWithError: false
        }
      })
    }, 3000);
  }

  // rendering send Invitation Btn
  resendInvitation() {
    if (this.state.resendInvitation.loading){
      return(
        <View style={ConfigStyles.invitationBtnW}>
          <ActivityIndicator size={'small'}/>
        </View>
      )
    } else {
      if (this.state.resendInvitation.sent){
        return (
          <View style={ConfigStyles.invitationBtnW}>
           {
              this.state.resendInvitation.sentWithError?
              <Text style={ConfigStyles.invitationBtnText}>Si è verificato un errore durante l'invio.</Text>
              :
              <Text numberOfLines={2} style={ConfigStyles.invitationBtnText}>Invito inoltrato con successo</Text>
            }
          </View>
        )
      } else {

        return(
          <View style={ConfigStyles.invitationBtnW}>
            <TouchableOpacity
              style={ConfigStyles.invitationBtn}
              onPress={()=>this._resendInvitation()}
            >
              <Text style={ConfigStyles.invitationBtnText}>Reinoltra l'invito</Text>
            </TouchableOpacity>
          </View>
        )
      }
    }
  }

  // Class Disabling logic
  async _disableOperatorToggle() {
    this.setState({
      enablingOperatorToggle: {
        loading: true,
        showButton: true,
        sent: false
      }
    });

    let sentWithError = true;
    let toggleAction = this.state.enablingOperatorToggle.toggleAction;
    // let disabled = null;

    try{

      if (toggleAction === 'enabling') {
        // ENABLING
        // https://autoscuole.egaf.it/users/set?func=assegnaLocationAdOperatore
        const enablingOperatorToggleResp = await axios.post("users/set?func=assegnaLocationAdOperatore", {
          idutente: this.props.operator.idutente,
          COD_INTERL: this.context.currentLocation.COD_INTERL,
          COD_SIGLAIND: this.context.currentLocation.COD_SIGLAIND,
        });

        const resp = enablingOperatorToggleResp.data;
         // console.info('enabling Class Toggle Resp ', resp);
        if (resp.hasOwnProperty("assegnaLocationAdOperatore") && parseInt(resp.assegnaLocationAdOperatore)){
          sentWithError = false;
          toggleAction = 'disabling';
          // disabled = "0";
        }

      } else {
        // DISABLING
        // https://autoscuole.egaf.it/users/set?func=revocaLocationAdOperatore
        const disablingOperatorToggleResp = await axios.post("users/set?func=revocaLocationAdOperatore", {
          idutente: this.props.operator.idutente,
          COD_INTERL: this.context.currentLocation.COD_INTERL,
          COD_SIGLAIND: this.context.currentLocation.COD_SIGLAIND,
        });

        const resp = disablingOperatorToggleResp.data;
        // console.info('disabling Class Toggle Resp ', resp);
        if (resp.hasOwnProperty("revocaLocationAdOperatore") && parseInt(resp.revocaLocationAdOperatore)){
          sentWithError = false;
          toggleAction = 'enabling';
          // disabled = "1";
        }
      }

    } catch (e) {
      sentWithError = true
    }

    this.setState({
      enablingOperatorToggle: {
        showButton: true,
        loading: false,
        sent: true,
        sentWithError: sentWithError,
        toggleAction: toggleAction
      }
    });

    // Refreshing operators list (and therefore the detail as well
    this.props.refreshOperators();

  }

  // rendergin disable/enable btn
  disableOperatorToggle() {
    if (this.state.enablingOperatorToggle.loading){
      return(
        <View style={ConfigStyles.enableBtnW}>
          <ActivityIndicator size={'small'}/>
        </View>
      )
    } else {
      if (this.state.enablingOperatorToggle.sent){
        return (
          <View style={[ConfigStyles.enableBtnW, this.context.hasUnprocessedEdits? {position: 'relative', opacity: 0.5} : null]}>
            {
              this.context.hasUnprocessedEdits?
                <View style={styles.addRemoveWidgetOverlay}></View>
                :
                null
            }
            {
              this.state.enablingOperatorToggle.sentWithError?
              <Text style={ConfigStyles.invitationBtnText}>Si è verificato un errore, riprovare.</Text>
              :
              <Text numberOfLines={2} style={ConfigStyles.invitationBtnText}>
                Operatore {this.state.enablingOperatorToggle.toggleAction === 'disabling'? 'abilitato' : 'disabilitato'} con successo
              </Text>
            }
          </View>
        )
      } else {

        return(
          <View style={[ConfigStyles.enableBtnW, this.context.hasUnprocessedEdits? {position: 'relative', opacity: 0.5} : null]}>
            {
              this.context.hasUnprocessedEdits?
                <View style={styles.addRemoveWidgetOverlay}></View>
                :
                null
            }
            <TouchableOpacity
              style={[ConfigStyles.invitationBtn,
                this.state.enablingOperatorToggle.toggleAction === 'disabling'? ConfigStyles.disableBtn : ConfigStyles.enableBtn
              ]}
              onPress={()=>this._disableOperatorToggle()}
            >
              <Text style={ConfigStyles.invitationBtnText}>
                {this.state.enablingOperatorToggle.toggleAction === 'disabling'? 'Disabilita' : 'Abilita'} operatore
              </Text>
            </TouchableOpacity>
          </View>
        )
      }
    }
  }

  renderActionButtons() {
    return (
      <View style={[styles.gridRow, {justifyContent: 'space-between', flex:1, marginBottom: 15}]}>
        <View style={{alignItems: 'flex-start', justifyContent: 'center', flex:1, paddingRight: 10}}>
            {
              parseInt(this.state.operatorDetails.INVITABILE)?
                this.resendInvitation()
                :
                null
            }
        </View>

        <View style={{alignItems: 'flex-end', justifyContent: 'center', flex:1, paddingLeft: 10}}>
          {this.disableOperatorToggle()}
        </View>
      </View>
    )
  }

  render() {
    if (this.state.operatorDetails) {
      const user = this.state.operatorDetails;
      return (
        <View style={styles.container}>
          <View style={actionStyles.actionDetailInnerHeader}>
            <Text style={actionStyles.actionDetailTitle}>{user.utente}</Text>
          </View>

          <ScrollView style={actionStyles.scrollableView}>
            {this.renderActionButtons()}

            {
              parseInt(user.ATTIVO)?
                <View style={styles.rowContainer}>
                  <Text style={styles.label}>Nome: </Text>
                  <Text style={[styles.textInputDisabled,]}>{user.utente}</Text>
                </View>
                :
                <View>
                  <Text style={styles.label}>Nome:</Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={(name) => this.updateDetail('utente', name)}
                    value={user.utente}
                  />
                </View>
            }


            {
              parseInt(user.ATTIVO)?
                <View style={styles.rowContainer}>
                  <Text style={styles.label}>Email: </Text>
                  <Text style={[styles.textInputDisabled,]}>{user.E_MAIL}</Text>
                </View>
                :
                <View>
                  <Text style={styles.label}>Email:</Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={(email) => this.updateDetail('E_MAIL', email)}
                    value={user.E_MAIL}
                  />
                </View>
            }

            <View style={styles.rowContainer}>
              <Text style={styles.label}>Attivo: </Text>
              <Text style={[styles.textInputDisabled,]}>
                {parseInt(user.ATTIVO)?
                  'SI'
                  :
                  'NO'
                }
              </Text>
            </View>

            <View style={styles.rowContainer}>
              <Text style={styles.label}>Insegnante: </Text>
              <Text style={[styles.textInputDisabled,]}>
                {parseInt(user.INSEGNANTE)?
                  'SI'
                  :
                  'NO'
                }
              </Text>
            </View>

            <View style={styles.rowContainer}>
              <Text style={styles.label}>Operatore: </Text>
              <Text style={[styles.textInputDisabled,]}>
                {parseInt(user.OPERATORE)?
                  'SI'
                  :
                  'NO'
                }
              </Text>
            </View>

            <View style={styles.rowContainer}>
              <Text style={styles.label}>Bloccato: </Text>
              <Text style={[styles.textInputDisabled,]}>
                {parseInt(user.BLOCCATO)?
                  'SI'
                  :
                  'NO'
                }
              </Text>
            </View>

            <View style={styles.rowContainer}>
              <Text style={styles.label}>Invitabile: </Text>
              <Text style={[styles.textInputDisabled,]}>
                {parseInt(user.INVITABILE)?
                  'SI'
                  :
                  'NO'
                }
              </Text>
            </View>

            { this.state.statusInfo?
              <Text style={styles.label}>{this.state.statusInfo}</Text>
              :
              null
            }

            {this.renderBtnToolbar()}

          </ScrollView>
        </View>
      );
    } else {
      return(
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    }
  }

}
