import {createStackNavigator} from "react-navigation";
import ProspectsHome from './views';


const ProspectsNavigator = createStackNavigator({
  main: {
    screen: ProspectsHome
  }
},
{
  mode: 'modal',
  headerMode: 'none',
  transparentCard: true,
  cardStyle: { opacity: 1 }
});

export default ProspectsNavigator;
