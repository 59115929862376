import React from "react";
import { decorate, observable, flow } from 'mobx'
import axios from "./commons/axios";


export class Store {
  authenticated= false;
  token = null;
  refreshToken = null;
  // Egaf specific params
  COD_LOGIN = null;

  // Observables

  isAllowedUser = false;
  idnuvola = null;
  COD_INTERL = null; // Id location
  opera = null; // Id course

  currentCloud = [];
  currentLocation = [];
  currentCourse = null;
  classes = [];
  showingStudentsOfClassIndex = 0;

  showMainTopButtons = true;
  hasUnprocessedEdits = false;

  statsPeriod = 30;
  showChangeLog = false;

  initWithCloud = flow(function*(cloudID) {
    const completeCloudsResp = yield axios.get("users/", {
      params: {
        allUserView: true
      }
    });

    // console.info('Clouds complete', completeCloudsResp.data);
    const clouds = completeCloudsResp.data;
    const defaultCloud = clouds.find((cloud)=>parseInt(cloud.nuvola_def));

    // console.info('DEFAULT CLOUDS', defaultCloud);
    const currentCloud = defaultCloud? defaultCloud : clouds[0];
    this.currentCloud = currentCloud;
    this.idnuvola = currentCloud['idnuvola'];
    this.hasMultiClouds = clouds.length > 1;

    // Then get all locations
    const locations = this.currentCloud.locations;
    // const locations = null;

    // FIRST check if user is assigned,
    // If locations are not set, the user is unassigned, show message
    if (!locations.length) {
      return {
        defaultCloud: null,
        defaultLocation: null,
        defaultCourse: null,
        userUnassigned: true
      }
    }

    // SECOND check if there is no default cloud, in case call the wizard
    // If the isn't a default cloud, call the wizard
    if (!defaultCloud) {
      return {
        defaultCloud: null,
        defaultLocation: null,
        defaultCourse: null,
        userUnassigned: false
      }
    }

    // console.info('Locations', locations);
    const defaultLocation = locations.find((location)=>parseInt(location.location_def));
    this.currentLocation = defaultLocation? defaultLocation : locations[0];
    this.hasMultiLocations = locations? locations.length > 1 : false;

    // THIRD check if there is no default location, in case call the wizard
    if (!defaultLocation) {
      return {
        defaultCloud: true,
        defaultLocation: null,
        defaultCourse: null,
        userUnassigned: false
      }
    }

    // Then get all courses
    const courses = this.currentLocation.corsi;

    // Then check if there are courses for this user otherwise show unassigned message
    if (!courses.length) {
      return {
        defaultCloud: null,
        defaultLocation: null,
        defaultCourse: null,
        userUnassigned: true
      }
    }

    // console.info('Courses', courses);
    let defaultCourse = null;
    if (courses.length) {
      defaultCourse = courses.find((course)=>parseInt(course.opera_def));
      this.currentCourse = defaultCourse? defaultCourse : courses[0];
    } else {
      this.currentCourse = {};
    }
    this.hasMultiCourses = courses? courses.length > 1 : false;

    // FOURTH check if there is no default course, in case call the wizard
    if (!defaultCourse) {
      return {
        defaultCloud: true,
        defaultLocation: true,
        defaultCourse: null,
        userUnassigned: false
      }
    }

    // Then get all classes for the current course
    try {
      const classes = this.currentCourse.classi;
      // console.info('Classes', classes);
      this.classes = classes;
      this.hasMultiClassesForCourse = classes? classes.length > 1 : false;
    } catch (e) {
      // console.info('Classes', []);
      this.classes = [];
      this.hasMultiClassesForCourse = false;
    }

    // Then get logged in user role
    const usersDetailResp = yield axios.get("users/getutentilocation/", {
      params: {
        idutente: defaultCloud.idutente,
        COD_INTERL: defaultLocation.COD_INTERL,
        COD_SIGLAIND: defaultLocation.COD_SIGLAIND
      }
    });
    const usersList = usersDetailResp.data;

    if (usersList.length){
      const userDetail = usersDetailResp.data.find((user)=>user.idutente === defaultCloud.idutente);
      // console.info('userDetail', userDetail);
      if (userDetail.PREDEFINITO || userDetail.OPERATORE){
        this.isAllowedUser = true;
      }
    }

    console.log('END initWithCloud');

    return {
      defaultCloud,
      defaultLocation,
      defaultCourse,
      userUnassigned: false
    }

  });

  refreshClasses = flow(function*() {
    console.log('Store refreshing class');
    try{

      const classResp = yield axios.get("classrooms/getclassiinsegnante", {
        params: {
          idutente: this.currentCloud.idutente,
          COD_INTERL: this.currentLocation.COD_INTERL,
          COD_SIGLAIND: this.currentLocation.COD_SIGLAIND,
          opera: this.currentCourse.opera

        }
      });

      // console.info('classResp complete', classResp.data);
      this.classes = classResp.data;

    } catch (e) {
      console.log('Class refreshing error!!'+e);
    }

  });

  changeCloud = (newCloud) => {
    // this.currentLocation = newLocation;
    // this.changeCourseForLocation(newLocation.corsi[0]['opera']);
  }

  changeLocation = (newLocation) => {
    this.currentLocation = newLocation;
    this.changeCourseForLocation(newLocation.corsi[0]['opera']);
  }

  changeCourseForLocation = (newopera) => {
    const newCurrentCourse = this.currentLocation.corsi.find(({opera}) => opera === newopera);
    // console.info('newCurrentCourse is ',newCurrentCourse);
    this.currentCourse = newCurrentCourse;
    this.classes = this.currentCourse.classi;
  }

  getDefaultClass = () => {
    return this.classes.find((classItem) => parseInt(classItem.PREDEFINITO) === 1)
  }

  resetStore = () => {
    this.authenticated= false;
    this.token = null;
    this.refreshToken = null;
    // Egaf specific params
    this.COD_LOGIN = null;

    // Observables
    this.isAllowedUser = false;
    this.idnuvola = null;
    this.COD_INTERL = null; // Id location
    this.opera = null; // Id course
  }

}

decorate(Store, {
  isAllowedUser: observable,
  idnuvola: observable,
  COD_INTERL: observable,
  opera: observable,
  currentCloud: observable,
  currentLocation: observable,
  currentCourse: observable,
  classes: observable,
  showingStudentsOfClassIndex: observable,
  showMainTopButtons: observable,
  hasUnprocessedEdits: observable,
  statsPeriod: observable,
  showChangeLog: observable,
});


let UserContext = React.createContext(new Store());

export const refreshContext = () => UserContext = React.createContext(new Store());

export default UserContext
