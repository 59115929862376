import React from 'react';
import { View, Text, Picker, ScrollView, ActivityIndicator, Switch, TouchableOpacity } from 'react-native';
import {actionStyles, Colors, styles} from '../../foundation/styles';
import ConfigStyles from '../styles';
import {ActionBlock, EgafSectionList} from "../../foundation/widgets";
import {isWeb} from "../../commons/utils";
import TextInput from "react-native-web/dist/exports/TextInput";
import UserContext from "../../user";
import axios from "../../commons/axios";
import {reaction} from "mobx";
import prospectStyles from "../../section_prospects/styles";
import errorManager from "../../commons/error_manager";


export default class ConfigurationClasses extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.activeComponent = null;

    this.sectionHeaders  = [
      {name: 'Nome', prop: 'classe'},
      {name: 'Location', prop: 'RAGSOCIALE'},
      {name: 'Corso', prop: 'corso'},
      {name: 'N° allievi', prop: 'Allievi'},
      // {name: 'Predefinita', prop: 'PREDEFINITO'},
      {name: 'Disabilitata', prop: 'DISABILITATO'}
    ];

    this.excludedFieldsOnActionEnabled = ['corso', ];

    this.unfilteredListItems = [];

    this.state = {
      loading: true,
      listItems: [],

      selectedUsers: [],
      showingClassDetailId: null,
      action: null,
      isActionEnabled: false,

      // State depending on outer button, to execute action on child component
      hasConfirmed: false,
      hasRejected: false,

      // Filters
      filters: {
        active: false,
        preset: false,
        text: ''
      }
    }
  }

  async loadClasses() {

    const classesResp = await axios.get("classrooms/getclassioperatore", {
      params: {
        idutente: this.context.currentCloud.idutente,
      }
    });

    // console.info('loaded classes ', classesResp.data);
    this.unfilteredListItems = classesResp.data;
    this.setState({
      loading: false,
      listItems: this.unfilteredListItems
    })
  }

  componentDidMount() {
    this.loadClasses();

    this._classesConfReactionDispose = reaction(
      () => this.context.currentCloud,
      () => {
        this.onReject();
        this.loadClasses();
      }
    );

    this._classesConfReactionClassesDispose = reaction(
      () => this.context.classes,
      async () => {
        this.actionRetry();
        await this.loadClasses();
        this.refreshAction();
      }
    );
  }

  componentWillUnmount() {
    this._classesConfReactionDispose();
    this._classesConfReactionClassesDispose();
  }

  // FILTERS STUFF

  updateFilters(newValue, filterType) {
    let filters = Object.assign({}, this.state.filters);
    filters[filterType] = newValue;
    this.setState({
      filters
    });
    this._applyAllFilters()
  }

  // Main filtering funcitons
  _applyAllFilters(newValue, filterType) {

    let items = Array.from(this.unfilteredListItems);
    // Preparing filters
    let filters = Object.assign({}, this.state.filters);
    filters[filterType] = newValue;
    let activeFilter = filters.active;
    let presetFilter = filters.preset;
    let textFilter = filters.text.toLowerCase();

    // Filtering logic
    // Better using for of loop with continue statement for performance
    let filteredItems = [];
    for (const item of items) {

      let passedFiltering = true;

      // First Status Filters
      if (activeFilter && parseInt(item.DISABILITATO) ) {
        passedFiltering = false;
        continue;
      }

      if (presetFilter && !parseInt(item.PREDEFINITO) ) {
        passedFiltering = false;
        continue;
      }

      if (textFilter && !item.classe.toLowerCase().includes(textFilter)) {
        passedFiltering = false;
        continue;
      }

      if (passedFiltering){
        filteredItems.push(item);
      }
    }

    // Filtering logic
    this.setState({
      listItems: filteredItems,
      filters: filters

    });

  }

  _createTextFilter() {
    return (
      <TextInput
        style={prospectStyles.searchTextInput}
        placeholder={'Cerca per Nome'}
        placeholderTextColor={'#797979'}
        onChangeText={(text) => this._applyAllFilters(text, 'text')}
        value={this.state.filters.text}
      />
    )
  }

  _createActiveFilter() {
    if (this.unfilteredListItems.find((operator)=>parseInt(operator.DISABILITATO))) {
      return (
        <View
          key={'active-filter'}
          style={[styles.rowContainer, prospectStyles.topFilterSeparator]}
        >
          <Text style={[prospectStyles.topFilterLabel,]}>
            ATTIVI
          </Text>
          <Switch
            // style={{backgroundColor: '#ccefef'}}
            value={this.state.filters.active}
            onValueChange={(newValue) => {
              this._applyAllFilters(newValue, 'active')
            }}
            thumbColor={Colors.textLight}
            activeThumbColor={Colors.textLight}
            activeTrackColor={Colors.primaryColor}
            trackColor={Colors.border}
          />
        </View>
      );
    } else {
      return null;
    }
  }

  _createPresetFilter() {
    if (this.unfilteredListItems.find((operator)=>parseInt(operator.PREDEFINITO))) {
      return (
        <View
          key={'preset-filter'}
          style={[styles.rowContainer, ]}
        >
          <Text style={[prospectStyles.topFilterLabel,]}>
            PREDEFINITI
          </Text>
          <Switch
            // style={{backgroundColor: '#ccefef'}}
            value={this.state.filters.preset}
            onValueChange={(newValue) => {
              this._applyAllFilters(newValue, 'preset')
            }}
            thumbColor={Colors.textLight}
            activeThumbColor={Colors.textLight}
            activeTrackColor={Colors.primaryColor}
            trackColor={Colors.border}
          />
        </View>
      );
    } else {
      return null;
    }
  }

  renderTopFilters() {
    return(
      <View style={[ConfigStyles.topFiltersMainWrapper, ]}>
          {this._createTextFilter()}
          <View style={[styles.rowContainer, ConfigStyles.topFilter] }>
            <Text style={prospectStyles.topFilterMainLabel}>STATO:</Text>
            {this._createActiveFilter()}
            {this._createPresetFilter()}
          </View>

      </View>
    )
  }

  // ACTION BLOCK METHODS
  // action param should be an obj like {name: 'userdetail', params: {id: 13}}
  openActionBox(action=null) {
    this.setState({
      action: action,
      isActionEnabled: true
    });
  }

  _prepareForAction() {
    let component = <CreateClass
      hasConfirmed={this.state.hasConfirmed}
      hasRejected={this.state.hasRejected}
      goBack={() => this.actionCompleted()}
      retry={() => this.actionRetry()}
    />;
    if (this.state.action){
      switch (this.state.action.name) {
        case 'classdetail':
          component = <ClassDetail
            classItem={this.state.action.params.classItem}
            hasConfirmed={this.state.hasConfirmed}
            hasRejected={this.state.hasRejected}
            goBack={() => this.actionCompleted()}
            retry={() => this.actionRetry()}
            refresh={()=>this.refresh()}
          />;
          break;
        default:
          break;
      }
      // Change detail component
    }
    this.activeComponent = component;
    return component;
  }

  onConfirm() {
    // console.log('onConfirm()');
    // Toggle Action Box
    this.setState({
      hasConfirmed: true
    })
  }

  onReject() {

    // if (this.state.action.name === 'classdetail') {
    //   // this.actionRetry();
    //   this.setState({
    //     hasRejected: true
    //   })
    //
    // } else {
    //   console.log('onReject()');
      // Toggle Action Box
      // this.openActionBox()
      this.setState({
        hasRejected: true,
        action: null,
        isActionEnabled: false,
        selectedUsers: [],
        showingClassDetailId: null,
      })
    // }
  }

  actionRetry() {
    this.setState({
      hasConfirmed: false,
      hasRejected: false,
    })
  }

  actionCompleted() {
    // this.openActionBox();
    this.loadClasses();
    this.setState({
      action: null,
      hasConfirmed: false,
      hasRejected: false,
      isActionEnabled: false,
      showingClassDetailId: null
    })
  }

  refreshAction() {
    // If exists, update action
    if (this.state.action) {
      // Getting refreshed class
      const classId = this.state.action.params.classItem.idclasse;
      const refreshedClass = this.unfilteredListItems.find((classItem) => classItem.idclasse === classId);
      let refreshedAction = Object.assign({}, this.state.action);
      refreshedAction.params.classItem = refreshedClass;
      this.openActionBox(refreshedAction);
    }
  }

  refresh() {
    this.loadClasses();
  }

  getActionBlockConfirmLabel() {
    let label = 'Salva';
    if (this.state.action) {
      label = this.state.action.params.confirmBtnLabel;
    }
    return label;
  }

  // Rendering Logic
  render() {
    return (
      <View style={styles.sectionContainer}>

        { isWeb ?
          <ActionBlock
            key="config_classes"
            renderMasterHeader={()=>this.renderMasterHeader()}
            renderMasterBody={()=>this.renderMasterBody()}
            prepareForAction={()=>this._prepareForAction()}
            onConfirm={()=>this.onConfirm()}
            onReject={()=>this.onReject()}
            isActionEnabled={this.state.isActionEnabled}
            cancelBtnLabel={'Annulla'}
            confirmBtnLabel={this.getActionBlockConfirmLabel()}
          />
          :
          <EgafSectionList
            headers={this.sectionHeaders}
            dataForListing={this.state.listItems}
            refID={'idclasse'}
            // onPressRow={(id)=>this.navigateToDetail(id)} in app go to detail passing id
          />
        }

      </View>
    );
  }

  renderMasterHeader() {
    if (!this.state.isActionEnabled){
      return(
        <View style={[actionStyles.actionMasterInnerHeader, styles.alignRight, {paddingTop: 17, paddingBottom: 16, marginBottom: 15}]}>
          <TouchableOpacity
            style={styles.actionBtn}
            onPress={()=>this.openActionBox()}
          >
            <Text style={styles.actionBtnLabel}>Crea</Text>
          </TouchableOpacity>
        </View>
      )
    } else {
      return (
        <View style={[actionStyles.actionMasterInnerHeader, styles.alignRight, {height: 72, paddingTop: 20, paddingBottom: 20, marginBottom: 15}]} />
      )
    }

  }

  renderMasterBody() {

    if (this.state.loading) {
      return <ActivityIndicator />
    } else {

      return (
        <View style={styles.container}>
          {this.renderTopFilters()}
          <EgafSectionList
            key={"config_classes_list"}
            headers={this.sectionHeaders}
            dataForListing={this.state.listItems}
            onPressRow={(classItem) => this.goToUserDetail(classItem)}
            selectedRowItemId={this.state.showingClassDetailId}
            isActionEnabled={this.state.isActionEnabled}
            hideFields={this.excludedFieldsOnActionEnabled}
            showRoundIndicators={true}
            indicatorsProps={['DISABILITATO', ]}
            refID={'idclasse'}
          />
        </View>
      );
    }
  }

  // Specific Class methods
  // Web only method, specify action
  goToUserDetail(classItem) {
    // console.info('going to show detail of class', classItem);
    const action = {
      name: 'classdetail',
      params: {
        classItem: classItem,
        confirmBtnLabel: 'Salva'
      }
    };
    this.setState({
      showingClassDetailId: classItem.idclasse
    });
    this.openActionBox(action);
  }

}


export class CreateClass extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      statusInfo: '',
      classDetails: {
        classe: '',
      }
    }
  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.hasConfirmed  && !prevProps.hasConfirmed){
      // console.log('componentDidUpdate.... executeAction');
      this.executeAction();
    }

    if (this.props.hasRejected && !prevProps.hasRejected){
      // console.log('componentDidUpdate.... rejectAction');
      this.rejectAction();
    }
  }

  alertMessage(message=null) {
    const msg = message? message : 'Non è stato possibile creare la classe, riprovare più tardi, grazie';
    alert(msg);
  }

  async saveClass() {

    try {

      // console.info('Going to create class');
      // console.info('COD_INTERL:', this.context.currentLocation.COD_INTERL);
      // console.info('COD_SIGLAIND:', this.context.currentLocation.COD_SIGLAIND);
      // console.info('opera:', this.context.currentCourse.opera);
      // console.info('nomeClasse:', this.state.classDetails.classe)


      const creaClassResp = await axios.post("/classrooms/set?func=creaClasse", {
        COD_INTERL: this.context.currentLocation.COD_INTERL,
        COD_SIGLAIND: this.context.currentLocation.COD_SIGLAIND,
        opera: this.context.currentCourse.opera,
        nomeClasse: this.state.classDetails.classe,
      });
      const resp = creaClassResp.data;
      // console.info('creaClassResp ', resp);
      if (resp.hasOwnProperty('idclasse') && resp.idclasse) {
        // console.log('Successfully created class with id ' + resp.idclasse);

        this.setState({
          // statusInfo: 'Class saved!',
          loading: false,
        });
        // this.context.refreshClasses();
        this.alertMessage('Classe creata con successo.');

        setTimeout(()=>{
          this.goBack();
          // this.context.refreshClasses();
        }, 1500);

      } else {
        this.setState({
          // statusInfo: 'Non è stato possibile creare la classe',
          loading: false
        });

        this.alertMessage();

        // setTimeout(()=>{
        //   this.goBack();
        //   // this.context.refreshClasses();
        // }, 1500);
      }

    } catch (e) {
      errorManager(e);
    }

  }

  executeAction() {
    // console.log('executing action: creating class !!!!!');

    if (this.state.classDetails.classe) {
      this.setState({
        loading: true,
      });
      this.saveClass();
    } else {
      this.setState({
        statusInfo: 'Compilare i campi per creare una classe, grazie.',
      });
      this.props.retry();
    }
  }

  rejectAction() {
    // console.log('rejectAction.... now close all');
    this.setState({
      classDetails: {
        classe: '',
      }
    });
    this.goBack();
  }

  goBack() {
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }

  updateDetail(prop, value) {
    this.context.hasUnprocessedEdits = true;
    let classDetails = {...this.state.classDetails};
    classDetails[prop] = value;
    this.setState({
      classDetails,
      statusInfo: '',
    });
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.executeAction()}
          >
            <Text style={styles.btnText}>CREA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return(
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    } else {
      const classroom = this.state.classDetails;
      return (
        <View style={styles.container}>
          <View style={actionStyles.actionDetailInnerHeader}>

            <Text style={actionStyles.actionDetailTitle}>Crea nuova classe</Text>
          </View>

          <ScrollView style={actionStyles.scrollableView}>
            <Text style={styles.label}>Nome:</Text>
            <TextInput
              maxLength={55}
              style={styles.textInput}
              onChangeText={(name) => this.updateDetail('classe', name)}
              value={classroom.classe}
            />

            { this.state.statusInfo?
              <Text style={styles.label}>{this.state.statusInfo}</Text>
              :
              null
            }

            {this.renderBtnToolbar()}
          </ScrollView>
        </View>
      );

    }
  }
}


export class ClassDetail extends React.Component{

  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.tempTeachersAdded = [];
    this.tempTeachersRemoved = [];

    const classDetails = {
      ...this.props.classItem,
      teachers: [],
      availableTeachers: []
    };

    this.rollbackErrors = false;

    this.state = {
      loading: true,
      classDetails: classDetails,
      addRemoveWidgetMessage: '',

      disablingClassToggle: {
        showButton: !parseInt(this.props.classItem.PREDEFINITO) && !parseInt(this.props.classItem.Allievi),
        loading: false,
        sent: false,
        sentWithError: false,
        toggleAction: parseInt(this.props.classItem.DISABILITATO)? 'enabling' : 'disabling'
      }
    }
  }

  async loadClassOperatorsDetail(newClassDetails = null) {

    // loading list of assigned teachers
    const teachersResp = await axios.get("classrooms/getinsegnanticlasse/", {
      params: {
        idutente: this.context.currentCloud.idutente,
        idclasse: this.props.classItem.idclasse
      }
    });
    const allTeachers = teachersResp.data;

    const teachers = allTeachers.filter((teacher) => parseInt(teacher.INSEGNANTE) === 1);
    const availableTeachers = allTeachers.filter((teacher) => parseInt(teacher.INSEGNANTE) === 0);

    let classDetails = newClassDetails ? Object.assign({}, newClassDetails) : Object.assign({}, this.state.classDetails);
    classDetails.teachers = teachers;
    classDetails.availableTeachers = availableTeachers;

    this.setState({
      classDetails
    });
  }

  componentDidMount() {
    this.loadClassOperatorsDetail();
  }

  // Check if action has been confirmed! (only for web)
  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.hasConfirmed  && !prevProps.hasConfirmed){
      this.executeAction();
    }

    if (this.props.hasRejected && !prevProps.hasRejected){
      // console.log('componentDidUpdate.... rejectAction');
      this.rejectAction();
    }

    if (this.props.classItem !== prevProps.classItem){

      this.loadClassOperatorsDetail(this.props.classItem);
      this.setState({
        disablingClassToggle: {
          showButton: !parseInt(this.props.classItem.PREDEFINITO) && !parseInt(this.props.classItem.Allievi),
          loading: false,
          sent: false,
          sentWithError: false,
          toggleAction: parseInt(this.props.classItem.DISABILITATO)? 'enabling' : 'disabling'
        }
      })
    }
  }

  alertMessage(message=null) {
    const msg = message? message : 'La classe non è stata aggiornata, riprovare più tardi. Grazie';
    alert(msg);
  }

  async updateClass() {
    try {

      const updateClass = await axios.post("classrooms/set?func=modificaClasse", {
        idclasse: this.state.classDetails.idclasse,
        opera: this.context.currentCourse.opera,
        nomeClasse: this.state.classDetails.classe,
      });
      // console.info('updateClass data', updateClass.data);
      const resp = updateClass.data;
      if (resp.hasOwnProperty('modificaClasse') && resp.modificaClasse) {
        // console.log('Successfully created class ' + resp.modificaClasse);

        this.setState({
          loading: false,
        });

        this.alertMessage('Classe aggiornata con successo.');
        // Refreshing class list
        // this.props.refresh();
        this.context.refreshClasses();

      } else {

        this.setState({
          loading: false,
        });

        this.alertMessage();

      }

    } catch (e) {
      // this.alertMessage();
      console.info('UPDTE ERROR: ', e);
      errorManager(e, 'La classe non è stata aggiornata, riprovare più tardi. Grazie');
      this.props.retry();

    }
  }

  executeAction() {
    // console.log('loading.... Saving details');
    this.setState({
      loading: true
    });
    this.updateClass();
  }

  async rejectAction() {
    // console.log('rejectAction.... now close all but after checking operators added and removed');
    this.goBack();
  }

  findTeachername(id) {
    let teacher = this.state.classDetails.teachers.find((teacher) => {
      return teacher.idutente === parseInt(id);
    });
    if (!teacher) {
      teacher = this.state.classDetails.availableTeachers.find((teacher) => {
        return teacher.idutente === parseInt(id);
      });
    }
    return teacher ? teacher.utente : '';
  }


  goBack() {
    // console.log('going back without saving');
    this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
  }


  updateDetail(prop, value) {
    this.context.hasUnprocessedEdits = true;
    let classDetails = {...this.state.classDetails};
    classDetails[prop] = value;
    this.setState({
      classDetails,
    });
  }

  renderBtnToolbar() {
    if (!isWeb) {
      return (
        <View style={styles.btnToolbar}>
          <TouchableOpacity
            style={styles.cancelBtn}
            onPress={() => this.goBack()}
          >
            <Text style={styles.btnText}>ANNULLA</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.confirmBtn}
            onPress={() => this.executeAction()}
          >
            <Text style={styles.btnText}>INVITA</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  async callAssignTeacher(id) {
    const teacherId = parseInt(id);
    // Now make the request to save
    const operatorAssignResp = await axios.post("classrooms/set?func=assegnaClasseAdInsegnante",
      {
        idutente: teacherId,
        idclasse: this.state.classDetails.idclasse,
    });
    return operatorAssignResp.data && operatorAssignResp.data.assegnaClasseAdInsegnante === 1;
  }

  async assignTeacher(teacherId) {
    const assignOperation = await this.callAssignTeacher(teacherId);

    if (assignOperation) {
      // Add teacher to temp array (to rollback in case of cancel operation)
      if (!this.tempTeachersAdded.includes(teacherId)) {
        this.tempTeachersAdded.push(teacherId);
      }

      this.loadClassOperatorsDetail();
    } else {
      this.alertMessage('Non è stato possibile aggiungere l\' operatore');
    }
  }

  async callRemoveTeacher(id) {
    const teacherId = parseInt(id);
    // Now make the request to save
    const operatorRemoveResp = await axios.post("classrooms/set?func=revocaClasseAdInsegnante",
      {
        idutente: teacherId,
        idclasse: this.state.classDetails.idclasse,
    });
    return operatorRemoveResp.data && operatorRemoveResp.data.revocaClasseAdInsegnante === 1;
  }

  async removeTeacher(teacherId) {
    const removeOperation = await this.callRemoveTeacher(teacherId);

    if (removeOperation) {
      // Add teacher to temp array (to make actual saving request on confirm)
      if (!this.tempTeachersRemoved.includes(teacherId)) {
        this.tempTeachersRemoved.push(teacherId);
      }

      this.loadClassOperatorsDetail();
    } else {
      this.alertMessage('Non è stato possibile rimuovere l\' operatore')
    }
  }

  renderAddRemoveWidget() {
    return(
      <View style={[styles.addRemoveWidget, this.context.hasUnprocessedEdits? {opacity: 0.5} : null]}>

        {
          this.context.hasUnprocessedEdits?
            <View style={styles.addRemoveWidgetOverlay}></View>
            :
            null
        }
        {
          this.state.classDetails.teachers.map((teacher) => {
            if (parseInt(teacher.ELIMINABILE)) {
              return <View
                key={teacher.idutente}
                style={[styles.rowContainer, styles.itemRowWidget, ]}
              >
                <Text style={styles.itemRemovableWidget}>{teacher.utente}</Text>
                <TouchableOpacity
                  style={styles.itemMinusBtnWidget}
                  onPress={()=>this.removeTeacher(teacher.idutente)}
                >
                  <Text style={styles.itemMinusBtnTextWidget}>-</Text>
                </TouchableOpacity>
              </View>
            } else {
              return <View
                key={teacher.idutente}
                style={[styles.rowContainer, styles.itemRowWidget, ]}
              >
                <Text style={styles.itemRemovableWidget}>{teacher.utente}</Text>
              </View>
            }

          })

        }

      <Picker
        selectedValue={''}
        style={styles.itemsAddListWidget}
        onValueChange={(teacherId, itemIndex) =>
          this.assignTeacher(teacherId)
        }>
          <Picker.Item key={'first'} label={'Aggiungi insegnante'} value={''} />
        {
          this.state.classDetails.availableTeachers.map((teacher, index) => {
            return <Picker.Item key={index} label={teacher.utente} value={teacher.idutente} />
          })
        }
      </Picker>

      <View>
        <Text>{this.state.addRemoveWidgetMessage}</Text>
      </View>
    </View>
    );
  }

  // Class Disabling logic
  async _disableToggleClass() {
    this.setState({
      disablingClassToggle: {
        loading: true,
        showButton: true,
        sent: false
      }
    });


    let sentWithError = true;
    let toggleAction = this.state.disablingClassToggle.toggleAction;
    let disabled = null;

    try{

      if (toggleAction === 'enabling') {
        // ENABLING
        // https://autoscuole.egaf.it/classrooms/set?func=riabilitaClasse
        const enablingClassToggleResp = await axios.post("classrooms/set?func=riabilitaClasse", {
          idclasse: this.state.classDetails.idclasse
        });

        const resp = enablingClassToggleResp.data;
         // console.info('enabling Class Toggle Resp ', resp);
        if (resp.hasOwnProperty("riabilitaClasse") && parseInt(resp.riabilitaClasse)){
          sentWithError = false;
          toggleAction = 'disabling';
          disabled = "0";

          // Reloading operators
          await this.loadClassOperatorsDetail();
        }

      } else {
        // DISABLING
        // https://autoscuole.egaf.it/classrooms/set?func=disabilitaClasse
        const disablingClassToggleResp = await axios.post("classrooms/set?func=disabilitaClasse", {
          idclasse: this.state.classDetails.idclasse
        });

        const resp = disablingClassToggleResp.data;
        // console.info('disabling Class Toggle Resp ', resp);
        if (resp.hasOwnProperty("disabilitaClasse") && parseInt(resp.disabilitaClasse)){
          sentWithError = false;
          toggleAction = 'enabling';
          disabled = "1";
        }
      }

    } catch (e) {
      sentWithError = true
    }

    let classDetails = this.state.classDetails;
    if (disabled) {
      classDetails = Object.assign({}, this.state.classDetails);
      classDetails.DISABILITATO = disabled;

    }

    this.setState({
      classDetails: classDetails,
      disablingClassToggle: {
        showButton: true,
        loading: false,
        sent: true,
        sentWithError: sentWithError,
        toggleAction: toggleAction
      }
    });

    // Refreshing class list
    this.context.refreshClasses();

  }

  disableToggleClass() {
    if (this.state.disablingClassToggle.loading){
      return(
        <View style={ConfigStyles.invitationBtnW}>
          <ActivityIndicator size={'small'}/>
        </View>
      )
    } else {
      if (this.state.disablingClassToggle.sent){
        return (
          <View style={[ConfigStyles.invitationBtnW, this.context.hasUnprocessedEdits? {position: 'relative', opacity: 0.5} : null]}>
            {
              this.context.hasUnprocessedEdits?
                <View style={styles.addRemoveWidgetOverlay}></View>
                :
                null
            }
            {
              this.state.disablingClassToggle.sentWithError?
              <Text style={ConfigStyles.invitationBtnText}>Si è verificato un errore, riprovare più tardi.</Text>
              :
              <Text style={ConfigStyles.invitationBtnText}>
                Classe {this.state.disablingClassToggle.toggleAction === 'disabling'? 'abilitata' : 'disabilitata'} con successo
              </Text>
            }
          </View>
        )
      } else {

        return(
          <View style={[ConfigStyles.invitationBtnW, this.context.hasUnprocessedEdits? {position: 'relative', opacity: 0.5} : null]}>
            {
              this.context.hasUnprocessedEdits?
                <View style={styles.addRemoveWidgetOverlay}></View>
                :
                null
            }
            <TouchableOpacity
              style={[ConfigStyles.invitationBtn,
                this.state.disablingClassToggle.toggleAction === 'disabling'? ConfigStyles.disableBtn : ConfigStyles.enableBtn
              ]}
              onPress={()=>this._disableToggleClass()}
            >
              <Text style={ConfigStyles.invitationBtnText}>
                {this.state.disablingClassToggle.toggleAction === 'disabling'? 'Disabilita' : 'Abilita'} classe
              </Text>
            </TouchableOpacity>
          </View>
        )
      }
    }
  }

  render() {
    if (this.state.classDetails) {
      const classroom = this.state.classDetails;

      return (
        <View style={styles.container}>
          <View style={actionStyles.actionDetailInnerHeader}>

            <Text style={[actionStyles.actionDetailTitle, {fontSize: 19}]}>{classroom.classe}</Text>
          </View>

          <ScrollView style={actionStyles.scrollableView}>

            {
              this.state.disablingClassToggle.showButton?
                this.disableToggleClass()
                :
                null
            }

            <Text style={styles.label}>Nome:</Text>
            {
              parseInt(classroom.PREDEFINITO) || parseInt(classroom.DISABILITATO) ?
                <Text style={[styles.textInputDisabled, ]}>{classroom.classe}</Text>
                :
                <TextInput
                  style={styles.textInput}
                  onChangeText={(name) => this.updateDetail('classe', name)}
                  value={classroom.classe}
                />
            }

            {
              parseInt(classroom.DISABILITATO) ?
                null
                :
                <View style={ConfigStyles.innerBlock}>
                  <Text style={styles.label}>Operatori:</Text>
                  { this.renderAddRemoveWidget() }
                </View>
            }

            {this.renderBtnToolbar()}

          </ScrollView>
        </View>
      );
    } else {
      return(
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size={'large'}/>
        </View>
      )
    }
  }
}


// Original ClassDetail with logic cancel/save both for name and teachers
// export class _ClassDetail extends React.Component{
//
//   static contextType = UserContext;
//
//   constructor(props) {
//     super(props);
//
//     this.tempTeachersAdded = [];
//     this.tempTeachersRemoved = [];
//
//     const classDetails = {
//       ...this.props.classItem,
//       teachers: [],
//       availableTeachers: []
//     };
//
//     this.rollbackErrors = false;
//
//     this.state = {
//       loading: true,
//       classDetails: classDetails,
//       addRemoveWidgetMessage: '',
//       statusInfo: '',
//
//       disablingClassToggle: {
//         showButton: !parseInt(this.props.classItem.PREDEFINITO) && !parseInt(this.props.classItem.Allievi),
//         loading: false,
//         sent: false,
//         sentWithError: false,
//         toggleAction: parseInt(this.props.classItem.DISABILITATO)? 'enabling' : 'disabling'
//       }
//     }
//   }
//
//   async loadClassOperatorsDetail(newClassDetails = null) {
//
//     // loading list of assigned teachers
//     const teachersResp = await axios.get("classrooms/getinsegnanticlasse/", {
//       params: {
//         idutente: this.context.currentCloud.idutente,
//         idclasse: this.props.classItem.idclasse
//       }
//     });
//     const allTeachers = teachersResp.data;
//
//     const teachers = allTeachers.filter((teacher) => parseInt(teacher.INSEGNANTE) === 1);
//     const availableTeachers = allTeachers.filter((teacher) => parseInt(teacher.INSEGNANTE) === 0);
//
//     let classDetails = newClassDetails ? Object.assign({}, newClassDetails) : Object.assign({}, this.state.classDetails);
//     classDetails.teachers = teachers;
//     classDetails.availableTeachers = availableTeachers;
//
//     this.setState({
//       classDetails
//     });
//   }
//
//   componentDidMount() {
//     this.loadClassOperatorsDetail();
//   }
//
//   // Check if action has been confirmed! (only for web)
//   componentDidUpdate(prevProps, prevState, snapshot) {
//
//     if (this.props.hasConfirmed  && !prevProps.hasConfirmed){
//       console.log('componentDidUpdate.... executeAction');
//       this.executeAction();
//     }
//
//     // console.log('componentDidUpdate, prevProps.hasRejected:' + prevProps.hasRejected);
//     if (this.props.hasRejected && !prevProps.hasRejected){
//       console.log('componentDidUpdate.... rejectAction');
//       this.rejectAction();
//     }
//
//     if (this.rollbackErrors){
//       this.goBack();
//     }
//
//     if (this.props.classItem !== prevProps.classItem){
//
//       this.loadClassOperatorsDetail(this.props.classItem);
//       this.setState({
//         disablingClassToggle: {
//           showButton: !parseInt(this.props.classItem.PREDEFINITO) && !parseInt(this.props.classItem.Allievi),
//           loading: false,
//           sent: false,
//           sentWithError: false,
//           toggleAction: parseInt(this.props.classItem.DISABILITATO)? 'enabling' : 'disabling'
//         }
//       })
//     }
//   }
//
//   alertMessage(message=null) {
//     console.log('something went wrong');
//     const msg = message? message : 'La classe non è stata aggiornata, riprovare più tardi. Grazie';
//     alert(msg);
//     console.log('closed alert');
//   }
//
//   async updateClass() {
//     try {
//
//       const updateClass = await axios.post("classrooms/set?func=modificaClasse", {
//         idclasse: this.state.classDetails.idclasse,
//         opera: this.context.currentCourse.opera,
//         nomeClasse: this.state.classDetails.classe,
//       });
//       console.info('updateClass ', updateClass);
//       console.info('updateClass data', updateClass.data);
//       const resp = updateClass.data;
//       if (resp.hasOwnProperty('modificaClasse') && resp.modificaClasse) {
//         console.log('Successfully created class ' + resp.modificaClasse);
//
//         this.setState({
//           // statusInfo: 'Classe aggiornata',
//           loading: false,
//         });
//         // this.context.refreshOperators();
//
//         this.alertMessage('Classe aggiornata con successo.');
//
//         setTimeout(() => {
//           this.goBack();
//         }, 1500);
//
//       } else {
//
//         this.setState({
//           // statusInfo: 'La classe non è stata aggiornata, riprovare più tardi. Grazie',
//           loading: false,
//         });
//
//         this.alertMessage();
//
//       }
//
//     } catch (e) {
//       // this.alertMessage();
//       console.info('UPDTE ERROR: ', e);
//       errorManager(e, 'La classe non è stata aggiornata, riprovare più tardi. Grazie');
//       this.props.retry();
//
//     }
//   }
//
//   executeAction() {
//     console.log('loading.... Saving details');
//     this.setState({
//       loading: true
//     });
//     this.updateClass();
//   }
//
//   async rejectAction() {
//     console.log('rejectAction.... now close all but after checking operators added and removed');
//     const errors = await this.cancelAddingRemoval();
//
//     console.info('Rolling back errors: ', errors);
//     if (errors.length) {
//       let msg = 'Non è stato possibile ripristinare questi operatori: ';
//       for (const error of errors) {
//         msg += error.name + ' '
//       }
//
//       this.alertMessage(msg);
//       this.rollbackErrors = true;
//       return;
//     }
//
//     this.goBack();
//   }
//
//   findTeachername(id) {
//     let teacher = this.state.classDetails.teachers.find((teacher) => {
//       return teacher.idutente === parseInt(id);
//     });
//     if (!teacher) {
//       teacher = this.state.classDetails.availableTeachers.find((teacher) => {
//         return teacher.idutente === parseInt(id);
//       });
//     }
//     return teacher ? teacher.utente : '';
//   }
//
//   async cancelAddingRemoval() {
//     let errors = [];
//     if (this.tempTeachersAdded.length ) {
//       // There is something to rollback,
//       console.log('rollback added teacher, remove them');
//       for (const teacherId of this.tempTeachersAdded) {
//         const teacherName = this.findTeachername(teacherId);
//         console.info('added teacher id is: ', teacherId);
//         console.info('added teacher name is: ', teacherName);
//         const success = await this.callRemoveTeacher(teacherId);
//         if (!success) {
//           errors.push({
//             name: teacherName
//           })
//         }
//       }
//     }
//
//     if (this.tempTeachersRemoved.length) {
//       console.log('rollback removed teachers, add them');
//       for (const teacherId of this.tempTeachersRemoved) {
//         const teacherName = this.findTeachername(teacherId);
//         console.info('removed teacher id is: ', teacherId);
//         console.info('removed teacher name is: ', teacherName);
//         const success = await this.callAssignTeacher(teacherId);
//         if (!success) {
//           errors.push({
//             name: teacherName
//           })
//         }
//       }
//     }
//
//     return errors;
//   }
//
//   goBack() {
//     console.log('going back without saving');
//     this.props.goBack ? this.props.goBack() : this.navigation.navigate.goBack();
//   }
//
//
//   updateDetail(prop, value) {
//     this.context.hasUnprocessedEdits = true;
//     let classDetails = {...this.state.classDetails};
//     classDetails[prop] = value;
//     this.setState({
//       classDetails,
//       statusInfo: '',
//     });
//   }
//
//   renderBtnToolbar() {
//     if (!isWeb) {
//       return (
//         <View style={styles.btnToolbar}>
//           <TouchableOpacity
//             style={styles.cancelBtn}
//             onPress={() => this.goBack()}
//           >
//             <Text style={styles.btnText}>ANNULLA</Text>
//           </TouchableOpacity>
//           <TouchableOpacity
//             style={styles.confirmBtn}
//             onPress={() => this.executeAction()}
//           >
//             <Text style={styles.btnText}>INVITA</Text>
//           </TouchableOpacity>
//         </View>
//       );
//     }
//   }
//
//   async callAssignTeacher(id) {
//     const teacherId = parseInt(id);
//     // Now make the request to save
//     const operatorAssignResp = await axios.post("classrooms/set?func=assegnaClasseAdInsegnante",
//       {
//         idutente: teacherId,
//         idclasse: this.state.classDetails.idclasse,
//     });
//     return operatorAssignResp.data && operatorAssignResp.data.assegnaClasseAdInsegnante === 1;
//   }
//
//   async assignTeacher(teacherId) {
//     const assignOperation = await this.callAssignTeacher(teacherId);
//
//     if (assignOperation) {
//       // Add teacher to temp array (to rollback in case of cancel operation)
//       if (!this.tempTeachersAdded.includes(teacherId)) {
//         this.tempTeachersAdded.push(teacherId);
//       }
//       // Updating interface
//       this.context.hasUnprocessedEdits = true;
//       this.loadClassOperatorsDetail();
//     } else {
//       this.alertMessage('Non è stato possibile aggiungere l\' operatore');
//     }
//   }
//
//   async callRemoveTeacher(id) {
//     const teacherId = parseInt(id);
//     // Now make the request to save
//     const operatorRemoveResp = await axios.post("classrooms/set?func=revocaClasseAdInsegnante",
//       {
//         idutente: teacherId,
//         idclasse: this.state.classDetails.idclasse,
//     });
//     return operatorRemoveResp.data && operatorRemoveResp.data.revocaClasseAdInsegnante === 1;
//   }
//
//   async removeTeacher(teacherId) {
//     const removeOperation = await this.callRemoveTeacher(teacherId);
//
//     if (removeOperation) {
//       // Add teacher to temp array (to make actual saving request on confirm)
//       if (!this.tempTeachersRemoved.includes(teacherId)) {
//         this.tempTeachersRemoved.push(teacherId);
//       }
//       // Updating interface
//       this.context.hasUnprocessedEdits = true; // Call this only if you want buttons "annulla/salva" appear
//       this.loadClassOperatorsDetail();
//     } else {
//       this.alertMessage('Non è stato possibile togliere l\' operatore')
//     }
//   }
//
//   renderAddRemoveWidget() {
//     return(
//       <View style={styles.addRemoveWidget}>
//
//         {
//           this.state.classDetails.teachers.map((teacher) => {
//             if (parseInt(teacher.ELIMINABILE)) {
//               return <View
//                 key={teacher.idutente}
//                 style={[styles.rowContainer, styles.itemRowWidget, ]}
//               >
//                 <Text style={styles.itemRemovableWidget}>{teacher.utente}</Text>
//                 <TouchableOpacity
//                   style={styles.itemMinusBtnWidget}
//                   onPress={()=>this.removeTeacher(teacher.idutente)}
//                 >
//                   <Text style={styles.itemMinusBtnTextWidget}>-</Text>
//                 </TouchableOpacity>
//               </View>
//             } else {
//               return <View
//                 key={teacher.idutente}
//                 style={[styles.rowContainer, styles.itemRowWidget, ]}
//               >
//                 <Text style={styles.itemRemovableWidget}>{teacher.utente}</Text>
//               </View>
//             }
//
//           })
//
//         }
//
//       <Picker
//         selectedValue={''}
//         style={styles.itemsAddListWidget}
//         onValueChange={(teacherId, itemIndex) =>
//           this.assignTeacher(teacherId)
//         }>
//           <Picker.Item key={'first'} label={'Aggiungi insegnante'} value={''} />
//         {
//           this.state.classDetails.availableTeachers.map((teacher, index) => {
//             return <Picker.Item key={index} label={teacher.utente} value={teacher.idutente} />
//           })
//         }
//       </Picker>
//
//       <View>
//         <Text>{this.state.addRemoveWidgetMessage}</Text>
//       </View>
//     </View>
//     );
//   }
//
//   // Class Disabling logic
//   async _disableToggleClass() {
//     this.setState({
//       disablingClassToggle: {
//         loading: true,
//         showButton: true,
//         sent: false
//       }
//     });
//
//
//     let sentWithError = true;
//     let toggleAction = this.state.disablingClassToggle.toggleAction;
//     try{
//
//       if (toggleAction === 'enabling') {
//         // ENABLING
//         // https://autoscuole.egaf.it/classrooms/set?func=riabilitaClasse
//         const enablingClassToggleResp = await axios.post("classrooms/set?func=riabilitaClasse", {
//           idclasse: this.state.classDetails.idclasse
//         });
//
//         const resp = enablingClassToggleResp.data;
//          console.info('enabling Class Toggle Resp ', resp);
//         if (resp.hasOwnProperty("riabilitaClasse") && parseInt(resp.riabilitaClasse)){
//           sentWithError = false;
//           toggleAction = 'disabling';
//         }
//
//       } else {
//         // DISABLING
//         // https://autoscuole.egaf.it/classrooms/set?func=disabilitaClasse
//         const disablingClassToggleResp = await axios.post("classrooms/set?func=disabilitaClasse", {
//           idclasse: this.state.classDetails.idclasse
//         });
//
//         const resp = disablingClassToggleResp.data;
//         console.info('disabling Class Toggle Resp ', resp);
//         if (resp.hasOwnProperty("disabilitaClasse") && parseInt(resp.disabilitaClasse)){
//           sentWithError = false;
//           toggleAction = 'enabling';
//         }
//       }
//
//     } catch (e) {
//       sentWithError = true
//     }
//
//
//     this.setState({
//       disablingClassToggle: {
//         showButton: true,
//         loading: false,
//         sent: true,
//         sentWithError: sentWithError,
//         toggleAction: toggleAction
//       }
//     });
//
//     // Refreshing class list
//     this.props.refresh();
//
//     // resetting button
//     setTimeout(() => {
//       this.setState({
//         disablingClassToggle: {
//           showButton: true,
//           loading: false,
//           sent: false,
//           sentWithError: false,
//           toggleAction: toggleAction
//         }
//       })
//     }, 3000);
//   }
//
//   disableToggleClass() {
//     if (this.state.disablingClassToggle.loading){
//       return(
//         <View style={ConfigStyles.invitationBtnW}>
//           <ActivityIndicator size={'small'}/>
//         </View>
//       )
//     } else {
//       if (this.state.disablingClassToggle.sent){
//         return (
//           <View style={ConfigStyles.invitationBtnW}>
//            {
//               this.state.disablingClassToggle.sentWithError?
//               <Text style={ConfigStyles.invitationBtnText}>Si è verificato un errore, riprovare più tardi.</Text>
//               :
//               <Text style={ConfigStyles.invitationBtnText}>
//                 Classe {this.state.disablingClassToggle.toggleAction === 'disabling'? 'abilitata' : 'disabilitata'} con successo
//               </Text>
//             }
//           </View>
//         )
//       } else {
//
//         return(
//           <View style={ConfigStyles.invitationBtnW}>
//             <TouchableOpacity
//               style={[ConfigStyles.invitationBtn,
//                 this.state.disablingClassToggle.toggleAction === 'disabling'? ConfigStyles.disableBtn : ConfigStyles.enableBtn
//               ]}
//               onPress={()=>this._disableToggleClass()}
//             >
//               <Text style={ConfigStyles.invitationBtnText}>
//                 {this.state.disablingClassToggle.toggleAction === 'disabling'? 'Disabilita' : 'Abilita'} classe
//               </Text>
//             </TouchableOpacity>
//           </View>
//         )
//       }
//     }
//   }
//
//   render() {
//     if (this.state.classDetails) {
//       const classroom = this.state.classDetails;
//
//       return (
//         <View style={styles.container}>
//           <View style={actionStyles.actionDetailInnerHeader}>
//
//             <Text style={actionStyles.actionDetailTitle}>{classroom.classe}</Text>
//           </View>
//
//           <ScrollView style={actionStyles.scrollableView}>
//
//             {
//               this.state.disablingClassToggle.showButton?
//                 this.disableToggleClass()
//                 :
//                 null
//             }
//
//             <Text style={styles.label}>Nome:</Text>
//             {
//               parseInt(classroom.PREDEFINITO) ?
//                 <Text style={[styles.textInputDisabled, ]}>{classroom.classe}</Text>
//                 :
//                 <TextInput
//                   style={styles.textInput}
//                   onChangeText={(name) => this.updateDetail('classe', name)}
//                   value={classroom.classe}
//                 />
//             }
//
//
//             {/*<Text style={styles.label}>Orari Lezioni:</Text>*/}
//             {/*<TextInput*/}
//               {/*style={styles.textInput}*/}
//               {/*onChangeText={(lesson_hours) => this.updateDetail('lesson_hours', lesson_hours)}*/}
//               {/*value={classroom.lesson_hours}*/}
//             {/*/>*/}
//
//             <Text style={styles.label}>Operatori:</Text>
//
//             { this.renderAddRemoveWidget() }
//
//
//             {/*<View>*/}
//               {/*<Text style={styles.label}>Disabilitata:</Text>*/}
//               {/*{*/}
//                 {/*!parseInt(classroom.PREDEFINITO) && !parseInt(classroom.Allievi) ?*/}
//
//                   {/*<View style={styles.selectWrapper}>*/}
//                     {/*<Picker*/}
//                       {/*selectedValue={classroom.DISABILITATO}*/}
//                       {/*style={styles.select}*/}
//                       {/*onValueChange={(itemValue, itemIndex) =>*/}
//                         {/*this.updateDetail('DISABILITATO', itemValue)*/}
//                       {/*}>*/}
//                       {/*<Picker.Item key={0} label={'No'} value={0} />*/}
//                       {/*<Picker.Item key={1} label={'Sì'} value={1} />*/}
//                       {/*/!*{*!/*/}
//                         {/*/!*Roles.map((role, index) => {*!/*/}
//                           {/*/!*return <Picker.Item key={index} label={role.label} value={role.value} />*!/*/}
//                         {/*/!*})*!/*/}
//                       {/*/!*}*!/*/}
//                     {/*</Picker>*/}
//                   {/*</View>*/}
//
//                   {/*:*/}
//
//                   {/*<Text style={[styles.textInputDisabled, ]}>{parseInt(classroom.DISABILITATO) ? 'No' : 'Sì'}</Text>*/}
//               {/*}*/}
//             {/*</View>*/}
//
//
//             {/*<Text style={styles.label}>Note:</Text>*/}
//             {/*<TextInput*/}
//               {/*multiline = {true}*/}
//               {/*numberOfLines = {8}*/}
//               {/*style={[styles.textArea, styles.noMarginB]}*/}
//               {/*onChangeText={(message) => this.updateDetail('notes', message)}*/}
//               {/*value={classroom.notes}*/}
//             {/*/>*/}
//
//             { this.state.statusInfo?
//               <Text style={styles.label}>{this.state.statusInfo}</Text>
//               :
//               null
//             }
//
//             {this.renderBtnToolbar()}
//
//           </ScrollView>
//         </View>
//       );
//     } else {
//       return(
//         <View style={styles.activityIndicatorContainer}>
//           <ActivityIndicator size={'large'}/>
//         </View>
//       )
//     }
//   }
// }

