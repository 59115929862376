import React, {useEffect, useRef, useState} from "react";
import {ScrollView, Text, View, ActivityIndicator, TouchableOpacity, Image, Animated} from 'react-native';
import axios, {CancelToken} from "../../commons/axios";
import errorManager from "../../commons/error_manager";
import {drillPanelStyles} from "../../foundation/styles";
import {StudentDetail} from "../../section_classes/views";


export const StudentDrillDownPanel = ({showDrillPanel, drillParams, close, currentClass, showImage, hasScroll}) => {

  const cancelRequest = CancelToken.source();
  const [student, setStudent] = useState(null);
  // const slideX = new Animated.Value(0);
  const slideX = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    console.info('EFFECT DRILL | SHOW:', showDrillPanel);
    // Manage Animation
    Animated.timing(slideX, {
      toValue: showDrillPanel ? 1 : 0,
      duration: 400,
    }).start();

    // Manage data loading (only when panel is opening)
    if (showDrillPanel) {
      console.info('LOADING DRILLED DATA');
      loadData();
    } else {
      // Closing, resetting stuff
      setStudent(null);
    }

  }, [showDrillPanel]);

  const loadData = async () => {
    try {
      const classId = drillParams.idClasse
      const studentsEndpoint = '/students/getallieviclasseSummary/'+classId;
      const studentsResp = await axios.get(studentsEndpoint, {
        cancelToken: cancelRequest.token
      });
      // Got Student list, extract info fo
      // console.info('Students: ', studentsResp.data);
      const studentData = studentsResp.data.find(s => s.IdAllievo === drillParams.idAllievo);
      // console.info('Student: ', student);

      setStudent(studentData);

    } catch (e) {
      errorManager(e, 'Non è stato possibile caricare i dati di questo studente. Riprovare più tardi. Grazie');
    }
  }

  // Show image in shadow (for wrong question stat)
  const showingImage = (path) => {
    showImage(path);
  }

  return (
    <Animated.View
      style={[
        drillPanelStyles.panelW,
        {
          opacity: slideX,
          transform: [
            {
              translateX: slideX.interpolate({
                inputRange: [0, 1],
                outputRange: ['100%', '0%']
              })
            }
          ],
        }
      ]}
    >
      <ScrollView style={drillPanelStyles.panelInner}>
        <View style={drillPanelStyles.header}>
          <Text style={drillPanelStyles.headerTitle}>{drillParams? drillParams.title : ''}</Text>
          <TouchableOpacity
            onPress={() => close()}
          >
            <Image style={drillPanelStyles.closeIcon} size={20} source={require('../../commons/images/x_dark.svg')}/>
          </TouchableOpacity>
        </View>

        {
          student?
            <View>
              <StudentDetail
                hidePeriodSelector
                student={student}
                currentClass={currentClass} // Pass class object
                showImage={(path) => showingImage(path)}
                hasScroll={hasScroll}
              />
            </View>
            :
            <ActivityIndicator size={'small'} />
        }
      </ScrollView>
    </Animated.View>
  )
}
