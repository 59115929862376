import EStyleSheet from 'react-native-extended-stylesheet';
// import {Platform, Dimensions} from 'react-native';
// const {width, height} = Dimensions.get('window');
EStyleSheet.build();

export const Colors = {
  // Layout
  primaryColor: '#2f549f',
  background: '#2f2f2f',
  textLight: '#d9d9d9',
  textDark: '#434343',
  border: '#404040',
  //Navigation
  navBg: '#c0c0c0', // New color from egaf
  navBar: '#272727',
  navBarTitle: '#ffffff',
  tabNormal: '#696969',
  tabSelected: '#404040',
  //Buttons
  btnBg: '#2f549f',
  btnText: '#d9e3f0',
  btnHeaderBg: '#585858',
  btnHeaderText: '#ffffff',
  confirm: '#51a940',
  reject: '#bf393c',
  // Inputs
  inputBg: '#3b3b3b',
  inputDisabledBg: '#505050',
  // Correct/wrong
  correct: '#3b8524',
  wrong: '#9c1e13',
  middleWrong: '#a44e4e',
  middleCorrect: '#749568',
};

export const Defaults = {
  spacing: 20,
  spacing2: 30,
  spacing3: 40,
  padder: 15,
  title1Size: 20
};

export const styles = EStyleSheet.create({
  container: {
    flex: 1,
  },
  activityIndicatorContainer:{
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionContainer: {
    flex: 1,
    backgroundColor: Colors.background,
    borderTopWidth: 15,
    borderLeftWidth: 15,
    borderRightWidth: 15,
    borderBottomWidth: 0,
    borderColor: '#5c5c5c', // New Egaf color, used to match selected tab
  },
  topHeaderBg: {
    backgroundColor: Colors.navBg,
    flex: 1
  },
  topHeaderWrapper: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topHeaderCompleteWrapper: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topActionBtns: {
    flexDirection: 'row',
    // height: '100%',
  },
  // actionEnabledContainer:{
  //   width: '50%'
  // },
  footer: {
    backgroundColor: '#5c5c5c',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  footerText: {
    color: Colors.textLight
  },
  link: {
    color: Colors.textLight,
    textDecorationLine: "underline"
  },
  scrollView: {
    paddingRight: 8
  },

  // Typography
  title1: {
    fontSize: Defaults.title1Size,
    marginBottom: Defaults.spacing,
    maxWidth: '100%'
  },
  sectionText: {
    color: Colors.textLight,
    fontSize: 16
  },
  loginTextInput: {
    width: '100%',
    backgroundColor: '#696969',
    color: '#ffffff',
    borderRadius: 3,
    paddingTop: 14,
    paddingRight: 10,
    paddingBottom: 14,
    paddingLeft: 10,
    marginBottom: Defaults.spacing
  },
  loginErrorMessage:{
    color: '#ffffff',
    marginTop: 20,
    fontSize: 20
  },
  label:{
    color: '#ffffff',
    marginBottom: 5
  },
  textInput: {
    // width: '100%',
    backgroundColor: Colors.inputBg,
    color: '#ffffff',
    borderRadius: 30,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    marginBottom: Defaults.spacing
  },
  textInputDisabled: {
    fontSize: 15,
    fontWeight: '100',
    color: '#d7d7d7',
    paddingTop: 0,
    marginBottom: 15
  },
  textArea:{
    width: '100%',
    backgroundColor: Colors.inputBg,
    color: '#ffffff',
    borderRadius: 20,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    marginBottom: Defaults.spacing
  },
  selectWrapper:{
    backgroundColor: '#d5d5d5',
    borderRadius: 30,
    height: 30,
    marginBottom: Defaults.spacing,
    paddingLeft: 7,
    paddingRight: 7,
    justifyContent: 'center',
    // marginRight: Defaults.spacing
    // test bg image traingle for select
  },
  select: {
    minWidth: 220,
    // backgroundColor: 'transparent',
    backgroundColor: 'transparent',
    color: Colors.textDark,
    borderWidth: 0,
  },
  actionSelectWrapper:{
    backgroundColor: '#353535',
    borderRadius: 30,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    justifyContent: 'center',
    marginRight: Defaults.spacing
  },
  actionSelect: {
    minWidth: 220,
    backgroundColor: 'transparent',
    color: Colors.textDark,
    borderWidth: 0,
  },

  // Utility
  rowContainer: {
    flexDirection: 'row'
  },
  alignLeft: {
    justifyContent: 'flex-start'
  },
  alignCenter: {
    justifyContent: 'center'
  },
  alignRight: {
    justifyContent: 'flex-end'
  },
  alignMiddle: {
    alignItems: 'center'
  },
  grow:{
    flexGrow: 1
  },

  noMarginB:{
    marginBottom: 0
  },
  noPaddingLR:{
    paddingLeft: 0,
    paddingRight: 0
  },
  textLight: {
    color: Colors.textLight,
    // fontWeight: '300'
  },
  textDark: {
    color: Colors.textDark
  },

  // Buttons
  newBtnHeader: {
    color: '#606060',
    fontSize: 14,
    fontWeight: '700',
    textTransform: 'uppercase',
    borderBottomWidth: 1,
    borderBottomColor: '#606060',
    borderBottomStyle: 'solid',
    marginRight: 20,
    marginLeft: 20,
  },
  newBtnHeaderDisabled: {
    color: '#cccccc',
    borderBottomColor: '#cccccc',
  },

  buttonHeader:{
    maxWidth: 150,
    backgroundColor: Colors.btnHeaderBg,
    color: Colors.btnHeaderText,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    borderRadius: 30,
    marginRight: 10,
    marginLeft: 10
  },
  buttonHeaderDisabled:{
    backgroundColor: '#313131',
    color: '#b2b2b2',
  },
  buttonsHeaderAlignmentRight: {
    marginRight: 30,
  },
  buttonHeaderSmall:{
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
    fontSize: 10,
    marginLeft: 5,
    textTransform: 'uppercase'
  },
  roundBtn: {
    backgroundColor: '#f1f1f1',
    width: 37,
    height: 37,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 5
  },

  roundBtnDisabled:{
    opacity: 0.1,
  },
  btnText: {
    color: '#ffffff'
  },
  btnToolbar: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cancelBtn: {
    backgroundColor: Colors.inputBg,
    width: '35%',
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmBtn: {
    backgroundColor: Colors.btnBg,
    width: '35%',
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',

  },
  actionBtn: {
    backgroundColor: Colors.btnBg,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 25,
    paddingRight: 25,

  },
  actionBtnDisabled: {
    backgroundColor: '#343434',
  },
  actionBtnLabel: {
    color: Colors.textLight,
  },
  actionBtnLabelDisabled: {
    color: '#cccccc',
  },
  logOutW: {
    display: 'flex',
    flex: 1,
    // width: 60,

    marginRight: 35,
    marginLeft: 25,
    // height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logOutIcon: {
    tintColor: '#606060',
    width: 20,
    height: 20,
  },
  portalBtn: {
    color: '#ffffff',
    backgroundColor: Colors.primaryColor,
    borderRadius: 10,
    borderColor: '#ffffff'
    // paddingTop: 4,
    // paddingBottom: 4,
    // paddingLeft: 10,
    // paddingRight: 10
  },
  portalBtnText: {
    color: Colors.textLight
  },

  // Tab bar
  tabTextStyle: {
    color: '#ffffff',
    fontSize: 18,
    marginTop: 7,
    marginRight: 10,
    marginBottom: 7,
    marginLeft: 10,
  },
  tabBarOverlay: {
    position: 'absolute',
    width: '100%',
    height: 80,
    zIndex: 100000,
    left: 0,
    top: 0,
    backgroundColor: '#333333',
    opacity: 0.5
  },

  // Add remove widget
  addRemoveWidget:{
    marginBottom: 10,
    position: 'relative'
  },
  addRemoveWidgetOverlay: {
    position: 'absolute',
    // backgroundColor: '#434343',
    // opacity: 0.4,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10000,
  },
  itemRemovableWidget: {
    flexGrow: 1,
    backgroundColor: Colors.btnHeaderBg,
    color: Colors.btnHeaderText,
    borderRadius: 30,
    paddingTop: 5,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 5,
    fontSize: 14,
  },
  itemRowWidget: {
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  itemMinusBtnWidget:{
    backgroundColor: Colors.reject,
    width: '27px',
    height: '27px',
    borderRadius: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  itemMinusBtnTextWidget:{
    fontSize: 20,
    lineHeight: 20,
    color: Colors.textLight,
  },
  itemsAddListWidget: {
    flexGrow: 1,
    backgroundColor: '#d5d5d5',
    borderRadius: 30,
    height: 'auto',
    marginTop: 10,
    marginBottom: Defaults.spacing,
    justifyContent: 'center',
    paddingTop: 5,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 5,
    // fontSize: 14,
  },

  // Grid
  gridRow: {
    flexDirection: 'row',
  },
  gridBlock12: {
    // backgroundColor: '#353535',
    flexBasis: '100%',
    padding: 8,
    margin: 2,
    // marginRight: 0,
  },
  gridBlock4: {
    // backgroundColor: '#353535',
    flexBasis: '33%',
    padding: 5,
    margin: 2,
  },
  gridBlock6: {
    // backgroundColor: '#353535',
    flexBasis: '50%',
    padding: 5,
    margin: 2,
  },
  // Custom percentage
  gridBlock_25: {
    flexBasis: '25%',
    padding: 5,
    margin: 2,
  },
  gridBlock_30: {
    flexBasis: '30%',
    padding: 5,
    margin: 2,
  },
  gridBlock_33: {
    flexBasis: '33%',
    padding: 5,
    margin: 2,
  },
  gridBlock_40: {
    flexBasis: '40%',
    padding: 5,
    margin: 2,
  },
  gridBlock_50: {
    flexBasis: '50%',
    padding: 5,
    margin: 2,
  }
});

export const heightActionEnabledPercent = '88%';

export const actionStyles = EStyleSheet.create({
  // Action Container
  actionContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1
  },

  // LAYOUT
  // NOT ENABLED
  actionMaster: {
    // height: heightPercent
  },
  actionDetail: {
    opacity: 0,
    height: heightActionEnabledPercent
  },
  actionBar: {
    width: '100%',
    height: '12%',
  },
  // ENABLED
  // actionMasterActive: {
  //   // backgroundColor: '#ff0000',
  //   // width: '70%',
  //   height: heightPercent
  // },
  // actionDetailActive: {
  //   // backgroundColor: '#ffaa00',
  //   // width: '30%',
  //   height: heightPercent
  // },
  // actionBarActive: {
  //   // backgroundColor: '#0000ee',
  //   width: '100%',
  //   height: '10%',
  // },

  // STYLING
  // Inner element
  actionMasterInner: {
    height: '100%',

  },
  actionMasterInnerHeader: {
    flexDirection: 'row',
    paddingTop: Defaults.padder,
    paddingRight: Defaults.padder,
    paddingBottom: Defaults.padder,
    paddingLeft: Defaults.padder,
    // paddingTop: 0,
    // paddingBottom: Defaults.padder,
    borderBottomColor: Colors.border,
    borderBottomWidth: 2,
    marginBottom: Defaults.spacing2,
    zIndex: 10000,
  },
  actionDetailInner: {
    // backgroundColor: '#2b2b2b',
    height: '100%',
    borderLeftWidth: 10,
    borderLeftColor: Colors.border,
    paddingTop: Defaults.padder,
    paddingRight: Defaults.padder,
    paddingBottom: Defaults.padder,
    paddingLeft: Defaults.padder
  },
  actionDetailInnerHeader: {
    height: 55,
    paddingBottom: 10,
    marginBottom: Defaults.spacing,
    borderBottomColor: Colors.border,
    borderBottomWidth: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  actionDetailTitle: {
    color: '#ffffff',
    fontSize: Defaults.title1Size,
    lineHeight: Defaults.title1Size,
    fontWeight: '400',

  },

  actionBarInner:{
    backgroundColor: '#1f1f1f',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 15,
    paddingLeft: 15,
  },
  noBtn:{
    backgroundColor: Colors.reject
  },
  yesBtn: {
    backgroundColor: Colors.confirm
  },

  // Filters on prospects section
  filtersOpen: {
    // flexDirection: 'column',
    flex: 1,
    backgroundColor: '#1f1f1f',
    paddingTop: Defaults.padder,
    paddingLeft: Defaults.padder,
    paddingRight: Defaults.padder,
    // paddingBottom: Defaults.padder,
    marginTop: 15,
    marginLeft: -Defaults.padder,
    marginRight: -Defaults.padder,
    borderColor: Colors.border,
    borderTopWidth: 1,
  },
  filtersClosed: {
    display: 'none',
  },
  scrollableView: {
    paddingRight: 18,
  },
  barBtn: {
    backgroundColor: '#f1f1f1',
    // width: 40,
    // height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 5
  },
  closeBtn: {
    backgroundColor: Colors.primaryColor,
  },
  btnLabel: {
    color: Colors.textLight,
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    minWidth: '100px',
    textAlign: 'center',
  }
});


export const listStyles = EStyleSheet.create({
  // Lists
  listWrapper:{
    // backgroundColor: '#ff00ee',
    flex: 1,
    // height: '100%',
    paddingRight: Defaults.padder,
    paddingLeft: Defaults.padder,
    paddingBottom: 20,
  },
  headerWrapper: {
    backgroundColor: '#2c2c2c',
    // paddingTop: 6,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#4a4a4a'
  },
  headerBlock: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    borderRightWidth: 1,
    borderRightColor: '#363636',
    justifyContent: 'center',
  },
  'headerBlock:last-child': {
    borderRightWidth: 10,
  },
  listHeadingText: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: '600'
  },
  rowWrapper: {
    // backgroundColor: '#00ff11',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#4a4a4a'
  },
  'rowWrapper:hover': {
    backgroundColor: '#00ff11',
  },
  rowSelected: {
    backgroundColor: '#3b3b3b',
  },
  mainRow: {
    // flexGrow: 1,
    flex: 1,
    flexDirection: 'row',
    // alignItems: 'center'
  },
  rowBlock: {
    justifyContent: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 10,
    paddingLeft: 10,
    borderRightWidth: 1,
    borderRightColor: '#4a4a4a'
  },
  'rowBlock:last-child': {
    borderRightWidth: 10,
  },
  listText: {
    color: '#f1f1f1'
  },
  noResultText: {
    textAlign: 'center',
    fontSize: 18,
    color: '#f1f1f1'
  },
  // Selector block
  selectorBlock: {
    // flexBasis: 40,
  },

  // Stat wrapper
  statWrapper: {
    flexDirection: 'row',
  },
  statTexts: {
    marginLeft: 10,
  },
  statText: {
    fontSize: 12,
  },
  // Blocks for 2 columns
  block1_2: {
    flexBasis: '40%',
  },
  block2_2: {
    flexBasis: '40%',
    flexGrow: 1,
    borderRightWidth: 0
  },
  // Blocks for 3 columns
  block1_3: {
    flexBasis: '30%',
  },
  block2_3: {
    flexBasis: '30%',
    // flexGrow: 1,
  },
  block3_3: {
    flexBasis: '40%',
    // flexGrow: 1,
    borderRightWidth: 0
  },
  // Blocks for 4 columns
  block1_4: {
    flexBasis: '40%',
    flexGrow: 1
  },
  block2_4: {
    flexBasis: '35%'
  },
  block3_4: {
    flexBasis: '13%'
  },
  block4_4: {
    flexBasis: '12%',
  },
  // Blocks for 5 columns
  block1_5: {
    flexBasis: '20%',
    flexGrow: 1
  },
  block2_5: {
    flexBasis: '22%'
  },
  block3_5: {
    flexBasis: '22%'
  },
  block4_5: {
    flexBasis: '12%',
  },
  block5_5: {
    flexBasis: '12%',
    flexShrink: 1,
    borderRightWidth: 0
  },
  // Blocks for 6 columns
  block1_6: {
    flexBasis: '20%',
    flexGrow: 1
  },
  block2_6: {
    flexBasis: '22%'
  },
  block3_6: {
    flexBasis: '22%'
  },
  block4_6: {
    flexBasis: '12%',
  },
  block5_6: {
    flexBasis: '12%',
  },
  block6_6: {
    flexBasis: '12%',
    flexShrink: 1,
    borderRightWidth: 0
  },
  // Blocks for 7 columns
  block1_7: {
    flexBasis: '20%',
    // flexGrow: 1
  },
  block2_7: {
    flexBasis: '20%'
  },
  block3_7: {
    flexBasis: '12%'
  },
  block4_7: {
    flexBasis: '12%',
  },
  block5_7: {
    flexBasis: '12%',
  },
  block6_7: {
    flexBasis: '12%',
  },
  block7_7: {
    flexBasis: '12%',
    borderRightWidth: 0
  },

  // Blocks for 8 columns
  block1_8: {
    flexBasis: '20%',
    // flexGrow: 1
  },
  block2_8: {
    flexBasis: '20%'
  },
  block3_8: {
    flexBasis: '10%'
  },
  block4_8: {
    flexBasis: '10%',
  },
  block5_8: {
    flexBasis: '10%',
  },
  block6_8: {
    flexBasis: '10%',
  },
  block7_8: {
    flexBasis: '10%',
  },
  block8_8: {
    flexBasis: '10%',
    flexShrink: 1,
    borderRightWidth: 0
  },
  // Blocks for 9 columns
  block1_9: {
    flexBasis: '20%',
    flexGrow: 1
  },
  block2_9: {
    flexBasis: '22%'
  },
  block3_9: {
    flexBasis: '22%'
  },
  block4_9: {
    flexBasis: '12%',
  },
  block5_9: {
    flexBasis: '12%',
  },
  block7_9: {
    flexBasis: '12%',
    flexShrink: 1,
  },
  block8_9: {
    flexBasis: '12%',
    flexShrink: 1,
  },
  block9_9: {
    flexBasis: '12%',
    flexShrink: 1,
    borderRightWidth: 0
  },


  editableContainer: {

  },
  checkbox: {
    width: 16,
    height: 16,
    borderColor: '#606060',
    borderWidth: 1,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkboxSelected: {
    width: 10,
    height: 10,
    borderWidth: 0,
    borderRadius: 2,
    backgroundColor: '#ffffff',
  },
  selectionBox: {
    width: 22,
    height: 22,
    borderColor: '#606060',
    borderWidth: 1,
    borderRadius: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectionboxSelected: {
    width: 16,
    height: 16,
    borderWidth: 0,
    borderRadius: '100%',
    backgroundColor: '#ffffff',
  },
  selectionBoxSmall: {
    width: 16,
    height: 16,
    borderColor: '#606060',
    borderWidth: 1,
    borderRadius: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectionboxSelectedSmall: {
    width: 12,
    height: 12,
    borderWidth: 0,
    borderRadius: '100%',
    backgroundColor: '#ffffff',
  },
  indicatorOff: {
    width: 15,
    height: 15,
    borderRadius: 15,
    backgroundColor: '#343434'
  },
  indicatorOn: {
    width: 15,
    height: 15,
    borderRadius: 15,
    backgroundColor: '#68a64e'
  }

});

export const tooltipStyles = EStyleSheet.create({
  tooltipW: {
    position: 'relative',
  },
  tooltip: {
    position: 'absolute',
    bottom: -5,
    right: 0,
    backgroundColor: '#171717',
    borderRadius: 3,
    paddingTop: 5,
    paddingRight: 8,
    paddingBottom: 5,
    paddingLeft: 8,
    opacity: 0.8,
    transform: [
      {translateY: '100%'},
    ],
    // marginTop: 12,
  },
  tooltipText: {
    fontSize: 13,
    color: Colors.textLight,
  },
  tooltipArrow: {
    width: 12,
    height: 12,
    position: 'absolute',
    top: -10,
    right: 15
  }
});


export const drillPanelStyles = EStyleSheet.create({
  panelW: {
    position: 'absolute',
    height: '100%',
    backgroundColor: '#313131',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,

  },
  panelWScrollable: {
    overflow: 'auto'
  },
  panelInner: {
    // height: '100%',
    padding: 15,
    // borderTopWidth: 10,
    // borderTopColor: '#ffffff',
    // borderStyle: 'solid'
  },
  // panel top header with title and close icon
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10,
  },
  headerTitle: {
    color: '#343434',
  },
  closeIcon: {
    width: 32,
    height: 32,
  },
  recapText: {
    paddingTop: 4,
    paddingBottom: 4,
    color: '#ffffff'
  },
  recapTextBold: {
    fontWeight: 'bold',
    marginRight: 6
  },
  recapRow: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  }
});
