import EStyleSheet from 'react-native-extended-stylesheet';
import {Colors, Defaults} from "../foundation/styles";

const styles = EStyleSheet.create({


  searchTextInput: {
    minWidth: 200,
    maxWidth: 260,
    backgroundColor: '#272727',
    color: '#ffffff',
    borderRadius: 30,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    marginRight: 7
  },
  filterBtn: {
    // backgroundColor: Colors.btnBg,
    backgroundColor: '#f1f1f1',
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 5
  },
  filterBtnActive: {
    backgroundColor: Colors.btnBg,
  },
  filterWrapper: {
    marginRight: 15
  },
  topFiltersMainWrapper: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  },
  topFiltersWrapper: {
    backgroundColor: '#272727',
    borderRadius: 30,
    paddingLeft: 15,
    paddingRight: 15,
    marginRight: 7,
    justifyContent: 'center'
  },
  topCourseFiltersWrapper: {
    // borderRadius: 30,
    // paddingLeft: 15,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 0,
    paddingRight: 15,
    paddingTop: 4,
    paddingBottom: 4,
    marginBottom: 15,
  },
  topCourseFiltersItems: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  courseFilterItem: {
    marginRight: 25,
    marginBottom: 8,
    // flex: 1,
  },
  topFilter: {
    paddingTop: 3,
    paddingBottom: 3,
    justifyContent: 'center'
  },
  topFilterMainLabel: {
    display: 'flex',
    flex: 1,
    fontSize: 14,
    fontWeight: '600',
    marginRight: 7,
    color: Colors.textLight,
    alignItems: 'center'
  },
  topFilterLabel: {
    display: 'flex',
    fontSize: 14,
    color: Colors.textLight,
    marginRight: 7,
    // justifyContent: 'center',
    alignItems: 'center'
  },
  topFilterSeparator: {
    paddingRight: 6,
    marginRight: 6,
    borderRightWidth: 1,
    borderRightColor: '#464646',
  },
  advFiltersWrapper: {
    // justifyContent: 'space-between'
    justifyContent: 'flex-start'
  },
  removeFiltersW: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  removeFiltersBtn: {
    maxWidth: 180,
    backgroundColor: Colors.btnBg,
    borderRadius: 30,
    height: 30,
    marginBottom: Defaults.spacing,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'center',
    alignItems: 'center',

  },
  schoolTitle:{
    fontSize: 15,
    marginTop: 25,
    marginBottom: 10,
    color: '#ffffff',
    fontWeight: 'bold',
  },
  courseTitle:{
    color: '#a7a7a7',
    fontSize: 12,
    marginBottom: 6
  }
});

export default styles;
