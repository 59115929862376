import React from 'react';
import { View } from 'react-native';
import UserContext from "../../user";
import {styles} from "../../foundation/styles";
import statsStyles from "../../section_statistics/styles";
import SegmentedControlTab from "react-native-segmented-control-tab";
import Topics from './topics';
import Results from './results';
import WrongQuestions from './wrong_questions';



class StudentStatsIndex extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  // TABS
  handleTabChange (index) {
    this.setState({tabIndex: index });
    this.props.onTabChange(index);
  };

  showImage(path) {
    this.props.showImage(path);
  }

  renderSelectedTab() {

    let tabComponent = null;

    switch (this.state.tabIndex) {

      case 0: {
          tabComponent = <Topics
            topicDrillDown={(idLesson, title)=>this.props.topicDrillDown(idLesson, title)}
            period={this.props.period}
            student={this.props.student}
            class={this.props.classItem}
            showImage={(path) => this.showImage(path)}
          />
        break;
      }

      case 1: {
          tabComponent = <Results
            period={this.props.period}
            student={this.props.student}
            class={this.props.classItem}
            resultsDrillDown={(idQuestionnaire, IdAllievo)=>this.props.resultsDrillDown(idQuestionnaire, IdAllievo)}
          />
        break;
      }

      case 2: {
          tabComponent =  <WrongQuestions
            period={this.props.period}
            student={this.props.student}
            currentClass={this.props.classItem}
            showImage={(path) => this.showImage(path)}
          />
        break;
      }

      default:
          tabComponent = <Topics period={this.props.period} student={this.props.student} class={this.props.classItem}/>
        break;
    }
    return tabComponent;
  }

  render() {

    return(
      <View style={styles.container}>
        <View style={[styles.container, ]}>
          <View style={{marginBottom: 10}}>
            <SegmentedControlTab
              key={'stats-tabs-control'}
              values={['Argomenti', 'Simulazioni d\'esame', 'Domande sbagliate' ]}
              selectedIndex={this.state.tabIndex}
              onTabPress={(index) => this.handleTabChange(index)}
              borderRadius={0}
              tabsContainerStyle={statsStyles.tabsContainerStyle}
              tabStyle={statsStyles.tabStyle}
              activeTabStyle={statsStyles.activeTabStyle}
              tabTextStyle={statsStyles.tabTextStyle}
              activeTabTextStyle={statsStyles.activeTabTextStyle}
              />
          </View>
          {this.renderSelectedTab()}
        </View>
      </View>
    );

  }

}


export default StudentStatsIndex;
