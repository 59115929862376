import axios from "axios";



const errorManager = (e, customMsg=null) => {
  console.info('ERROR MANAGER e: ', e);

  const genericMsg = 'Si è verificato un errore, riprovare più tardi.';
  const sessionExpiredMsg = 'La tua sessione è scaduta, effettua di nuovo il login.';
  const msg = customMsg? customMsg : genericMsg;

  if (axios.isCancel(e)) {
    console.info('request canceled');
  } else {
    if (e.response.status === 403){
      alert(sessionExpiredMsg);
    }
    else{
      alert(msg + "(Errore " + e.response.status +")");
    }
  }
};

export default errorManager;
