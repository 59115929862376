import React, {useContext, useEffect, useRef, useState} from "react";
import {View, Text, FlatList, ActivityIndicator, TouchableOpacity, Image, Animated, ScrollView} from 'react-native';
import {drillPanelStyles, styles} from '../../foundation/styles';
import statsStyles from '../../section_statistics/styles';
import UserContext from "../../user";
import axios, {CancelToken} from "../../commons/axios";
import {EgafStackedChart} from "../../foundation/widgets";
import Moment from 'moment';
import errorManager from "../../commons/error_manager";
import {ResultsAccordion} from "../../section_statistics/statistics/results";
import {WrongCorrectimage} from "../../section_statistics/statistics/wrong_questions";
import {getFormattedDate} from "../../commons/utils";
import {WrongQuestion} from '../../section_statistics/statistics/wrong_questions';


export default class Results extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Cancel Token for topics request
    this.source = CancelToken.source();

    this.period = props.period;

    this.state = {
      loading: true,
      statsData: [],
      resultLength: 0
    }
  }

  async loadStats() {

    const currentClass = this.context.classes[this.context.showingStudentsOfClassIndex];

    try {
      // https://autoscuole.egaf.it/statistics/?stat=statAndamentoQuest_AllievoClasseOpera&idClasse=8&opera=4
      const statsResp = await axios.get("statistics/", {
        cancelToken: this.source.token,
        params: {
          stat: 'statAndamentoQuestSingolo_AllievoClasseOpera',
          idClasse: currentClass.idclasse,
          idAllievo: this.props.student.IdAllievo,
          opera: this.context.currentCourse.opera,
          giorni: this.period
        }
      });

      console.info('statsResp for tab STUDENT RESULTS', statsResp.data);

      const coursesGraphData = [];
      for (const courseData of statsResp.data) {
        coursesGraphData.push({
          key: courseData.id,
          title: courseData.descrizione,
          graphData: courseData.stat.length? this._processRawData(courseData) : null,
          info: courseData.info ? courseData.info : null
        });
      }

      this.setState({
        loading: false,
        statsData: coursesGraphData,
        resultLength: statsResp.data[0].stat.length,
      });
    } catch (e) {
      errorManager(e, 'Non è stato possibile processare le statistiche nel periodo scelto. Riprovare più tardi. Grazie');
    }
  }

  // Process data foreach course { description: 'fdsfsf', stat: [...] }
  _processRawData(graph) {
    const data = {
      labels: [],
      datasets: [],
      idMapping: [],
      errorsAvg: '',
      legend: []
    }

    console.info('_processRawData(CLASS SECTION)', graph);

    // Set up datasets based on first questionary
    let datasets = [];
    let errorsAvg = [];

    datasets.push({
      label: 'Giuste',
      backgroundColor: [],
      hoverBackgroundColor: [],
      borderWidth: 0,
      data: [],
      questionsNumbers: []
    });

    // Populating labels and dataset with values
    graph.stat.forEach(stat => {
      data.labels.push(Moment(stat.QU_DATA).format('DD/MM/YYYY'));
      // data.labels.push(stat.QU_DATA);
      datasets[0].data.push(stat.QU_NG);
      datasets[0].backgroundColor.push(stat.ESITO_COLOR);
      const questionsTotalNumber = stat.QU_TOTALE_DOMANDE;
      datasets[0].questionsNumbers.push(questionsTotalNumber);
      // popoulate this with errors to calculate avg
      errorsAvg.push(questionsTotalNumber - parseInt(stat.QU_NG));
      // Mapping all ids
      data.idMapping.push(stat.IDQUESTIONARIO);
    })

    data.datasets = datasets;
    // Calculating avg
    const avg = errorsAvg.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / errorsAvg.length;
    data.errorsAvg = avg.toFixed(1);
    data.legend = graph.info;

    return data;
  }

  componentDidMount() {
    this.loadStats();
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.student !== this.props.student) {
      this.setState({
        loading: true,
      });
      this.loadStats();
    }

    if (prevProps.period !== this.props.period) {
      this.period = this.props.period;
      this.setState({
        loading: true,
      });
      this.loadStats();
    }
  }

  showQuestions(id) {
    console.info('METHOD SHOWING QUESTIONS!!!');
    console.info('ID QUESTIONARIO in showQuestions', id);
    console.info('ID ALLIEVO in showQuestions', this.props.student.IdAllievo);
    this.props.resultsDrillDown(id, this.props.student.IdAllievo);
  }

  render() {

    if (this.state.loading) {
      return (
        <View style={statsStyles.statContainer}>
          <ActivityIndicator/>
        </View>
      )
    } else {
      return (
        <View style={styles.container}>
            {
              this.state.statsData.map((data, index)=>{
                let mappedColors = null;
                // if (data.graphData) {
                //   mappedColors = {};
                //   for (const dataset of data.graphData.datasets) {
                //     mappedColors[dataset.label] = dataset.backgroundColor;
                //   }
                // }
                if (data.graphData && data.graphData.hasOwnProperty('legend')) {
                  mappedColors = {};
                  data.graphData.legend.forEach( l => {
                    mappedColors[l.title] = l['ESITO_COLOR'];
                  });
                }

                return (
                  <View key={data.key} style={{marginTop: 20}}>

                    {
                      data.graphData?
                        <ResultsAccordion
                          key={'result_'+index}
                          title={data.title}
                          open={index === 0}
                          isSingle={this.state.statsData.length === 1}
                          info={data.info}
                          mappedColors={mappedColors}
                        >
                          <Legend infos={data.graphData.legend} />
                          <Text style={statsStyles.avg}>Media errori periodo: {data.graphData.errorsAvg}</Text>
                          <EgafStackedChart
                            data={data.graphData}
                            isSingleStudent
                            showQuestions={(id) => this.showQuestions(id)}
                          />
                        </ResultsAccordion>
                        :
                        <ResultsAccordion
                          key={'result_'+index}
                          title={data.title}
                          open={index === 0}
                          isSingle={this.state.statsData.length === 1}
                          info={data.info}
                          mappedColors={mappedColors}
                        >
                          <Text style={statsStyles.noStatMessage}>Queste statistiche non sono ancora disponibili</Text>
                        </ResultsAccordion>

                    }
                  </View>
                )
              })
            }
        </View>
      );
    }
  }

  _renderListHeading() {
    return (
      <View
        style={styles.rowContainer}
      >
        <View style={[styles.rowContainer, {marginRight: 25}]}>
          <View style={statsStyles.correctLegend}/>
          <Text style={[styles.textLight,]}>giuste</Text>
        </View>

        <View style={styles.rowContainer}>
          <View style={statsStyles.wrongLegend}/>
          <Text style={[styles.textLight,]}>sbagliate</Text>
        </View>
      </View>
    );
  }
}


const Legend = ({infos}) => {
  return (
    <View style={statsStyles.legendW}>
      {
        infos.map((info, index) => {
          return (
            <View
              key={`legend-item-${index}`}
              style={statsStyles.legendItem}
            >
              <View style={[statsStyles.legendColor, {backgroundColor: info.ESITO_COLOR}]}>
              </View>
              <Text style={statsStyles.legendText}>{info.ESITO_LABEL}</Text>
            </View>
          )
        })
      }
    </View>
  )
}


export const ResultsDrillDownPanel = ({showDrillPanel, drillParams, close, hasScroll, showImage}) => {

  const cancelRequest = CancelToken.source();
  const [data, setData] = useState(null);
  // const slideX = new Animated.Value(0);
  const store = useContext(UserContext);
  const slideX = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    // Manage Animation
    Animated.timing(slideX, {
      toValue: showDrillPanel ? 1 : 0,
      duration: 400,
    }).start();

    // Manage data loading (only when panel is opening)
    if (showDrillPanel) {
      loadData();
    } else {
      setData(null);
    }

  }, [showDrillPanel]);

  const loadData = async () => {
    console.info('Drill PARAMSSSSS:', drillParams);
    try {
      // https://autoscuole.egaf.it/statistics/?stat=statDomandeSbagliate_AllievoOperaLezione&idAllievo=288818&idClasse=869&opera=3&idLezione=66&giorni=30
      const statsTopicsResp = await axios.get("statistics/", {
        cancelToken: cancelRequest.token,
        params: {
          stat: 'statDettaglioQuestJson',
          idAllievo: drillParams.idAllievo,
          opera: store.currentCourse.opera,
          idQuestionario: drillParams.idQuestionnaire
        }
      });

      setData(statsTopicsResp.data);
      console.info('DATA:', statsTopicsResp.data);

    } catch (e) {
      errorManager(e, 'Non è stato possibile processare queste statistiche. Riprovare più tardi. Grazie');
      setData([])
    }
  }

  const renderWrongQuestions = (data) => {
    debugger;
    if (data && data.length) {
      return (
        <View style={statsStyles.statContainer}>
          <FlatList
            key={'stat__wrong_questions'}
            data={data}
            renderItem={ ({item, index}) => _renderStatItem(item, index)}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      )
    } else {
      return (
        <View style={statsStyles.statContainer}>
          <Text style={statsStyles.noStatMessage}>Queste statistiche non sono ancora disponibili</Text>
        </View>
      )
    }
  }

  const _renderStatItem = (item, index) => {
    // return <WrongQuestion question={item} />;
    return <WrongQuestion question={item} showImage={(path) => showImage(path)}/>;
  }

  const getFormattedTime = (timeInSeconds) => {
    var date = new Date(null);
    date.setSeconds(timeInSeconds);
    return date.toLocaleTimeString('it-IT', {timeZone: 'UTC'})
  }

  return (
    <Animated.View
      style={[
        drillPanelStyles.panelW,
        hasScroll? drillPanelStyles.panelWScrollable : null,
        {
          opacity: slideX,
          transform: [
            {
              translateX: slideX.interpolate({
                inputRange: [0, 1],
                outputRange: ['100%', '0%']
              })
            }
          ],
        }
      ]}
    >
      {/*<ScrollView style={drillPanelStyles.panelInner}>*/}
        <View style={drillPanelStyles.header}>

          <Text style={drillPanelStyles.headerTitle}>{drillParams? drillParams.title : ''}</Text>
          <TouchableOpacity
            onPress={() => close()}
          >
            <Image style={drillPanelStyles.closeIcon} size={20} source={require('../../commons/images/x_dark.svg')}/>
          </TouchableOpacity>
        </View>

      <ScrollView style={drillPanelStyles.panelInner}>

      {
        data?
          <View>
            <View style={drillPanelStyles.recapRow}>
              <Text style={[drillPanelStyles.recapText, drillPanelStyles.recapTextBold]}>Data questionario:</Text>
              <Text style={drillPanelStyles.recapText}>{getFormattedDate(data.QU_DATA)}</Text>
            </View>
            <View style={drillPanelStyles.recapRow}>
              <Text style={[drillPanelStyles.recapText, drillPanelStyles.recapTextBold]}>Durata:</Text>
              <Text style={drillPanelStyles.recapText}>{getFormattedTime(data.QU_TEMPOIMPIEGATO)}</Text>
            </View>
            <View style={drillPanelStyles.recapRow}>
              <Text style={[drillPanelStyles.recapText, drillPanelStyles.recapTextBold]}>Giuste:</Text>
              <Text style={drillPanelStyles.recapText}>{data.QU_NG}</Text>
            </View>
            <View style={drillPanelStyles.recapRow}>
              <Text style={[drillPanelStyles.recapText, drillPanelStyles.recapTextBold]}>Sbagliate:</Text>
              <Text style={drillPanelStyles.recapText}>{data.QU_NS}</Text>
            </View>
            <View style={drillPanelStyles.recapRow}>
              <Text style={[drillPanelStyles.recapText, drillPanelStyles.recapTextBold]}>La colonna VF riporta la risposta giusta</Text>
            </View>
          </View>
          :
          null
      }

        <View>
          {
            data?
              renderWrongQuestions(data.domande)
              :
              <ActivityIndicator size={'small'} />
          }
        </View>

      </ScrollView>
    </Animated.View>
  )

}
