import React, {useState, useEffect} from 'react';
import {View, Text, Animated, Image, TouchableOpacity} from 'react-native';
import {actionStyles, Colors, styles, tooltipStyles} from '../foundation/styles';
import { EgafSelectionCheck} from '../foundation/widgets';
import {NavigationActions} from "react-navigation";
import homeStyles from './styles';
import UserContext from '../user'
import {Observer} from 'mobx-react';
import axios from "../commons/axios";


export class UnassignedUser extends React.Component{

  static contextType = UserContext;

  static navigationOptions = {
    headerLeft: null,
  };

  async logout() {
    const logoutResponse = await axios.post("logout/", {
      refreshToken: this.context.refreshToken
    });
    // console.info('LOGOUT', logoutResponse);
    if (logoutResponse.data.success) {
      this.context.token = null;
      this.context.refreshToken = null;
      this.props.navigation.pop();
    }
  };

  render() {
    return (
      <View style={homeStyles.container}>
        <View style={homeStyles.unassignedContainer}>
          <Text style={[styles.title1, {color: Colors.textLight, fontSize: 30}]}>Al momento il tuo utente ha un accesso limitato alla App.</Text>
          <Text style={[styles.title1, {color: Colors.textLight}]}>Contatta l’amministratore del sistema per risolvere il problema.</Text>

          <TouchableOpacity onPress={() => this.logout()} >
            <Text style={[styles.buttonHeader, styles.buttonsHeaderAlignmentRight]}>Logout</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}


export const UnassignedUserAction = NavigationActions.navigate({
  routeName: 'Unassigned',
  params: {isAuth: false}, // Pass here already selected cloud

});


// CLOUD choosing stuff

export const WizardCloudChooserAction = NavigationActions.navigate({
  routeName: 'WizardCloudChooser',
  params: {isAuth: false}, // Pass here already selected cloud

});


export class CloudChooserButton extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleOnMouseOver() {
    this.setState({
      showTooltip: true
    })
  }

  handleOnMouseOut() {
    this.setState({
      showTooltip: false
    })
  }

  chooseCloud = () => {
    this.props.navigation.navigate(WizardCloudChooserAction);
  };

  render() {
    return (
      <View
        style={styles.rowContainer}
        onMouseOver={()=>this.handleOnMouseOver()}
        onMouseOut={() => this.handleOnMouseOut()}
      >
        <Observer>
          {() => {
            if (this.context.hasMultiClouds) {

              if (this.context.showMainTopButtons) {
                return (
                  <View>
                    <TouchableOpacity onPress={() => this.chooseCloud()}>
                      <Text numberOfLines={1} style={[styles.newBtnHeader]}>
                        {this.context.currentCloud.nuvola}
                      </Text>
                    </TouchableOpacity>
                    {
                      this.context.currentCloud.nuvola.length > 20 ?
                        <Tooltip
                          show={this.state.showTooltip}
                          text={this.context.currentCloud.nuvola}
                        />
                        :
                        null
                    }
                  </View>
                )
              } else {
                return (
                  <View>
                    <Text numberOfLines={1}
                      style={[styles.newBtnHeader, styles.newBtnHeaderDisabled]}
                    >
                      {this.context.currentCloud.nuvola}
                    </Text>
                    {
                      this.context.currentCloud.nuvola.length > 20 ?
                        <Tooltip
                          show={this.state.showTooltip}
                          text={this.context.currentCloud.nuvola}
                        />
                        :
                        null
                    }
                  </View>
                );
              }

            } else {
              return null;
            }
          }
          }
        </Observer>
      </View>
    );
  }
}


// LOCATION choosing stuff

export const WizardSchoolChooserAction = NavigationActions.navigate({
  routeName: 'WizardLocationChooser',
  params: {isAuth: false},

});

export class SchoolChooserButton extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false
    }
  }

  chooseSchool() {
    this.props.navigation.navigate(WizardSchoolChooserAction);
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  handleOnMouseOver() {
    this.setState({
      showTooltip: true
    })
  }

  handleOnMouseOut() {
    this.setState({
      showTooltip: false
    })
  }

  render() {
    return (
      <View
        style={styles.rowContainer}
        onMouseOver={()=>this.handleOnMouseOver()}
        onMouseOut={() => this.handleOnMouseOut()}
      >

        <Observer>{() => {
          if (this.context.hasMultiLocations) {
            if (this.context.showMainTopButtons) {
              return (
                <View>
                  <TouchableOpacity onPress={() => this.chooseSchool()}>
                    <Text numberOfLines={1} style={[styles.newBtnHeader]}>
                      {this.context.currentLocation.RAGSOCIALE}
                    </Text>
                  </TouchableOpacity>
                  {
                    this.context.currentLocation.RAGSOCIALE.length > 20 ?
                      <Tooltip
                        show={this.state.showTooltip}
                        text={this.context.currentLocation.RAGSOCIALE}
                      />
                      :
                      null
                  }
                </View>
              );
            } else {
              return (
                <View>
                  <Text numberOfLines={1}
                    style={[styles.newBtnHeader, styles.newBtnHeaderDisabled,]}
                  >
                    {this.context.currentLocation.RAGSOCIALE}
                  </Text>
                  {
                    this.context.currentLocation.RAGSOCIALE.length > 20 ?
                      <Tooltip
                        show={this.state.showTooltip}
                        text={this.context.currentLocation.RAGSOCIALE}
                      />
                      :
                      null
                  }
                </View>
              );
            }
          } else {
            return null;
          }
        }
        }</Observer>

      </View>
    );
  }
}


// COURSE choosing stuff

export const WizardCourseChooserAction = NavigationActions.navigate({
  routeName: 'WizardCourseChooser',
  params: {isAuth: false},

});

export class CourseChooserButton extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  handleOnMouseOver() {
    this.setState({
      showTooltip: true
    })
  }

  handleOnMouseOut() {
    this.setState({
      showTooltip: false
    })
  }

  chooseCourse() {
    this.props.navigation.navigate(WizardCourseChooserAction);
  }

  render() {
    return (
      <View
        style={styles.rowContainer}
        onMouseOver={()=>this.handleOnMouseOver()}
        onMouseOut={() => this.handleOnMouseOut()}
      >
        <Observer>
          {
            () => {

              if (this.context.hasMultiCourses) {

                if (this.context.showMainTopButtons) {
                  return (
                    <View>
                      <TouchableOpacity onPress={() => this.chooseCourse()}>
                        <Text numberOfLines={1} style={[styles.newBtnHeader]}>
                          {this.context.currentCourse.corso}
                        </Text>
                      </TouchableOpacity>
                      {
                        this.context.currentCourse.corso.length > 20 ?
                          <Tooltip
                            show={this.state.showTooltip}
                            text={this.context.currentCourse.corso}
                          />
                          :
                          null
                      }

                    </View>
                  );
                } else {
                  return (
                    <View>
                      <Text numberOfLines={1}
                            style={[styles.newBtnHeader, styles.newBtnHeaderDisabled,]}>
                        {this.context.currentCourse.corso}
                      </Text>
                      {
                        this.context.currentCourse.corso.length > 20 ?
                          <Tooltip
                            show={this.state.showTooltip}
                            text={this.context.currentCourse.corso}
                          />
                          :
                          null
                      }
                    </View>

                  );
                }
              } else {
                return null;
              }
            }
          }
        </Observer>

      </View>
    );
  }
}


// Tooltip

const Tooltip = (props) => {
  const [value] = useState(new Animated.Value(props.show? 0 : 1));

  useEffect(()=>{
      Animated.timing(value, {
          toValue: props.show? 1 : 0,
          duration: 200,
          useNativeDriver: false
      }).start() // < Don't forget to start!
  }, [props.show]) // < Run animation only when props.value changed

  // Apply animated property to your style
  return (
      <Animated.View style={[tooltipStyles.tooltip, {opacity: value}]} >
        <Text numberOfLines={1} style={tooltipStyles.tooltipText}>{props.text}</Text>
        <Image size={20} style={tooltipStyles.tooltipArrow} source={require('../commons/images/triangle.svg')}/>
      </Animated.View>
  )

}


export const ChooserBar = (props) => {

  return (
    <View style={homeStyles.chooserBar}>
      <TouchableOpacity
        style={[styles.roundBtn, actionStyles.noBtn]}
        onPress={() => props.onReject()}
      >
        <Image size={25} style={{width: 30, height: 30}} source={require('../commons/images/x.svg')}/>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.roundBtn, actionStyles.yesBtn]}
        onPress={() => props.onConfirm()}
      >
        <Image size={25} style={{width: 30, height: 30}} source={require('../commons/images/check.svg')}/>
      </TouchableOpacity>
    </View>
  )
};


export const ListItem = (props) => {

  return (
    <TouchableOpacity
      style={homeStyles.listItem}
      onPress={() => props.onChoose(props.item)}
    >
      <EgafSelectionCheck isSelected={props.isCurrent}/>
      <Text style={homeStyles.listItemName}>{props.text}</Text>
    </TouchableOpacity>
  );

}
