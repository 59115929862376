import EStyleSheet from 'react-native-extended-stylesheet';
import {Colors, Defaults} from "../foundation/styles";


const styles = EStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#404040',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginWrapper: {
    backgroundColor: Colors.background,
    width: 340,
    paddingTop: 0,
    paddingRight: 15,
    paddingBottom: 35,
    paddingLeft: 15,
    alignItems: 'center'
  },
  loginLoader: {
    flexDirection: 'row',
    height :35,
    marginTop: 6,
    justifyContent: 'flex-end',
    width: '100%'
  },
  title: {
    color: '#ffffff',
    fontSize: 28,
    fontWeight: '600',
    marginBottom: 40
  },
  loginBtn: {
    maxWidth: 180,
    backgroundColor: Colors.btnBg,
    color: '#ffffff',
    fontSize: 24,
    fontWeight: '500',
    paddingTop: 15,
    paddingRight: 45,
    paddingBottom: 15,
    paddingLeft: 45,
    borderRadius: 50,
    marginTop: 30
  },
  dashboardWrapper:{
    flex: 1,
    flexDirection: 'row'
  },
  leftWrapper: {
    flexBasis: '21%',
    flexGrow: 1,
    // flex: 1,
    borderRightWidth: 2,
    borderColor: Colors.border
  },
  centralWrapper:{
    flexBasis: '58%',
    maxWidth: 1010,
    // width: 830,

    borderRightWidth: 2,
    borderColor: Colors.border
  },
  rightWrapper:{
    flexBasis: '21%',
    flexGrow: 1,
    // flex: 1,
  },
  // '@media (max-width: 1350)': { // media queries
  //   leftWrapper: {
  //     flexBasis: '21%',
  //     // flex: 1,
  //   },
  //   centralWrapper:{
  //     flexBasis: '58%',
  //   },
  //   rightWrapper:{
  //     flexBasis: '21%',
  //   },
  // },
  // '@media (min-width: 1600)': { // media queries
  //   leftWrapper: {
  //     flexBasis: '30%',
  //   },
  //   centralWrapper: {
  //     flexBasis: '40%',
  //   },
  //   rightWrapper:{
  //     flexBasis: '30%',
  //   },
  // },
  blockHeader: {
    flexDirection: 'column',
    borderBottomWidth: 2,
    borderColor: Colors.border,
    justifyContent: 'center',
    height: 72,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 10
  },
  blockInner: {
    flex: 1,
    padding: Defaults.padder
  },
  fakeMessage: {
    backgroundColor: '#434343',
    borderRadius: 50,
    height: 60,
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  fakeMessageDate: {
    color: '#ffffff',
    fontWeight: '500',
    fontSize: 13
  },

  chooserContainer: {
    flex: 1,
    backgroundColor: '#404040',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 30
  },

  chooserColWrapper: {
    flexDirection: 'row',
    flex: 1,
    width: '98%',
    justifyContent: 'space-around'
  },

  chooserThird: {
    backgroundColor: '#2f2f2f',
    flexBasis: '32%',
    paddingLeft: 15,
    paddingRight: 15
  },

  chooserThirdWrapper: {
    height: 'auto',
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 30,
  },
  chooserWrapper: {
    height: 'auto',
    width: '40%',
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 30,
    paddingRight: 20,
  },
  chooserBar: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 35,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: '#1f1f1f',
  },
  yesBtnDisabled: {
    opacity: 0.3
  },
  listItem: {
    backgroundColor: '#393939',
    borderRadius: 50,
    height: 60,
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  listItemName: {
    // textTransform: 'uppercase',
    // fontSize: 20,
    marginLeft: 20,
    color: '#ffffff',
  },

  // Unassigned user stuff
  unassignedContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },

  // Dashboard LEFT Area
  contactsWrapper: {
    marginBottom: 30,
  },
  contactTitleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  contactTitle: {
    color: '#ffffff',
    fontSize: Defaults.title1Size,
    marginBottom: 7,
  },
  contactTitleLink: {
    color: Colors.textLight,
    fontWeight: '700',
  },
  contactItem: {
    flexDirection: 'row',
    paddingTop: 12,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: Colors.border
  },
  contactItemLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  contactItemRight: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  contactItemText: {
    color: Colors.textLight
  },

  // Dashboard center area
  statsWrapper: {

  },
  statW: {

  },
  statsBlock: {

  }
});


export default styles;
