import axios from "axios";
import {LogoutAction} from "../navigator/actions";


const instance = axios.create({
  baseURL: "https://autoscuole.egaf.it",
  params: {
    server_name_egaf: window.location.hostname
  },
});

// Managing refreshing token
const createAxiosResponseInterceptor = (store, navigation) => {

  console.info('INTERCEPTING...');

  const interceptor = instance.interceptors.response.use(
    response => response,
    error => {

      // debugger;
      //
      // console.info('axios.isCancel(error);', axios.isCancel(error));
      // if (axios.isCancel(error)){
      //   return Promise.reject(error);
      // }


      // Intercepting Network error where response is undefined
      if (!error.response) {
        error.response = {
          status: 500
        };
        return Promise.reject(error);
        // dispatch navigation to login
      }

      // If forbidden request login
      if (error.response.status === 403) {
        return Promise.reject(error);
        // dispatch navigation to login
      }


      // Reject promise if usual error
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      instance.interceptors.response.eject(interceptor);

      return instance.post('refreshtoken/', {
        'refreshToken': store.refreshToken
      }).then(response => {
        store.token = response.data.authToken;
        error.response.config.headers['Authorization'] = 'Bearer ' + response.data.authToken;
        instance.defaults.headers.common["Authorization"] = "Bearer " + response.data.authToken;
        return instance(error.response.config);
      }).catch(error => {
        store.token = '';
        store.refreshToken = '';
        navigation.replace(LogoutAction);
        return Promise.reject(error);
      }).finally(createAxiosResponseInterceptor(store, navigation));
    }
  );
}


// Calling interceptor to refresh token when setting token as header
export const setAuthorizationHeader = (store, navigation) => {
  instance.defaults.headers.common["Authorization"] = "Bearer " + store.token;
  createAxiosResponseInterceptor(store, navigation);
}


// exporting instance
export default instance;


// exporting cancel Token to cancel requests
export const CancelToken = axios.CancelToken;
