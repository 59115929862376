import React from "react";
import {View, Text, ActivityIndicator, FlatList} from 'react-native';
import statsStyles from '../../section_statistics/styles';
import UserContext from "../../user";
import axios, {CancelToken} from "../../commons/axios";
import {WrongQuestion} from '../../section_statistics/statistics/wrong_questions';
import errorManager from "../../commons/error_manager";


export default class WrongQuestions extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Cancel Token for topics request
    this.source = CancelToken.source();

    this.period = props.period;

    this.state = {
      loading: true,
      statsData: []
    }
  }

  async loadStats() {

    const currentClass = this.context.classes[this.context.showingStudentsOfClassIndex];

    try {
      // https://autoscuole.egaf.it/statistics/?stat=statTopDomandeSbagliate_AllievoOpera&idAllievo=14564&idClasse=8&opera=3&giorni=180
      const statsStudentWrongResp = await axios.get("statistics/", {
        cancelToken: this.source.token,
        params: {
          stat: 'statTopDomandeSbagliate_AllievoOpera',
          idAllievo: this.props.student.IdAllievo,
          idClasse: currentClass.idclasse,
          opera: this.context.currentCourse.opera,
          giorni: this.period
        }
      });

      // console.info('statsResp for tab STUDENT WRONGQUESTIONS', statsStudentWrongResp.data);

      this.setState({
        loading: false,
        statsData: statsStudentWrongResp.data
      });
    } catch (e) {
      errorManager(e, 'Non è stato possibile processare le statistiche nel periodo scelto. Riprovare più tardi. Grazie');
    }
  }

  componentDidMount() {
    this.loadStats();
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.student !== this.props.student) {
      this.setState({
        loading: true,
      });
      this.loadStats();
    }

    if (prevProps.period !== this.props.period) {
      this.period = this.props.period;
      this.setState({
        loading: true,
      });
      this.loadStats();
    }
  }

  showImage(path) {
    this.props.showImage(path);
  }

  render() {

    if (this.state.loading) {
      return <View style={statsStyles.statContainer}>
        <ActivityIndicator/>
      </View>;
    } else {

      if (this.state.statsData.length ) {
        return (
          <View style={statsStyles.statContainer}>
            <FlatList
              key={'stat__wrong_questions'}
              data={this.state.statsData}
              renderItem={ ({item, index}) => this._renderStatItem(item, index)}
              keyExtractor={(item, index) => index.toString()}
            />
          </View>
        );
      } else {
        return (
          <View style={statsStyles.statContainer}>
            <Text style={statsStyles.noStatMessage}>Queste statistiche non sono ancora disponibili</Text>
          </View>
        );
      }

    }
  }

  _renderStatItem(item, index) {
    return <WrongQuestion question={item} showImage={(path) => this.showImage(path)}/>;
  }

}
