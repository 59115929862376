import React, { Component } from "react";
import {View} from 'react-native';
import Footer from './commons/footer';
import {styles} from './foundation/styles';
import UserContext from './user';
import MainNavigation from './navigator/navigation';
import EStyleSheet from "react-native-extended-stylesheet";
EStyleSheet.build();

const backup = console.warn;

console.warn = function filterWarnings(msg) {
  const supressedWarnings = ['Animated:', 'PanGestureHandler is not yet supported on web'];

  if (!supressedWarnings.some(entry => msg.includes(entry))) {
    backup.apply(console, arguments);
  }
};

class App extends Component {

  static contextType = UserContext;

  handleNavigationChange(prevState, newState, action) {
    const routesDisablingMainButtons = ['Configuration', 'Prospects', ];

    if (routesDisablingMainButtons.includes(action.routeName)){
      this.context.showMainTopButtons = false;
    } else {
      this.context.showMainTopButtons = true;
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.container}>
          <MainNavigation onNavigationStateChange={(prevState, newState, action)=>this.handleNavigationChange(prevState, newState, action)} />
        </View>
        <Footer />
      </View>
    )
  }

  componentDidMount() {}

  componentWillUnmount() {}
}

export default App;
