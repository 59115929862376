import React from "react";
import {View, Text, ActivityIndicator, FlatList, ScrollView} from 'react-native';
import {Colors, styles} from '../../foundation/styles';
import statsStyles from '../styles';
import UserContext from "../../user";
import axios, {CancelToken} from "../../commons/axios";
import {reaction} from "mobx";
import {QuizAnswersBarChart} from "../../foundation/widgets";
import classStyles from "../../section_classes/styles";
import errorManager from "../../commons/error_manager";
import {TouchableOpacity} from "react-native-web";
import {StudentDrillDownPanel} from "./drilldown_panels";


export default class TopTen extends React.Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    // Cancel Token for topics request
    this.source = CancelToken.source();

    this.state = {
      loading: true,
      statsData: []
    }
  }

  async loadStats() {
    // console.info('LOADING QUESTIONS STAT IN PERIOD: ', this.context.statsPeriod);
    try {
      // https://autoscuole.egaf.it/statistics/?stat=statTopAllievi_ClasseOpera&idClasse=8&opera=3&giorni=90
      const statsResp = await axios.get("statistics/", {
        cancelToken: this.source.token,
        params: {
          stat: 'statTopAllievi_ClasseOpera',
          idClasse: this.props.currentClass.idclasse,
          opera: this.context.currentCourse.opera,
          giorni: this.context.statsPeriod
        }
      });

      // console.info('statsResp for tab TOPTEN', statsResp.data);

      this.setState({
        loading: false,
        statsData: statsResp.data
      });
    } catch (e) {
      errorManager(e);

    }
  }

  componentDidMount() {
    this.loadStats();

    // Reaction to location changing
    this._statPeriodReactionDispose = reaction(
      () => this.context.statsPeriod,
      () => {
        this.setState({
          loading: true,
        });
        this.loadStats();
      }
    );
  }

  componentWillUnmount() {
    this._statPeriodReactionDispose();
    this.source.cancel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentClass !== this.props.currentClass) {
      this.setState({
        loading: true,
      });
      this.loadStats();
    }
  }

  drillBack() {
    this.setState({
      showDrillPanel: false
    });
  }

  openStudentDetail(item) {
    console.info('OPEN DETAIL FOR STUDENT', item);
    console.info('GET ALL STUDENT FOR CURRENT CLASS', this.props.currentClass.idclasse);
    ///students/getallieviclasse/
    this.setState({
      showDrillPanel: true,
      drillParams: {
        title: `${item.Nome} ${item.Cognome}`,
        idClasse: this.props.currentClass.idclasse,
        idAllievo: item.IdAllievo,
        // opera: this.context.currentCourse.opera,
        // period: this.period
      }
    });
  }

  render() {
    if (this.state.loading) {
      return <View style={statsStyles.statContainer}>
        <ActivityIndicator/>
      </View>;
    } else {
      return (
        <View style={statsStyles.statContainer}>
          {
            this.state.statsData.length ?
              <ScrollView style={styles.scrollView}>
                {/*{this._renderListHeading()}*/}
                <FlatList
                  // style={statsStyles.classListW}
                  key={'stat_questions'}
                  data={this.state.statsData}
                  renderItem={({item, index}) => this._renderStatItem(item, index)}
                  keyExtractor={(item, index) => index.toString()}
                />

              </ScrollView>
              :
              <Text style={statsStyles.noStatMessage}>Le statistiche per questa classe non sono ancora disponibili</Text>
          }
          <StudentDrillDownPanel
            showDrillPanel={this.state.showDrillPanel}
            close={() => this.drillBack()}
            drillParams={this.state.drillParams}
            currentClass={this.props.currentClass}
            showImage={(path)=>this.props.showImage(path)}
            hasScroll={false}
          />
        </View>
      );
    }
  }

  _renderListHeading() {
    return (
      <View
        style={statsStyles.top10Heading}
      >
        <View style={statsStyles.t10NameBlock}>
          <Text  style={[statsStyles.top10HeadingText]}>Nome</Text>
        </View>
        <View style={statsStyles.t10NameBlock}>
          <Text style={[statsStyles.top10HeadingText, statsStyles.textLeftPadder]}>Cognome</Text>
        </View>
        <View style={statsStyles.t10StatusBlock}>
          <Text style={[statsStyles.top10HeadingText, statsStyles.textLeftPadder]}>Stato</Text>
        </View>

      </View>
    );
  }

  _renderClassItemGraph(stat) {

    const wrongPercentage = stat.TOTALE ? stat.SBAGLIATE / stat.TOTALE : 0;
    return <QuizAnswersBarChart
      style={{paddingLeft: 10}}
      completed={wrongPercentage ? 1 : 0}
      wrong={wrongPercentage}
      height={15}
    />

  }

  _renderClassItemGraphValues(stat) {
    const wrongPercentage = stat.TOTALE ? stat.SBAGLIATE / stat.TOTALE*100 : 0;
    // const correctPercentage = stat.TOTALE? stat.GIUSTE/stat.TOTALE*100 : 0;
    const errors = wrongPercentage.toFixed();
    const corrects = errors? 100 - errors : 0;

    return (
      <View style={{paddingLeft: 7, paddingRight: 7, minWidth: 80}}>
        <Text style={[{color: Colors.textLight}, statsStyles.smallGraphText]}>Punti: {stat.PUNTI}</Text>
        <Text style={[classStyles.completedColor, statsStyles.smallGraphText]}>{corrects}%</Text>
        <Text style={[classStyles.errorColor, statsStyles.smallGraphText]}>{errors}%</Text>
      </View>
    )

  }

  _renderStatItem(item, index) {
    return (
      <TouchableOpacity
        style={statsStyles.questionItemW}
        onPress={()=>this.openStudentDetail(item)}
      >
        <View style={statsStyles.nameBlock}>
          <Text style={statsStyles.questionItemText} numberOfLines={2}>{item.Nome} {item.Cognome}</Text>

          <View style={statsStyles.questionGraphBlock}>
            {this._renderClassItemGraph(item)}
          </View>
        </View>

        <View style={statsStyles.questionValuesBlock}>
          {this._renderClassItemGraphValues(item)}
        </View>
      </TouchableOpacity>
    )
  }

  __renderStatItem(item, index) {
    return <View
      style={statsStyles.top10ItemW}
    >
      <View style={statsStyles.t10NameBlock}>
        <Text style={statsStyles.questionItemText} numberOfLines={1}>{item.Nome}</Text>
      </View>

      <View style={statsStyles.t10NameBlock}>
        <Text style={[statsStyles.questionItemText, statsStyles.textLeftPadder]} numberOfLines={1}>{item.Cognome}</Text>
      </View>

      <View style={statsStyles.t10StatusBlock}>
        <View style={styles.rowContainer}>
          {this._renderClassItemGraph(item)}
          {this._renderClassItemGraphValues(item)}
        </View>
      </View>
    </View>
  }

}
